import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const chatSearchAPI = function (data) {
    const url = `${config.baseUrl}/chat/search`;
    return postRequest({
        url,
        data,
    });
}; 
export const chatCreateAPI = function (data) {
    const url = `${config.baseUrl}/chat/save`;
    return postRequest({
        url,
        data,
    });
};
export const chatUpdateAPI = function ({
    chatId,
    data,
}) {
    const url = `${config.baseUrl}/chat/update/${chatId}`;
    return putRequest({
        url,
        data,
    });
};
