import React from 'react';
import Routings from './routes';
import NotificationProvider from './components/Notification/ToastNotification';
import { useEffect } from 'react';

const App = () => {
  // useEffect(() => {
  //   const disableRightClick = (e) => e.preventDefault();
  //   document.addEventListener("contextmenu", disableRightClick);
  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);
  return (
    <>
      <NotificationProvider>
        <Routings />
      </NotificationProvider>
    </>
  );
};

export default App;
