import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const RelationshipStatus = () => {
      const dispatch = useDispatch();
      const {
        currentRelationshipStatus,
      } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [relationship, setRelationship] = useState(currentRelationshipStatus||'');

  const relationshipOptions = [
    'Single',
    'In a Relationship', 
    'Married',
    'Divorced',
    'Widowed',
    'Prefer not to say',
  ];

  // const handleRelationship = (value) => {
  //   setRelationship(value);
  //   dispatch(setPayload({ data: { currentRelationshipStatus: value } }));
  // };

  
 const handleRelationship = (value) => {
  // If the same option is clicked again, unselect it
  const newValue = relationship === value ? "" : value;
  setRelationship(newValue);
  dispatch(setPayload({ data: { currentRelationshipStatus: newValue } }));
};

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">
          What is Your Current Relationship Status?
        </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Please let us know your relationship status to help us match you with
          compatible individuals.
        </div>
      </div>
      <div className="relationship-selection text-center mt-32 mb-16">
        {/* <h3 className="font-18 font-m fw-medium mt-24 mb-16">Choose Your Relationship Status</h3> */}
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render relationship status options */}
          {relationshipOptions.map((status) => (
            <button
              key={status}
              className={`font-m border font-16 fw-normal ${relationship === status ? 'selected' : ''}`}
              onClick={() => handleRelationship(status)}
            >
              {status}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelationshipStatus