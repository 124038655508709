import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { initConversationsListAction, setPendingAction } from 'src/containers/chat-container/actions/ChatActions';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import Fancybox from './Fancybox';
const UserProfileHeader = ({ userData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 const  [message, setMessage] = useState('');
  const handleChatClick = () => {
    const modifiedProfile = { ...userData, _id: userData?._id };
    dispatch(setPendingAction({data:{user:modifiedProfile,text:message}}));
    dispatch(initConversationsListAction({ data: [], roomId:null, selectedUser: modifiedProfile }));
    localStorage.removeItem('chatRoomId');
    navigate('/chat');
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && message.trim() !== '') {
      handleChatClick();
    }
  };
  return (
    <div className="user-profile-header gap-5 userDprofl">
      <div className='userDprofl d-flex gap-4'>
      <Link to="/members" className='arrlinkleft' style={{ padding: '0.6vw', color: '#000' }}>
                                  <ArrowBackIosNewOutlinedIcon />
                              </Link>
      {/* Profile Image */}
      {/* <img
        src={userData.profileImage}
        alt="Profile"
        className="user-profile-image"
      /> */}
      <div className='ProfileUserImg'>
      <Fancybox>
       <a data-fancybox="gallery" href={userData.profileImage}>
      <img
        src={userData.profileImage}
        alt="Profile"
        className="user-profile-image" 
      />
    </a>
    </Fancybox>
      </div>
      </div>

      {/* User Details */}
      <div className="user-profile-details">
        <div className="userheddtal">
          <h2 className='d-flex align-items-center gap-1'>
            {userData.userName}, {userData.age} {
              userData.isOnLine ? <span className="user-online-status"></span> : null
            } <span className="veryfiedIcnc wset001"><img src="/assets/images/icons/check-blue.svg" class="img-fluid" alt="" /></span>
          </h2>
          <p className="user-location mb-0">{userData.location}</p>
        </div>

        <div className="usersbhdtl">
          <p className="user-subheading mb-0">{userData.subheading}</p>
          <p className="user-description mb-0">{userData.description}</p>
        </div>

        {/* User Info Cards */}
        <div className="user-info-cards">
          {userData.infoCards.map((info, index) => (
            <div key={index} className="user-info-item">
              <span className="d-block">
                <img src={info.icon} className="img-fluid" alt={info.label} />
              </span>
              {info.value}
              <span className="font-12 font-m fw-normal color7b">{info.label}</span>
            </div>
          ))}
        </div>

        {/* Actions */}
        <div className="user-profile-actions">
          <input type="text" onChange={(e) => setMessage(e.target.value)} onKeyPress={handleKeyPress} placeholder={`Hi ${userData.userName}...`} />
          <button className="user-message-button" onClick={handleChatClick}>Message</button>
        </div>
      </div>
    </div>
  );
};

export default UserProfileHeader