import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import { Link } from "react-router-dom";
import { formatLastMessageTime, time_ago } from 'src/utils/CommonUtils';
import Fancybox from 'src/Component/Profile/user-profile/Fancybox'

const MessageList = ({ msg, userId, key }) => {
    return (
        <li key={key} className={msg.senderId !== userId ? "sender" : "repaly"}>
            <span className="time">{time_ago(msg.timestamp)}</span>
            <div className="message__html-content">
                {/* Conditional rendering based on messageType */}
                {msg.messageType === "image" && msg.files && msg.files.length > 0 && (
                    <Fancybox
                        options={{
                            Carousel: {
                                infinite: false, // Infinite scrolling option
                            },
                        }}
                    >
                        <div className={`uploaded-images ${msg.files.length === 1 ? "single-image" : ""}`}>
                            {msg.files.slice(0, 4).map((file, idx) => (
                                <div className="uploadImg" key={idx}>
                                    {idx === 3 && msg.files.length > 4 ? (
                                        <div className="overlay" data-fancybox="gallery" href={file.url}>
                                            <img src={file.url} alt={file.name} className="" />
                                            <div className="extra-count">+{msg.files.length - 4} More</div>
                                        </div>
                                    ) : (
                                        <a data-fancybox="gallery" href={file.url}>
                                            <img src={file.url} alt={file.name} className="" />
                                        </a>
                                    )}
                                </div>
                            ))}
                            {/* Add hidden links for all images so Fancybox includes them in the modal */}
                            {msg.files.length > 4 && (
                                <div style={{ display: "none" }}>
                                    {msg.files.slice(4).map((file, idx) => (
                                        <a key={idx} data-fancybox="gallery" href={file.url} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </Fancybox>
                )}

                {msg.messageType === "audio" && msg.files && msg.files.length > 0 && (
                    <div className="audio-message">
                        {msg.files.map((file, idx) => (
                            <audio key={idx} controls>
                                <source src={file.url} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        ))}
                    </div>
                )}
                {msg.messageType === "text" && (
                    // <span dangerouslySetInnerHTML={{ __html: msg.message.replace(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu, '<span class="emoji">$1</span>') }} />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: msg.message.replace(/([\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu, (match) => {
                                // Convert emoji to unicode hex code
                                const codePoint = match.codePointAt(0).toString(16);
                                return `<img class="emoji-img" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${codePoint}.png" alt="${match}" />`;
                            }),
                        }}
                    />
                )}
            </div>

            {msg.senderId === userId && (
                <div className="view-unview">
                    {msg.isDelivered ? (
                        <DoneAllIcon style={{ color: msg.isRead ? "blue" : "gray" }} />
                    ) : (
                        <DoneIcon />
                    )}
                </div>
            )}
        </li>
    )
}

export default MessageList;
