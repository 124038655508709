import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPayload } from "src/containers/signup-container/actions/SignupActions";

const SecurityQuestionSetup = () => {
  const dispatch = useDispatch();
  const { securityQuestions } = useSelector(
    (state) => state.SignupReducer.currentRecord,
    shallowEqual
  );

  const [availableQuestions] = useState([
    "What is your favorite color?",
    "What is your mother's maiden name?",
    "What was your first pet's name?",
    "What is your hometown?",
    "What is your first school?"
  ]);

  const [questions, setQuestions] = useState([
    { questionId: "1", question: "", answerHash: "" },
    { questionId: "2", question: "", answerHash: "" },
    { questionId: "3", question: "", answerHash: "" }
  ]);

  // Populate state from Redux if data exists
  useEffect(() => {
    if (securityQuestions?.length) {
      setQuestions(securityQuestions);
    }
  }, [securityQuestions]);

  // Update Redux store when all questions and answers are filled
  useEffect(() => {
    if (questions.every((q) => q.question && q.answerHash)) {
      dispatch(setPayload({ data: { securityQuestions: questions } }));
    }
    else {
      dispatch(setPayload({ data: { securityQuestions: [] } }));
    }
  }, [questions, dispatch]);

  const handleChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...updatedQuestions[index], [field]: value };
    setQuestions(updatedQuestions);
  };

  const getAvailableOptions = (currentIndex) => {
    const selectedQuestions = questions.map((q) => q.question);
    return availableQuestions.filter(
      (q) => q === questions[currentIndex].question || !selectedQuestions.includes(q)
    );
  };

  return (
    <div className="security-setup-container mw-570-px mx-auto btnsWithConnects">
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Set Up Your Security Question</div>
        <div className="font-m font-14 colorf5 pt-6 text-center">
          To help secure your account, please select three security questions and
          provide answers. This will be used to verify your identity in case you
          need to reset your password later.
        </div>
      </div>

      <form className="security-form pt-24">
        <div className="font-20 font-m fw-medium mb-16 text-center">Choose 3 Security Questions:</div>
        {questions.map((q, index) => (
          <div className="security-row d-flex gap-3" key={q.questionId}>
            <select
              className="form-select mb-10 w-100"
              value={q.question}
              onChange={(e) => handleChange(index, "question", e.target.value)}
              required
            >
              <option value="">Select Question {index + 1}</option>
              {getAvailableOptions(index).map((question, qIndex) => (
                <option key={qIndex} value={question}>
                  {question}
                </option>
              ))}
            </select>
            <input
              className="form-control mb-10"
              type="text"
              placeholder="Type your answer here"
              value={q.answerHash}
              onChange={(e) => {
                const alphaSpaceOnly = e.target.value.replace(/[^a-zA-Z ]/g, '');
                handleChange(index, "answerHash", alphaSpaceOnly);
              }}
              required
            />
          </div>
        ))}
      </form>
    </div>
  );
};

export default SecurityQuestionSetup;
