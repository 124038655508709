import React, { useEffect, useRef, useState } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterCheckboxList from "./FilterCheckboxList";
import HeightRangeSlider from "./HeightRangeSlider";
import FilterSingalSelect from "./FilterSingalSelect";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setFilterAction } from "src/containers/member-container/actions/MemberActions";

const ShowMembersSeeking = ({ handleCheckboxChange }) => {
  const dispatch = useDispatch();
  const { currentFilter } = useSelector((state) => state.MemberReducer, shallowEqual);
  // const [expanded, setExpanded] = useState(null);
  const [membersSeeking, setMembersSeeking] = useState(currentFilter.memberSeeking || []);
  const [hobbiesSeeking, setHobbiesSeeking] = useState(currentFilter.hobbies || []);
  const [expanded, setExpanded] = useState({});
  const childrenPanelRef = useRef(null); // Ref for "Children" panel
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded((prev) => ({
  //     ...prev,
  //     [panel]: isExpanded, // Toggle only the clicked accordion
  //   }));
  // };
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded((prev) => ({
  //     ...prev,
  //     [panel]: isExpanded, // Toggle only the clicked accordion
  //   }));

  //   // Scroll to the "Children" panel when expanded
  //   if (isExpanded && panel === "panel12" && childrenPanelRef.current) {
  //     setTimeout(() => {
  //       childrenPanelRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }, 100);
  //   }
  // };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: isExpanded, // Toggle only the clicked accordion
    }));
  
    // Scroll smoothly to the expanded accordion
    if (isExpanded) {
      setTimeout(() => {
        const panelElement = document.getElementById(`${panel}-header`);
        if (panelElement) {
          panelElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 100);
    }
  };
  
  const handleMembersSeekingSelection = (value) => {
    setMembersSeeking((prev) => {
      const updatedMembersSeeking = prev.includes(value)
        ? prev.filter((member) => member !== value) // Remove if already selected
        : prev.length < 20
          ? [...prev, value] // Add if under the limit
          : prev;
      dispatch(setFilterAction({ data: { membersSeeking: updatedMembersSeeking } }));

      return updatedMembersSeeking; // Update local state
    });
  };

  const handleHobbiesSeekingSelection = (value) => {
    setHobbiesSeeking((prev) => {
      const updatedHobbiesSeeking = prev.includes(value)
        ? prev.filter((hobbie) => hobbie !== value) // Remove if already selected
        : prev.length < 20
          ? [...prev, value] // Add if under the limit
          : prev;
      dispatch(setFilterAction({ data: { hobbies: updatedHobbiesSeeking } }));
      return updatedHobbiesSeeking
    })
  };

  useEffect(() => {
    setMembersSeeking(currentFilter.memberSeeking);
    setHobbiesSeeking(currentFilter.hobbies);
  }, [currentFilter.memberSeeking, currentFilter.hobbies])
  const accordionsData = [
    {
      panel: "panel1",
      name: "memberSeeking",
      title: "show member with luxe interest in",
      content: (
        <div className="btnsetAll btnsetAll2 btnsetAll3">
          {[
            "Fine Dining",
            "Luxury Travel",
            "Yachting",
            "Private Jets",
            "Art Collecting",
            "Wine Tasting",
            "Fashion & Design",
            "Exclusive Events",
            "Golf",
            "High-End Cars",
            "Wellness & Fitness",
            "Spa Retreats",
            "Gourmet Cooking",
            "Philanthropy",
            "Skiing/Snowboarding",
            "Collecting (e.g., stamps, coins)",
          ].map((members, index) => (
            <button
              key={index}
              className={`font-m border font-16 fw-normal ${membersSeeking.includes(members) ? "selected" : ""
                }`}
              onClick={() => handleMembersSeekingSelection(members)}
            >
              {members}
            </button>
          ))}
        </div>
      ),
    },
    {
      panel: "panel2",
      name: "hobbies",
      title: "Hobbies",
      content: (
        <div className="btnsetAll btnsetAll2 btnsetAll3">
          {[
            "Reading",
            "Traveling",
            "Cooking/Baking",
            "Hiking/Outdoor Adventures",
            "Photography",
            "Painting/Drawing",
            "Playing Sports",
            "Writing",
            "Yoga/Meditation",
            "Playing Musical Instruments",
            "Gardening",
            "Watching Movies/TV Shows",
            "Dancing",
            "Volunteering/Community Service"
          ].map((hobbies, index) => (
            <button
              key={index}
              className={`font-m border font-16 fw-normal ${hobbiesSeeking.includes(hobbies) ? "selected" : ""
                }`}
              onClick={() => handleHobbiesSeekingSelection(hobbies)}
            >
              {hobbies}
            </button>
          ))}
        </div>
      ),
    },
    {
      panel: "panel3",
      title: "Body Type",
      content: (
        <FilterCheckboxList
          name="bodyType"
          selectedValues={currentFilter.bodyType}
          handleCheckboxChange={handleCheckboxChange}
          options={["Slim",
            "Athletic",
            "Average",
            "Curvy",
            "Plus-size",
            "Muscular",
            "Broad",
            "Lean",
            "Prefer not to say",]}
          gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
        />
      ),
    },
    // {
    //   panel: "panel4",
    //   title: "Verification",
    //   content: (
    //     <FilterCheckboxList
    //       name="verification"
    //       handleCheckboxChange={handleCheckboxChange}
    //       selectedValues={currentFilter.verification}
    //       options={["Verified", "Not Verified"]}
    //       gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
    //     />
    //   ),
    // },
    {
      panel: "panel5",
      title: "Ethnicity",
      content: (
        <FilterCheckboxList
          name="ethnicity"
          handleCheckboxChange={handleCheckboxChange}
          selectedValues={currentFilter.ethnicity}
          options={["Asian",
            "Black/African descent",
            "Hispanic/Latino",
            "Middle Eastern",
            "Native American/Indigenous",
            "Pacific Islander",
            "White/Caucasian",
            "Mixed/Multiracial",
            "Other",]}
          gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
        />
      ),
    },
    {
      panel: "panel6",
      name: "height",
      title: "Height",
      content: (
        <div className="AGESlider">
          {/* <div className="unit-toggle mb-16">
            <button
              className={unit === "cm" ? "active" : ""}
              onClick={() => setUnit("cm")}
            >
              cm
            </button>
            <button
              className={unit === "feet" ? "active" : ""}
              onClick={() => setUnit("feet")}
            >
              ft/in
            </button>
          </div> */}
          <HeightRangeSlider  />
        </div>
      ),
    },
    {
      panel: "panel7",
      title: "Smoking",
      content: (
        <FilterCheckboxList
          name="smoking"
          handleCheckboxChange={handleCheckboxChange}
          selectedValues={currentFilter.smoking}
          options={["Yes", "No", "Occasionally"]}
          gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
        />
      ),
    },
    {
      panel: "panel8",
      title: "Relationship Status",
      content: (
        <FilterCheckboxList
          handleCheckboxChange={handleCheckboxChange}
          name="relationshipStatus"
          selectedValues={currentFilter.relationshipStatus}
          options={["Single",
            "In a Relationship",
            "Married",
            "Divorced",
            "Widowed",]}
          gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
        />
      ),
    },
    {
      panel: "panel9",
      title: "Drinking",
      content: (
        <FilterCheckboxList
          handleCheckboxChange={handleCheckboxChange}
          name="drinking"
          selectedValues={currentFilter.drinking}
          options={["Yes", "No", 'Occasionally']}
          gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
        />
      ),
    },
    {
      panel: "panel10",
      title: "Education",
      content: (
        <FilterCheckboxList
          handleCheckboxChange={handleCheckboxChange}
          name="education"
          selectedValues={currentFilter.education}
          options={["High School or Equivalent",
            "Some College",
            "Associate’s Degree",
            "Bachelor’s Degree",
            "Master’s Degree",
            "Doctorate or PhD",]}
          gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
        />
      ),
    },
    {
      panel: "panel11",
      title: "Work Field ",
      content: (
        <FilterCheckboxList
          handleCheckboxChange={handleCheckboxChange}
          name="workField"
          selectedValues={currentFilter.workField}
          options={["Finance/Investment",
            "Technology/Software",
            "Arts/Entertainment",
            "Healthcare/Medical",
            "Law/Legal",
            "Education",
            "Marketing/Sales",
            "Hospitality/Real Estate",
            "Entrepreneurship/Startups",
            "Other",]}
          gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
        />
      ),
    },
    {
      panel: "panel12",
      title: "Children",
      content: (
        <div ref={childrenPanelRef}>
        <FilterSingalSelect
          // onChange={handleCheckboxChange}
          name="children"
          selectedValues={currentFilter.children}
          options={["Yes", "No", "Prefer Not To Say"]}
          gridClassName="flex-column justify-content-between gap-15 btnsetAll btnsetAll2 btnsetAll3 mb-32"
        />
        </div>
      ),
    },
    // {
    //   panel: "panel13",
    //   title: "Levels",
    //   content: (
    //     <FilterCheckboxList
    //       name="levels"
    //       selectedValues={currentFilter.Levels}
    //       options={["Level 1", "Level 2", "Level 3"]}
    //       gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
    //     />
    //   ),
    // },
    // {
    //   panel: "panel14",
    //   title: "Language",
    //   content: (
    //     <FilterCheckboxList
    //       name="languages"
    //       selectedValues={currentFilter.Languages}
    //       options={["English", "Español", "Français", "Deutsch", "中文(简)", "日本語", "Nederlandse", "Português",]}
    //       gridClassName="d-flex flex-column justify-content-between gap-15 section1-grid"
    //     />
    //   ),
    // },
    // {
    //   panel: "panel15",
    //   name: "profileText",
    //   title: "PROFILE TEXT",
    //   content: (
    //     <TextField
    //       label="Search by Profile Text"
    //       type="text"
    //       placeholder="e.g. Sandy104"
    //       variant="outlined"
    //       fullWidth
    //       className="filter-input"
    //       InputProps={{
    //         endAdornment: (
    //           <InputAdornment position="end">
    //             <SearchIcon />
    //           </InputAdornment>
    //         ),
    //       }}
    //       sx={{
    //         borderRadius: '8px', // Add border radius
    //         '& .MuiOutlinedInput-root': {
    //           borderRadius: '8px', // Ensure border radius is applied to the input
    //           '&:hover .MuiOutlinedInput-notchedOutline': {
    //             borderColor: '#916008', // Change border color on hover
    //           },
    //           '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //             borderColor: '#916008', // Change border color on focus
    //           },
    //           '& .MuiOutlinedInput-input': {
    //             height: '11px', // Set the height of the input field

    //           },
    //         },
    //       }}
    //     />
    //   ),
    // },


  ];

  return (
    <div>
      {accordionsData.map(({ panel, title, content }) => (
        <Accordion
          key={panel}
          expanded={!!expanded[panel]}
          onChange={handleChange(panel)}
          className="border-0 shadow-none filterbgnone"
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                className={`filterbghead0 fw-medium ${expanded[panel] ? "bg-blue-500 text-brown" : "bg-gray-200 text-black"
                  }`}
              />
            }
            aria-controls={`${panel}-content`}
            id={`${panel}-header`}
            className={`filterbghead1 px-0 border-0 text-uppercase fw-medium ${expanded[panel] ? "bg-blue-500 text-brown" : "bg-gray-200 text-black"
              }`}
          >
            {title}
          </AccordionSummary>
          <AccordionDetails className="p-0 border-0 filterbgtext">{content}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ShowMembersSeeking