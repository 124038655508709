import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const getAccountSettingAPI = function ({userId}) {
    const url = `${config.baseUrl}account/get-account-settings/${userId}`;
    return postRequest({
        url,
    });
}; 
export const accountCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/account/save`;
    return postRequest({
        url,
        data,
    });
};
export const changePasswordAPI = function (data) {
    const url = `${config.baseUrl}auth/change-password`;
    return postRequest({
        url,
        data,
    });
};
export const accountStatusAPI = function (data) {
    const url = `${config.baseUrl}auth/deactivate-account`;
    return postRequest({
        url,
        data,
    });
};
export const deleteAccountAPI = function (data) {
    const url = `${config.baseUrl}auth/delete-account`;
    return postRequest({
        url,
        data
    });
};
export const accountUpdateAPI = function ({
    accountId,
    data,
}) {
    const url = `${config.baseUrl}account/update-account-settings/${accountId}`;
    return putRequest({
        url,
        data,
    });
};

export const getPrivatePicAccessAPI = function ({accountId}) {
    const url = `${config.baseUrl}account/get-private-pic-access/${accountId}`;
    return postRequest({
        url,
    });
}; 
export const updatePrivatePicAccessAPI = function ({accessId, data}) {
    const url = `${config.baseUrl}account/updated-private-pic-access/${accessId}`;
    return putRequest({
        url,
        data
    });
}; 
export const getPrivatePicAccessRequestAPI = function (data) {
    const url = `${config.baseUrl}account/get-private-pic-access-request`;
    return postRequest({
        url,
        data
    });
}; 
export const setPrivatePicRequestAccessStatusAPI = function ({data}) {
    const url = `${config.baseUrl}account/updated-private-pic-access-request`;
    return putRequest({
        url,
        data
    });
}; 
export const removeAllPrivatePicAccessAPI = function ({userId}) {
    const url = `${config.baseUrl}account/remove-all-private-pic-access/${userId}`;
    return putRequest({
        url
    });
}; 
export const getHideMemberAPI = function ({userId}) {
    const url = `${config.baseUrl}account/get-hidden-member/${userId}`;
    return postRequest({
        url
    });
}
export const updateBlockedMemberAPI = function ({userId}) {
    const url = `${config.baseUrl}account/get-blocked-member/${userId}`;
    return postRequest({
        url
    });
}
export const unblockUnhideMemberAPI = function ({activityId}) {
    const url = `${config.baseUrl}account/unblock-unhide-member/${activityId}`;
    return postRequest({
        url
    });
}
export const createSubscriptionAPI = function (data) {
    const url = `${config.baseUrl}payment/create-subscription-razorpay`;
    return postRequest({
        url,
        data
    });
}
export const verifyPaymentAPI = function (data) {
    const url = `${config.baseUrl}payment/verify-payment`;
    return postRequest({
        url,
        data
    });
}
export const getPlanListAPI = function () {
    const url = `${config.baseUrl}payment/get-subscription`;
    return getRequest({
        url,
    });
}; 