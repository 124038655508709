import React from 'react';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import UserProfileDetails from 'src/Component/Profile/user-profile/UserProfileDetails';
import UserAboutText from 'src/Component/Profile/user-profile/UserAboutText';
import { shallowEqual, useSelector } from 'react-redux';
import { time_ago } from 'src/utils/CommonUtils';
import UserPhotos from 'src/Component/Profile/user-profile/UserPhotos';
import { sendPrivatePicRequestAPI } from 'src/api/MembersApi';
import { useState } from 'react';
const ProfileCardRight = ({ closeProfileCard }) => {
  const { selectedUser,socket } = useSelector((state) => state.ChatReducer, shallowEqual);
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [privatePickRequestStatus, setPrivatePickRequestStatus] = useState(selectedUser.privatePicAccess?.status);
  const userData = {
    infoCards: [
      { icon: "/assets/images/user/user-profile/height-icon.svg", value: `${selectedUser?.tall?.cm?.toFixed(0)} cm`, label: "Height" },
      { icon: "/assets/images/user/user-profile/member-since.svg", value: time_ago(selectedUser.createdAt), label: "Member Since" },
      { icon: "/assets/images/user/user-profile/curvy-icon.svg", value: selectedUser.bodyType || "NA", label: "Body" },
      { icon: "/assets/images/user/user-profile/single-icon.svg", value: selectedUser.currentRelationshipStatus || "NA", label: "Relationship status" },
    ],
  };
    const aboutContent = [
    {
      title: "About Me",
      paragraphs: [
        `${selectedUser?.aboutUsDescription || "Not available"}`
      ],
    },
    {
      title: "What I am Seeking",
      paragraphs: [
        `${selectedUser?.preferences?.aboutPartnerDescription || "Not available"}`,
      ],
    },
  ];
  const handlePrivatePicRequest = () => {
    const payload = {
        targetUserId: user._id,
        receiverId: selectedUser._id
    }
    sendPrivatePicRequestAPI(payload)
    setPrivatePickRequestStatus("Pending");
    socket.emit("privatePicRequest", payload);
}
  return (
    <div className="modal-dialog-scrollable border-start rightsteuwith">
      <div className="modal-content">
        <div className="msg-head border-0 py-0">
          <div className="headerRight text-center">
            <button className="crossBtn" onClick={closeProfileCard}>
              <ArrowBackIosNewOutlinedIcon />
            </button>
            {/* <div className="headerRightImg mb-10"><img
          src={selectedUser?.profilePicture || "/assets/images/user/user-profile/profile.png"}
          alt="Profile"
          className="img-fluid profile-image"
        /></div> */}
            {/* <div className="font-16 font-m fw-medium text-black mb-10 username">{selectedUser?.userName}, {selectedUser?.age}</div> */}

            {/* <div className="font-14 font-m fw-medium color5f miles">600 miles</div> */}
          </div>
        </div>
        <div className="modal-body overflowauto">
          <div className='ProfileUserPhotos'>
            <UserPhotos images={selectedUser.publicPhotos||[]} defaultLimit={3} />
            <div className='ProfilesecondGalls'>
              <UserPhotos 
              images={selectedUser?.privatePhotos || [""]}
              defaultLimit={1}
              onClick={handlePrivatePicRequest}  
              showRequestView={selectedUser.privatePicAccess?.status !== "Accepted"}
              privatePickRequestStatus={privatePickRequestStatus}  

              // images={secondGalleryImages} 
              // defaultLimit={1} 
              /></div>
          </div>
          <div className="liveLctDetals">
            <ul>
              <li>{`${selectedUser.lastLocation?.city}`} <span>{selectedUser.lastLocation?.distance==0?1:selectedUser.lastLocation?.distance} {user?.preferredMeasurement? "km" : "mile"}</span></li>
              <li>Last Active <span>{time_ago(selectedUser?.lastActive)}</span></li>
              <li>Status <span>{selectedUser?.isOnline ? 'online' : 'offline'}</span></li>
              <li>Verification <span>{(selectedUser?.isProfileVerified || selectedUser?.isIdVerified) ? 'Verified' : 'Not Verified'}</span></li>
            </ul>
          </div>
          {/* <div className="font-14 font-m fw-medium color5f location">{selectedUser?.country}, {selectedUser?.state}, {selectedUser?.city}</div> */}
          <div className="profile-cardRight p-20">
            {/* <p className="tagline text-center font-14 font-m fw-normal text-black mb-0">An obedient disciple in search of a young guru!</p> */}
            {/* User Info Cards */}
            <div className="user-info-cards gridProSet0">
              {userData.infoCards.map((info, index) => (
                <div key={index} className="user-info-item">
                  <span className="d-block">
                    <img src={info.icon} className="img-fluid" alt={info.label} />
                  </span>
                  {info.value}
                  <span className="font-12 font-m fw-normal color7b">{info.label}</span>
                </div>
              ))}
            </div>
            <UserProfileDetails />
            <UserAboutText className="pt-20" content={aboutContent} />

          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCardRight