import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { deliveredMessageAction, readMessageAction, resetPendingAction, updateConversationsListAction } from './actions/ChatActions';
import { fileDeleteAPI, uploadPublicFileAPI } from 'src/api/FileApi';
import { X, Search } from "lucide-react";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import MessageList from 'src/components/chat/MessageList';
import CancelIcon from '@mui/icons-material/Cancel';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EmojiPicker from 'emoji-picker-react';
import { debounce } from 'lodash';
import ProfileCardRight from './ProfileCardRight';
import NotificationList from 'src/components/chat/NotificationList';


const ChatBox = ({ isChatBoxOpen, setChatBoxOpen, isMobileView }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { user: { _id: userId } } = useSelector((state) => state.auth, shallowEqual);
  const { pendingChatText, pendingChatUser } = useSelector((state) => state.ChatReducer, shallowEqual);
  const { socket, selectedUser, roomId, conversations } = useSelector((state) => state.ChatReducer, shallowEqual);
  const [messages, setMessages] = useState(
    conversations || []
    // [{ text: 'Hello! How can I assist you today?', sender: true, time: '10:06 am', viewed: true },]
  );
  const [inputValue, setInputValue] = useState(pendingChatText || '');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showProfileCard, setShowProfileCard] = useState(false); // State to show/hide ProfileCardRight
  const [isPendingChatUser, setPendingChatUser] = useState(pendingChatUser || '');
  const [isTyping, setIsTyping] = useState(false);
  const [files, setFiles] = useState([]);
  const [messageType, setMessageType] = useState("text");
  const messagesEndRef = useRef(null);
  const emojiPickerRef = useRef(null);


  useEffect(() => {
    function handleClickOutside(event) {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }

    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleReceivedMessage = (data) => {
    console.log("Received message:", data)
    if (data && data.result) {
      dispatch(updateConversationsListAction({ data: data.result }))
    } else {
      console.error("Invalid message data received:", data)
    }
  }
  const handleReadAllMessagesResponse = ({ roomId: responseRoomId }) => {
    if (roomId === responseRoomId) {
      dispatch(readMessageAction());
    }
  }

  const handleDeliveredMessage = ({ userId: deliveredUserId, isOnline }) => {
    if (isOnline && deliveredUserId === selectedUser._id) {
      dispatch(deliveredMessageAction());
    }
  }
  let typingTimeoutId = null;
  const handleTyping = () => {
    if (typingTimeoutId) {
      clearTimeout(typingTimeoutId);
    }
    setIsTyping(true);
    typingTimeoutId = setTimeout(() => {
      setIsTyping(false);
    }, 3000);
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    // setMessages(conversations);
    if (Array.isArray(conversations)) {
      setMessages([...conversations]);  // ✅ Creates a new reference to trigger re-render
    }
  }, [conversations]);
  useEffect(() => {
    if (!socket || !socket.connected) {
      console.warn("⚠️ Socket is disconnected. Waiting for reconnection...")
      return
    }
    const eventHandlers = {
      messageResponse: handleReceivedMessage,
      typingResponse: handleTyping,
      userStatusChange: handleDeliveredMessage,
      readAllMessagesResponse: handleReadAllMessagesResponse,
      deliveredMessage: handleTyping,
    }
    Object.entries(eventHandlers).forEach(([event, handler]) => {
      socket.on(event, handler)
      console.log(`🔹 Listener added for ${event}`)
    })

    return () => {
      Object.entries(eventHandlers).forEach(([event, handler]) => {
        socket.off(event, handler)
        console.log(`🔹 Listener removed for ${event}`)
      })
    }
  }, [socket, handleReceivedMessage, handleTyping, handleDeliveredMessage, handleReadAllMessagesResponse])

  const handleSendMessage = () => {
    if (inputValue.trim() === '' && uploadedFiles.length === 0) return;
    if (socket.connected === false) {
      socket.connect();
      // openWarningNotification(
      //   "Unstable internet connection detected. Please check your connection or refresh and try again."
      // );
      return;
    }
    let data = {
      roomId: roomId,
      senderId: userId,
      receiverId: selectedUser._id,
      message: inputValue,
      messageType: messageType,
      userAgentSent: null,
      files: files?.length > 0 ? files : [],
    };
    socket?.emit("message", data);
    if (isPendingChatUser !== '') {
      setTimeout(() => {
        const chatRoomId = localStorage.getItem("chatRoomId");
        socket.emit("getRecentChatList", { userId });
        const data = { userId, roomId: chatRoomId };
        socket.emit('initialMessages', data);
      }, 1000);
      setPendingChatUser('');
    }
    setInputValue('');
    setMessageType("text");
    setFiles([]);
    setUploadedFiles([]);
  };
  const debouncedTypingRef = useRef(
    debounce((userId, selectedUser, socket) => {
      socket.emit("typing", {
        senderId: userId,
        receiverId: selectedUser?._id,
      });
    }, 1000)
  );
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    debouncedTypingRef.current(userId, selectedUser, socket);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const uploadFiles = async (files) => {
    const uploadedFile = [];
    for (const file of files) {
      const payload = { file, by: userId };
      try {
        const uploadResponse = await uploadPublicFileAPI(payload);
        if (uploadResponse.data?.data?.url) {
          uploadedFile.push(uploadResponse.data.data);
        }
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }
    setMessageType("image");
    setFiles((prevFileIds) => [...prevFileIds, ...uploadedFile]);
  };
  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const filePreviews = files.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
    }));
    await uploadFiles(files);
    e.target.value = "";
    setUploadedFiles((prevFiles) => [...prevFiles, ...filePreviews]);
  };

  const handleRemoveFile = async (index) => {
    const fileId = files[index]._id;
    const updatedFiles = files.filter((_, i) => i !== index);
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFiles((prevFileIds) => prevFileIds.filter((file, index) => file._id !== fileId));
    // If all files are deleted, reset messageType to "text"
    if (updatedFiles.length === 0) {
      setMessageType("text");
    }
    await fileDeleteAPI(fileId);
  };
  const emojiStyle = {
    fontSize: '1.5rem', // Adjust emoji size
  };
  const onEmojiClick = (emojiObject) => {
    setInputValue((prevValue) => prevValue + emojiObject.emoji);
  };

  const toggleProfileCard = () => {
    setShowProfileCard((prev) => !prev); // Toggle the visibility of ProfileCardRight
  };

  const closeProfileCard = () => {
    setShowProfileCard(false); // Close the ProfileCardRight component
  };

  useEffect(() => {
    if (pendingChatText != null && roomId) {
      handleSendMessage();
      setTimeout(() => {
        dispatch(resetPendingAction());
      }, 100)
    }
  }, [pendingChatText, dispatch, handleSendMessage, roomId]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [message, setMessage] = useState("");
  const addEmojiToMessage = (emoji) => {
    const emojiUrl = `https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${emoji.unified}.png`;
    setMessage((prev) => prev + `<img class="emoji-img" src="${emojiUrl}" alt="${emoji.native}" />`);
  };

  return (
    <div className={`chatbox d-flex ${isChatBoxOpen && isMobileView ? "mobile-open" : ""}`}>
      <div className="modal-dialog-scrollable w-100">
        <div className="modal-content">
          {/* Chatbox Header */}
          <div className="msg-head headChatUser">
            <div className="row">
              <div className="col-8 headChatUserLeft">
                <div className="d-flex align-items-center">
                  <span
                    className="chat-icon"
                    onClick={() => isMobileView && setChatBoxOpen(false)} // Fix: Sirf mobile view me toggle hoga
                  >
                    <ArrowBackIosNewOutlinedIcon />
                  </span>
                  <div className="flex-shrink-0 userImgActive">
                    <img className="img-fluid" onClick={toggleProfileCard} src={selectedUser.profilePicture} alt={selectedUser.userName} />
                  </div>
                  <div className="flex-grow-1 ms-3 userNameActive">
                    <h3>{selectedUser.userName}</h3>
                    {/* <p>{chat.role}</p> */}
                    <p>{isTyping ? 'Typing...' : ''}</p>
                  </div>
                </div>
              </div>
              <div className="col-4 headChatUserRight">
                <div className="d-flex justify-content-end align-items-center hehjtst">
                  <div className="relative">
                    {/* Search Button */}
                    <Button onClick={toggleDropdown} variant="outline" size="sm" className="flex items-center gap-2" sx={{ minWidth: '30px', textTransform: 'capitalize' }}>
                      {isOpen ? <X className="w-4 h-4 text-danger" /> : <Search className="w-4 h-4" />}
                      <span>{isOpen ? "Close" : ""}</span>
                    </Button>

                  </div>
                  <Button>
                    <img src="/assets/images/messages/video.svg" className="img-fluid" alt="video" />
                  </Button>
                  <Button>
                    <img src="/assets/images/messages/menudotted.svg" className="img-fluid" alt="menu" />
                  </Button>
                  <Button onClick={toggleProfileCard}> {/* Show ProfileCardRight on click */}
                    <img
                      src={
                        showProfileCard
                          ? '/assets/images/messages/user-active.svg' // Active state image
                          : '/assets/images/messages/user.svg' // Default state image
                      }
                      className="img-fluid"
                      alt="user"
                    />
                  </Button>
                </div >
              </div >
              {/* Dropdown */}
              {
                isOpen && (
                  <div className="position-absolute right-0 inputUsertextSearch  shadow-lg z-3 bg-white">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="w-100 border border-gray-300 rounded-3 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                )
              }
            </div >
          </div >
          {/* Chatbox Messages */}
          < div className="modal-body bg-EB overflow auto" >
            <div className="msg-body">
              <ul className="p-44vw">
                {messages.map((msg, index) => (
                  msg.messageType === "pic_request" ? (
                    <NotificationList key={index} msg={msg} userId={userId} />
                  ) : (
                    <MessageList key={index} msg={msg} userId={userId} />
                  )
                ))}
                <div ref={messagesEndRef} />
              </ul>
            </div>
          </div >
          {/* Message Input Section */}
          < div className="send-box" >
            <div className="attachfile">
              {uploadedFiles.map((file, index) => (
                <div className="uploadImg" key={index}>
                  <img src={file.url} alt={file.name} />
                  <CancelIcon className="icon" onClick={() => handleRemoveFile(index)} />
                </div>
              ))}
            </div>

            <div className="emoji-picker-container emoji-pickerbg" ref={emojiPickerRef}>
              {showEmojiPicker && (
                <div className="emoji-picker">
                  <EmojiPicker
                    onEmojiClick={(emojiObject) => setInputValue((prev) => prev + emojiObject.emoji)}
                  />
                </div>
              )}
            </div>

            <div className="sexBoxSetup d-flex justify-content-between align-items-center">
              <div className="emoji-icon">
                <button className='border-0 bg-none p-0 text-black' onClick={() => setShowEmojiPicker((prev) => !prev)}>
                  {!showEmojiPicker ? <SentimentSatisfiedOutlinedIcon /> : <CloseOutlinedIcon sx={{ color: '#fff', fontSize: '12px', width: '30px', height: '30px', borderRadius: '50px', background: 'rgba(201, 30, 30, 1)' }} />}
                </button>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Type a message..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                style={emojiStyle}
              />
              <input
                type="file"
                multiple
                style={{ display: 'none' }}
                id="file-upload"
                onChange={(event) => {
                  handleFileUpload(event);
                }}
              />
              <Button className="imgsendBox"
                onClick={() => document.getElementById('file-upload').click()}
              >
                <img src="/assets/images/messages/voice.svg" className="img-fluid" alt="" />
              </Button>
              <Button className="imgsendBox"
                onClick={() => document.getElementById('file-upload').click()}
              >
                <img src="/assets/images/messages/attachfile.svg" className="img-fluid" alt="" />
              </Button>
              <Button className="sendbuntchat"
                sx={{ textTransform: 'capitalize', background: '#916008', }}
                type="button"
                onClick={() => {
                  handleSendMessage();
                  setShowEmojiPicker(false);
                }}
              >
                <span className='desktopVsetp'>Send</span> <SendIcon className="iconDnone" />
              </Button>
            </div>
          </div >
        </div >
      </div >

      {showProfileCard && (
        <div className="profile-cardRight">
          <ProfileCardRight closeProfileCard={closeProfileCard} />
        </div>
      )}

    </div >
  );
};

export default ChatBox;
