import React, { useState, useEffect, useRef } from "react"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import LockIcon from "@mui/icons-material/Lock"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import ReplayIcon from "@mui/icons-material/Replay"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { fileDeleteAPI, fileDeleteByUrlAPI, uploadPrivateFileAPI, uploadPublicFileAPI } from "src/api/FileApi"
import { setPayload } from "src/containers/signup-container/actions/SignupActions"
import { signupUpdateAPI } from "src/api/SignupApi"
import { cleanUrl } from "src/utils/CommonUtils"

const AdditionalPhotosUpload = ({ updateUserInfo }) => {
  const dispatch = useDispatch()
  const { publicPhotos, privatePhotos, profilePicture } = useSelector(
    (state) => state.SignupReducer.currentRecord,
    shallowEqual,
  )
  const { user } = useSelector((state) => state.auth, shallowEqual)
  const [photos, setPhotos] = useState({
    profilePicture,
    publicPhotos: Array(8).fill(null),
    privatePhotos: Array(8).fill(null),
  })

  useEffect(() => {
    setPhotos({
      profilePicture,
      publicPhotos: [...publicPhotos, ...Array(8 - publicPhotos.length).fill(null)],
      privatePhotos: [...privatePhotos, ...Array(8 - privatePhotos.length).fill(null)],
    })
  }, [profilePicture, publicPhotos, privatePhotos])

  // Helper function to update signup API
  const updateSignup = (updatedData) => {
    const { profilePicture, publicPhotos, privatePhotos } = updatedData
    const cleanedPrivatePhotos = privatePhotos?.map(cleanUrl).filter(Boolean);
    signupUpdateAPI({
      signupId: user._id,
      data: {
        step: 21,
        accountUpdatePayload: {
          ...(profilePicture && { profilePicture }),
          ...(publicPhotos?.length > 0 && { publicPhotos: publicPhotos.filter(Boolean) }),
          ...(cleanedPrivatePhotos?.length > 0 && { privatePhotos: cleanedPrivatePhotos }),
        },
      },
    })
  }

  const handleFileChange = async (event, photoType, index = null) => {
    const file = event.target.files[0]
    if (file) {
      const payload = { file, by: user._id }
      let fileResponse

      if (photoType === "profilePicture") {
        fileResponse = await uploadPublicFileAPI(payload)
      } else if (photoType === "publicPhotos") {
        fileResponse = await uploadPublicFileAPI(payload)
      } else if (photoType === "privatePhotos") {
        fileResponse = await uploadPrivateFileAPI(payload)
      }

      if (fileResponse?.data?.data.url) {
        const fileUrl = fileResponse.data.data.url
        const fileSignedUrl = fileResponse.data.data?.signedUrl

        setPhotos((prevState) => {
          const updatedState = { ...prevState }

          if (photoType === "profilePicture") {
            updatedState.profilePicture = fileUrl
          } else if (photoType === "publicPhotos") {
            const updatedPublicPhotos = [...updatedState.publicPhotos]
            updatedPublicPhotos[index] = fileUrl
            updatedState.publicPhotos = updatedPublicPhotos
          } else if (photoType === "privatePhotos") {
            const updatedPrivatePhotos = [...updatedState.privatePhotos]
            updatedPrivatePhotos[index] = fileUrl
            updatedState.privatePhotos = updatedPrivatePhotos
          }

          // Update Redux state
          // dispatch(
          //   setPayload({
          //     data: {
          //       profilePicture: updatedState.profilePicture,
          //       publicPhotos: updatedState.publicPhotos.filter(Boolean),
          //       privatePhotos: updatedState.privatePhotos.filter(Boolean),
          //     },
          //   }),
          // )
          dispatch(
            setPayload({
              data: {
                profilePicture: updatedState.profilePicture,
                publicPhotos: updatedState.publicPhotos.filter(Boolean),
                privatePhotos: updatedState.privatePhotos.map((photo, i) => (i === index ? fileSignedUrl : photo)).filter(Boolean),
              },
            }),
          )

          // Call signup update API with new data
          updateSignup(updatedState)

          return updatedState
        })
      }
    }
  }

  const handleDelete = async (photoType, index) => {
    let photoToDelete
    if (photoType === "profilePicture") {
      photoToDelete = photos.profilePicture
    } else if (photoType === "publicPhotos") {
      photoToDelete = photos.publicPhotos[index]
    } else if (photoType === "privatePhotos") {
      photoToDelete = photos.privatePhotos[index]
    }

    if (photoToDelete) {
      try {
        await fileDeleteByUrlAPI({ url: photoToDelete })

        setPhotos((prevState) => {
          const updatedState = { ...prevState }
          if (photoType === "profilePicture") {
            updatedState.profilePicture = null
          } else if (photoType === "publicPhotos") {
            updatedState.publicPhotos[index] = null
          } else if (photoType === "privatePhotos") {
            updatedState.privatePhotos[index] = null
          }

          // Update Redux state
          dispatch(
            setPayload({
              data: {
                profilePicture: photoType === "profilePicture" ? null : updatedState.profilePicture,
                publicPhotos: updatedState.publicPhotos.filter(Boolean),
                privatePhotos: updatedState.privatePhotos.filter(Boolean),
              },
            }),
          )

          // Call signup update API with new data
          updateSignup(updatedState)

          return updatedState
        })
      } catch (error) {
        console.error("Error deleting photo:", error)
      }
    }
  }

  const handleLockPhoto = async (index) => {
    const photoUrlToLock = photos.publicPhotos[index]
    if (photoUrlToLock) {
      try {
        const response = await fetch(photoUrlToLock)
        const blob = await response.blob()
        const file = new File([blob], "image.jpg", { type: blob.type })

        const payload = { file, by: user._id }
        const uploadResponse = await uploadPrivateFileAPI(payload)

        if (uploadResponse.data.data.url) {
          const updatedPublicPhotos = [...photos.publicPhotos]
          updatedPublicPhotos[index] = null

          const updatedPrivatePhotos = [...photos.privatePhotos]
          const emptyIndex = updatedPrivatePhotos.indexOf(null)
          if (emptyIndex !== -1) {
            updatedPrivatePhotos[emptyIndex] = uploadResponse.data.data.url
          }

          const updatedState = {
            ...photos,
            publicPhotos: updatedPublicPhotos,
            privatePhotos: updatedPrivatePhotos,
          }

          setPhotos(updatedState)

          // Update Redux state
          dispatch(
            setPayload({
              data: {
                publicPhotos: updatedPublicPhotos.filter(Boolean),
                privatePhotos: updatedPrivatePhotos.filter(Boolean),
              },
            }),
          )

          // Call signup update API with new data
          updateSignup(updatedState)
        }
      } catch (error) {
        console.error("Error locking photo:", error)
      }
    }
  }

  const handleUnlockPhoto = async (index) => {
    const photoUrlToUnlock = photos.privatePhotos[index]
    if (photoUrlToUnlock) {
      try {
        const response = await fetch(photoUrlToUnlock)
        const blob = await response.blob()
        const file = new File([blob], "image.jpg", { type: blob.type })

        const payload = { file, by: user._id }
        const uploadResponse = await uploadPublicFileAPI(payload)

        if (uploadResponse.data.data.url) {
          const updatedPrivatePhotos = [...photos.privatePhotos]
          updatedPrivatePhotos[index] = null

          const updatedPublicPhotos = [...photos.publicPhotos]
          const emptyIndex = updatedPublicPhotos.indexOf(null)
          if (emptyIndex !== -1) {
            updatedPublicPhotos[emptyIndex] = uploadResponse.data.data.url
          }

          const updatedState = {
            ...photos,
            publicPhotos: updatedPublicPhotos,
            privatePhotos: updatedPrivatePhotos,
          }
          setPhotos(updatedState)

          // Update Redux state
          dispatch(
            setPayload({
              data: {
                publicPhotos: updatedPublicPhotos.filter(Boolean),
                privatePhotos: updatedPrivatePhotos.filter(Boolean),
              },
            }),
          )

          // Call signup update API with new data
          updateSignup(updatedState)
        }
      } catch (error) {
        console.error("Error unlocking photo:", error)
      }
    }
  }
  const handleMakeProfile = (photo) => {
    const updatedState = { ...photos, profilePicture: photo }
    setPhotos(updatedState)

    // Update Redux state
    dispatch(setPayload({ data: { profilePicture: photo } }))

    // Call signup update API with new data
    updateSignup(updatedState)
  }

  const handleReupload = (photoType, index) => {
    document.getElementById(`${photoType}-${index}`).click()
  }

  const renderPhotoBox = (photo, onClick, type, index, size) => {
    return (
      <div
        className="photo-box"
        onClick={() => document.getElementById(`${type}-${index}`).click()}
        style={{
          position: "relative",
          padding: "0px",
          borderRadius: "9px",
          margin: "0px",
          display: "inline-block",
          width: size.width,
          height: size.height,
          cursor: "pointer",
          overflow: "hidden",
          background: "#EBEBEB",
        }}
      >
        {photo ? (
          <img
            src={photo || "/placeholder.svg"}
            alt="Uploaded"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <div
            style={{
              textAlign: "center",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="d-flex flex-column" style={{ color: "rgba(196, 196, 196, 1)" }}>
              <AddCircleOutlineIcon /> <CameraAltOutlinedIcon />
            </span>
          </div>
        )}

        {photo && (
          <div className="photoHoverEffect">
            {
              (type === "privatePhotos"|| type === "publicPhotos") && (
                <div
                  className="delete-icon"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDelete(type, index)
                  }}
                >
                  <span style={{ fontSize: "11px" }}>X</span>
                </div>
              )
            }


            {type === "privatePhotos" && (
              <div
                className="unlock-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  handleUnlockPhoto(index)
                }}
              >
                <LockOpenIcon />
              </div>
            )}

            {type === "publicPhotos" && (
              <div
                className="lock-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  handleLockPhoto(index)
                }}
              >
                <LockIcon />
              </div>
            )}

            <div
              className="reupload-icon"
              onClick={(e) => {
                e.stopPropagation()
                handleReupload(type, index)
              }}
            >
              <ReplayIcon />
            </div>

            {type !== "profilePicture" && (
              <div
                className="make-profile-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  handleMakeProfile(photo)
                }}
              >
                Make Profile
              </div>
            )}
          </div>
        )}

        <input
          id={`${type}-${index}`}
          type="file"
          accept="image/*"
          onChange={(event) => handleFileChange(event, type, index)}
          style={{ display: "none" }}
        />
      </div>
    )
  }

  return (
    <div className="PrimaryPhotobgList" style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
      <div className="d-flex flex-column gap-50">
        <div className="PrimaryPhoto d-flex gap-15 align-items-center">
          <div className="PrimaryPhoto001">
            {renderPhotoBox(photos.profilePicture, handleFileChange, "profilePicture", 0, {
              width: "200px",
              height: "266px",
            })}
          </div>
          <button className="PrimaryPhotobtn"
            onClick={() => document.getElementById("profilePicture-0").click()}

          >
            Upload Primary Photo
          </button>
        </div>

        <div className="publicPhotos text-start">
          <label
            className="font-m mb-16"
            style={{fontSize: "16px", fontWeight: 500, color: "#081726" }}
          >
            Public Photos ({publicPhotos?.length})
          </label>
          <div className="publicPhotosGrid"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {photos.publicPhotos.map((photo, index) =>
              renderPhotoBox(photo, handleFileChange, "publicPhotos", index, { width: "100%", height: "180px" }),
            )}
          </div>
        </div>
        <div className="privatePhotos text-start">
          <label
            className="font-m mb-16"
            style={{fontSize: "16px", fontWeight: 500, color: "#081726" }}
          >
            Upload Private Photos <span>({privatePhotos?.length})</span>
          </label>
          <div className="publicPhotosGrid"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {photos.privatePhotos.map((photo, index) =>
              renderPhotoBox(photo, handleFileChange, "privatePhotos", index, { width: "100%", height: "180px" }),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdditionalPhotosUpload