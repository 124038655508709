import React, { useState } from 'react';
import { TextField, Button, Menu, MenuItem, Fade } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFilterAction, setFilterAction } from 'src/containers/member-container/actions/MemberActions';

const SavedFilters = ({filters}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [isFiltersVisible, setFiltersVisible] = useState(false); // Tracks visibility of filters

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event, index) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setEditIndex(index);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setEditIndex(null);
  };

  const handleDelete = (e,id) => {
    e.stopPropagation();
    dispatch(deleteFilterAction({ id }));
    handleCloseMenu();
  };

  const toggleFilters = () => {
    setFiltersVisible((prev) => !prev);
  };

  return (
    <>
      <div
        className="saved-filters samefont"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          color: isFiltersVisible ? '#916008' : '#7B7B7B', // Conditional text color
        }}
        onClick={toggleFilters}
      >
        SAVED FILTERS
        <ArrowForwardIosIcon
          style={{
            marginLeft: '8px',
            color: isFiltersVisible ? '#916008' : '#7B7B7B',
            transform: isFiltersVisible ? 'rotate(90deg)' : 'none',
            transition: 'transform 0.2s ease, color 0.2s ease',
          }}
        />
      </div>

      {isFiltersVisible && (
        <div className="w-100 SvFtr">
          {filters.map((filter, index) => (
            <div className="SAVEDFlt001" key={index} onClick={() => dispatch(setFilterAction({ data: filter }))}>
              <div className="d-flex justify-content-between align-items-start">
                <label className="checkinput">
                  {filter.filterName}
                  <input type="radio" name="filter" />
                  <span className="checkmark" />
                </label>
                <Button
                  id="fade-button"
                  className="more" sx={{ color: '#7B7B7B', minWidth: '40px', maxWidth: '40px' }}
                  aria-controls={menuOpen ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? "true" : undefined}
                  onClick={(event) => handleClick(event, index, filter)}
                >
                  <MoreVertIcon />
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={menuOpen && editIndex === index}
                  onClose={handleCloseMenu}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    className="d-flex gap-2 align-items-center"
                    onClick={(e) => handleDelete(e,filter._id)}
                  >
                    <DeleteOutlinedIcon /> Delete
                  </MenuItem>
                </Menu>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SavedFilters;
