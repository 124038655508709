import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Footer from 'src/containers/home-container/layout/Footer';
import GenderInterestSelection from 'src/components/signup/GenderInterestSelection';
import TillUsDOB from 'src/components/signup/TillUsDOB';
import LinksYourAccount from 'src/components/signup/LinksYourAccount';
import LinkViaEmail from 'src/components/signup/LinkViaEmail';
import EmailVerification from 'src/components/signup/EmailVerification';
import CreateYourPassword from 'src/components/signup/CreateYourPassword';
import SecurityQuestionSetup from 'src/components/signup/SecurityQuestionSetup';
import YourLocation from 'src/components/signup/YourLocation';
import HeightSelector from 'src/components/signup/HeightSelector';
import BodyType from 'src/components/signup/BodyType';
import YourEthnicity from 'src/components/signup/YourEthnicity';
import LevelOfEducation from 'src/components/signup/LevelOfEducation';
import YourField from 'src/components/signup/YourField';
import RelationshipStatus from 'src/components/signup/RelationshipStatus';
import HaveChildren from 'src/components/signup/HaveChildren';
import NetWorth from 'src/components/signup/NetWorth';
import AgePreferences from 'src/components/signup/AgePreferences';
import YourHobbies from 'src/components/signup/YourHobbies';
import DoYouSmoke from 'src/components/signup/DoYouSmoke';
import DoYouDrinkAlcohol from 'src/components/signup/DoYouDrinkAlcohol';
import LuxeInterests from 'src/components/signup/LuxeInterests';
import YouLookingForPartner from 'src/components/signup/YouLookingForPartner';
import PhotoUpload from 'src/components/signup/PhotoUpload';
import CreateCatchyHeading from 'src/components/signup/CreateCatchyHeading';
import FewWords from 'src/components/signup/FewWords';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createPasswordAction, setPayload, signupUpsertAction, updateSecurityQuestionAction, verifyOtpAction } from './actions/SignupActions';
import UserNameComponent from 'src/components/signup/UserName';
import { SignupDefaultProps, validatePayload } from './SignupPropTypes';
import { headerShowAction } from '../header-container/actions/HeaderActions';
import SelfieVerification from 'src/components/signup/SelfieVerification';
import { cleanUrl } from 'src/utils/CommonUtils';

const SignUpContainer = () => {
  const dispatch = useDispatch();
  const {
    currentRecord,
    currentRecord: { localStep, maxStep },
  } = useSelector((state) => state.SignupReducer, shallowEqual);
  const {
    headerMessages,
  } = useSelector((state) => state.HeaderReducer, shallowEqual);
  const {
    user,
  } = useSelector((state) => state.auth, shallowEqual);
  const [currentStep, setCurrentStep] = useState(localStep || 1);
  const [isValidated,setIsValidated] = useState(false);
  const stepTexts = {
    1: "Your preferences help us tailor your matches according to your desires.",
    2: "You must be at least 18 years old to use this service.",
    3: "Linking your account does not grant us permission to post on your behalf without your explicit consent.",
    4: "Linking your account does not grant us permission to post on your behalf without your explicit consent.",
    5: "Please check your email and spam folder for the OTP.",
    6: "",
    7: "This information will be used for account recovery and will remain confidential.",
    8: "Your username is your unique identity, elegant and exclusive to you.",
    9: "Your exact location is never shared with other users and will remain private.",
    10: "This detail will be visible to your matches and helps in creating better compatibility.",
    11: "This information helps create a more tailored experience for you and your matches.",
    12: "This detail is required to personalize your matches.",
    13: "This detail helps us match you with people who share similar values and interests.",
    14: "This is optional, but it helps us create a more tailored experience for you.",
    15: "This information is necessary and will help us tailor your experience to find better matches.",
    16: "This information is optional but helps us personalize your experience.",
    17: "This helps match you with individuals who share similar financial goals.",
    // 18: "This helps match you with individuals who share similar financial goals.",
    18: "Your age preference ensures personalized matches for meaningful connections.",
    19: "These hobbies help us connect you with like-minded individuals.",
    20: "This information is public and can be viewed on your profile.",
    21: "This information is public and can be viewed on your profile.",
    22: "These tags help us match you with people who share your lifestyle preferences and passions.",
    23: "This detail is optional but will help us refine your matches based on your personal preferences and relationship goals.",
    24: "Make sure to share the photos that represent you authentically and help others get to know you.",
    25: "Tip: Keep it brief, engaging, and authentic to attract the right matches.",
    26: "Tip: Be genuine, and let your personality shine through. Whether it’s your sense of humor, passion for adventure, or dedication to success, this is your moment to stand out.",
  };

  const handleContinue = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Dispatch headerShowAction to reset header messages
    dispatch(headerShowAction({ data: '', }));
    const commonSteps = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];

    // Define dynamic payloads for common steps
    const userId = user?._id;
    const commonPayloads = {
      8: { _id: userId, data: { step: 4, accountUpdatePayload: { userName: currentRecord.userName } } },
      9: { _id: userId, data: { step: 5, accountUpdatePayload: { location: currentRecord.location, city: currentRecord.city, state: currentRecord.state, country: currentRecord.country } } },
      10: { _id: userId, data: { step: 6, accountUpdatePayload: { tall: currentRecord.tall } } },
      11: { _id: userId, data: { step: 7, accountUpdatePayload: { bodyType: currentRecord.bodyType } } },
      12: { _id: userId, data: { step: 8, accountUpdatePayload: { ethnicity: currentRecord.ethnicity } } },
      13: { _id: userId, data: { step: 9, accountUpdatePayload: { highestEducation: currentRecord.highestEducation } } },
      14: { _id: userId, data: { step: 10, accountUpdatePayload: { workField: currentRecord.workField } } },
      15: { _id: userId, data: { step: 11, accountUpdatePayload: { currentRelationshipStatus: currentRecord.currentRelationshipStatus } } },
      16: { _id: userId, data: { step: 12, accountUpdatePayload: { children: currentRecord.children } } },
      // 17: { _id: userId, data: { step: 13, accountUpdatePayload: { incomeRange: currentRecord.incomeRange } } },  //==Removed
      17: { _id: userId, data: { step: 13, accountUpdatePayload: { netWorthRange: currentRecord.netWorthRange } } },
      18: { _id: userId, data: { step: 14, accountUpdatePayload: { preferences: currentRecord.preferences } } },
      19: { _id: userId, data: { step: 15, accountUpdatePayload: { hobbies: currentRecord.hobbies } } },
      20: { _id: userId, data: { step: 16, accountUpdatePayload: { smoke: currentRecord.smoke } } },
      21: { _id: userId, data: { step: 17, accountUpdatePayload: { drink: currentRecord.drink } } },
      22: { _id: userId, data: { step: 18, accountUpdatePayload: { luxuryInterests: currentRecord.luxuryInterests } } },
      23: { _id: userId, data: { step: 19, accountUpdatePayload: { preferences: { aboutPartnerDescription: currentRecord.preferences.aboutPartnerDescription } } } },
      24: { _id: userId, data: { step: 20, accountUpdatePayload: { profilePicture: currentRecord.profilePicture, publicPhotos: currentRecord.publicPhotos, privatePhotos: currentRecord.privatePhotos } } },
      25: { _id: userId, data: { step: 21, accountUpdatePayload: { myHeading: currentRecord.myHeading } } },
      26: { _id: userId, data: { step: 22, accountUpdatePayload: { aboutUsDescription: currentRecord.aboutUsDescription } } },
      27: { _id: userId, data: { step: 23, accountUpdatePayload: { realTimePicture: currentRecord.realTimePicture } } },
    };

    // Actions for specific steps
    const stepSpecificActions = {
      1: () => ({
        data: {
          gender: currentRecord.gender,
          preferences: currentRecord.preferences,
        }
      }),
      2: () => ({
        data: {
          dateOfBirth: currentRecord.dateOfBirth,
        }
      }),
      3: () => ({
        data: {
          isTermsAndConditionsAccepted: currentRecord.isTermsAndConditionsAccepted,
          ReCAPTCHAToken: currentRecord.ReCAPTCHAToken,
        }
      }),
      4: () => ({
        data: {
          email: currentRecord.email,
          gender: currentRecord.gender,
          lookingFor: currentRecord.preferences.gender,
          dateOfBirth: currentRecord.dateOfBirth,
        }
      }),
      5: () => ({
        data: {
          email: currentRecord.email,
          otp: currentRecord.otp,
        },
      }),
      6: () => ({
        data: {
          userId: user._id,
          password: currentRecord.password,
          confirmPassword: currentRecord.confirmPassword,
        },
      }),
      7: () => ({
        data: {
          userId: user._id,
          questions: currentRecord.securityQuestions,
        },
      }),
    };
    // const currentStep = getCurrentStep(); // Replace with your logic to get the current step
    try {
      dispatch(headerShowAction({ data: '', }));
      if (commonSteps.includes(currentStep)) {
        if (currentStep === 24) {
          const payload = commonPayloads[currentStep];
          const updatedPayload = {
            ...payload,
            data: {
              ...payload.data,
              accountUpdatePayload: {
                ...payload.data.accountUpdatePayload,
                profilePicture: currentRecord.profilePicture,
                publicPhotos: currentRecord.publicPhotos?.filter((pic) => pic != null) || [],
                privatePhotos: (currentRecord.privatePhotos || []).filter(Boolean).map(cleanUrl),
              }
            },
          };
          validatePayload(currentStep, updatedPayload.data);
          dispatch(signupUpsertAction(updatedPayload));
        }
        else {
          const payload = commonPayloads[currentStep];
          validatePayload(currentStep, payload.data);
          dispatch(signupUpsertAction(payload));
        }

        // setCurrentStep((prevStep) => prevStep + 1);
      } else if (stepSpecificActions[currentStep]) {
        const payload = stepSpecificActions[currentStep]();
        validatePayload(currentStep, payload);
        switch (currentStep) {
          case 1:
          case 2:
          case 3:
            // setCurrentStep((prevStep) => prevStep + 1);
            const updatedMaxStep = (localStep + 1) > maxStep ? (localStep + 1) : maxStep;
            dispatch(setPayload({ data: { localStep: localStep + 1, maxStep: updatedMaxStep } }));
            break;
          case 4:
            dispatch(signupUpsertAction(payload));
            // setCurrentStep((prevStep) => prevStep + 1);
            break;
          case 5:
            dispatch(verifyOtpAction(payload));
            // setCurrentStep((prevStep) => prevStep + 1);
            break;
          case 6:
            dispatch(createPasswordAction(payload));
            // setCurrentStep((prevStep) => prevStep + 1);
            break;
          case 7:
            dispatch(updateSecurityQuestionAction(payload));
            // setCurrentStep((prevStep) => prevStep + 1);
            break;
          default:
            throw new Error("Invalid step for specific actions");
        }
      } else {
        throw new Error("Invalid step");
      }
    } catch (error) {
      dispatch(headerShowAction({
        data: error.message,
      }
      ));
      // console.error("Validation failed:", error.message);
      // Optionally, display the error message to the user
      // showToast(error.message); // Replace with your notification logic
    }

  };
  const handleBack = () => {
    if (currentStep > 1) //setCurrentStep((prevStep) => prevStep - 1);
      dispatch(setPayload({ data: { localStep: localStep - 1 } }));
    dispatch(headerShowAction({ data: '', }));
  };
  const handleSkip = () => {
    // Customize the skip logic
    if (currentStep < 27) {
      // setCurrentStep((prevStep) => prevStep + 1); // Skip to the next step
      const updatedMaxStep = (localStep + 1) > maxStep ? (localStep + 1) : maxStep;
      dispatch(setPayload({ data: { localStep: localStep + 1, maxStep: updatedMaxStep } }));
      dispatch(headerShowAction({ data: '', }));
    }
  };
  const handleNext = () => {
    if (currentStep < 27) {
      // setCurrentStep((prevStep) => prevStep + 1); // Move to the next step
      const updatedMaxStep = (localStep + 1) > maxStep ? (localStep + 1) : maxStep;
      dispatch(setPayload({ data: { localStep: localStep + 1, maxStep: updatedMaxStep } }));
      dispatch(headerShowAction({ data: '', }));
    }
  }
  useEffect(() => {
    setCurrentStep(localStep);
  }, [localStep])
  // useEffect(() => {
  //   if (user?.profileCompleted) {
  //     navigate("/members");
  //   }
  // },[user?.profileCompleted])
  useEffect(() => {
    let isValid = false;
    try {
      validatePayload(localStep, 
        localStep <= 7 
          ? { data: currentRecord } 
          : { accountUpdatePayload: currentRecord }
      );
      isValid = true;
    } catch (error) {
      isValid = false;
    }  
    setIsValidated(isValid);
  }, [currentRecord, localStep]); 
      useEffect(() => {
       dispatch(headerShowAction({ data: '', }));
       dispatch(setPayload({ data: SignupDefaultProps }));
       setCurrentStep(1);
      },[])

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (<div className="step step1"><GenderInterestSelection /></div>);
      case 2:
        return (<div className="step step2"><TillUsDOB /></div>);
      case 3:
        return (
          <div className="step step3">
            <LinksYourAccount
              type="signup"
              AccountTitle="Great! Let’s Link Your Account"
              AccountSubTitle="Connect your account to get started quickly and securely."
              handleContinue={handleContinue}
            />
          </div>
        );
      case 4:
        return (<div className="step step4"><LinkViaEmail /></div>);
      case 5:
        return (<div className="step step5"><EmailVerification title="Verify Your Email" subtitle="Enter the verification code sent to your email to proceed." handleContinue={handleContinue} /></div>);
      case 6:
        return (<div className="step step6"><CreateYourPassword handleContinue={handleContinue} /></div>);
      case 7:
        return (<div className="step step7"><SecurityQuestionSetup /></div>);
      // case 8:
      //   return (<div className="step step8"><FullName /></div>);
      case 8:
        return (<div className="step step9"><UserNameComponent /></div>);
      case 9:
        return (
          <div className="step step10"> <YourLocation /></div>
        );
      case 10:
        return (
          <div className="step step11"><HeightSelector /></div>
        );
      case 11:
        return (
          <div className="step step12"> <BodyType /></div>
        );
      case 12:
        return (
          <div className="step step13"> <YourEthnicity /></div>
        );
      case 13:
        return (
          <div className="step step14"> <LevelOfEducation /></div>
        );
      case 14:
        return (
          <div className="step step15"><YourField /></div>
        );
      case 15:
        return (
          <div className="step step16"> <RelationshipStatus /></div>
        );
      case 16:
        return (
          <div className="step step17"><HaveChildren /></div>
        );
      // case 17:
      //   return (
      //     <div className="step step18"> <YourIncomeRange /></div>
      //   );
      case 17:
        return (
          <div className="step step19"> <NetWorth /></div>
        );
      case 18:
        return (
          <div className="step step20"> <AgePreferences /></div>
        );
      case 19:
        return (
          <div className="step step21"> <YourHobbies /></div>
        );
      case 20:
        return (
          <div className="step step22"> <DoYouSmoke /></div>
        );
      case 21:
        return (
          <div className="step step23"> <DoYouDrinkAlcohol /></div>
        );
      case 22:
        return (
          <div className="step step24"> <LuxeInterests /></div>
        );
      case 23:
        return (
          <div className="step step25"> <YouLookingForPartner /></div>
        );
      case 24:
        return (
          <div className="step step26">  <PhotoUpload /></div>
        );
      case 25:
        return (
          <div className="step step27">  <CreateCatchyHeading /></div>
        );
      case 26:
        return (
          <div className="step step28"> <FewWords /></div>
        );
      case 27:
        return (
          <div className="step step29"> <SelfieVerification /></div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-16 signUpmain">
            <Box className="d-flex flex-column align-items-stretch justify-content-between signUpTextdv">
              <div className="">
                <div className="d-flex align-items-center justify-content-between logoWithTop py-0">
                  <div className="Logo">
                    <Link to="/">
                      <img
                        src="/assets/images/signup/logo-signup.svg"
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="font-16 fw-medium pb-24">
                    Already have an account? <Link className="text-gold-light" to="/login">Log In</Link>
                  </div>
                </div>
                <div className="maxw602">
                  {/* <div className="d-flex align-items-center justify-content-between">
                    {
                      localStep !== 6 && (
                        <button
                          className="bg-transparent border-0 text-dark font-16 font-m"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      )
                    }

                    {/* Conditionally render the "Signup" div */}
                    {/* {currentStep <= 5 && (
    <div className="font-32 font-m fw-medium">Signup</div>
  )}  
                    <div className="font-32 font-m fw-medium">
                      {currentStep <= 5 ? "Signup" : <span className='font-16'>Profile Setup</span>}
                    </div>
                    <button
                      className="bg-transparent border-0 text-dark font-16 font-m"
                      disabled={(localStep + 1) > maxStep}
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  </div> */}
                  <div className="d-flex align-items-center justify-content-between bknxtSnup">
  {
    localStep !== 6 && (
      <button
        className="bg-transparent border-0 text-dark font-16 font-m"
        onClick={handleBack}
        disabled={localStep === 1} // First step pe disable rahega
      >
        Back
      </button>
    )
  }

  <div className="font-32 font-p fw-medium">
    {currentStep <= 5 ? "Signup" : <span className='font-16'>Profile Setup</span>}
  </div>

  <button
    className="bg-transparent border-0 text-dark font-16 font-m"
    disabled={(localStep + 1) > maxStep}
    onClick={handleNext}
  >
    Next
  </button>
</div>

                  {currentStep <= 5 && (
                    <Box
                      sx={{
                        marginY: 2,
                        height: 5,
                        background: '#ddd',
                        borderRadius: 2,
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        className="linecustmSet"
                        sx={{
                          width: `${(currentStep / 27) * 100}%`,
                          height: '100%',
                        }}
                      ><span className="bgsignline"></span></Box>
                    </Box>
                  )}
                </div>
              </div>
              {
                headerMessages?.length > 0 && (
                  <div className="maxw602">
                    <div className="alert alert-danger alert-dismissible p-2 fade mb-0 mt-16 show" role="alert">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="text-center">
                          <p className="mb-0">{headerMessages}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              <Box className="h-100 maxw602">{renderStepContent(currentStep)}</Box>
              <div className="bg-white text-center maxw602 mt-32 stykbtnsebtn" style={{ position: 'sticky', bottom: '0' }}>
                <div className="text-center mb-0 mt-16">
                  {([14, 16, 17, 23].includes(currentStep)) && (
                    <button
                      disabled={isValidated}
                      className="bg-white border-0 text-decoration-underline font-16 font-m fw-medium skipbtn"
                      onClick={handleSkip} // Skip action: move to the next step
                    >
                      Skip
                    </button>
                  )}
                </div>
                <div className="pb-16 pdBopsetmobile">
                  <small className="color5f font-m fst-italic fw-medium">
                    {stepTexts[currentStep]}
                  </small>
                </div>
                <Box className="ctfnsbtn">
                  {
                    currentStep < 27 && (
                      <Button
                        variant="contained"
                        disabled={!isValidated}
                        sx={{
                          width: '100%',
                          backgroundColor: '#916008',
                          padding: '10px',
                          color: '#fff',
                          '&:hover': { backgroundColor: '#9d6f3b' },
                        }}
                        onClick={handleContinue}
                      >
                        {currentStep === 27 ? 'Finish' : 'Continue'}
                      </Button>
                    )
                  }

                </Box>
              </div>

            </Box>

            <div className="rightImgSignup">
              <img
                src="/assets/images/how-luxury-life-works-bg.png"
                className="img-fluid"
                alt="background"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SignUpContainer;