import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LuxuryFooter from "src/components/luxury-home/LuxuryFooter";
import Header from "src/containers/home-container/layout/Header";

const sections = [
    {
        id: "INTRODUCTION",
        title: "INTRODUCTION",
        content: (
            <>
                <p>Luxury Life ("Company," "we," "us") is committed to maintaining a secure and exclusive community. Privacy and confidentiality are of the utmost importance to our members, applicants, and other users ("you"). This Privacy Policy outlines the information we collect, how we use it, and with whom it may be shared. By using our website or related services (collectively, the "Service"), you agree to be bound by this Privacy Policy. We encourage you to read this Privacy Policy and our Terms of Use carefully and contact us at <Link to="mailto:support@luxurylife.ai" style={{}}>support@luxurylife.ai</Link> with any questions.</p>

                <p>We may update this Privacy Policy periodically. When changes occur, we will revise the "updated" date. Continued use of the Service following updates constitutes acceptance of the revised policy. If you do not consent to the changes, you may terminate your account at any time. </p>

            </>
        )
    },
    {
        id: "INFORMATIONCOLLECTION",
        title: "INFORMATION COLLECTION",
        content: (
            <>
                <p><span className="d-block fw-bold">Information You Provide</span>
                    To apply for membership, you may be asked to provide credentials such as your social media handles, contact details, professional background, and personal preferences. This information is used for identity verification and to ensure alignment with our community standards. Additional details such as images, preferences, and a biographical description may be collected to enhance your profile. </p>

                <p>You may also choose to share your location data for personalized recommendations. Communications within the website, including messages and interactions, may be stored to ensure community safety and compliance with our guidelines. </p>

                <p><span className="d-block fw-bold">Information From Other Users</span>
                    Other members may provide information about you, such as recommendations or reports. This helps us maintain a high-quality and trusted network. </p>

                <p><span className="d-block fw-bold">Information Collected Automatically</span>
                    We may automatically collect data related to your device, IP address, interactions with other users, and website usage. If you enable location services, we may collect real-time location data. Even if you do not enable location services, we may infer your general location based on your IP address. </p>

                <p><span className="d-block fw-bold">Financial Information</span>
                    Payments are processed exclusively through third-party provider, Stripe. We do not collect or store your financial details. </p>

                <p><span className="d-block fw-bold">Cookies and Tracking Technologies</span>
                    We use cookies and similar technologies to enhance user experience, analyze trends, and remember preferences. Most browsers allow you to control cookies through settings. </p>

            </>
        )
    },
    {
        id: "USEANDSHARINGOFINFORMATION",
        title: "USE AND SHARING OF INFORMATION",
        content: (
            <>
                <p><span className="d-block fw-bold">With Other Members</span>
                    Your profile information, including name, social media links, professional details, and select personal interests, may be visible to other members. Mutual connections enable direct messaging and interactions within the Service.</p>

                <p><span className="d-block fw-bold">With Third-Party Service Providers</span>
                    We may share non-personal, aggregated data with analytics providers to improve the Service. Third-party partners assisting in technical operations, fraud prevention, and customer support may also have limited access to necessary data under confidentiality agreements.</p>

                <p><span className="d-block fw-bold">Legal and Compliance Obligations</span>
                    We may disclose information if required by law, to enforce our Terms of Use, to investigate fraudulent activities, or in response to legal requests by authorities.</p>

                <p><span className="d-block fw-bold">Business Transactions</span>
                    In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction. You will be notified of any changes to data handling in such cases.</p>
            </>
        )
    },
    {
        id: "DATASECURITY",
        title: "DATA SECURITY",
        content: (
            <>
                <p>We implement industry-standard security measures to protect your data. However, no system is entirely secure. We encourage users to maintain strong passwords and exercise caution when sharing personal information.</p>
            </>
        )
    },
    {
        id: "DATARETENTION",
        title: "DATA RETENTION",
        content: (
            <>
                <p>Your information is retained for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, and resolve disputes. Deleted accounts are subject to a retention window for security and compliance purposes before data is fully removed.</p>
            </>
        )
    },
    {
        id: "YOURRIGHTS",
        title: "YOUR RIGHTS",
        content: (
            <>
                <p>You have control over your personal data and may:</p>
                <ul>
                    <li>Access, modify, or delete your profile information within the settings.</li>
                    <li>Adjust permissions for data collection, such as location services and notifications.</li>
                    <li>Request a copy of your personal data or its deletion by contacting us at <Link to="mailto:support@luxurylife.ai" style={{}}>support@luxurylife.ai</Link>.</li>
                    <li>We may decline requests that are excessive, infringe on others' privacy, or conflict with legal obligations.</li>
                </ul>
            </>
        )
    },
    {
        id: "AGEPOLICY",
        title: "AGE POLICY",
        content: (
            <>
                <p>Our Service is restricted to individuals 18 years and older. Misrepresentation of age may result in immediate account suspension. We do not knowingly collect information from minors.</p>
            </>
        )
    },
    {
        id: "JURISDICTIONANDCONTACT",
        title: "JURISDICTION AND CONTACT",
        content: (
            <>
                <p>This Privacy Policy is governed by the laws of India and USA. By using the Service, you consent to the exclusive jurisdiction of Indian and US courts. </p>
                <p>For questions or concerns regarding this Privacy Policy, contact us at <Link to="mailto:support@luxurylife.ai" style={{}}>support@luxurylife.ai</Link>.</p>
            </>
        )
    },
    {
        id: "COMPANYDETAILS",
        title: "COMPANY DETAILS",
        content: (
            <>
                <p><span className="d-block fw-bold">Company Name</span>
                    Acadecraft Inc.</p>

                <p><span className="d-block fw-bold">Legal Entity Type </span>
                    Corporation</p>

                <p><span className="d-block fw-bold">Registered Address</span>
                    A-61, B/3 Ground Floor, Sector-63, Noida, Uttar Pradesh, India-201301 </p>
            </>
        )
    },
    {
        id: "COMPANYCONTACTINFORMATION",
        title: "COMPANY CONTACT INFORMATION",
        content: (
            <>
                <p><span className="d-block fw-bold">Email</span>
                    info@acadecraft.com </p>

                <p><span className="d-block fw-bold">Phone </span>
                    IN +91 700 053 0247</p>

                <p><span className="d-block fw-bold">Website </span>
                    www.acadecraft.com </p>

                <p><span className="d-block fw-bold">Business Hours </span>
                    9 am to 10 pm IST</p>
            </>
        )
    }
];

const PrivacyPolicy = () => {
    const [activeSection, setActiveSection] = useState("");

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.pageYOffset;
            sections.forEach(({ id }) => {
                const section = document.getElementById(id);
                if (section) {
                    const vwOffset = (5 / 100) * window.innerWidth; // 5vw ko px me convert kiya
                    const sectionTop = section.offsetTop - vwOffset;
                    const sectionHeight = section.offsetHeight;
                    if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                        setActiveSection(id);
                    }
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [sections]);

    // ✅ `handleNavClick` ko useEffect ke bahar define karein
    const handleNavClick = (event, id) => {
        event.preventDefault();
        const section = document.getElementById(id);
        if (section) {
            const vwOffset = (4 / 100) * window.innerWidth; // 4vw ko px me convert kiya
            const sectionTop = section.offsetTop - vwOffset;

            window.scrollTo({
                top: sectionTop,
                behavior: "smooth",
            });

            setActiveSection(id); // Active section update karein
        }
    };

    return (
        <>
            <div className='aboutusnEwv1' style={{ padding: '5vw 20.5vw' }}>
                <Header />
                <div className="Terms-privCont d-flex">
                    <div className="privCont001" style={{ minWidth: '23vw' }}>
                        <div className="hdtoptermpriv">
                        <h2 className="font48vw text-black fw-bold" style={{ padding: '2vw 0 0vw 0' }}>Luxury Life - Privacy Policy</h2>  
                            
                            <p className="font08vw color7b">Updated and Effective 31-01-2025</p>
                        </div>
                        <nav className="sidebar-TermsPrivacy position-sticky">
                            <ul className="navigation">
                                {sections.map(({ id, title }) => (
                                    <li key={id}>
                                        <a
                                            href={`#${id}`}
                                            className={activeSection === id ? "active" : ""}
                                            onClick={(e) => handleNavClick(e, id)}
                                        >
                                            {title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="TermsPrivacy-content" style={{ minWidth: "32.5vw" }}>
                        {sections.map(({ id, title, content }) => (
                            <section id={id} key={id}>
                                <h2>{title}</h2>
                                {content}
                            </section>
                        ))}
                    </div>
                </div>
            </div>
            <LuxuryFooter />
        </>
    );
}

export default PrivacyPolicy