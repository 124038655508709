import React, { useState } from "react";
import {
    Box,
    Checkbox,
    Typography,
    Button,
    FormControlLabel,
} from "@mui/material";

import Modal from '@mui/material/Modal';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: "80vh", // Restricts height for scroll
    overflowY: "auto", // Adds vertical scroll
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    p: "20px 20px 0 20px",
};

// Common style object for FormControlLabel and Checkbox
const checkboxStyle = {
    display: "flex",
    justifyContent: "space-between",
    textAlign: 'left',
    border: '1px solid #ccc',
    borderRadius: '10px',
    marginBottom: '10px',
    width: "100%",
    padding: '12px',
    marginLeft: "0",
    fontSize: "14px", // Label font size
    ".MuiTypography-root": {
        fontSize: "14px", // Label text font size
    },
};

const GuidelinesForm = () => {
    const [checked, setChecked] = useState({
        respectful: false,
        intentional: false,
        authentic: false,
        journey: false,
    });

    const handleCheckboxChange = (event) => {
        setChecked({ ...checked, [event.target.name]: event.target.checked });
    };


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography variant="h5" className="text-center" sx={{ fontSize: '32px', fontWeight: 'bold', color: '#000', }} gutterBottom>
                        Thank you for joining the Luxurylife community!
                    </Typography>
                    <Typography variant="body1" className="text-center" gutterBottom sx={{ fontSize: '18px', color: '#000', }}>
                        As a valued member, please keep the following guidelines in mind:
                    </Typography>
                    <Typography variant="body2" className="text-center" color="text.secondary" sx={{ fontSize: '18px', color: '#916008', }} gutterBottom>
                        <span className="d-block fw-medium"> Note:</span>
                        Kindly review each section, check the boxes, and click "I Agree" before engaging with other members. This agreement will only appear once.
                    </Typography>

                    <Box mt={3}>
                        {[
                            {
                                label:
                                    "Be Respectful: Luxurylife upholds high standards for our members. Any form of rudeness or disrespect may result in removal from the platform.",
                                name: "respectful",
                            },
                            {
                                label:
                                    "Intentional Connections: Luxurylife is a platform for meaningful relationships. Please refrain from joining solely for casual encounters or hookups.",
                                name: "intentional",
                            },
                            {
                                label:
                                    "Authentic Relationships Only: Luxurylife promotes genuine connections and does not support pay-per-meet (PPM) arrangements.",
                                name: "authentic",
                            },
                            {
                                label:
                                    "Embrace the Journey: Enjoy the attention, exclusivity, and elegance of being part of our community.",
                                name: "journey",
                            },
                        ].map((item, index) => (
                            <FormControlLabel
                                key={index}
                                sx={{
                                    ...checkboxStyle,
                                    backgroundColor: checked[item.name] ? "rgba(191, 133, 0, 0.08)" : "transparent", // Change background when checked
                                    borderColor: checked[item.name] ? "#916008" : "#ccc", // Change border color when checked
                                    borderWidth: "2px", // Add border width for better visibility
                                }}
                                control={
                                    <Checkbox
                                        name={item.name}
                                        checked={checked[item.name]}
                                        onChange={handleCheckboxChange}
                                        sx={{
                                            color: "#ccc", // Color when unchecked
                                            "&.Mui-checked": {
                                                color: "#916008", // Color when checked
                                            },
                                        }}
                                    />
                                }
                                label={item.label}
                            />
                        ))}
                    </Box>

                    <Box className="mt-24 position-sticky bottom-0 z-3 pb-16" sx={{ background: '#fff', }}>

                    </Box>

                    {/* Buttons */}
                    <Box className="border-top mt-16 position-sticky bottom-0 z-3 bg-base pb-16 pt-10" sx={{ marginTop: "20px", background: '#fff' }}>

                        <small className="text-center color5f">
                            By adhering to these guidelines, we can ensure a safe, welcoming, and luxurious experience for everyone.
                        </small>
                        <Button
                            variant="contained"
                            color="primary"
                            className="w-100 mt-10"
                            onClick={handleClose}
                            sx={{
                                background: "#916008",
                                color: "#fff",
                                fontSize: "16px",
                                textTransform: "capitalize",
                                fontFamily: "Neue Montreal",
                                width: "100%",
                            }}
                        >
                            I Agree
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default GuidelinesForm;
