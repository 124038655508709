// const isLive = ["localhost", "127.0.0.1", "::1"].includes(
//   window.location.hostname
// )
//   ? false
//   : true;

const isLive = false
const config = require(`./${isLive ? "production" : "local"}`); //`./${process.env.REACT_APP_MODE || 'local'}`
export const isProduction = () => isLive; //process.env.REACT_APP_MODE === 'production';
export default config.default;
