import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ProfileCompletion from "./ProfileCompletion";
import { Container } from '@mui/material';
import BasicInformation from "./BasicInformation";
import AdditionalInformation from "./AdditionalInformation";
import AdditionalPhotosUpload from "./AdditionalPhotosUpload";
import PhotoRequestList from "./PhotoRequestList";
import Footer from "../home-container/layout/Footer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCurrentActiveTabAction } from "../account-setting/actions/AccountSettingActions";
import { setPayload, signupUpsertAction } from "../signup-container/actions/SignupActions";
import { headerShowAction } from "../header-container/actions/HeaderActions";
import { validatePayload } from "../signup-container/SignupPropTypes";
import { getAuthInfoAction } from "../auth-container/actions/AuthActions";
import { getPrivatePicRequestAction } from "./actions/ProfileActions";

const UserProfileContainer = () => {
    const {
        currentRecord,
    } = useSelector((state) => state.SignupReducer, shallowEqual);
    const {
        headerMessages,
    } = useSelector((state) => state.HeaderReducer, shallowEqual);
    const { user } = useSelector((state) => state.auth, shallowEqual);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    // const [userData, setUserData] = useState(user); // State for user data
    // const [progress, setProgress] = useState(50); // Default progress value

    // Load user on page load
    useEffect(() => {
        dispatch(getAuthInfoAction());
    }, [dispatch]);

    // Simulate fetching user data (you should replace this with an actual API call)
    useEffect(() => {
        dispatch(setPayload({ data: user }));
    }, [user])

    // Handle case where user data is not loaded yet
    if (!user) return <div>Loading...</div>;

    const handleNavigationSubscription = () => {
        dispatch(setCurrentActiveTabAction({ data: { currentActiveTab: 'Membership and Billing' } }));
        navigation('/account-settings');
    }
    // isInternal:true for reload auth info
    const updateUserInfo = (step) => {
        const commonSteps = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
        const commonPayloads = {
            8: { _id: user._id, data: { step: 4, isInternal: true, accountUpdatePayload: { userName: currentRecord.userName } } },
            9: { _id: user._id, data: { step: 5, isInternal: true, accountUpdatePayload: { location: currentRecord.location, city: currentRecord.city, state: currentRecord.state, country: currentRecord.country } } },
            10: { _id: user._id, data: { step: 6, isInternal: true, accountUpdatePayload: { tall: currentRecord.tall } } },
            11: { _id: user._id, data: { step: 7, isInternal: true, accountUpdatePayload: { bodyType: currentRecord.bodyType } } },
            12: { _id: user._id, data: { step: 8, isInternal: true, accountUpdatePayload: { ethnicity: currentRecord.ethnicity } } },
            13: { _id: user._id, data: { step: 9, isInternal: true, accountUpdatePayload: { highestEducation: currentRecord.highestEducation } } },
            14: { _id: user._id, data: { step: 10, isInternal: true, accountUpdatePayload: { workField: currentRecord.workField } } },
            15: { _id: user._id, data: { step: 11, isInternal: true, accountUpdatePayload: { currentRelationshipStatus: currentRecord.currentRelationshipStatus } } },
            16: { _id: user._id, data: { step: 12, isInternal: true, accountUpdatePayload: { children: currentRecord.children } } },
            17: { _id: user._id, data: { step: 13, isInternal: true, accountUpdatePayload: { netWorthRange: currentRecord.netWorthRange } } },
            18: { _id: user._id, data: { step: 14, isInternal: true, accountUpdatePayload: { preferences: currentRecord.preferences } } },
            19: { _id: user._id, data: { step: 15, isInternal: true, accountUpdatePayload: { hobbies: currentRecord.hobbies } } },
            20: { _id: user._id, data: { step: 16, isInternal: true, accountUpdatePayload: { smoke: currentRecord.smoke } } },
            21: { _id: user._id, data: { step: 17, isInternal: true, accountUpdatePayload: { drink: currentRecord.drink } } },
            22: { _id: user._id, data: { step: 18, isInternal: true, accountUpdatePayload: { luxuryInterests: currentRecord.luxuryInterests } } },
            23: { _id: user._id, data: { step: 19, isInternal: true, accountUpdatePayload: { preferences: { aboutPartnerDescription: currentRecord.preferences.aboutPartnerDescription } } } },
            24: { _id: user._id, data: { step: 20, isInternal: true, accountUpdatePayload: { profilePicture: currentRecord.profilePicture, publicPhotos: currentRecord.publicPhotos, privatePhotos: currentRecord.privatePhotos } } },
            25: { _id: user._id, data: { step: 21, isInternal: true, accountUpdatePayload: { myHeading: currentRecord.myHeading } } },
            26: { _id: user._id, data: { step: 22, isInternal: true, accountUpdatePayload: { aboutUsDescription: currentRecord.aboutUsDescription } } },
            27: { _id: user._id, data: { step: 23, isInternal: true, accountUpdatePayload: { realTimePicture: currentRecord.realTimePicture } } },
        };
        try {
            const currentStep = step;
            if (commonSteps.includes(currentStep)) {
                const payload = commonPayloads[currentStep];
                validatePayload(currentStep, payload.data);
                dispatch(signupUpsertAction(payload));
            }
        } catch (error) {
            dispatch(headerShowAction({
                data: error.message,
            }
            ));
            // console.error("Validation failed:", error.message);
            // Optionally, display the error message to the user
            // showToast(error.message); // Replace with your notification logic
        }
    }

    return (
        <>
            <Container maxWidth="100%" className="px-5vw">
                {
                    headerMessages?.length > 0 && (
                        <div className="maxw602">
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16"> 
                                        </svg>
                                    </div>
                                    <div className="ms-3">
                                        <p className="mb-0">{headerMessages}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="d-flex align-items-center justify-content-between gap-5 py-24vw user-profilemobile">
                    <div className="user-profile-header gap-15 align-items-center">
                        <Link to="/members" className='arrlinkleft' style={{ padding: '0.6vw', color: '#000' }}>
                            <ArrowBackIosNewOutlinedIcon />
                        </Link>
                        {/* Profile Image */}
                        <img src={user.profilePicture} alt="Profile" className="profile-edit-image" />
                        {/* User Details */}
                        <div className="user-profile-details">
                            <div className="userheddtal">
                                <div className="myProfileTexthead font-16 fw--medium colorc3">My Profile</div>
                                <h2 className="d-flex align-items-center gap-1">{user.userName}, {user.age} <span className="user-online-status"></span> <span className="veryfiedIcnc wset001"><img src="/assets/images/icons/check-blue.svg" class="img-fluid" alt="" /></span>  {
                                    (!user.isSubscribed) && <button onClick={() => handleNavigationSubscription()} className="ms-32 gradientbtn border-0 font-16 font-m fw-medium text-white updatedPlnPre py-6 rounded-3">Upgrade to premium</button>
                                }</h2>
                                <p className="user-location mb-0">{`${user.country}, ${user.city}`}</p> 

                            </div>
                        </div>
                    </div>
                    <div className='ProfileCompletionBs'>
                        <ProfileCompletion progress={user.profileCompletion} />
                    </div>
                </div>
            </Container>
            <section className="border-top">
                <Container  maxWidth="100%" className="px-5vw">
                    <div className="d-flex justify-content-between profileEditstip">
                        <div className="mw-wdfine p-32 border-end">
                            <BasicInformation updateUserInfo={updateUserInfo} />
                            <AdditionalInformation updateUserInfo={updateUserInfo} />
                        </div>
                        <div className="mw-wdfine2 p-32">
                            {/* <PhotoUpload /> */}
                            <AdditionalPhotosUpload updateUserInfo={updateUserInfo} />
                            <PhotoRequestList />
                        </div>
                    </div>
                </Container>
            </section>
            {/* <Footer /> */}
        </>
    );
}
export default UserProfileContainer