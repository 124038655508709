import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const LevelOfEducation = () => {
  const dispatch = useDispatch();
  const {
    highestEducation,
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [education, setEducation] = useState(highestEducation || "");

  // Define the education options dynamically
  const educationOptions = [
    "High School or Equivalent",
    "Some college",
    "Associates Degree",
    "Bachelors Degree",
    "Master Degree",
    "Doctorate or PhD",
    "Prefer not to say"
  ];

  // const handleEducation = (value) => {
  //   setEducation(value);
  //   dispatch(setPayload({ data: { highestEducation: value } }));
  // };

  const handleEducation = (value) => {
    // If the same option is clicked again, unselect it
    const newValue = education === value ? "" : value;
    setEducation(newValue);
    dispatch(setPayload({ data: { highestEducation: newValue } }));
  };

  return (
    <div>
      <div className="">
        <div className="font-m font-32 fw-medium text-center">
          What is Your Highest Level of Education?
        </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
        This detail helps us create a bespoke experience.
        </div>
      </div>
      <div className="education-selection text-center mt-32 mb-16">
        {/* <h3 className="font-18 font-m fw-medium mt-24 mb-16">
          Choose Your Level of Education
        </h3> */}
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render buttons */}
          {educationOptions.map((option, index) => (
            <button
              key={index}
              className={`font-m border font-16 fw-normal ${education === option ? "selected" : ""
                }`}
              onClick={() => handleEducation(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LevelOfEducation