import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'; 
import CardActions from '@mui/material/CardActions'; 
import { Button, Box, Grid, Typography, Stack } from '@mui/material'; 
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import VerifyYourIdentity from '../../Component/Profile/user-profile/VerifyYourIdentity';
import IdentityUpload from '../../Component/Profile/user-profile/IdentityUpload';
import CompleteVerification from './CompleteVerification';
import StatusComponent from './StatusComponent';
import SelfieVerificationComponent from '../selfie-verification/index';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAuthInfoAction } from 'src/containers/auth-container/actions/AuthActions'; 
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import NewComponent from '../../Component/Profile/user-profile/NewComponent'; // Import your new component

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '700px',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxHeight: '90%',
  overflow: "auto",
  p: '30px 30px 0 30px',
  borderRadius: '20px',
};

const YourVerifications = ({ open, handleClose }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [modalOpen, setModalOpen] = useState(false);
  const [verificationType, setVerificationType] = useState(null); // Manage which flow to display
  const [isVerificationStarted, setIsVerificationStarted] = useState(false); // For toggling button text between 'Start' and 'Submit'
  const [isVerificationInProgress, setIsVerificationInProgress] = useState(false); // For toggling step
  const [isFinalStep, setIsFinalStep] = useState(false); // For managing the new component step
  const [currentStep, setCurrentStep] = useState(0); // For step-based photo verification


  const [facebookVerified, setFacebookVerified] = useState(false);
  const [instagramVerified, setInstagramVerified] = useState(false);
  const [linkedinVerified, setLinkedinVerified] = useState(false);

  const handleVerify = (platform) => {
    if (platform === 'facebook') setFacebookVerified(true);
    if (platform === 'instagram') setInstagramVerified(true);
    if (platform === 'linkedin') setLinkedinVerified(true);
  };


  const handleOpen = (type) => {
    setVerificationType(type);
    setModalOpen(true);
    setIsVerificationStarted(false); // Reset state
    setIsVerificationInProgress(false); // Reset state
    setIsFinalStep(false); // Reset state
    setCurrentStep(0); // Reset photo verification steps
  };

  const handleModalClose = () => {
    setModalOpen(false);
    dispatch(getAuthInfoAction());
  };

  const handleVerificationButtonClick = () => {
    if (verificationType === 'photo') {
      // Increment the step for photo verification
      setCurrentStep(currentStep + 1);
      // if (currentStep === 0 || currentStep === 1) {
      //   setCurrentStep(currentStep + 1);
      // } else if (currentStep === 2) {
      //   setCurrentStep(6);
      // }
    } else {
      // ID verification flow
      if (isVerificationInProgress) {
        // Transition to final step
        setIsFinalStep(true);
        setIsVerificationInProgress(false);
      } else if (isFinalStep) {
        // Final submission logic
        handleModalClose(); // Close modal after finishing
      } else {
        // Start the verification
        setIsVerificationStarted(true);
        setIsVerificationInProgress(true);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setModalOpen(true); // Open modal when `open` prop changes to true
    }
  }, [open]);
  useEffect(() => {
    dispatch(getAuthInfoAction());
  }, [])

  return (
    <div>
      <div className='msetmobile mb-10'>
        <Typography className="ac-heading"
          variant="h6"
          gutterBottom
          sx={{ fontWeight: '500', color: '#000', fontFamily: 'ivypresto-headline', }}
        >
          Your Verifications
        </Typography>
        <Typography className="font875vw"
          variant="p"
          gutterBottom
          sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}
        >
          Verifications help keep our members safe and trustworthy...
        </Typography>
      </div>

      <div className="container">
      <div className="row">
        <div className="col-md-6" item>
          <Card className="h-100 d-flex flex-column justify-content-between align-items-center p1vw border001vw" sx={{ boxShadow: 'none' }}>
            <div>
              <CardMedia className="ac-verificationImg"
                component="img"
                height="140"
                image="/assets/images/account-settings/id-verification.png"
                alt="ID Verification"
                sx={{ objectFit: 'contain' }}
              />
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography
                  gutterBottom
                  component="div" className="font105vw vrfmovTxt"
                  sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}
                >
                  ID Verification
                </Typography>
                <Box>
                  <Typography className="font875vw"
                    variant="body2"
                    sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}
                  >
                    To ensure the highest level of trust and security, we offer an ID verification service. This process includes submitting a government-issued ID along with a thorough background check, confirming your identity, and verifying your background. This paid option guarantees the utmost authenticity and helps you build credibility on the platform, ensuring you're connecting with trusted individuals.
                  </Typography>
                  <Typography className="font875vw mt24vw"
                    sx={{ fontStyle: 'italic', fontWeight: '400', color: '#7B7B7B', fontFamily: 'indivisible' }}
                  >
                    (Note: Provides trust by securely validating your identity and background.)
                  </Typography></Box>
                {
                  user?.documents?.status === 'Approved' ?
                    (
                      <>
                        <div className='font875vw fw-normal color7b font-m text-center py-24'>
                          <div className='d-flex justify-content-center flex-column align-items-center gap-1 font875vw fw-bold colorc3'><span className='d-block verified-icon01'><img src="/assets/images/verified-icon.svg" className='img-fluid' /></span> Verified
                            <p className='font875vw' style={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Your identity has been confirmed, ensuring a secure and trusted experience on Luxurylife.</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Box className="text-start">
                          <div className='text-center d-flex justify-content-center align-items-center gap-2 startusCkk font875vw mb32vw'>Status:<StatusComponent status={user?.documents?.status ? user?.documents?.status : "Unverified"} /></div>
                          <p className='font875vw fw-normal color7b font-m text-start mb04vw'><span className='fw-medium font875vw d-block'>Explanation:</span>
                            The image you provided is for an ID verification service. The process of verifying your identity has likely not yet been completed. Therefore, the current status is pending.
                          </p>
                          <p className='font875vw fw-normal color7b font-m mb04vw'>
                            Once the company's team has verified your ID and performed the background check, your status will either be:
                          </p>
                          <div className='font875vw fw-normal color7b font-m'> <p className='mb-0 font875vw'><span className='fw-medium'>Verified:</span> If the verification and background check are successful.</p>
                            <p className='mb-0 font875vw'><span className='fw-medium'>Declined:</span> If the verification or background check fails.</p>
                            <p className='mb-0 font875vw'> I hope this helps!</p>
                          </div>
                        </Box>
                      </>
                    )
                }
              </CardContent>
            </div>
            {user?.documents?.status !== 'Pending' && user?.documents?.status !== 'Rejected' && user?.documents?.status !== 'Approved' && (
              <CardActions>
                <Button
                  size="small"
                  onClick={() => handleOpen('id')}
                  className="fillBtn2 px20vw py10vw mx-auto font875vw btnMobFont"
                  sx={{ fontSize: '12px', fontWeight: '400', color: '#7B7B7B', fontFamily: 'indivisible' }}
                >
                  Initiate Verification
                </Button>
              </CardActions>
            )}
          </Card>
        </div>

        <div className="col-md-6" item>
          <Card className="h-100 d-flex flex-column justify-content-between align-items-center p-20 border" sx={{ boxShadow: 'none' }}>
            <div>
              <CardMedia className="ac-Photo-verificationImg"
                component="img"
                height="140"
                image="/assets/images/account-settings/photo-verifications.png"
                alt="Photo Verification"
                sx={{ objectFit: 'contain' }}
              />
              <CardContent sx={{ textAlign: 'center' }}>
                {
                  !user?.isProfileVerified &&
                  <Typography
                    gutterBottom
                    component="div"
                    className='font105vw vrfmovTxt' style={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}
                  >
                    Selfie Verification
                  </Typography>
                }
                <Box>
                  <Typography
                    variant="body2"
                    className='font875vw' style={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}
                  >
                    For users who prefer a quicker, no-cost verification, we also offer selfie verification. This process requires you to upload a selfie that matches your profile picture. We use advanced technology to confirm the authenticity of your image and ensure that your profile is genuine. This option is easy to complete and enhances the overall safety of your interactions.
                  </Typography>
                  <Typography
                    className='font875vw mt24vw' style={{ fontStyle: 'italic', fontWeight: '400', color: '#7B7B7B', fontFamily: 'indivisible' }}
                  >
                    (Note: Quick, easy, and free to verify profile authenticity instantly.)
                  </Typography>
                </Box>
                {
                  user?.selfieVerificationStatus === 'Approved' ?
                    (
                      <>
                        <div className='font875vw fw-normal color7b font-m text-center py24vw'>
                          <div className='d-flex justify-content-center flex-column align-items-center gap-1 font875vw fw-bold colorc3'><span className='d-block verified-icon01'><img src="/assets/images/verified-icon.svg" className='img-fluid' /></span> Verified
                            <p className='font875vw' style={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Your profile is trusted and ready to connect with others.</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Box className="text-start">
                          <div className='text-center d-flex justify-content-center align-items-center gap-2 startusCkk font875vw mb32vw'>Status:<StatusComponent status={user?.selfieVerificationStatus ? user?.selfieVerificationStatus : "Unverified"} /></div>
                          <p className='font875vw fw-normal color7b font-m text-start mb04vw'><span className='fw-medium font875vw d-block'>Explanation:</span>
                            <span className='fw-normal font875vw d-block'>We need to confirm it’s really you! Follow these quick steps to complete the identity verification.</span>
                          </p>
                        </Box>
                      </>
                    )
                }
              </CardContent>
            </div>
            {user?.selfieVerificationStatus !== 'Pending' && user?.selfieVerificationStatus !== 'Rejected' && user?.selfieVerificationStatus !== 'Approved' && (
              <CardActions>
                <Button
                  size="small"
                  onClick={() => handleOpen('photo')}
                  className="fillBtn2 px20vw py10vw mx-auto font875vw btnMobFont"
                  sx={{ fontWeight: '400', color: '#7B7B7B', fontFamily: 'indivisible' }}
                >
                  Start Verification
                </Button>
              </CardActions>
            )
            }
          </Card>

        </div>

<div className='col-md-12'>
<Box sx={{ padding: '30px 0' }}>
      <Typography className="ac-heading font-hding" variant="h5" fontWeight="500" sx={{color: '#081726',mb: 2,fontFamily: 'ivypresto-headline',}}>Social Media Verification</Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3, fontSize: '14px', color: '#081726', fontFamily: 'indivisible',}}>
      To enhance trust and authenticity, we offer social media verification. Connect your Instagram, LinkedIn, or Facebook account to confirm your identity and establish credibility. This process ensures you’re engaging with real, high-value individuals and unlocks exclusive platform features.
      </Typography>

      <Stack direction="row" spacing={2}>
        {/* Facebook */}
        <Button
          variant="contained"
          color="primary" 
          fullWidth
          onClick={() => handleVerify('facebook')}
          disabled={facebookVerified}
          sx={{ textTransform: 'none', minWidth: '200px', justifyContent: 'start', display: 'flex', alignItems: 'center', gap: '12px', padding: '12px 15px', borderRadius: '10px', border: 'none', background: '#1877F2', boxShadow: '0 0 10px rgba(0,0,0,0.3)', color: '#fff',fontSize: '16px',fontFamily: 'indivisible', }}
        > <img src="/assets/images/icons/facebook-icon.svg" alt="Facebook" width="20" height="20" />
          {facebookVerified ? "You’re Verified!" : "Connect your Facebook"}
        </Button>

        {/* Instagram */}
        <Button
          variant="outlined" 
          fullWidth
          onClick={() => handleVerify('instagram')}
          disabled={instagramVerified}
          sx={{ textTransform: 'none', minWidth: '200px', justifyContent: 'start', display: 'flex', alignItems: 'center', gap: '12px', padding: '12px 15px', borderRadius: '10px', border: 'none', background: '#fff', boxShadow: '0 0 10px rgba(0,0,0,0.3)',color: 'rgba(0,0,0,0.54)', fontSize: '16px',fontFamily: 'indivisible', }}
        >
          <img src="/assets/images/icons/instagram-icon.svg" alt="Facebook" width="20" height="20" />
          {instagramVerified ? "You’re Verified!" : "Connect your Instagram"}
        </Button>

        {/* LinkedIn */}
        <Button
          variant="outlined" 
          fullWidth
          onClick={() => handleVerify('linkedin')}
          disabled={linkedinVerified}
          sx={{ textTransform: 'none', minWidth: '200px', justifyContent: 'start', display: 'flex', alignItems: 'center', gap: '12px', padding: '12px 15px', borderRadius: '10px', border: 'none', background: '#fff', boxShadow: '0 0 10px rgba(0,0,0,0.3)',color: 'rgba(0,0,0,0.54)', fontSize: '16px',fontFamily: 'indivisible', }}
        ><img src="/assets/images/icons/linkedin-icon.svg" alt="Facebook" width="20" height="20" />
          {linkedinVerified ? "You’re Verified!" : "Connect your LinkedIn"}
        </Button>
      </Stack>
    </Box>
    <p className='color3c' style={{fontSize: '12px' }}>(Note: Verifies your online presence for added trust and exclusivity.)</p>
</div>

      </div>
      </div>

      <Modal className="SelfieVerificationModal" open={modalOpen} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="motoolModl" sx={style}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleModalClose} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: '9' }}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Conditional rendering based on the selected verification type */}
          {verificationType === 'photo' ? (
            <>
              <SelfieVerificationComponent currentStep={currentStep} setCurrentStep={setCurrentStep} source="account" />
            </>
          ) : (
            <>
              {!isVerificationStarted ? (
                <VerifyYourIdentity />
              ) : isVerificationInProgress ? (
                <IdentityUpload />
              ) : (
                <CompleteVerification />
              )}
            </>

          )}

          {/* Button Section */}
          {verificationType === 'id' && (
            <Box textAlign="center" marginTop="20px" className="position-sticky bottom-0 py-20 bg-white z-3">
              <Button
                variant="contained"
                color="primary"
                onClick={handleVerificationButtonClick}
                size="large"
                sx={{
                  width: '100%',
                  background: '#916008',
                  textTransform: 'capitalize',
                }}
              >
                {isVerificationStarted && isVerificationInProgress
                  ? 'Submit'
                  : isFinalStep
                    ? 'Finish'
                    : 'Start ID Verification'}
              </Button>
            </Box>
          )}
          {verificationType === 'photo' && (currentStep === 0 || currentStep === 1 || currentStep === 2) && (
            <Box textAlign="center" marginTop="20px" className="position-sticky bottom-0 py-20 bg-white z-3">
              <Button
                variant="contained"
                color="primary"
                onClick={handleVerificationButtonClick}
                size="large"
                sx={{
                  width: '100%',
                  background: '#916008',
                  textTransform: 'capitalize',
                }}
              >
                {currentStep === 0
                  ? 'Upload Your Profile Photo'
                  : currentStep === 1
                    ? 'How It Works'
                    : currentStep === 2
                      ? 'Allow Camera Access'
                      : ''}
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default YourVerifications;
