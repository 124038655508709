import React, { useState } from 'react'
import { Switch } from '@mui/material'
const SwitchComp = ({status,onChange}) => {
    // const [status, setReceiveCallsFromContacts] = useState(status||false);
  return (
    <div>
        <Switch className="wsetSwitch"
        checked={status}
        // onChange={() => setReceiveCallsFromContacts(!status)}
        onChange={onChange}
        color="secondary" // Change to predefined color like primary or secondary
        sx={{
          padding: '0',
          height: '32px', 
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#ff5722', // Custom thumb color when checked
            borderRadius: '50px',
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#fff', // Custom track color when checked
            border: '1px solid #ccc',
          borderRadius: '50px',
          },
          '& .MuiSwitch-switchBase':{padding: '6px 7px 6px 7px'},
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#916008', // Custom track color when checked
            borderRadius: '50px',
            padding: '6px 7px 6px 12px',
          }, 
          '& .MuiSwitch-track': { 
            borderRadius: '50px',
          }, 
        }}
      />
    </div>
  )
}

export default SwitchComp
