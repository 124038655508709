import { combineReducers } from "redux";
import HomeReducer from './containers/home-container/reducer/HomeReducer';
import authReducer from "./containers/home-container/reducer/AuthReducer"
import SignupReducer from "./containers/signup-container/reducer/SignupReducer";
import HeaderReducer from "./containers/header-container/reducer/HeaderReducer";
import AccountSettingReducer from "./containers/account-setting/reducer/AccountSettingReducer";
import ProfileReducer from "./containers/profile-container/reducer/ProfileReducer";
import MemberReducer from "./containers/member-container/reducer/MemberReducer";
import FavoriteReducer from "./containers/favorites-container/reducer/FavoriteReducer";
import ChatReducer from "./containers/chat-container/reducer/ChatReducer";


export default combineReducers({
    HeaderReducer,
    HomeReducer,
    auth: authReducer,
    SignupReducer,
    AccountSettingReducer,
    ProfileReducer,
    MemberReducer,
    FavoriteReducer,
    ChatReducer
});
