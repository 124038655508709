import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CustomLoader from 'src/Component/loader/CustomLoader';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { GetRestrictedUsersAction, setUnrestrictedUsersAction } from 'src/containers/account-setting/actions/AccountSettingActions';

const HiddenMembers = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const {hideMembers,searchReqStatus} = useSelector((state) => state.AccountSettingReducer, shallowEqual);
  // const profiles = [
  //   {
  //       image: '/assets/images/user/img34.png',
  //       name: 'John Doe',
  //       location: 'New York, USA',
  //       description: 'Loves photography and traveling.',
  //     },
  //     {
  //       image: '/assets/images/user/img35.png',
  //       name: 'Jane Smith',
  //       location: 'Los Angeles, USA',
  //       description: 'Enjoys reading and hiking.',
  //     },
  //     {
  //       image: '/assets/images/user/img36.png',
  //       name: 'Bob Johnson',
  //       location: 'Chicago, USA',
  //       description: 'Avid gamer and tech enthusiast.',
  //     },
  //     {
  //       image: '/assets/images/user/img37.png',
  //       name: 'Alice Brown',
  //       location: 'Miami, USA',
  //       description: 'Passionate about cooking and design.',
  //     },
  // ];
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(GetRestrictedUsersAction({ userId: user._id, userType: 'hide' }));
  }, [dispatch])
  const handleUnHide = (activityId) => {
    dispatch(setUnrestrictedUsersAction({ activityId,userId: user._id,activityType: 'hide' }));
  }
  if(searchReqStatus === REQUEST_STATUS.PENDING){
    return ( 
      <CustomLoader />
    )
  }
  return (
    <div>
      <Typography variant="h6" className="ac-heading" gutterBottom sx={{ fontWeight: '500', color: '#081726', fontFamily: 'ivypresto-headline', }}>
        Hidden Members
      </Typography>

      {/* Conditional rendering for profiles */}
      {hideMembers.length === 0 ? (
        <div className="border rounded-4 px-20 py-120 text-center">
          <h4 style={{fontFamily:"ivypresto-headline"}}>No Hidden, Just Opportunities!</h4>
          <p>
          You haven’t hidden anyone yet. Keep exploring and connecting without restrictions! You can hide anyone without them knowing.
          </p>
          <button className='fillBtn rounded-pill px-20 py-10 text-white border-0' onClick={() => navigate('/members')}>Browse Members</button>
        </div>
      ) : (
        hideMembers.map((profile, index) => (
          <div key={index} className="favorite-profile flex-row justify-content-between align-items-center">
            <div className="d-flex w-100 align-items-center">
              <img src={profile?.targetUserId?.profilePicture} alt={profile?.targetUserId?.userName} className="profile-img" />
              <div className="profile-info">
                <h3 className="d-flex align-items-center gap-1 justify-content-between mb-0">
                  <span className="font-20 fw-medium text-black font-m">
                    {profile?.targetUserId?.userName}
                    {profile?.targetUserId?.isSubscribed && (
                      <button className="gradientbtn font-14 fw-medium text-white border-0 py-8 px-16 rounded-3 ms-10">
                        Premium
                      </button>
                    )}
                  </span>
                </h3>
                <p className="font-12 fw-normal font-m color5f mb-0">{profile?.targetUserId?.country},{profile?.targetUserId?.state},{profile?.targetUserId?.city}</p>
                <p className="font-14 fw-normal font-m color5f mb-0">{profile?.targetUserId?.aboutUsDescription}</p>
              </div>
            </div>
            <div className="text-center">
              <Button
                variant="outlined"
                onClick={() => handleUnHide(profile._id)}
                className="d-flex align-items-center gap-10 mx-auto"
                sx={{
                  border: '1px solid #BF8500',
                  background: '#BF8500',
                  fontSize: '16px',
                  color: '#fff',
                  borderRadius: '50px',
                  padding: '6px 18px',
                  textTransform: 'capitalize',
                  fontFamily: 'indivisible',
                }}
              >
                Unhide
              </Button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default HiddenMembers