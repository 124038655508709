import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const YourField = () => {
    const dispatch = useDispatch();
    const {
      workField,
    } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [selectedWorkField, setSelectedWorkField] = useState(workField||"");

  const fieldOptions = [
    'Finance/Investment',
    'Technology/Software',
    'Art/Entertainment',
    'Healthcare/Medical',
    'Law/Legal',
    'Education',
    'Marketing/Sales',
    'Hospitality/Real Estate',
    'Entrepreneur/Startup',
    'Other',
    'Prefer not to say'
  ];

  // const handleYourField = (value) => {
  //   setSelectedWorkField(value);
  //   dispatch(setPayload({ data: { workField: value } }));
  // };

  const handleYourField = (value) => {
      // Toggle selection: If the same option is clicked again, unselect it
      const newValue = selectedWorkField === value ? "" : value;
      setSelectedWorkField(newValue);
      dispatch(setPayload({ data: { workField: newValue } }));
    };

  return (
    <div>
      <div className="">
        <div className="font-m font-32 fw-medium text-center">What Field Do You Work In?</div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Let us know your profession to help us match you with like-minded individuals.
        </div>
      </div>
      <div className="yourfield-selection text-center mt-32 mb-16">
        {/* <h3 className="font-18 font-m fw-medium mt-24 mb-16">Choose Your Field</h3> */}
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render field options */}
          {fieldOptions.map((option) => (
            <button
              key={option}
              className={`font-m border font-16 fw-normal ${selectedWorkField === option ? "selected" : ""}`}
              onClick={() => handleYourField(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourField;
