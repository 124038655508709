import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Grid, Box, Button, Typography } from '@mui/material';



  
const VerifyYourIdentity = () => { 
    const steps = [
      {
        number: 1,
        title: "Ensure Good Lighting",
        description: "Find a well-lit area so your face is clearly visible.",
      },
      {
        number: 2,
        title: "Remove Accessories",
        description: "Take off hats, sunglasses, or anything covering your face.",
      },
      {
        number: 3,
        title: "Hold Your Device Steady",
        description: "Position your face in the frame and follow the prompts.",
      },
    ];

  return (
    <div>
       <h3 className="text-center font-32">Verify Your Identity </h3>
      <p className="text-center font-18" style={{ color: 'rgba(0,0,0,0.7)' }}>
      We need to confirm it’s really you! Follow these quick steps to complete the identity verification.
      </p>
      <Grid container spacing={2}>
        {/* List Section */}
        <Grid item xs={12} sm={7} lg={7}>
            <Box>
            <Typography sx={{display: 'flex', alignItems: 'center', fontSize: '16px', color: '#916008', fontWeight: '600', gap: '10px'}}><span><img src="/assets/images/signup/instructions.svg" alt="" /></span> Instructions</Typography>
            </Box>
          <Box>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {steps.map((step) => (
                <ListItem key={step.number} sx={{paddingLeft: '0'}}>
                  <ListItemAvatar>
                    <Avatar sx={{ background: '#faf5eb', color: '#916008', fontWeight: '600',fontSize: '14px' }}>
                      {step.number}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={step.title}
                    secondary={step.description}
                    sx={{
                      '& .MuiListItemText-primary': {
                        color: '#081726',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: 'indivisible',
                      },
                      '& .MuiListItemText-secondary': {
                        color: '#081726',
                        fontSize: '12px',
                        fontWeight: '500',
                        fontFamily: 'indivisible',
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>

        {/* Avatar Section */}
        <Grid item xs={12} sm={5} lg={5}>
          <Box className="text-center h-100 d-flex align-items-center justify-content-center flex-column">
            <img alt="User Name" src="/assets/images/verify-your-identity.png" />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default VerifyYourIdentity
