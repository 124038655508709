import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Button, Paper, Box, Typography, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
  maxHeight: '80vh',
  overflow: 'auto',
};

const HelpCenter = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleSave = () => {
    console.log('Saved information:');
    handleCloseModal();
  };

  const openInFaqTab = () => {
    window.open("/faqs", "_blank");
};
  const openInSafetyTab = () => {
    window.open("/safety-and-security", "_blank");
};
  const openInTermsTab = () => {
    window.open("/terms-and-conditions", "_blank");
};
  const openInPrivacyTab = () => {
    window.open("/privacy-policy", "_blank");
};
  const openInSupportTab = () => {
    window.open("/support", "_blank");
};
 

    return (
        <div>
           <Box className="d-flex flex-column">
             <Typography className="ac-heading"
                            variant="h6"
                            gutterBottom
                            sx={{ 
                                fontWeight: '500',
                                color: '#000',
                                fontFamily: 'ivypresto-headline',
                            }}
                        > 
          Help Center 
            </Typography>
            <Typography variant="p" className="font875vw" gutterBottom sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>
            Find quick answers to your queries or get personalized assistance through our comprehensive Help Center.
            </Typography>
            </Box>


            <Box className="border001vw gap05vw innerBtnStu mtopBottom05 p1vw"
                component={Paper}
                sx={{ 
                    borderRadius: '0.3vw',
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'column', 
                }}
            >
                <Typography className="font875vw"
                    variant="body2"
                    sx={{ 
                        fontWeight: '500',
                        color: '#081726',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}
                >
                    Help
                </Typography>

                <Box className="d-flex align-items-center justify-content-between">
                    <Link className="font875vw" to="/faqs" target="_blank"
                     style={{ 
                        fontWeight: '400',
                        color: '#BF8500',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}>FAQs </Link>
                    <Button
                    className="border001vw font08vw px20vw py10vw" onClick={openInFaqTab}
                    style={{ 
                        fontWeight: '400',
                        color: '#081726',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                        textTransform: 'capitalize', 
                        borderRadius: '50vw'
                    }}>View</Button>
                </Box>
                <Box className="d-flex align-items-center justify-content-between">
                    <Link className="font875vw" to="/safety-and-security" target="_blank"  style={{ 
                        fontWeight: '400',
                        color: '#BF8500',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}>Safety Tips for Dating </Link>
                    <Button className="border001vw font08vw px20vw py10vw" onClick={openInSafetyTab} style={{ 
                        fontWeight: '400',
                        color: '#081726',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                        textTransform: 'capitalize', 
                        borderRadius: '50vw'
                    }}>View</Button>
                </Box>
                <Box className="d-flex align-items-center justify-content-between">
                    <Link className="font875vw"  style={{ 
                        fontWeight: '400',
                        color: '#BF8500',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}>Anti-Sex Trafficking  </Link>
                    <Button className="border001vw font08vw px20vw py10vw" style={{ 
                        fontWeight: '400',
                        color: '#081726',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                        textTransform: 'capitalize', 
                        borderRadius: '50vw'
                    }}>View</Button>
                </Box>
                <Box className="d-flex align-items-center justify-content-between">
                    <Link className="font875vw" to="/support" target="_blank" style={{ 
                        fontWeight: '400',
                        color: '#BF8500',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}>Customer Support</Link>
                    <Button className="border001vw font08vw px20vw py10vw" onClick={openInSupportTab} style={{ 
                        fontWeight: '400',
                        color: '#081726',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                        textTransform: 'capitalize', 
                        borderRadius: '50vw'
                    }}>View</Button>
                </Box>
                {/* <Box className="d-flex align-items-center justify-content-between">
                    <Box className="contsupport"><Link className="font875vw" to="/support" target="_blank" style={{ 
                        fontWeight: '400',
                        color: '#BF8500',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}>Contact Customer Support</Link> <span className='font875vw ms16vw' style={{ color: '#BEBEBE', fontStyle: 'italic', fontWeight: '400', }}>Upgrade to Unlock</span></Box>
                    <Button className="font08vw gap05vw px20vw py10vw wthstbpmn" style={{ 
                        fontWeight: '400',
                        color: '#BF8500',
                        fontFamily: 'indivisible',
                        marginBottom: '0', 
                        background: 'rgb(191, 133, 0, .3)',
                        textTransform: 'capitalize',
                        border: '0.01vw solid rgba(221, 189, 118, 1)',
                        borderRadius: '50vw', display: 'flex', alignItems: 'center',
                    }}  onClick={handleOpenModal}><img src="/assets/images/account-settings/diamond-icon.svg" className='img-fluid withvwseup' alt="" /> DIAMOND</Button>
                </Box> */}

            </Box>


            <Box className="border001vw gap05vw innerBtnStu mtopBottom05 p1vw"
                component={Paper}
                sx={{  
                    borderRadius: '0.3vw',
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'column', 
                }}
            >
                <Typography className="font875vw"
                    variant="body2"
                    sx={{ 
                        fontWeight: '500',
                        color: '#081726',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}
                >
                   Site Information 
                </Typography>

                <Box className="d-flex align-items-center justify-content-between">
                    <Link className='font875vw' to="/privacy-policy" target="_blank" style={{ 
                        fontWeight: '400',
                        color: '#BF8500',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}>Privacy Policy </Link>
                    <Button className="font08vw px20vw py10vw" onClick={openInPrivacyTab} style={{ 
                        fontWeight: '400',
                        color: '#081726',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                        textTransform: 'capitalize',
                        border: '0.01vw solid #C4C4C4',
                        borderRadius: '50vw'
                    }}>View</Button>
                </Box>
                <Box className="d-flex align-items-center justify-content-between">
                    <Link className='font875vw' to="/terms-and-conditions" target="_blank" style={{ 
                        fontWeight: '400',
                        color: '#BF8500',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                    }}>Terms and Conditions </Link>
                    <Button className="font08vw px20vw py10vw" onClick={openInTermsTab} style={{ 
                        fontWeight: '400',
                        color: '#081726',
                        fontFamily: 'indivisible',
                        marginBottom: '0',
                        textTransform: 'capitalize',
                        border: '0.01vw solid #C4C4C4',
                        borderRadius: '50vw'
                    }}>View</Button>
                </Box>

                <Typography className="font08vw mt10vw"
                    variant="body2"
                    sx={{ 
                        fontWeight: '400',
                        color: '#777',
                        fontFamily: 'indivisible', 
                    }}
                >
                    Copyright: © 2025 Luxurylife. All rights reserved.
                </Typography> 
            </Box>
{/* Modal */}
<Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: '9' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            sx={{
              fontSize: '32px',
              fontWeight: '400',
              color: '#000',
              textAlign: 'center',
              fontFamily: 'indivisible',
            }}
          >
           Unlock Exclusive Perks with Diamond Membership! 
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              fontSize: '16px',
              fontWeight: '400',
              color: '#081726',
              textAlign: 'center',
              fontFamily: 'indivisible',
            }}
          >
          Upgrade to Diamond Membership today and access premium features designed for a luxurious dating experience. Enjoy unlimited messaging, enhanced visibility, advanced filters, and connect with verified profiles for more meaningful matches. Don’t miss out on the ultimate way to elevate your connections!

          </Typography>  
          <Box gap={2} sx={{ marginTop: '32px' }}>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              sx={{padding: '14px 15px ',
                fontFamily: 'indivisible',
                fontSize: '15px',
                fontWeight: '500',
                background: '#916008',
                color: '#fff',
                width: '100%',
                textTransform: 'capitalize',
              }}
            >
             Upgrade Now
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={2} sx={{ margin: '10px 0 0 0' }}>
            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{
                width: '200px', margin: '0 auto',
                border: '1px solid rgb(155, 155, 155)',
                color: 'rgb(0, 0, 0)',
                textTransform: 'capitalize',
                fontSize: '15px',
                fontWeight: '500',
              }}
            >
             Maybe Later
            </Button> 
          </Box>
        </Box>
      </Modal>
        </div>
    )
}

export default HelpCenter
