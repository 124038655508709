import React from 'react'
import { Link } from 'react-router-dom'
import LuxuryFooter from 'src/components/luxury-home/LuxuryFooter'
import Header from 'src/containers/home-container/layout/Header'

const ContactUs = () => {
  return (
    <>
    <div className='aboutusnEwv1' style={{ padding: '5vw 5.5vw' }}>
       <Header /> 
      <div className='aboutusTextNewbg'>
        <div className='aboutusTextNew'> 
        <h2 className='fw-semibold font-m font48vw mb-0' style={{padding: '2vw 0'}}>Contact Us </h2>
        <p className='fw-normal font-m font24vw mb-0'>We value exclusivity and personalized support for our distinguished members. If you have any inquiries regarding membership, events, or services, please reach out to us using the information below.</p>
        </div> 
      </div>
    <div className='d-flex justify-content-between contactbgText'>
        <div className='contactLeftText' style={{background: '#F4F2EF',}}>
       <div className='text singal'>
       <h4 className='font04vw fw-bold mb-0'>General Inquiries</h4>
<p className='color7b font08vw mb-0'>For questions about our platform, membership benefits, or general assistance, contact us at:</p>
<Link to="mailto:support@luxurylife.ai" className='textRedLik font24vw'>support@luxurylife.ai</Link>  
       </div>
       <div className='d-flex justify-content-between twogridContactus'>
       <div className='w-100 text'>
       <h4 className='font04vw fw-bold mb-0'>Membership Support</h4>
<p className='color7b font08vw mb-0'>Interested in joining our elite network? Need assistance with an existing application? Our membership team is here to help.</p>
<Link to="mailto:support@luxurylife.ai" className='textRedLik font08vw'>support@luxurylife.ai</Link>  
       </div>
       <div className='w-100 text'>
       <h4 className='font04vw fw-bold mb-0'>Event & Partnership Inquiries</h4>
<p className='color7b font08vw mb-0'>For collaborations, sponsorships, or invitations to exclusive gatherings, connect with our partnerships team.</p>
<Link to="mailto:support@luxurylife.ai" className='textRedLik font08vw'>support@luxurylife.ai</Link>  
       </div>
       </div>
       <div className='d-flex justify-content-between twogridContactus'>
       <div className='w-100 text'>
       <h4 className='font04vw fw-bold mb-0'>Press & Media</h4>
<p className='color7b font08vw mb-0'>For media inquiries, press releases, or interview requests, reach out to our PR team.</p>
<Link to="mailto:support@luxurylife.ai" className='textRedLik font08vw'>support@luxurylife.ai</Link>  
       </div>
       <div className='w-100 text'>
       <h4 className='font04vw fw-bold mb-0'>Visit Us</h4>
<p className='color7b font08vw mb-0'>Stay connected and follow us on our exclusive social channels for updates and events.</p>
<Link to="/" className='textRedLik font08vw'>www.luxurylife.ai</Link>  
       </div>
       </div>
       <div className='d-flex justify-content-between twogridContactus'>
       <div className='w-100 text'>
       <h4 className='font04vw fw-bold mb-0'>Address</h4>
<p className='color7b font08vw mb-0'>H-54, H Block, Sector 63, Noida,  Uttar Pradesh, 201301</p>  
       </div> 
       </div>
        </div>
        <div className='contactusImg undtImg'>
            <img src='/assets/images/first-home/contact-us/contact-us.png' className='img-fluid' alt="" />
        </div>
    </div>
    </div>  
      <LuxuryFooter />
    </>
  )
}

export default ContactUs
