import React, { useState } from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSelectedUserDetailsAction, initConversationsListAction, resetPendingAction } from './actions/ChatActions';
import { time_ago } from 'src/utils/CommonUtils';

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "80vh", // Restricts height for scroll
  overflowY: "auto", // Adds vertical scroll
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: "20px 20px 0 20px",
};

// Common style object for FormControlLabel and Checkbox
const checkboxStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginLeft: "0",
  fontSize: "14px", // Label font size
  ".MuiTypography-root": {
    fontSize: "14px", // Label text font size
  },
  control: {
    color: "#916008", // Unchecked color
    "&.Mui-checked": {
      color: "#916008", // Checked color
    },
  },
};

const ChatUserList = ({isChatBoxOpen, setChatBoxOpen,isMobileView }) => {
  const dispatch = useDispatch();
  const { recentUserList, socket,pendingChatUser,pendingChatText } = useSelector(state => state.ChatReducer, shallowEqual);
  const { user: { _id: userId } } = useSelector(state => state.auth, shallowEqual);
  const [searchTerm, setSearchTerm] = useState('');
  const [chatList, setChatList] = useState(recentUserList);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (socket) {
      socket.on('userStatusChange', (data) => {
        const updatedChats = chatList?.map((chat) => {
          if (chat.participantId?._id === data.userId) {
            return { ...chat, participantId: { ...chat.participantId, isOnline: data.isOnline } };
          }
          return chat;
        });
        setChatList(updatedChats);
      });
      socket.on("unreadCountUpdate", (data) => {
        const updatedChats = chatList?.map((chat) => {
          if (chat.OneToOneId === data.OneToOneId) {
            return { ...chat, unreadCount: chat.unreadCount + 1, lastMessage: { ...chat.lastMessage, message: data.message, messageType: data.messageType } };
          }
          return chat;
        });
        setChatList(updatedChats);
      });
      socket.on('messageResponse', updatedChat => {
        setChatList(prevChats => {
          const updatedChats = prevChats.map(chat => chat.id === updatedChat.id ? { ...chat, ...updatedChat } : chat);
          return updatedChats.sort((a, b) => (b.isPinned ? 1 : 0) - (a.isPinned ? 1 : 0));
        });
      });
      return () => socket.off('messageResponse');
    }
  }, [socket, chatList]);

  useEffect(() => {
    setChatList(recentUserList);
  }, [recentUserList]);

  const handlePinToggle = index => {
    const updatedChats = [...chatList];
    updatedChats[index].isPinned = !updatedChats[index].isPinned;
    updatedChats.sort((a, b) => (b.isPinned ? 1 : 0) - (a.isPinned ? 1 : 0));
    setChatList(updatedChats);
  };

  const handleChatClick = (participant) => {
    setSelectedUser(participant);
    initiateRoomSocket(participant._id);
    dispatch(getSelectedUserDetailsAction({participantId:participant._id}));

    // ChatBox ka mobile-open class remove karne ke liye state update karein
    setChatBoxOpen(true);
  };

  const initiateRoomSocket = (participantId) => {
    const onRoomResponse = (response) => {
      handleSocketResponse(response);
      socket.off("roomResponse", onRoomResponse); // ✅ Remove listener after response
    };
    socket.emit("checkRoom", { users: { participantId, userId } });
    socket.once("roomResponse", onRoomResponse);
  };

  const handleSocketResponse = ({ roomId }) => {
    const newRoomId = roomId?.toString();
    const previousRoomId = localStorage.getItem("chatRoomId");

    if (previousRoomId) {
      socket.emit("leaveRoom", { roomId: previousRoomId });
    }
    setChatRoomId(newRoomId);
    localStorage.setItem("chatRoomId", newRoomId);
  };

  const getStoredChatRoomId = () => localStorage.getItem('chatRoomId');

  const fetchUserChats = () => {
    if (!socket || !chatRoomId) return;
    const data = { userId, roomId: chatRoomId };
    socket.emit('initialMessages', data);
    socket.on('initialMessagesResponse', handleInitialMessages);
  };
  useEffect(() => {
    fetchUserChats();
  }, [chatRoomId]);

  const handleInitialMessages = ({ initialMessages, roomId }) => {
    if (roomId !== localStorage.getItem('chatRoomId')) return;
    dispatch(initConversationsListAction({ data: initialMessages, roomId, selectedUser }));
  };
  useEffect(() => {
    if (pendingChatUser) {
      handleChatClick(pendingChatUser);
      if (!pendingChatText) {
        dispatch(resetPendingAction());       
      }
    }
  }, [pendingChatUser, pendingChatText, handleChatClick, dispatch]);
  const handleUserFilter=(value)=>{
    setSearchTerm(value);
    if(value){
      const filteredChats = recentUserList?.filter((chat) => chat.participantId?.userName?.toLowerCase()?.includes(value.toLowerCase()));
      setChatList(filteredChats);
    }
    else{
      setChatList(recentUserList)
    }
  }
  return (
    <div className={`chatlist ${isChatBoxOpen && isMobileView ? "closechatlist" : ""}`}>
      <div className="modal-dialog-scrollable">
        {/* Conditionally render based on whether there are filtered chats */}

        <div className="modal-content chatListField">
          <div className="chat-header p-16">
            <div className="msg-search w-100">
              <TextField className="inlineSetupnw"
                type="text"
                id="inlineFormInputGroup"
                placeholder="Search"
                aria-label="search"
                value={searchTerm}
                onChange={(e) => handleUserFilter(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="mrgn04">
                      <SearchIcon sx={{ width: '0.9vw', height: '0.9vw' }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0.2vw',
                    height: '2vw',
                    fontSize: '0.8vw',
                    color: '#8FA0AF',
                    '&:hover fieldset': {
                      borderColor: '#916008',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'green',
                    },
                  },
                  '& .MuiInputAdornment-root': {
                    color: 'grey',
                    '&:hover': {
                      color: '#916008',
                    },
                  },
                }}
              />
            </div>
            <div className="hdtopsetup pt-16">
              <h3 className="font-20 fw-medium color3c fw-bold">Inbox Messages</h3>
              <div className="font-12 fw-medium color7b d-flex justify-content-between align-items-center">
                Show Unread Only{' '}
                <Button className="btnFilterIcon" onClick={() => setIsModalOpen(true)}>
                  <img src="/assets/images/messages/filter-icon.svg" className="img-fluid" alt="Filter Icon" />
                </Button>
                 {/* <FilterDropdown /> */}
              </div>
            </div>
          </div>
          {chatList.length > 0 ? (
            <div className="modal-body p-0 overflowauto messagesList">
              <div className="chat-lists">
                <div className="chat-list">
                  {chatList.map((chat, index) => (
                    <div
                      key={index}
                      className={`d-flex align-items-center flex-row py-16 px-24 conversation gap-10 ${chat.OneToOneId === chatRoomId ? "activeUser" : ""}`}
                      onClick={() => handleChatClick(chat.participantId)}
                    >
                      <div className="flex-shrink-0 cs-avatar">
                        <img
                          className="img-fluid"
                          src={chat.participantId?.profilePicture}
                          alt="user img"
                        />
                        {
                          chat.participantId?.isOnLine ? (
                            <span className="active"></span>
                          ) : (
                            <span className="offline"></span>
                          )
                        }
                      </div>
                      <div className="flex-grow-1 conversation_content">
                        <h3>{chat.participantId?.userName}</h3>
                        {chat.lastMessage.messageType === "image" ? (
                          <span className="material-icons">image</span>
                        ) : chat.lastMessage.messageType === "audio" ? (
                          <span className="material-icons">volume_up</span>
                        ) : (
                          <p>{chat.lastMessage.messageType==="pic_request" ? "Request for Private Photos" : chat.lastMessage.message}</p> // Default case for text messages
                        )}
                      </div>
                      <div className="conversationTimeUnreadPin">
                        <div className="time text-nowrap d-flex align-items-center justify-content-end">
                          {time_ago(chat.updatedAt)}
                        </div>
                        <div className="conversationPin_unread d-flex align-items-center justify-content-end gap-1">
                          <div
                            className="pin-unpinBg"
                            style={{ overflow: 'hidden' }}
                          >
                            <Button
                              className={`pin-unpin ${chat.isPinned ? 'show' : ''}`}
                              sx={{
                                minWidth: '30px',
                                padding: '0',
                                height: '20px',
                                lineHeight: '27px',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePinToggle(index);
                              }}
                            >
                              <img
                                src={
                                  chat.isPinned
                                    ? '/assets/images/messages/pinned.svg'
                                    : '/assets/images/messages/pin.svg'
                                }
                                className="img-fluid"
                                alt="pin icon"
                              />
                            </Button>
                          </div>
                          {chat.unreadCount > 0 && (
                            <div className="conversation_unread">
                              {chat.unreadCount}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (

            <div className="modal-body overflowauto emptyList">
              <div className="text-center d-flex justify-content-center align-items-center flex-column p1vw">
                <span>
                  <img
                    src="/assets/images/messages/no-messages-yet.svg"
                    className="img-fluid no-messages-yet"
                    alt=""
                  />
                </span>
                <h3 className="font-1-25vw font-m fw-medium text-black">
                  No Messages Yet?
                </h3>
                <p className="font08vw font-m fw-normal text-black">
                  Don’t worry – your perfect match might just be a conversation away. Stay active and discover exciting connections! Once you receive your messages, make sure to use the filters above.
                </p>
              </div>
            </div>

          )}
        </div>

      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <FormGroup>
            {["New Messages", "Favorite/Not Favorite", "Unread Messages", "Read Messages", "Starred or Pinned Messages", "Message Age", "Messages with Attachments", "Blocked Users", "Verified Profiles"].map((label, index) => (
              <FormControlLabel
                key={index}
                sx={checkboxStyle}
                control={<Checkbox defaultChecked={index === 0} />}
                label={label}
                labelPlacement="start"
              />
            ))}
          </FormGroup>
          <Box className="border-top mt-16 position-sticky bottom-0 z-3 bg-base pb-16" sx={{ marginTop: '20px', background: '#fff' }}>
            <Button onClick={() => setIsModalOpen(false)} className="w-100" sx={{ background: '#fff', color: '#000', fontSize: '16px', textTransform: 'capitalize', fontFamily: 'indivisible', marginBottom: '10px' }}>
              Reset
            </Button>
            <Button onClick={() => setIsModalOpen(false)} className="w-100" sx={{ background: '#916008', color: '#fff', fontSize: '16px', textTransform: 'capitalize', fontFamily: 'indivisible' }}>
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ChatUserList;
