import React from 'react';
import { Box, Slider, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { setFilterAction } from 'src/containers/member-container/actions/MemberActions';

const HeightRangeSlider = () => {
  const dispatch = useDispatch();
  const { currentFilter } = useSelector((state) => state.MemberReducer, shallowEqual);
  const unit = currentFilter.tall.unit;

  // Conversion utilities
  const cmToFeet = (cm) => {
    const totalFeet = cm / 30.48;
    const feet = Math.floor(totalFeet);
    const inches = Math.round((totalFeet - feet) * 12);
    return Number.parseFloat(`${feet}.${inches * 10}`); // e.g., 5'6" -> 5.6
  };

  const feetToCm = (feet) => {
    const wholeFeet = Math.floor(feet);
    const decimalPart = feet - wholeFeet;
    const inches = Math.round(decimalPart * 10); // e.g., 0.6 -> 6 inches
    const totalInches = wholeFeet * 12 + inches;
    return Math.round(totalInches * 2.54);
  };

  // Compute heightRange based on unit
  const heightRange = unit === "cm"
    ? [currentFilter.tall.min, currentFilter.tall.max] // Use cm directly
    : [cmToFeet(currentFilter.tall.min), cmToFeet(currentFilter.tall.max)]; // Convert cm to ft

  // Slider config based on unit
  const sliderConfig = {
    min: unit === "cm" ? 122 : 4.0,
    max: unit === "cm" ? 218 : 7.2,
    step: unit === "cm" ? 1 : 0.1,
  };

  const handleRangeChange = (event, newValue) => {
    const cmValueRange = unit === "cm" ? newValue : [feetToCm(newValue[0]), feetToCm(newValue[1])];
    dispatch(setFilterAction({
      data: { tall: { unit, range: newValue, min: parseInt(cmValueRange[0]), max: parseInt(cmValueRange[1]) } },
    }));
  };

  const handleUnitChange = (event, newUnit) => {
    if (newUnit !== null && newUnit !== unit) {
      let convertedRange;
      if (newUnit === "cm" && unit === "ft") {
        convertedRange = [feetToCm(heightRange[0]), feetToCm(heightRange[1])];
      } else if (newUnit === "ft" && unit === "cm") {
        convertedRange = [cmToFeet(heightRange[0]), cmToFeet(heightRange[1])];
      } else {
        convertedRange = heightRange;
      }
      const cmValueRange = newUnit === "cm" ? convertedRange : [feetToCm(convertedRange[0]), feetToCm(convertedRange[1])];
      dispatch(setFilterAction({
        data: { tall: { unit: newUnit, range: convertedRange, min: parseInt(cmValueRange[0]), max: parseInt(cmValueRange[1]) } },
      }));
    }
  };

  // Format display text
  const formatDisplayText = () => {
    if (unit === "cm") {
      return `${heightRange[0]} cm - ${heightRange[1]} cm`;
    } else {
      const feet1 = Math.floor(heightRange[0]);
      const inches1 = Math.round((heightRange[0] - feet1) * 10);
      const feet2 = Math.floor(heightRange[1]);
      const inches2 = Math.round((heightRange[1] - feet2) * 10);
      return `${feet1}' ${inches1}" - ${feet2}' ${inches2}"`;
    }
  };
  return (
    <div>
      <Box className="fontsldset1" sx={{ width: 300, mx: 'auto', textAlign: 'center', mt: 5 }}>
        <ToggleButtonGroup
          value={unit}
          exclusive
          onChange={handleUnitChange}
          aria-label="height unit"
          sx={{ mb: 2, display: "flex", justifyContent: "center" }}
        >
          <ToggleButton value="cm" aria-label="centimeters">
            cm
          </ToggleButton>
          <ToggleButton value="ft" aria-label="feet">
            ft/in
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="subtitle1" sx={{
          fontSize: '32px',           // Font size 
          color: '#5F6368',              // Text color 
          padding: '8px 16px',        // Padding inside the text 
          display: 'inline-flex',     // Aligns icons and text inline
          alignItems: 'center',       // Centers icons vertically
          fontWeight: 'medium',
        }}>
          {formatDisplayText()}
        </Typography>
        <Slider
          className="sldrsetup"
          value={heightRange}
          onChange={handleRangeChange}
          valueLabelDisplay="auto"
          min={sliderConfig.min}
          max={sliderConfig.max}
          step={sliderConfig.step}
          sx={{
            // Main color of the slider
            color: '#916008',

            // Styles for the track (filled area of the slider)
            '& .MuiSlider-track': {
              backgroundColor: '#916008', // Track color
              height: '7px',
            },

            // Styles for the thumb (the draggable circle)
            '& .MuiSlider-thumb': {
              backgroundColor: '#BF8500', // Thumb color 
              '&:hover': {
                backgroundColor: '#734f05', // Thumb color on hover
              },
              '&.Mui-active': {
                backgroundColor: '#a07511', // Thumb color when active (dragging)
              },
            },

            // Styles for the rail (unfilled area of the slider)
            '& .MuiSlider-rail': {
              backgroundColor: '#d9d9d9', // Rail color
              height: '7px',
            },

            // Styles for the value label (popup displaying current value)
            '& .MuiSlider-valueLabel': {
              backgroundColor: '#916008', // Value label background color
              color: '#fff',              // Value label text color
            },
          }}
        />

      </Box>
    </div>
  )
}

export default HeightRangeSlider
