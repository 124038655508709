export const CHAT_ACTIONS = {
    CHAT_SEARCH: 'CHAT_SEARCH',
    CHAT_SEARCH_SUCCESS: 'CHAT_SEARCH_SUCCESS',
    CHAT_SEARCH_ERROR: 'CHAT_SEARCH_ERROR',

    CHAT_UPSERT: 'CHAT_UPSERT',
    CHAT_UPSERT_SUCCESS: 'CHAT_UPSERT_SUCCESS',
    CHAT_UPSERT_ERROR: 'CHAT_UPSERT_ERROR',

    CHAT_EDIT: 'CHAT_EDIT',

    CHAT_RESET: 'CHAT_RESET',

    CHAT_INIT_SOCKET: 'CHAT_INIT_SOCKET',

    UPDATED_USER_LIST: 'UPDATED_USER_LIST',
    INIT_CONVERSATIONS_LIST: 'INIT_CONVERSATIONS_LIST',
    UPDATE_CONVERSATIONS_LIST: 'UPDATE_CONVERSATIONS_LIST',
    READ_MESSAGE: 'READ_MESSAGE',
    DELIVERED_MESSAGE: 'DELIVERED_MESSAGE',

    PENDING_SET: 'PENDING_SET',
    PENDING_RESET: 'PENDING_RESET',

    GET_SELECTED_USER_DETAILS: 'GET_SELECTED_USER_DETAILS',
    SET_SELECTED_USER_DETAILS: 'SET_SELECTED_USER_DETAILS',
};

export const chatResetAction = () => ({
    type: CHAT_ACTIONS.CHAT_RESET,
});

export const chatSearchAction = (payload) => ({
    type: CHAT_ACTIONS.CHAT_SEARCH,
    ...payload,
});

export const chatEditAction = (payload) => ({
    type: CHAT_ACTIONS.CHAT_EDIT,
    ...payload,
});

export const chatUpsertAction = (payload) => ({
    type: CHAT_ACTIONS.CHAT_UPSERT,
    ...payload,
});
export const chatInitAction = (payload) => ({    
    type: CHAT_ACTIONS.CHAT_INIT_SOCKET,
    ...payload,    
})
export const updatedUserListAction = (payload) => ({
    type: CHAT_ACTIONS.UPDATED_USER_LIST,
    ...payload,
});
export const initConversationsListAction = (payload) => ({
    type: CHAT_ACTIONS.INIT_CONVERSATIONS_LIST,
    ...payload,
})

export const updateConversationsListAction = (payload) => ({
    type: CHAT_ACTIONS.UPDATE_CONVERSATIONS_LIST,
    ...payload,
})
export const readMessageAction = (payload) => ({
    type: CHAT_ACTIONS.READ_MESSAGE,
    ...payload,
})
export const deliveredMessageAction = (payload) => ({
    type: CHAT_ACTIONS.DELIVERED_MESSAGE,
    ...payload,
})
export const setPendingAction = (payload) => ({
    type: CHAT_ACTIONS.PENDING_SET,
    ...payload,
})
export const resetPendingAction = (payload) => ({
    type: CHAT_ACTIONS.PENDING_RESET,
    ...payload,
})
export const getSelectedUserDetailsAction = (payload) => ({
    type: CHAT_ACTIONS.GET_SELECTED_USER_DETAILS,
    ...payload,
})

export default {
    CHAT_ACTIONS,
    chatSearchAction,
    chatUpsertAction,
    chatEditAction,
    updatedUserListAction
};
