export const ACCOUNT_SETTING_ACTIONS = {
    ACCOUNT_SETTING_SEARCH: 'ACCOUNT_SETTING_SEARCH',
    ACCOUNT_SETTING_SEARCH_SUCCESS: 'ACCOUNT_SETTING_SEARCH_SUCCESS',
    ACCOUNT_SETTING_SEARCH_ERROR: 'ACCOUNT_SETTING_SEARCH_ERROR',

    ACCOUNT_SETTING_UPSERT: 'ACCOUNT_SETTING_UPSERT',
    ACCOUNT_SETTING_UPSERT_SUCCESS: 'ACCOUNT_SETTING_UPSERT_SUCCESS',
    ACCOUNT_SETTING_UPSERT_ERROR: 'ACCOUNT_SETTING_UPSERT_ERROR',

    ACCOUNT_SETTING_EDIT: 'ACCOUNT_SETTING_EDIT',

    ACCOUNT_SETTING_RESET: 'ACCOUNT_SETTING_RESET',

    ACCOUNT_GET_PRIVATE_PIC_ACCESS: 'ACCOUNT_GET_PRIVATE_PIC_ACCESS',
    ACCOUNT_SET_PRIVATE_PIC_ACCESS: 'ACCOUNT_SET_PRIVATE_PIC_ACCESS',
    ACCOUNT_UPDATE_PRIVATE_PIC_ACCESS: 'ACCOUNT_UPDATE_PRIVATE_PIC_ACCESS',
    REMOVE_ALL_PRIVATE_PIC_ACCESS: 'REMOVE_ALL_PRIVATE_PIC_ACCESS',

    GET_RESTRICTED_USERS: 'GET_RESTRICTED_USERS',
    SET_RESTRICTED_USERS: 'SET_RESTRICTED_USERS',
    SET_UNRESTRICTED_USERS: 'SET_UNRESTRICTED_USERS',

    SET_CURRENT_ACTIVE_TAB: 'SET_CURRENT_ACTIVE_TAB',

    GET_PLAN_LIST: 'GET_PLAN_LIST',
    SET_PLAN_LIST: 'SET_PLAN_LIST',
};

export const AccountSettingResetAction = () => ({
    type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_RESET,
});

export const AccountSettingSearchAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH,
    ...payload,
});

export const AccountSettingEditAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_EDIT,
    ...payload,
});

export const AccountSettingUpsertAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_UPSERT,
    ...payload,
});
export const AccountGetPrivatePicAccessAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_GET_PRIVATE_PIC_ACCESS,
    ...payload,
});
export const AccountUpdatePrivatePicAccessAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_UPDATE_PRIVATE_PIC_ACCESS,
    ...payload,
})
export const RemoveAllPrivatePicAccessAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.REMOVE_ALL_PRIVATE_PIC_ACCESS,
    ...payload,
})
export const GetRestrictedUsersAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.GET_RESTRICTED_USERS,
    ...payload,
})
export const setUnrestrictedUsersAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.SET_UNRESTRICTED_USERS,
    ...payload,
})
export const setCurrentActiveTabAction = (payload) => ({
    type: ACCOUNT_SETTING_ACTIONS.SET_CURRENT_ACTIVE_TAB,
    ...payload,
})
export const getPlanListAction=()=>({
    type:ACCOUNT_SETTING_ACTIONS.GET_PLAN_LIST,
})

export default {
    ACCOUNT_SETTING_ACTIONS,
    AccountSettingSearchAction,
    AccountSettingUpsertAction,
    AccountSettingEditAction,
};
