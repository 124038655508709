export const MEMBER_ACTIONS = {
    MEMBER_SEARCH: 'MEMBER_SEARCH',
    MEMBER_SEARCH_SUCCESS: 'MEMBER_SEARCH_SUCCESS',
    MEMBER_SEARCH_ERROR: 'MEMBER_SEARCH_ERROR',
    MEMBER_SEARCH_RESET: 'MEMBER_SEARCH_RESET',

    MEMBER_UPSERT: 'MEMBER_UPSERT',
    MEMBER_UPSERT_SUCCESS: 'MEMBER_UPSERT_SUCCESS',
    MEMBER_UPSERT_ERROR: 'MEMBER_UPSERT_ERROR',

    MEMBER_EDIT: 'MEMBER_EDIT',

    MEMBER_RESET: 'MEMBER_RESET',

    
    GET_FILTER: 'GET_FILTER',
    GET_FILTER_SUCCESS: 'GET_FILTER_SUCCESS',
    EDIT_FILTER: 'EDIT_FILTER',
    DELETE_FILTER: 'DELETE_FILTER',
    UPSERT_FILTER: 'UPSERT_FILTER',
    SET_FILTER: 'SET_FILTER',

};

export const memberResetAction = () => ({
    type: MEMBER_ACTIONS.MEMBER_RESET,
});

export const memberSearchAction = (payload) => ({
    type: MEMBER_ACTIONS.MEMBER_SEARCH,
    ...payload,
});

export const memberEditAction = (payload) => ({
    type: MEMBER_ACTIONS.MEMBER_EDIT,
    ...payload,
});

export const memberUpsertAction = (payload) => ({
    type: MEMBER_ACTIONS.MEMBER_UPSERT,
    ...payload,
});
export const setFilterAction = (payload) => ({
    type: MEMBER_ACTIONS.SET_FILTER,
    ...payload
})
export const getFilterAction = (payload) => ({
    type: MEMBER_ACTIONS.GET_FILTER,
    ...payload
})
export const upsertFilterAction = (payload) => ({
    type: MEMBER_ACTIONS.UPSERT_FILTER,
    ...payload
})
export const deleteFilterAction = (payload) => ({
    type: MEMBER_ACTIONS.DELETE_FILTER,
    ...payload
})
export default {
    MEMBER_ACTIONS,
    memberSearchAction,
    memberUpsertAction,
    memberEditAction,
    setFilterAction,
    deleteFilterAction,
};
