import React, { useState } from 'react';
import LuxuryFooter from 'src/components/luxury-home/LuxuryFooter';
import Header from 'src/containers/home-container/layout/Header';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ExclusiveMembershipPlans = () => {
    const [selectedPlan, setSelectedPlan] = useState('Gold'); // Default selected plan

    const plans = [
        {
            name: 'Gold Membership',
            namePlan: 'Gold',
            price: 100,
            cycle: 'per month',
            features: [
                'Access to private forums and discussion groups',
                'Connect with verified high-net-worth individuals',
                'Monthly thought-leadership webinars',
                'Premium customer support',
            ],
        },
        {
            name: 'Diamond Membership',
            namePlan: 'Diamond',
            price: 150,
            cycle: 'per month',
            features: [
                'All Gold Membership benefits',
                'Invitation to exclusive in-person events and masterminds',
                'Personalized concierge support',
            ],
        },
    ];

    return (
        <div>
            <div className='aboutusnEwv1' style={{ padding: '5vw 5.5vw' }}>
                <Header />

                <div className='aboutusTextNewbg' style={{ marginBottom: '4vw' }}>
                    <div className='aboutusTextNew text-center mx-auto'>
                        <h2 className='fw-semibold font-m font48vw mb-0' style={{ padding: '2vw 0' }}>
                            Exclusive Membership Plans
                        </h2>
                        <p className='fw-normal font-m font20vw mb-0'>
                            Join an elite network of visionaries, entrepreneurs, and industry leaders. Elevate your
                            connections with our premium membership options.
                        </p>
                    </div>
                </div>

                {/* Mobile View Buttons with Active Class */}
                <div className='MobileVewBtns'>
                    <button
                        className={`Gold_membership ${selectedPlan === 'Gold' ? 'active' : ''}`}
                        onClick={() => setSelectedPlan('Gold')}
                    >
                        Gold Membership
                    </button>
                    <button
                        className={`Diamond_membership ${selectedPlan === 'Diamond' ? 'active' : ''}`}
                        onClick={() => setSelectedPlan('Diamond')}
                    >
                        Diamond Membership
                    </button>
                </div>

                <div className="subscription-container">
                    {/* Mobile View: Show Only Selected Plan */}
                    <div className="plans d-flex justify-content-center gap-15 my-32 planNewSetup planNewSetupN002 d-md-none">
                        {plans
                            .filter(plan => plan.namePlan === selectedPlan) // Show only selected plan
                            .map((plan, index) => (
                                <div key={index} className={`plan ${plan.name.toLowerCase()} p-20 border rounded-3 h-100 w-100`}>
                                    <h3 className="text-center font-20 fw-medium pt-16">
                                        {plan.name}
                                    </h3>

                                    <p className="price text-center font-32 fw-bold">
                                        ${plan.price}
                                        <span className="d-block font-14 fw-normal">{plan.cycle}</span>
                                    </p>

                                    <button className="upgrade text-center mx-auto d-flex justify-content-center my-16 w-100">
                                        {`Upgrade To ${plan.namePlan}`}
                                    </button>

                                    <div className="featuresHd mb-16">
                                        {plan.name === 'Gold Membership'
                                            ? 'Unlock a world of exclusive opportunities with our Gold Membership.'
                                            : 'Experience unparalleled networking and premium benefits with our Diamond Membership.'}
                                    </div>

                                    <ul className="features ps-0">
                                        {plan.features.map((feature, i) => (
                                            <li key={i}>
                                                <CheckCircleIcon /> {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                    </div>

                    {/* Desktop View: Show Both Plans */}
                    <div className="plans d-none d-md-flex justify-content-center gap-15 my-32 planNewSetup">
                        {plans.map((plan, index) => (
                            <div key={index} className={`plan ${plan.name.toLowerCase()} p-20 border rounded-3 h-100 w-100`}>
                                <h3 className="text-center font-20 fw-medium pt-16">{plan.name}</h3>

                                <p className="price text-center font-32 fw-bold">
                                    ${plan.price}
                                    <span className="d-block font-14 fw-normal">{plan.cycle}</span>
                                </p>

                                <button className="upgrade text-center mx-auto d-flex justify-content-center my-16 w-100">
                                    {`Upgrade To ${plan.namePlan}`}
                                </button>

                                <div className="featuresHd mb-16">
                                    {plan.name === 'Gold Membership'
                                        ? 'Unlock a world of exclusive opportunities with our Gold Membership.'
                                        : 'Experience unparalleled networking and premium benefits with our Diamond Membership.'}
                                </div>

                                <ul className="features ps-0">
                                    {plan.features.map((feature, i) => (
                                        <li key={i}>
                                            <CheckCircleIcon /> {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <LuxuryFooter />
        </div>
    );
};

export default ExclusiveMembershipPlans;
