import React, { useEffect, useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';
import { useDispatch } from 'react-redux';

const CreatePassword = ({setResetPassword, headerContent}) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const passwordsMatch = password === confirmPassword || confirmPassword === '';

  // const handleNext = async() => {
    // if (passwordsMatch) {
      // dispatch(setPayload({data:{password, confirmPassword}}));
      // const payload = {
      //   userId,
      //   password, 
      //   confirmPassword
      // }
      // const response = await createPasswordAPI(payload);
      // if(response.status === 200) {
      //   handleContinue();
      // }
      // else{
      //   setErrorMessage("Something went wrong"+response.message);
      // }
      // Passwords match, proceed to the next step
    // } else {
      // Password does not match, show an error message or handle accordingly
    // }
  // };
  useEffect(() => {
     if(password===confirmPassword){
          setResetPassword(password);
      // dispatch(setPayload({data:{password, confirmPassword}}));
      setErrorMessage('');
     }
     else{
      setErrorMessage('Password does not match');
     }

      },[password,confirmPassword]);

  return (
    <div>
      <div>
      <div className="font-m font-32 fw-medium text-center">{headerContent.title}</div>
        {/* <div className="font-m font-32 fw-medium text-center">Create Your Password</div> */}
        <div className="font-m font-18 colorf5 pt-6 text-center">
          To ensure your account’s security, please choose a new password.
        </div>
        <p
          className="font-m font-14 pt-16 text-center fst-italic text-center"
          style={{ color: '#7B7B7B' }}
        >
          Your password must be at least 8 characters long and include a mix of letters, numbers, and special characters.
        </p>
      
  
      </div>
      <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto' }}>
        {/* New Password Field */}
        <TextField
          placeholder="Enter your new password"
          autoComplete="off"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          // onblur={handleNext}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            height: '44px',
            '& .MuiOutlinedInput-root': {
              height: '44px',
              borderRadius: '15px',
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#916008',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#916008',
                borderWidth: '1px',
              },
            },
          }}
        />
        {/* Confirm Password Field */}
        <TextField
          placeholder="Confirm your password"
          autoComplete="off"
          type="text" // Always show the text for confirm password
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          // onblur={handleNext}
          fullWidth
          variant="outlined"
          margin="normal"
          error={!passwordsMatch && confirmPassword !== ''}
          helperText={
            !passwordsMatch && confirmPassword !== '' ? 'Password does not match' : ''
          }
          sx={{
            height: '44px',
            '& .MuiOutlinedInput-root': {
              height: '44px',
              borderRadius: '15px',
              '& fieldset': {
                borderColor: passwordsMatch && confirmPassword ? 'green' : confirmPassword ? 'red' : '#ccc', // Change border color dynamically
              },
              '&:hover fieldset': {
                borderColor: '#916008',
              },
              '&.Mui-focused fieldset': {
                borderColor: passwordsMatch && confirmPassword ? 'green' : confirmPassword ? 'red' : '#916008',
                borderWidth: '1px',
              },
            },
          }}
        /> 
      </Box>
    </div>
  );
};

export default CreatePassword;
