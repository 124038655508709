import { AUTH_ACTIONS } from 'src/containers/auth-container/actions/AuthActions';
import { HEADER_ACTIONS } from '../actions/HeaderActions';

const initialState = () => ({
    headerMessages: [],
});

export default (state = initialState(), action) => {
    switch (action.type) {
        case HEADER_ACTIONS.HEADER_HIDE: {
            return {
                ...state,
                headerMessages: [],
            };
        }
        case HEADER_ACTIONS.HEADER_SHOW: {
            return {
                ...state,
                headerMessages: action.data,
            };
        }

        
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...initialState(),
            };
        default:
            return state;
    }
};
