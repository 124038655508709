import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useScrollTrigger,
  Menu,
  MenuItem,
  Avatar,
  Divider,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleLogout } from 'src/api/AuthAPI';
import { logout } from 'src/containers/home-container/actions/authAction';
import { useNotification } from '../Notification/ToastNotification';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { setCurrentActiveTabAction } from 'src/containers/account-setting/actions/AccountSettingActions';

const UserHeader = () => {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileAnchor, setProfileAnchor] = useState(null);
  const { openSuccessNotification, openErrorNotification } = useNotification();

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchor(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchor(null);
  };

  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  const textColor = trigger ? '#fff' : '#000';

  const handleLogoutClick = async () => {
    try {
      if (await handleLogout(user?._id)) {
        dispatch(logout());
        navigate("/login");
        openSuccessNotification("Logged out successfully.");
      }
    } catch (error) {
      return openErrorNotification(
        error.response.data.message ?? "Error logging out"
      );

    } finally {
      dispatch(logout());
      navigate("/login");
    }
  };



  const menuItems = [
    { label: "Members", url: "/members" },
    { label: "Favorites", url: "/favorites" },
    { label: "Chat", url: "/chat" },
    { label: "Profile Edit", url: "/profile-edit" },
    { label: "Account Settings", url: "/account-settings" },
    {
      label: "Logout",
      isButton: true,
      onClick: (e) => {
        handleLogoutClick(e); // Logout function call
        handleProfileMenuClose(e); // Close menu function call
      }
    }
  ];
  const handleBecomeAMemberShip = () => {
    dispatch(setCurrentActiveTabAction({ data: { currentActiveTab: 'Membership and Billing' } }));
    navigate('/account-settings');
    handleProfileMenuClose();
  }
  const handleIDVerification = () => {
    dispatch(setCurrentActiveTabAction({ data: { currentActiveTab: 'Verifications' } }));
    navigate('/account-settings');
    handleProfileMenuClose();
  }
  const handleHelpCenter=()=>{
    dispatch(setCurrentActiveTabAction({ data: { currentActiveTab: 'Help Center' } }));
    navigate('/account-settings');
    handleProfileMenuClose();
  }
  return (
    <>
      <AppBar className="HeaderAfterSignIn"
        position="sticky" // This ensures the AppBar stays at the top when scrolling
        sx={{
          backgroundColor: trigger ? 'rgba(255,255,255,0.9)' : 'transparent',
          boxShadow: trigger ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
          backdropFilter: trigger ? 'blur(6px)' : 'none',
          transition: 'box-shadow 0.3s, backdrop-filter 0.3s',
          top: 0,
          zIndex: 999, // Ensure it stays above other content
          padding: '10px 0',
          borderBottom: '1px solid #ccc',
        }}
      >
        <Toolbar className="ToolbarNav" sx={{ justifyContent: 'space-between' }}>
          <a className='LogoAfterSignIn' href="/members" variant="h6" sx={{ textAlign: 'center', flexGrow: 0 }}>
            <img
              src="/assets/images/luxurylife.svg"
              className="img-fluid"
              alt="logo"
            />
          </a>

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            {[
              {
                link: '/favorites',
                icon: <div className='position-relative heartnav'><img src="/assets/images/heartnav.svg" className='img-fluid' alt="heart icon" />
                  {/* <span className="position-absolute top-0 start-50 translate-middle-y badge rounded-pill bg-danger-600 border-0">2</span> */}
                </div>,
              },
              {
                link: '/chat',
                icon: <div className='position-relative messagenav'><img src="/assets/images/messagenav.svg" className='img-fluid' alt="message icon" />
                  {/* <span className="position-absolute top-0 start-50 translate-middle-y badge rounded-pill bg-danger-600 border-0">2</span> */}
                </div>,
                className: 'chatNavBtn rounded-pill fw-bold',
              },
            ].map((item) => (
              <Button onClick={handleProfileMenuClose}
                key={item.link}
                sx={{
                  color: textColor,
                  textTransform: 'none',
                  marginLeft: '0.8vw',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.7vw', border: 'none',
                }}
                component={Link}
                to={item.link}
                className={item.className || 'custom-button-class'}
              >
                {item.icon}
              </Button>
            ))}

            {/* Profile Dropdown */}
            <IconButton className="userHeadPicBtn"
              onClick={handleProfileMenuOpen}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Avatar className="userHeadPic"
                alt="Profile"
                src={user?.profilePicture} // Replace with the path to your profile image
              />
              <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
              anchorEl={profileAnchor}
              open={Boolean(profileAnchor)}
              onClose={handleProfileMenuClose}
              PaperProps={{
                style: { width: "333px", padding: "17px 24px", borderRadius: '14px', },
              }}
            >
              {/* Profile Section */}
              <Box display="flex" alignItems="center" gap="10px" paddingBottom="10px">
                <Avatar
                  alt={user?.userName}
                  src={user?.profilePicture}
                  sx={{ width: 40, height: 40 }}
                /> 
                <Box className="w-100">
                  <Typography variant="h6" sx={{ fontSize: '16px', color: '#081726', fontWeight: 500, fontFamily: 'indivisible',textTransform: 'capitalize', display: 'flex', alignItems: 'center', gap: '4px' }}>{user?.userName}  <span className="veryfiedIcnc"><img src="/assets/images/icons/check-blue.svg" class="img-fluid" alt="" /></span></Typography>
                  <Box className="d-flex justify-content-start align-items-center w-100 gap-1">
                    <MenuItem sx={{ padding: '0' }}>
                      <Button sx={{ textTransform: 'capitalize', }}
                        className="fontSte002"
                        onClick={() => {
                          handleProfileMenuClose(); // Close the menu
                          navigate("/profile-edit"); // Navigate to the profile edit page
                        }}
                      >
                        View &amp; Edit Profile
                      </Button>
                    </MenuItem> 
                  </Box>
                </Box>
              </Box>

              {/* Profile Completion */}
              <Typography variant="body2" color="textSecondary" className="font-12" sx={{ fontFamily: 'indivisible' }}>
                {user?.profileCompletion}% Profile Completion
              </Typography>

              {/* Divider */}
              <Divider sx={{ marginY: "10px" }} />

              {/* VIP Membership */}
              {
                !user?.isSubscribed && <Box textAlign="center" marginBottom="10px" sx={{ background: 'rgba(191, 133, 0, 0.32)', padding: '8px', fontFamily: 'indivisible', borderRadius: '8px' }}>
                  <Typography variant="body2" color="#916008" sx={{ fontWeight: 500, fontFamily: 'indivisible' }}>
                    Get 3x More Matches
                  </Typography>
                  <Button onClick={handleBecomeAMemberShip}
                    variant="contained"
                    color="warning"
                    fullWidth
                    className="gradientbtn font-16 fw-medium text-white border-0 py-8 px-16 rounded-3"
                    sx={{
                      textTransform: "capitalize",
                      marginTop: '14px', fontFamily: 'indivisible',
                    }}
                  >
                    Become a VIP Member Today
                  </Button>
                </Box>
              }


              {/* ID Verification */}
              {
                !user?.isIdVerified && <Button className="fw-medium"
                  variant="outlined"
                  fullWidth
                  onClick={handleIDVerification}
                  sx={{ marginBottom: "10px", borderRadius: '50px', fontFamily: 'indivisible', whiteSpace: "nowrap", textTransform: "capitalize", fontSize: '14px', fontWeight: '500', color: '#916008', border: '1px solid #E1E1E1' }}
                  startIcon={<Avatar src="/assets/images/icons/check-blue.svg" />}
                >
                 Get Your Profile Verified
                </Button>
              }


              {/* Other Options */}
              {/* <MenuItem>
                 <Link to="/boost-profile" className='d-flex align-items-center font-14 fw-medium gap-3' style={{ color: '#081726', fontFamily: 'indivisible', textDecoration: 'none' }} onClick={handleProfileMenuClose} underline="none" color="inherit">
                  <Avatar src="/assets/images/user/user-profile/boost.svg" /> Boost Your Profile
                </Link> 
              </MenuItem>*/}
              <MenuItem sx={{ padding: '0' }}>
                <Link to="/account-settings"  className='d-flex align-items-center font-14 fw-medium gap-3 w-100 hpbtn001' style={{ color: '#081726', fontFamily: 'indivisible', textDecoration: 'none' }} onClick={handleProfileMenuClose} underline="none" color="inherit">
                  <Avatar src="/assets/images/user/user-profile/account.svg" /> Account Settings
                </Link>
              </MenuItem>
              <MenuItem sx={{ padding: '0' }}>
                <Button  onClick={handleHelpCenter} className='d-flex align-items-center  font-14 fw-medium gap-3 p-0 w-100 justify-content-start hpbtn001' sx={{ color: '#081726', fontFamily: 'indivisible', textDecoration: 'none',textTransform: 'capitalize' }}  underline="none" color="inherit">
                  <Avatar src="/assets/images/user/user-profile/help.svg" /> Help Center
                </Button>
              </MenuItem>

              {/* Divider */}
              <Divider sx={{ marginY: "10px" }} />

              {/* Logout */}
              <MenuItem sx={{ padding: '0' }}>
                <Button
                  onClick={(e) => {
                    handleLogoutClick(e); // Call the first function
                    handleProfileMenuClose(e); // Call the second function
                  }}
                  className='d-flex align-items-center justify-content-center font-16 fw-medium gap-3 w-100 text-center w-100' style={{ color: '#916008', fontFamily: 'indivisible', textDecoration: 'none' }} underline="none" color="error" textAlign="center">
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </Box>

          <IconButton className="iconBtnSetM"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'flex', md: 'none', padding: '0' }, color: textColor }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
          <List>
            {menuItems.map((item) => (
              <ListItem key={item.label} disablePadding>
                {item.isButton ? (
                  <ListItemButton
                    component="button"
                    onClick={(e) => {
                      item.onClick(e); // Handle logout action
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                ) : (
                  <ListItemButton component={Link} to={item.url}>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

    </>
  );
};

export default UserHeader