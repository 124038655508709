import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { PROFILE_ACTIONS } from '../actions/ProfileActions';
import { ProfileDefaultProps } from '../ProfilePropTypes';

const initialState = () => ({
    currentRecord: { ...ProfileDefaultProps },
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },
    private_pic_request: {
        data: [],
        // currentPage: 0,
        pages: 0,
        total: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case PROFILE_ACTIONS.PROFILE_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PROFILE_ACTIONS.PROFILE_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PROFILE_ACTIONS.PROFILE_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case PROFILE_ACTIONS.SET_PAYLOAD: {
            return {
                ...state,
                currentRecord:{
                    ...state.currentRecord,
                    ...action.data,
                },
            };
        }        
        case PROFILE_ACTIONS.PROFILE_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PROFILE_ACTIONS.PROFILE_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PROFILE_ACTIONS.PROFILE_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case PROFILE_ACTIONS.PROFILE_RESET: {
            return {
                ...state,
                currentRecord: { ...ProfileDefaultProps },
            };
        }
        case PROFILE_ACTIONS.SET_PRIVATE_PIC_REQUEST: {
            return {
                ...state,
                private_pic_request:{
                    ...state.private_pic_request,
                    ...action.data,
                }
            };
        }

        default:
            return state;
    }
};
