import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { ACCOUNT_SETTING_ACTIONS } from '../actions/AccountSettingActions';
import { AccountSettingDefaultProps } from '../AccountSettingPropTypes';

const initialState = () => ({
    currentRecord: { ...AccountSettingDefaultProps },
    privatePicAccess:[],
    blockedMembers:[],
    hideMembers:[],
    currentActiveTab: 'Manage Your Account',//''
    plans:[],
    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {

        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH:
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_GET_PRIVATE_PIC_ACCESS:
        case ACCOUNT_SETTING_ACTIONS.GET_RESTRICTED_USERS:{
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data !== null ? action.data : state.currentRecord,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_SET_PRIVATE_PIC_ACCESS: {
            return {
                ...state,
                privatePicAccess: action.data,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.SET_RESTRICTED_USERS: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
                blockedMembers: action.blockedMembers !== undefined ? action.blockedMembers : state.blockedMembers,
                hideMembers: action.hideMembers !== undefined ? action.hideMembers : state.hideMembers,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_UPDATE_PRIVATE_PIC_ACCESS: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
                privatePicAccess: action.data,
            };
        }
        
        case ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_RESET: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
                currentRecord: { ...AccountSettingDefaultProps },
            };
        }
        case ACCOUNT_SETTING_ACTIONS.SET_CURRENT_ACTIVE_TAB: {
            return {
                ...state,
                currentActiveTab: action.data.currentActiveTab,
            };
        }
        case ACCOUNT_SETTING_ACTIONS.SET_PLAN_LIST: {
            return {
                ...state,
                plans: action.data,
            };
        }

        default:
            return state;
    }
};
