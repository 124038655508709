import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const FavoritePropTypes = PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
});

export const FavoriteDefaultProps = {
    userName: '',
    email: '',
    _id: undefined
};
export const FavoriteDefaultFilterProps = {
    userNameSearchText: '',
    filterName: '',
    currentCity: '',
    isOtherLocation: false,
    otherLocation: '',
    maxDistance: 150,    
    _id: undefined
}


export const FavoritePaymentYupSchema = Yup.object().shape({
    date: Yup.string().required('Required'),
    amount: Yup.number().required("Required").positive().integer().min(1, 'Invalid Amount'),
});

export default {
    FavoriteDefaultProps,
    FavoritePropTypes
};
