import React, { useState } from 'react'
import {
  Container, Typography, Box, Button, ToggleButton, IconButton, ToggleButtonGroup, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Stack } from "@mui/material";

const plans = [
  {
    name: "Gold Plan",
    price: 100,
    benefits: {
      unlockConversations: true,
      hideStatus: true,
      Controlvisibility: true,
      featuredDashboard: false,
      privateBrowsing: false,
      exclusive: [
        "Controlvisibility"
      ]
    }
  },
  {
    name: "Luxury Plan",
    price: 300,
    benefits: {
      unlockConversations: true,
      hideStatus: true,
      Controlvisibility: true,
      featuredDashboard: true,
      privateBrowsing: true,
      exclusive: [
        "Attractive members respond faster to Luxury members",
        "Luxury badge makes you stand out everywhere",
        "Attractive members know you're truthful and serious",
        "More chances of connection"
      ]
    }
  }
];
// Pricing Multiplier for different billing cycles
const pricingMultiplier = {
    monthly: 1,
    quarterly: 0.8, // 20% discount
    annual: 0.6 // 40% discount
  };
const MembershipPlansList = () => {
     const [billing, setBilling] = useState("monthly");

       const [openMainModal, setOpenMainModal] = useState(false);
       const [openSecondModal, setOpenSecondModal] = useState(false);
     
       const handleOpenMain = () => setOpenMainModal(true);
       const handleCloseMain = () => setOpenMainModal(false); 
     
       const handleCloseSecond = () => setOpenSecondModal(false);

     const handleOpenSecond = () => {
        setOpenMainModal(false); // Close first modal
        setOpenSecondModal(true); // Open second modal
      };

  return (
    <>
        <Box> 
          <ToggleButtonGroup
            value={billing}
            exclusive
            onChange={(event, newBilling) => newBilling && setBilling(newBilling)}
            sx={{ margin: '20px auto', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', border: 'none', fontFamily: 'indivisible' }}
          >
            <ToggleButton
              value="monthly"
              sx={{
                border: 'none',
                fontFamily: 'indivisible',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '600',
                borderRadius: '50px', // Default border-radius
                "&.Mui-selected": { backgroundColor: "#000", color: "#fff" },
                "&:hover": { backgroundColor: "#fff9ed", color: "#8f8f8f" }, // Hover effect
                "&:first-of-type": { borderRadius: "10px !important" }, // First button
              }}
            >
              Monthly
            </ToggleButton>

            <ToggleButton
              value="quarterly"
              sx={{
                border: 'none',
                fontFamily: 'indivisible',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '600',
                borderRadius: '10px !important', // Default border-radius
                "&.Mui-selected": { backgroundColor: "#000", color: "#fff" },
                "&.Mui-selected span.descount": { background: "rgb(255 255 255 / 30%) !important", color: "#fff !important" }, // Change span color & bg when selected
                "&:hover": { backgroundColor: "#fff9ed", color: "#8f8f8f" }, // Hover effect
              }}
            >
              Quarterly <span className="descount" style={{ fontSize: "12px", marginLeft: "5px", color: "#667085", background: '#F3F4F6', borderRadius: '50px', padding: '1px 8px' }}>(20% Off)</span>
            </ToggleButton>

            <ToggleButton
              value="annual"
              sx={{
                border: 'none',
                fontFamily: 'indivisible',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontWeight: '600',
                borderRadius: '10px', // Default border-radius
                "&.Mui-selected": { backgroundColor: "#000", color: "#fff" },
                "&.Mui-selected span.descount": { background: "rgb(255 255 255 / 30%) !important", color: "#fff !important" }, // Change span color & bg when selected
                "&:hover": { backgroundColor: "#fff9ed", color: "#8f8f8f" }, // Hover effect
                "&:last-of-type": { borderRadius: "10px !important" }, // Last button
              }}
            >
              Annual <span className="descount" style={{ fontSize: "12px", marginLeft: "5px", color: "#667085", background: '#F3F4F6', borderRadius: '50px', padding: '1px 8px' }}>(40% Off)</span>
            </ToggleButton>
          </ToggleButtonGroup>  
          <Grid container spacing={3} justifyContent="center">
            {plans.map((plan, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card sx={{ borderRadius: '8px', fontFamily: 'indivisible', borderTop: index === 1 ? "4px solid #F44336" : "4px solid #916008", background: index === 1 ? "linear-gradient(180deg, #FFD1CE 0%, #FFF 100%)" : "linear-gradient(180deg, #E5D1A7 0%, #FFF 100%)" }}>
                  <CardContent>
                    <Stack direction="row" spacing={2} alignItems="left">
                      <img
                        src={index === 1 ? "/assets/images/user/luxury-plan-icon.svg" : "/assets/images/user/gold-plan-icon.svg"}
                        alt="Plan Icon"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <div className="text-start w-100">
                        <Box className="d-flex justify-content-between align-items-center">
                          <Typography variant="h5" fontWeight="bold" sx={{ alignItems: "left", fontFamily: "indivisible" }}>
                            {plan.name}
                          </Typography>
                          {billing !== "monthly" && ( // Monthly selected hone par span hide rahega
                            <span style={{
                              fontSize: "12px",
                              marginLeft: "5px",
                              color: "#fff",
                              background: plan.name === "Gold Plan"
                                ? (billing === "quarterly" ? "#916008" : "#916008") // Gold Plan colors
                                : (billing === "quarterly" ? "#F44336" : "#F44336"), // Luxury Plan colors
                              borderRadius: "50px",
                              padding: "1px 8px"
                            }}>
                              ({billing === "quarterly" ? "20% Off" : "40% Off"})
                            </span>
                          )}
                        </Box>

                        <Typography variant="body2" color="text.secondary" sx={{ alignItems: "left", fontFamily: 'indivisible', fontSize: '14px', color: '#18181B', }}>
                          {index === 1
                            ? "Exclusive access to elite connections"
                            : "Essential perks for a premium dating experience."}
                        </Typography>
                      </div>
                    </Stack>
                    {/* <Typography variant="h4" fontWeight="bold" color="primary"  sx={{textAlign: 'left', color: '#18181B', margin: '30px 0'}}>
                  ${plan.price} <span style={{ fontSize: "16px" }}>/month</span>
                </Typography> */}
                    <Typography variant="h4" fontWeight="bold" sx={{ textAlign: 'left', color: '#18181B', margin: '10px 0' }}>
                      ${Math.round(plan.price * pricingMultiplier[billing])} <span style={{ fontSize: "16px" }}>/month</span>
                    </Typography>
                    <Button onClick={handleOpenSecond} // Open second modal
                      variant="contained"
                      sx={{ padding: '12px 10px', fontWeight: '500', boxShadow: 'none', mt: 2, background: index === 1 ? "linear-gradient(90deg, #FF4A4A 0%, #B53535 100%)" : "none", color: index === 1 ? "white" : "#000", border: index === 1 ? "1px solid transparent" : "1px solid #916008" }}
                      fullWidth
                    >
                      Get Started
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid> 
          <Typography variant="h5" sx={{ mt: 4, textAlign: 'left', fontFamily: 'indivisible', fontSize: '20px', fontWeight: '600', color: '#18181B', }}>All Features:</Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {plans.map((plan, index) => (
                    <TableCell key={index} align="center" sx={{ fontWeight: '600', fontSize: "16px", color: '#18181B', fontFamily: 'indivisible', }}>{plan.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  { label: "Instant chat access with matches", key: "unlockConversations" },
                  { label: "Hide your join date for privacy", key: "hideStatus" },
                  { label: "Featured on Attractive members' dashboard", key: "featuredDashboard" },
                  { label: "Control your search visibility", key: "Controlvisibility" },
                  { label: "Private Browsing", key: "privateBrowsing" }
                ].map((feature, idx) => (
                  <TableRow key={idx}>
                    <TableCell sx={{ fontWeight: '500', fontSize: "16px", color: '#18181B', fontFamily: 'indivisible', }}>{feature.label}</TableCell>
                    {plans.map((plan, index) => (
                      <TableCell key={index} align="center" sx={{ fontFamily: 'indivisible', fontSize: '16px', fontWeight: '500', }}>
                        {plan.benefits[feature.key] ? <CheckCircleIcon sx={{ color: '#916008' }} /> : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell colSpan={3} sx={{ fontWeight: "bold", color: "#916008", fontSize: '16px', }}>Exclusivity Features</TableCell>
                </TableRow>

                {plans[1].benefits.exclusive.map((exclusiveFeature, idx) => (
                  <TableRow key={idx}>
                    <TableCell sx={{ fontFamily: 'indivisible', fontSize: '16px', fontWeight: '500', color: '#18181B', }}>{exclusiveFeature}</TableCell>
                    <TableCell align="center" sx={{ fontFamily: 'indivisible', fontSize: '16px', fontWeight: '500', color: '#18181B', }}>-</TableCell>
                    <TableCell align="center" sx={{ fontFamily: 'indivisible', fontSize: '16px', fontWeight: '500', color: '#916008', }}><CheckCircleIcon sx={{ color: '#916008' }} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Box> 
    </>
  )
}

export default MembershipPlansList
