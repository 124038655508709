import React from 'react'
import Header from 'src/containers/home-container/layout/Header'
import LuxuryBanner from './LuxuryBanner'
import WhyJoinOurEliteNetwork from './WhyJoinOurEliteNetwork'
import TwoTierMembershipStructure from './TwoTierMembershipStructure'
import AboutUs from './AboutUs'
import WhatYouGet from './WhatYouGet'
import MembershipCriteria from './MembershipCriteria'
import YourNetwork from './YourNetwork'
import WhatOurMembersAreSaying from './WhatOurMembersAreSaying' 
import EliteFeatures from './EliteFeatures'
import ApplyForMembership from './ApplyForMembership'
import LuxuryFooter from './LuxuryFooter'

const LuxuryHome = () => {
  return (
    <div className='homeSetupneHead'>
      <Header /> 
      <LuxuryBanner /> 
      <WhyJoinOurEliteNetwork /> 
      <TwoTierMembershipStructure /> 
      <AboutUs />
      <WhatYouGet />
      <MembershipCriteria />  
      <YourNetwork /> 
      <WhatOurMembersAreSaying />  
      <EliteFeatures />
      <ApplyForMembership />
      <LuxuryFooter />
    </div>
  )
}

export default LuxuryHome
