import { Button } from '@mui/material';
import React from 'react';
import UserPhotos from '../Profile/user-profile/UserPhotos';
import { AccountUpdatePrivatePicAccessAction } from 'src/containers/account-setting/actions/AccountSettingActions';
import { useDispatch } from 'react-redux';

const SharingPrivatePhotos = ({access ,profile, user }) => {
  const dispatch=useDispatch();
  const firstGalleryImages = user.privatePhotos;
const handleChangeStatus = (status) => {
  dispatch(AccountUpdatePrivatePicAccessAction({userId: access.userId,accessId: access._id, data: { status: status } }));
}
  return (
    <div className="favorite-profile flex-row justify-content-between align-items-center">
      <div className="d-flex w-100 align-items-center">
        <img src={profile.profilePicture} alt={profile.userName} className="profile-img" />
        <div className="profile-info">
          <h3 className="d-flex align-items-center gap-1 justify-content-between mb-0">
            <span className="font-20 fw-bold text-black font-m">
              {profile.userName}
              {
                profile.isSubscribed && (
                  <button className="gradientbtn font-14 fw-medium text-white border-0 py-8 px-16 rounded-3 ms-10">
                    Premium
                  </button>
                )
              }
            </span>
          </h3>
          <p className="font-12 fw-normal font-m color5f mb-0">{profile.country},{profile.state},{profile.city}</p>
          <p className="font-14 fw-normal font-m color5f mb-0">{profile.aboutUsDescription}</p>
        </div>
      </div>
      <div className='w-100 text-center'>
        <Button
          variant="outlined"
          onClick={() => {handleChangeStatus('Removed')}}
          className="d-flex align-items-center gap-10 mx-auto"
          sx={{
            border: '1px solid #916008',
            fontSize: '16px',
            color: '#916008',
            borderRadius: '50px',
            padding: '6px 18px',
            textTransform: 'capitalize',
            fontFamily: 'indivisible',
          }}
        >
          <img src="/assets/images/hideb.svg" className="img-fluid" alt="" /> Hide
        </Button>
      </div>

      {/* Conditional Rendering */}
      {firstGalleryImages.length === 0 ? (
        <div className="border rounded-4 p-20 text-center">
          <h4>Hurray...you don't have any private photos to share.</h4>
          <p>
            Sharing private photos allows you to selectively share images with people you trust. You can upload photos here to share them.
          </p>
        </div>
      ) : (
        <div className='uerImagesser001'>
          <UserPhotos images={firstGalleryImages} defaultLimit={4} />
        </div>
      )}
    </div>
  );
};

export default SharingPrivatePhotos