import React, { useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getPrivatePicRequestAction, updatePrivatePicRequestAction } from "./actions/ProfileActions"
import { time_ago } from "src/utils/CommonUtils"
const PhotoRequestList = () => {
  const {
    private_pic_request: { data, total },
  } = useSelector((state) => state.ProfileReducer, shallowEqual)

  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(0)
  const handleAccept = (accessId) => {
    dispatch(updatePrivatePicRequestAction({ data: { accessId, type: "single", status: "Accepted" } }))
  }
  const handleReject = (accessId) => {
    dispatch(updatePrivatePicRequestAction({ data: { accessId, type: "single", status: "Rejected" } }))
  }
  const handleAcceptAll = () => {
    dispatch(updatePrivatePicRequestAction({ data: { accessId: null, type: "all", status: "Accepted" } }))
  }

  const handleRejectAll = () => {
    dispatch(updatePrivatePicRequestAction({ data: { accessId: null, type: "all", status: "Rejected" } }))
  }
  const handleNext = () => {
    if (currentPage < Math.ceil(total / 10)) {
      setCurrentPage(currentPage + 1)
      // dispatch(getPrivatePicRequestAction({ data: currentPage + 1 }));
    }
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
      // dispatch(getPrivatePicRequestAction({ data: currentPage - 1 }));
    }
  }
  useEffect(() => {
    dispatch(getPrivatePicRequestAction({currentPage }))
  }, [dispatch, currentPage])
  return (
    <div>
      {/* <div className="header d-flex justify-content-between align-items-center py-16 mobiletableFlex">
        <div className="font-20 font-m text-black fw-medium mb-0 d-flex gap-3 align-items-center">
          Request Private Photos
          <span className="TotalCountRequest">{total}</span> {/* Dynamically display the total count  
        </div>
        {total > 0 && (
          <div className="actions d-flex justify-content-end gap-2">
            <button className="reject-all outLineBtn px-20 py-8 bg-none font-14 fw-medium" onClick={handleRejectAll}>
              Reject all
            </button>
            <button
              className="accept-all fillBtn text-white px-20 py-8 border font-14 fw-medium"
              onClick={handleAcceptAll}
            >
              Accept all
            </button>
          </div>)
        }
      </div>

      <div className="requests-list">
        {data.map((request, index) => (
          <div key={index} className="request-item d-flex align-items-center gap-3 px-16 py-10 rounded-3 border mb-10">
            <div className="user-info d-flex gap-2 align-items-center w-100">
              <img src={request?.targetUserId?.profilePicture || "/placeholder.svg"} alt="User" className="user-avatar" />
              <div className="user-details">
                <p className="user-name mb-0 fw-medium" style={{ color: "#081726" }}>
                  {`${request?.targetUserId?.userName}, ${request?.targetUserId?.age}`}
                </p>
                <p className="user-location mb-0">{`${request?.targetUserId?.city}, ${request?.targetUserId?.state}, ${request?.targetUserId?.country}`}</p>
              </div>
            </div>
            <div className="request-actions">
              <span className="time-label d-block">{time_ago(request.timestamp)}</span>
              <div className="d-flex gap-2">
                <button
                  className="reject-btn badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-primary-600 text-white"
                  onClick={() => handleReject(request._id)}
                >
                  ✖
                </button>
                <button
                  className={`accept-btn badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-warning-900 text-white ${request.accepted ? "accepted" : ""}`}
                  onClick={() => handleAccept(request._id)}
                >
                  ✓
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {total > 10 && (
        <div className="pagination d-flex justify-content-center align-items-center gap-3">
          <button
            className="prev-btn outLineBtn px-20 py-8 bg-none font-16 fw-medium"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="next-btn fillBtn text-white px-32 py-8 border font-16 fw-medium"
            onClick={handleNext}
            disabled={currentPage === Math.ceil(total / 10)}
          >
            Next
          </button>
        </div>
      )} */}
    </div>
  )
}
export default PhotoRequestList