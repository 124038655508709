import React from "react";
import { Box, Grid, Skeleton, Button } from "@mui/material";

const ViewedMeSkeleton = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Skeleton variant="text" width="30%" height={40} sx={{ mb: 2 }} />

      {[...Array(3)].map((_, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            p: 2,
            borderRadius: 2,
            border: "1px solid #ddd",
            mb: 2,
          }}
        >
          <Skeleton variant="circular" width={60} height={60} />
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="40%" height={20} />
            <Skeleton variant="text" width="60%" height={15} />
            <Skeleton variant="text" width="30%" height={15} />
          </Box>
          <Skeleton variant="rectangular" width={100} height={35} sx={{ borderRadius: 2 }} />
          <Skeleton variant="rectangular" width={100} height={35} sx={{ borderRadius: 2 }} />
          <Skeleton variant="circular" width={30} height={30} />
          <Skeleton variant="circular" width={30} height={30} />
        </Box>
      ))}
    </Box>
  );
};

export default ViewedMeSkeleton