import { Box, Typography } from '@mui/material';
import React from 'react';

const AboutUs = () => {
  // Local data
  const aboutData = {
    version: '1.2.5',
    updatedOn: '15 Jan 2025',
    description:
      'Luxury Life has facilitated thousands of meaningful connections across 20 countries and continues to grow globally.',
  };

  return (
    <div>
      <Typography className="ac-heading"
        variant="h6"
        gutterBottom
        sx={{ 
          fontWeight: '500',
          color: '#000',
          fontFamily: 'ivypresto-headline',
        }}
      >
        About
      </Typography>
      <Typography
        className="mb16vw font08vw"
        sx={{  
          color: '#081726',
          fontWeight: '400',
          fontFamily: 'indivisible',
        }}
      >
        {aboutData.description}
      </Typography>
      <Typography className="font08vw mb-16"
        sx={{  
          color: '#081726',
          fontWeight: '400',
          fontFamily: 'indivisible',
        }}
      >
        Inspired by the increasing demand for high-quality, streamlined online dating experiences, we created our
        platform to help successful individuals find exceptional partners free from the common pitfalls of scams or
        deceptive practices. Our mission is clear: to offer a safe, exclusive space for those seeking genuine
        connections with like-minded individuals.
      </Typography>

      <Box className="border001vw mtopBottom05 gap05vw p1vw"
        sx={{
          
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '0.4vw', 
        }}
      >
        <Box className="font08vw"
          sx={{ 
            fontWeight: '400',
            color: '#081726',
            fontFamily: 'indivisible',
            marginBottom: '0',
          }}
        >
          Version
        </Box>
        <Box className="font08vw"
          sx={{ 
            fontWeight: '400',
            color: '#777',
            fontFamily: 'indivisible',
            marginTop: '0px',
          }}
        >
          {aboutData.version}
        </Box>
      </Box>

      <Box className="border001vw mtopBottom05 gap05vw p1vw"
        sx={{ 
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '0.4vw',
         
        }}
      >
        <Box className="font08vw"
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            color: '#081726',
            fontFamily: 'indivisible',
            marginBottom: '0',
          }}
        >
          Latest Update
        </Box>
        <Box className="font08vw"
          sx={{ 
            fontWeight: '400',
            color: '#777',
            fontFamily: 'indivisible',
            marginTop: '0px',
          }}
        >
          {aboutData.updatedOn}
        </Box>
      </Box>
    </div>
  );
};

export default AboutUs