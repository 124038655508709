import React, { useState, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updatedUserListAction } from './actions/ChatActions';
import ChatBox from './ChatBox';
import EmptyChatStatus from './EmptyChatStatus';
import ChatSkeleton from './ChatSkeleton';
import ChatUserList from './ChatUserList';

const ChatContainer = () => {
    const dispatch = useDispatch();
    const { selectedUser, socket, roomId } = useSelector((state) => state.ChatReducer, shallowEqual);
    const { user } = useSelector((state) => state.auth, shallowEqual);
    
    const [isChatBoxOpen, setChatBoxOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [loading, setLoading] = useState(true); // Default: true (jab tak data nahi aata)

    // Function to check screen size
    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobileView(window.innerWidth <= 768);
            if (window.innerWidth > 768) {
                setChatBoxOpen(false);
            }
        };
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);
        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    // Handle chat list response
    const handleRecentChatListResponse = useCallback(({ list }) => {
        dispatch(updatedUserListAction({ data: list }));
        setLoading(false); // Data load hone ke baad loading hatao
    }, [dispatch]);

    // Manage socket events
    useEffect(() => {
        if (!socket) return;

        const handleSocketConnect = () => {
            socket.emit("userOnline", { userId: user?._id });
            socket.emit("getRecentChatList", { userId: user?._id });
            const storedRoomId = localStorage.getItem("chatRoomId");
            if (storedRoomId && selectedUser) {
                socket.emit("checkRoom", { users: { participantId: selectedUser._id, userId: user._id } });
            }
        };

        const handleSocketDisconnect = () => {
            if (socket.connected) {
                socket.emit("userOffline", { userId: user?._id });
            }
        };

        socket.on("connect", handleSocketConnect);
        socket.on("disconnect", handleSocketDisconnect);
        socket.on("recentChatListResponse", handleRecentChatListResponse);

        if (socket.connected) {
            handleSocketConnect();
        } else {
            socket.connect();
        }

        return () => {
            socket.off("connect", handleSocketConnect);
            socket.off("disconnect", handleSocketDisconnect);
            socket.off("recentChatListResponse", handleRecentChatListResponse);
        };
    }, [socket, user, selectedUser, handleRecentChatListResponse]);

    return (
        <div>
            <section className={`message-area ${isChatBoxOpen && isMobileView ? "active" : ""}`}>
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-12">
                            {loading ? (
                                <ChatSkeleton /> // Jab tak data load ho raha hai, skeleton dikhayega
                            ) : (
                                <div className="chat-area">
                                    <ChatUserList 
                                        setChatBoxOpen={setChatBoxOpen} 
                                        isChatBoxOpen={isChatBoxOpen} 
                                        isMobileView={isMobileView}
                                    />
                                    {roomId ? (
                                        <ChatBox 
                                            isChatBoxOpen={isChatBoxOpen} 
                                            setChatBoxOpen={setChatBoxOpen} 
                                            isMobileView={isMobileView} 
                                        />
                                    ) : (
                                        <EmptyChatStatus />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ChatContainer;
