import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProfileCompletion = ({ progress }) => {
  return (
    <div className="progressSetpu" style={{ display: "flex", alignItems: "center", }}>
      {/* Left Text */}
      {progress >= 100 ? <div className="font-20 font-m fw-medium UnlockMach"> Profile Completed </div> : <div className="font-20 font-m fw-medium UnlockMach"> Unlock Matches by <span className="d-block">Completing Profile!</span> </div>}

      {/* Circular Progress Bar */}
      <div className="progressSetupWth" style={{ position: "relative", width: "112px", height: "112px" }}>
      {/* Circular Progress Bar */}
      <CircularProgressbar
        value={progress}
        text={`${progress}%`}
        styles={buildStyles({
          textColor: "#000",
          pathColor: "#c0850a",
          trailColor: "#e6e6e6",
          textSize: "16px",
        })}
      />
      {/* Complete Text */}
      <div
        style={{
          position: "absolute",
          top: "65%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "12px",
          color: "#000",
          fontWeight: "bold",
        }}
      >
        Complete
      </div>
    </div>
    </div>
  );
};

export default ProfileCompletion