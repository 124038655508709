import React, { useState, useMemo } from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import HeightSelector from '../../components/signup/HeightSelector';
import BodyType from '../../components/signup/BodyType';
import YourEthnicity from '../../components/signup/YourEthnicity';
import RelationshipStatus from '../../components/signup/RelationshipStatus';
import HaveChildren from '../../components/signup/HaveChildren';
import DoYouSmoke from '../../components/signup/DoYouSmoke';
import DoYouDrinkAlcohol from '../../components/signup/DoYouDrinkAlcohol';
import LevelOfEducation from '../../components/signup/LevelOfEducation';
import YourIncomeRange from '../../components/signup/YourIncomeRange';
import NetWorth from '../../components/signup/NetWorth';
import LuxeInterests from '../../components/signup/LuxeInterests';
import GenderInterestSelection from '../../components/signup/GenderInterestSelection';
import AgePreferences from '../../components/signup/AgePreferences';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { shallowEqual, useSelector } from 'react-redux';
import { convertHeight } from 'src/utils/CommonUtils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import YourField from 'src/components/signup/YourField';
import YourHobbies from 'src/components/signup/YourHobbies';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '700px',
  width: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px'
};

const AdditionalInformation = ({ updateUserInfo }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [open, setOpen] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(null);

  const handleOpen = (item) => {
    setSelectedInfo(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedInfo(null);
  };

  const handleSave = () => {
    updateUserInfo(selectedInfo.step);
    handleClose(); // Close modal after saving
  };

  const infoItems = useMemo(() => [
    { label: "Who are you interested in", step: 18, value: user.preferences?.gender?.join(", "), component: <GenderInterestSelection isInternal={true}/> },
    { label: "Height", step: 10, value: `${user?.tall?.unit === "cm" ? user?.tall?.value + ' cm' : convertHeight(user?.tall?.value)}`, component: <HeightSelector /> },
    { label: "Body Type", step: 11, value: user.bodyType, component: <BodyType /> },
    { label: "Ethnicity", step: 12, value: user.ethnicity, component: <YourEthnicity /> },
    { label: "Education", step: 9, value: user.highestEducation, component: <LevelOfEducation /> },
    { label: "Profession", step: 14, value: user.workField ? user.workField : 'skipped', component: <YourField /> },
    { label: "Relationship status", step: 15, value: user.currentRelationshipStatus, component: <RelationshipStatus /> },
    { label: "Children", step: 16, value: user.children ? user.children : 'skipped', component: <HaveChildren /> },
    { label: "Net worth", step: 17, value: `${user.netWorthRange ? user.netWorthRange : 'skipped'}`, component: <NetWorth /> },
    { label: "Age preferences", step: 18, value: `${user.preferences.ageRange.min} - ${user.preferences.ageRange.max}`, component: <AgePreferences /> },
    { label: "Hobbies", step: 19, value: user.hobbies?.join(", "), component: <YourHobbies /> },
    { label: "Do you smoke?", step: 20, value: user.smoke, component: <DoYouSmoke /> },
    { label: "Do you drink?", step: 21, value: user.drink, component: <DoYouDrinkAlcohol /> },
    { label: "Luxe Interests", step: 22, value: user.luxuryInterests?.join(", "), component: <LuxeInterests /> },
    // { label: "Seeking Tags",step: 10,value: "Discretion, Flexible schedule, Friends, No strings attached", component: <DoYouSmoke /> },
  ], [user]);

  // const renderTags = (tags) => {
  //   const tagsArray = tags.split(',').map((tag) => tag.trim());
  //   return (
  //     <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
  //       {tagsArray.map((tag, index) => (
  //         <span
  //           key={index}
  //           style={{
  //             border: '1px solid rgb(221, 221, 221)',
  //             borderRadius: '20px',
  //             padding: '5px 10px',
  //             backgroundColor: 'rgb(249, 249, 249)',
  //             fontSize: '0.9rem',
  //           }}
  //         >
  //           {tag}
  //         </span>
  //       ))}
  //     </div>
  //   );
  // };

  return (
    <div>
      <div className="color08 font-24 font-m fw-medium mb-16">Additional Information</div>
      {infoItems.map((item, index) => (
        <div className="border-top py-20" key={index}>
          <div className="d-flex align-items-center justify-content-between color08 font-16 fw-medium">
            <div className="d-flex align-items-center color08 font-16 fw-medium gap-3">
              {item.label}
              <span className="color7b fw-normal">
                {item.label === "Seeking Tags" ? "Females" : item.value}
              </span>
            </div>

            <div className=''>
              {item.value !== 'skipped' && (
                <button className="ModeEditbtn" style={{ color: 'rgb(231 214 187)' }}>
                  <CheckCircleOutlineIcon />
                </button>
              )}

              {/* <button
                      className="ModeEditbtn"
                      onClick={() => handleOpen(item)} // Pass the clicked item's data
                    >
                      <ModeEditOutlineOutlinedIcon />
                    </button> */}
              <button className="ModeEditbtn" onClick={() => handleOpen(item)}>
                {item.value === 'skipped' ? <Tooltip title="Complete your profile for better matches!" arrow placement="top"><AddCircleOutlineIcon sx={{ color: '#916008' }} /></Tooltip> : <ModeEditOutlineOutlinedIcon />}
              </button>
            </div>
          </div>
          {/* {item.label === "Seeking Tags" && (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
              {renderTags(item.value)}
            </div>
          )} */}
        </div>
      ))}

      <Modal className="AddiMdalSet"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="toolmdalSet" sx={{ ...style, maxHeight: '80vh', overflow: 'auto' }}>
          {/* Close Button */}
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: '9' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Modal Content */}
          <Typography id="modal-modal-description" sx={{ mb: 3 }}>
            {selectedInfo?.component || selectedInfo?.value}
          </Typography>

          {/* Save Button */}
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button onClick={handleClose} variant="outlined" sx={{ border: '1px solid rgb(145, 96, 8)', color: 'rgb(145, 96, 8)' }}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary" sx={{ background: 'linear-gradient(90deg, #916008, rgba(204, 153, 51, 1))', color: '#fff' }}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

    </div>
  );
};

export default AdditionalInformation;
