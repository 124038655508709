import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { changePasswordAPI } from "src/api/AccountSettingApi";

const SecurityAccordion = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  // Password validation rules
  const passwordRules = {
    minLength: 8,
    hasUpperCase: /[A-Z]/,
    hasLowerCase: /[a-z]/,
    hasNumber: /\d/,
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/,
  };

  const validatePassword = (password)=> {
    const errors= [];
    
    if (password.length < passwordRules.minLength) {
      errors.push(`Password must be at least ${passwordRules.minLength} characters long`);
    }
    if (!passwordRules.hasUpperCase.test(password)) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!passwordRules.hasLowerCase.test(password)) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!passwordRules.hasNumber.test(password)) {
      errors.push("Password must contain at least one number");
    }
    if (!passwordRules.hasSpecialChar.test(password)) {
      errors.push("Password must contain at least one special character");
    }

    return errors;
  };

  const handleOldPasswordChange = (e) => {
    const value = e.target.value;
    setOldPassword(value);
    if (!value) {
      setErrors(prev => ({ ...prev, oldPassword: "Current password is required" }));
    } else {
      setErrors(prev => ({ ...prev, oldPassword: "" }));
    }
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    
    if (value === oldPassword) {
      setErrors(prev => ({ 
        ...prev, 
        newPassword: "New password must be different from current password" 
      }));
      return;
    }

    const validationErrors = validatePassword(value);
    if (validationErrors.length > 0) {
      setErrors(prev => ({ 
        ...prev, 
        newPassword: validationErrors.join(". ") 
      }));
    } else {
      setErrors(prev => ({ ...prev, newPassword: "" }));
    }

    // Check confirm password match if it's already entered
    if (confirmPassword) {
      setErrors(prev => ({
        ...prev,
        confirmPassword: value !== confirmPassword ? "Password does not match" : ""
      }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setErrors(prev => ({
      ...prev,
      confirmPassword: value !== newPassword ? "Password does not match" : ""
    }));
  };

  const handleToggleOldPasswordVisibility = () => {
    setShowOldPassword(prev => !prev);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(prev => !prev);
  };

  const isFormValid = () => {
    return (
      oldPassword &&
      newPassword &&
      confirmPassword &&
      !errors.oldPassword &&
      !errors.newPassword &&
      !errors.confirmPassword
    );
  };

  const handleSubmit = async () => {
    if (!isFormValid()) return;

    setIsSubmitting(true);
    setSubmitError("");
    setSubmitSuccess(false);

    try {
      // Replace this with your actual API call
      const changePassPayload = {
        oldPassword, newPassword, confirmPassword
      }
      const response =await changePasswordAPI(changePassPayload);
      if (!response.status) {        
        throw new Error(response.message || 'Failed to change password');
      }

      setSubmitSuccess(true);
      // Reset form
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      setSubmitError(error instanceof Error ? error.response.data.message : 'An error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Accordion className="border" sx={{ boxShadow: "none", borderRadius: "0.3vw" }}>
      <AccordionSummary 
        className="textSrtyQs" 
        expandIcon={<ExpandMoreIcon />} 
        aria-controls="panel2-content" 
        id="panel2-header"
      >
        <Typography component="span" className="font875vw fw-medium" sx={{color: '#081726'}}>
        Change Password 
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form autoComplete="off" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <Box className="p3vw" sx={{ mx: "auto", p: 3 }}>
            {submitSuccess && (
              <Alert severity="success" sx={{ mb: 2 }}>
                Password changed successfully!
              </Alert>
            )}
            
            {submitError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {submitError}
              </Alert>
            )}

            <TextField
              className="MuiOutlinedInputInput"
              placeholder="Enter Current Password"
              type={showOldPassword ? "text" : "password"}
              value={oldPassword}
              onChange={handleOldPasswordChange}
              error={!!errors.oldPassword}
              helperText={errors.oldPassword}
              fullWidth
              size="small"
              margin="normal"
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleOldPasswordVisibility}>
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#E8E6EA",
                  },
                  "&:hover fieldset": {
                    borderColor: "#bf8500",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#bf8500",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  height: "30px",
                  padding: "10px 14px",
                },
              }}
            />

            <TextField
              className="MuiOutlinedInputInput"
              placeholder="Create a New Password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={handleNewPasswordChange}
              error={!!errors.newPassword}
              helperText={errors.newPassword}
              fullWidth
              size="small"
              margin="normal"
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleNewPasswordVisibility}>
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#E8E6EA",
                  },
                  "&:hover fieldset": {
                    borderColor: "#bf8500",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#bf8500",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  height: "30px",
                  padding: "10px 14px",
                },
              }}
            />

            <TextField
              className="MuiOutlinedInputInput"
              placeholder="Re-Enter New Password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              fullWidth
              size="small"
              margin="normal"
              autoComplete="new-password"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#E8E6EA",
                  },
                  "&:hover fieldset": {
                    borderColor: "#bf8500",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#bf8500",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  height: "30px",
                  padding: "10px 14px",
                },
              }}
            />

            <Button
              className="Muibdradius125vw"
              variant="contained"
              onClick={handleSubmit}
              disabled={!isFormValid() || isSubmitting}
              sx={{
                mt: 2,
                borderRadius: "25px",
                backgroundColor: "#916008",
                textTransform: "none",
                fontWeight: "bold",
                "&:disabled": {
                  backgroundColor: "#cccccc",
                },
              }}
            >
              {isSubmitting ? "Changing..." : "Change"}
            </Button>
          </Box>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default SecurityAccordion;
