import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { HomeDefaultProps } from 'src/containers/home-container/HomePropTypes';
import { HOME_ACTIONS } from '../actions/HomeActions';

const initialState = () => ({
    currentRecord: { ...HomeDefaultProps },
    currentLocation: {
        lat: 0,
        lng: 0,
        country: '',
        state: '',
        city: '',
    },
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {

        case HOME_ACTIONS.HOME_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case HOME_ACTIONS.HOME_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case HOME_ACTIONS.HOME_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }


        case HOME_ACTIONS.HOME_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case HOME_ACTIONS.HOME_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case HOME_ACTIONS.HOME_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case HOME_ACTIONS.SET_CURRENT_LOCATION: {
            return {
                ...state,
                currentLocation: {
                    // ...state.currentLocation,
                    ...action.data
                }
            };
        }


        case HOME_ACTIONS.HOME_RESET: {
            return {
                ...state,
                currentRecord: { ...HomeDefaultProps },
                currentLocation: {
                    lat: 0,
                    lng: 0,
                    country: '',
                    state: '',
                    city: '',
                }
            };
        }

        default:
            return state;
    }
};
