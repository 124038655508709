import React, { useState, useRef } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const TillUsDOB = () => {
  const dispatch = useDispatch();
  const { currentRecord } = useSelector((state) => state.SignupReducer, shallowEqual);
  const dateOfBirth = currentRecord.dateOfBirth || '';

  const [formData, setFormData] = useState(() => {
    if (dateOfBirth) {
      const [year, month, day] = dateOfBirth.split('-');
      return { birthdate: { day, month, year } };
    }
    return { birthdate: { day: '', month: '', year: '' } };
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [age, setAge] = useState(null);

  // Refs for input fields
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  // Validate the entered date
  const isValidDate = (day, month, year) => {
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === parseInt(year, 10) &&
      date.getMonth() === parseInt(month, 10) - 1 &&
      date.getDate() === parseInt(day, 10)
    );
  };

  // Calculate age based on the entered date
  const calculateAge = (day, month, year) => {
    if (!day || !month || !year || !isValidDate(day, month, year)) {
      setErrorMessage('Please enter a valid date of birth.');
      return null;
    }

    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();

    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      calculatedAge--;
    }

    setErrorMessage('');
    return calculatedAge;
  };

  // const handleInputChange = (type, value) => {
  //   const sanitizedValue = value.replace(/\D/g, '');
  //   const maxLength = type === 'year' ? 4 : 2;

  //   setFormData((prevData) => {
  //     const updatedBirthdate = {
  //       ...prevData.birthdate,
  //       [type]: sanitizedValue.slice(0, maxLength),
  //     };

  //     return { ...prevData, birthdate: updatedBirthdate };
  //   });

  //   // Auto-focus logic
  //   if (sanitizedValue.length === maxLength) {
  //     if (type === 'day') {
  //       monthRef.current?.focus();
  //     } else if (type === 'month') {
  //       yearRef.current?.focus();
  //     }
  //   }
  // };
  // const handleInputChange = (type, value) => {
  //   const sanitizedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
  //   const maxLength = type === 'year' ? 4 : 2;

  //   setFormData((prevData) => {
  //     const updatedBirthdate = {
  //       ...prevData.birthdate,
  //       [type]: sanitizedValue.slice(0, maxLength),
  //     };

  //     validateAndDispatchDOB(updatedBirthdate); // Validate and update Redux immediately

  //     return { ...prevData, birthdate: updatedBirthdate };
  //   });
  // };


  const handleInputChange = (type, value) => {
    const sanitizedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
    const maxLength = type === 'year' ? 4 : 2;

    let validatedValue = sanitizedValue.slice(0, maxLength);

    // Additional validation for day and month
    if (type === 'day' && validatedValue > 31) {
      validatedValue = '31'; // Cap the day at 31
    } else if (type === 'month' && validatedValue > 12) {
      validatedValue = '12'; // Cap the month at 12
    }
    else if ((type === 'month' || type === 'day') && (validatedValue.length === 2 && validatedValue == '00')) {
      validatedValue = '01';
    }
    setFormData((prevData) => {
      const updatedBirthdate = {
        ...prevData.birthdate,
        [type]: validatedValue,
      };

      validateAndDispatchDOB(updatedBirthdate); // Validate and update Redux immediately

      return { ...prevData, birthdate: updatedBirthdate };
    });

    // Auto-focus logic
    if (validatedValue.length === maxLength) {
      if (type === 'day') {
        monthRef.current?.focus();
      } else if (type === 'month') {
        yearRef.current?.focus();
      }
    }
  };



  const validateAndDispatchDOB = (birthdate) => {
    const { day, month, year } = birthdate;
    // hold validation until all fields are entered and year input should be 4 digits
    if (day === '' || month === '' || year === '' || year.length < 4) {
      dispatch(setPayload({ data: { dateOfBirth: "" } }));
      setErrorMessage("");
      setAge(null);
      return
    }

    if (!day || !month || !year) {
      dispatch(setPayload({ data: { dateOfBirth: "" } }));
      setErrorMessage("Please enter a valid date of birth.");
      return;
    }

    if (!isValidDate(day, month, year)) {
      dispatch(setPayload({ data: { dateOfBirth: "" } }));
      setErrorMessage("Invalid date.");
      return;
    }

    const calculatedAge = calculateAge(day, month, year);
    if (calculatedAge === null) return;

    setAge(calculatedAge);

    const formattedDate = `${year}-${month}-${day}`;

    if (calculatedAge >= 18 && calculatedAge <= 100) {
      setErrorMessage("");
      dispatch(setPayload({ data: { dateOfBirth: formattedDate } }));
    } else {
      setErrorMessage("Age must be between 18 and 100.");
      dispatch(setPayload({ data: { dateOfBirth: "" } }));
    }
  };

  // const handleBlur = () => {
  //   const { day, month, year } = formData.birthdate;
  //   const calculatedAge = calculateAge(day, month, year);

  //   if (calculatedAge !== null) {
  //     setAge(calculatedAge);

  //     const formattedDate = `${year}-${month}-${day}`;
  //     if (errorMessage == ''&& calculatedAge >= 18 && calculatedAge <= 100) {
  //       dispatch(setPayload({ data: { dateOfBirth: formattedDate } }));
  //     }
  //     else {
  //       dispatch(setPayload({ data: { dateOfBirth: '' } }));
  //     }
  //   }
  // };
  const handleBlur = (field) => {
    setFormData((prevData) => {
      let value = prevData.birthdate[field];  
      // Ensure value is treated as a string and trim spaces
      value = value?.toString().trim();  
      // If value is "0", set it to "01"
      if (value === "0") {
        value = "01";
      }
      // If value is a single digit, add leading zero
      else if (value.length === 1) {
        value = `0${value}`;
      }
  
      return {
        ...prevData,
        birthdate: {
          ...prevData.birthdate,
          [field]: value,
        },
      };
    });
  };

  return (
    <div className="d-flex justify-content-center flex-column align-items-center p5vh h-100 text-center">
      <div className="font-m font-32 fw-medium">Tell Us Your Date of Birth?</div>
      <div className="font-m font-14 colorf5 pt-6">
        To ensure you meet our platform’s age requirements, please enter your date of birth.
      </div>

      <div className="dobSetnew d-flex justify-content-center gap-3" style={{ margin: '16px 0' }}>
        {/* <div className="day">
          <input
            ref={dayRef}
            className="form-control font-m"
            type="number"
            placeholder="DD"
            value={formData.birthdate.day}
            onChange={(e) => handleInputChange('day', e.target.value)}
            // onBlur={handleBlur}
            onKeyDown={(e) => {
              if (["+", "-", "e"].includes(e.key)) e.preventDefault(); // Block +, -, and e
            }}
            min="1"
            max="31"
          />
        </div>
        <div className="month">
          <input
            ref={monthRef}
            className="form-control font-m"
            type="number"
            placeholder="MM"
            value={formData.birthdate.month}
            onChange={(e) => handleInputChange('month', e.target.value)}
            onKeyDown={(e) => {
              if (["+", "-", "e"].includes(e.key)) e.preventDefault(); // Block +, -, and e
            }}
            // onBlur={handleBlur}
            min="1"
            max="12"
          />
        </div> */}
        <div className="day">
          <input
            ref={dayRef}
            className="form-control font-m"
            type="number"
            placeholder="DD"
            value={formData.birthdate.day}
            onBlur={() => handleBlur("day")}
            onChange={(e) => handleInputChange('day', e.target.value)}
            onKeyDown={(e) => {
              if (["+", "-", "e"].includes(e.key)) e.preventDefault(); // Block +, -, and e
            }}
            min="1"
            max="31"
          />
        </div>
        <div className="month">
          <input
            ref={monthRef}
            className="form-control font-m"
            type="number"
            placeholder="MM"
            onBlur={() => handleBlur("month")}
            value={formData.birthdate.month}
            onChange={(e) => handleInputChange('month', e.target.value)}
            onKeyDown={(e) => {
              if (["+", "-", "e"].includes(e.key)) e.preventDefault(); // Block +, -, and e
            }}
            min="1"
            max="12"
          />
        </div>
        <div className="year">
          <input
            ref={yearRef}
            className="form-control font-m"
            type="number"
            placeholder="YYYY"
            value={formData.birthdate.year}
            onChange={(e) => handleInputChange('year', e.target.value)}
            onKeyDown={(e) => {
              if (["+", "-", "e"].includes(e.key)) e.preventDefault(); // Block +, -, and e
            }}
            // onBlur={handleBlur}
            min="1900"
            max={new Date().getFullYear().toString()}
          />
        </div>
      </div>

      {/* Validation Error Message */}
      {errorMessage && (
        <div className="error-message" style={{ color: '#D7443E', marginBottom: '8px' }}>
          {errorMessage}
        </div>
      )}

      {/* Age Validation and Eligibility Message */}
      {age !== null && errorMessage === '' && (
        <div className="f16-mvw font-m">
          Your Age: {age}
          {age >= 18 && age <= 100 ? (
            <div className="correct" style={{ color: '#02BC7D' }}>
              <CheckCircleIcon /> You are eligible to register on our platform.
            </div>
          ) : (
            <div className="incorrect" style={{ color: '#D7443E' }}>
              <CancelIcon /> You are not eligible to register on our platform.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TillUsDOB