import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '10px 30px 0px 30px',
  borderRadius: '20px',
  maxHeight: '90vh',
  overflow: 'auto',
}; 

 
const PaymentStatusModal = ({ open, handleClose }) => {
     
  
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
         <Box sx={{ position: "sticky", top: '0px', display:'flex', justifyContent: 'end', alignItems:'center', background: "white", zIndex: 2, p: '0px 0', borderBottom: "0px solid #ddd" }}> 
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
        {/* ✅ Fixed Header */}
        <div className="text-center setSuccFail">
            <img src="/assets/images/payment/successful-icon.svg" className="img-fluid" alt="successful" />
            <h3 className="pt-24">Your payment was successful! </h3>
            <p>Successfully paid </p>

            {/* <div className="pt-16 odr001"> Order #123456 is confirmed.</div> */}
        </div>
        <Box sx={{ position: "sticky", bottom: 0, background: "white", py: 2, mt: 2, borderTop: "1px solid #ddd" }}>
          <Link
            to="/members"
            fullWidth 
            sx={{ bgcolor: "#916008", color: "white", fontSize: '15px', fontWeight: '500',textTransform: 'capcapitalize', borderRadius: '50px', padding:'12px 10px' }}
        
          >
          Go to Dashboard
          </Link>
        </Box>
      </Box>
    </Modal>
    
  );
};

export default PaymentStatusModal;  