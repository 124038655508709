import React from 'react';

const MembershipCriteria = () => {
  const membershipData = {
    title: "Membership Criteria",
    description: "Admission to Luxury Life is selective. We seek individuals who demonstrate excellence, influence, and a commitment to an exclusive network.",
    buttonText: "Apply now to request an invitation",
    requirements: [
      "High-profile industry standing",
      "Verified net worth or professional achievements",
      "Referral from an existing member"
    ]
  };

  const handlemembershipDataClick = () => {
    window.location.href = '/signup';
  };
  
  return (
    <div className='WhatYouGet' style={{ padding: '5vw 5.5vw', background: 'rgba(191, 133, 0, 0.22)' }}>
      <div className='d-flex justify-content-between align-items-center MembershipCriteriabg'>
        <div className='w-100 MembershipCriteria'>
          <h2 className='text-start font48vw'>{membershipData.title}</h2>
          <p className='font04vw'>{membershipData.description}</p>
          <button className="text-white font-semibold ApplyNowBntSt2" onClick={handlemembershipDataClick}>
            {membershipData.buttonText}
          </button>
        </div>
        <div className='w-100 Requirementstext'>
          <div className='heading font24vw font-e fst-italic'>Requirements</div>
          <ul>
            {membershipData.requirements.map((requirement, index) => (
              <li key={index} className='font20vw'>
                <span className='img'>
                  <img src='/assets/images/first-home/requirements-check-icon.svg' className='img-fluid' alt="" />
                </span>
                {requirement}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MembershipCriteria;