import React from "react";

const UserAboutText = ({ content }) => {
  return (
    <div className="aboutText">
      {content.map((section, index) => (
        <div key={index} className="Text border-top">
          <h3 className="font-24 font-m fw-medium text-black">{section.title}</h3>
          {section.paragraphs.map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default UserAboutText;
