import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LuxuryFooter from "src/components/luxury-home/LuxuryFooter";
import Header from "src/containers/home-container/layout/Header";

const sections = [
    {
        id: "WELCOME",
        title: "WELCOME",
        content: (
            <>
                <p>Luxury Life is a membership-based online community for exclusive networking and meaningful connections. Our goal is to foster a safe, trusted community where members can engage with like-minded individuals to build personal and professional relationships that align with their lifestyle. With that in mind, here are a few of the most important things to know about Luxury Life:</p>

                <p>We strive to foster a community where all members feel comfortable, and secure, and that their privacy is protected. We do this in several ways:</p>

                <p>Our team and membership committee have been rigorously vetted and are under strict non-disclosure agreements, ensuring that, other than as you elect to share through your actions or activity within the Service (as defined below), your information, interests, and involvement remain strictly between you and us.</p>

                <p>We only select community members who we believe will adhere to our code of conduct, including respecting the privacy of our members. Further, we periodically review our community and remove members who are not acting in accordance with our code of conduct or the below Terms of Use or who are otherwise engaging in disrespectful, distasteful, inappropriate, or improper behavior.</p>

                <p>We provide privacy-enhancing features such as profile “hide” options and “paused” account status, which members can utilize anytime if they wish to take a break. We have also made social visibility and location services easy to toggle on and off, ensuring that you have full control over your visibility at all times.</p>

                <p>Our members are our top priority. Your comfort, safety, and trust in our community are paramount, and we will never compromise that. Our community is different. If you're a current member and have read this far – because you value exclusivity and privacy – then we built this for you. Every decision we make is to provide you with an exceptional experience. Should you ever have any questions regarding these Terms of Use, membership, or any other aspect of our Service, please feel free to contact us at <Link to="javascript:void(0);" style={{}}>support@luxurylife.ai</Link>.</p>
            </>
        )
    },
    {
        id: "INTRODUCTION",
        title: "INTRODUCTION",
        content: (
            <>
                <p>Luxury Life, (“Company,” “we,” “us,” “our”) provides its related services, including, but not limited to, those related to events, venues, and curated in-person experiences (collectively, the “Service”), subject to the following Terms of Use (as amended from time to time, the “Terms of Use”). By submitting an application to Luxury Life, creating an account, RSVPing to or attending an event, or otherwise using the Service in any manner whatsoever, you agree to be bound by these Terms of Use and our Privacy Policy, which is incorporated herein by reference and available at <Link to="javascript:void(0);" style={{}}>luxurylife.ai/privacypolicy</Link>. We reserve the right, in our sole discretion, to change or modify these Terms of Use at any time. Each time we do so, we will indicate at the top of this page the date of the most recent version. Your continued use of the Service after any changes become effective constitutes your acceptance of the new Terms of Use. You should regularly check this page for updates. If you do not accept and agree to be bound by all of the Terms of </p>
            </>
        )
    },
    {
        id: "APPLICATION",
        title: "APPLICATION AND REGISTRATION PROCESS",
        content: (
            <>
                <p>To gain access to the Service, prospective users must complete a brief application, with accepted users being offered membership for a fee. While all applications will be considered, we reserve complete control and sole discretion regarding membership approvals. Our membership committee evaluates applicants based on various factors, including their connection to existing members and their alignment with the community’s standards. Those who are not granted access will be placed on our waitlist, which is reviewed periodically for additional admissions. By submitting an application, you agree to receive text messages and emails from us regarding, among other things, the status of your application.</p>

                <p>Members will be required to select a subscription option from among various pricing and duration plans. Payments automatically renew until canceled and are subject to the terms and conditions applicable to the payment method. All membership fees and purchases are non-refundable.</p>

                <p>Upon joining, you will be responsible for maintaining the confidentiality of your password and account, ensuring the accuracy of your information, and being fully responsible for all activities that occur under your account. You agree to immediately notify Luxury Life of any unauthorized use or security breach and to keep your information current. We are not liable for any loss, injury, or damage arising from your failure to comply with these obligations.</p>

            </>
        )
    },
    {
        id: "TERMS",
        title: "TERMS OF USE",
        content: (
            <>
                <p><span className="d-block fw-bold">Legally Binding Agreement</span>
                    These Terms of Use constitute a legally binding agreement governing your use of the Service.</p>

                <p><span className="d-block fw-bold">Eligibility</span>
                    You must be at least 18 years old to use the Service. By submitting an application, creating an account, or attending events, you represent and warrant that you are legally permitted to use the Service and are not prohibited under any applicable laws.</p>

                <p><span className="d-block fw-bold">Termination </span>
                    These Terms of Use remain in effect while you use the Service or have an account. We reserve the right to suspend or terminate your account or access to the Service at any time for any reason, including failure to comply with our Terms or conduct that we determine to be inappropriate.</p>

                <p><span className="d-block fw-bold">Commercial Use</span>
                    The Service is for personal use only. You may not use the Service for business promotion, advertising, or solicitation.</p>

                <p><span className="d-block fw-bold">User Interactions</span>
                    We are not responsible for the conduct of any user, whether interactions take place online or at in-person events. You are solely responsible for your interactions and should take all necessary precautions when engaging with other users.</p>

                <p><span className="d-block fw-bold">User Content </span>
                    You are responsible for all content you post on the Service. You may not post offensive, misleading, abusive, sexually explicit, or otherwise inappropriate content. We reserve the right to remove any content that violates these Terms.</p>

                <p><span className="d-block fw-bold">Prohibited Activities</span>
                    Users may not engage in harassment, intimidation, impersonation, solicitation, or any other activities that violate these Terms or disrupt the community. Violations may result in immediate account termination.</p>

                <p><span className="d-block fw-bold">Intellectual Property </span>
                    The Company retains full ownership of all proprietary rights associated with the Service, including but not limited to content, trademarks, patents, trade names, service marks, and other intellectual property rights. The Service contains copyrighted material, proprietary branding, and other legally protected assets belonging to the Company and its licensors. You agree not to copy, modify, transmit, create derivative works from, or use any intellectual property accessible through the Service without prior written consent from the Company. Furthermore, you must not remove or obscure any proprietary notices, including copyright or trademark indications, from any content obtained through the Service.</p>

                <p><span className="d-block fw-bold">Digital Millennium Copyright Act </span>
                    The Company complies with the Digital Millennium Copyright Act ("DMCA") and takes copyright infringement seriously. If you believe that any content available through the Service infringes on your intellectual property rights, you may submit a DMCA Takedown Notice to support@luxurylife.ai with the following details: (i) an electronic or physical signature of the authorized representative of the copyright owner; (ii) identification of the copyrighted work claimed to have been infringed; (iii) identification of the infringing material with sufficient detail for the Company to locate it; (iv) your contact information; (v) a statement affirming your good faith belief that the disputed use is unauthorized; and (vi) a statement under penalty of perjury that the provided information is accurate and that you are authorized to act on behalf of the copyright owner.</p>

                <p><span className="d-block fw-bold">Third-Party Content</span>
                    The Company is not liable for any content provided by users or third parties, including inaccuracies, omissions, or any damages incurred as a result of reliance on such content. While we reserve the right to moderate and remove objectionable content at our discretion, we do not pre-screen all material submitted to the Service. Users must evaluate and assume all risks associated with the use of third-party content, understanding that the Company is not responsible for the accuracy or reliability of such material.</p>

                <p><span className="d-block fw-bold">External Links and Third-Party Websites </span>
                    The Service may include links to third-party websites or resources. The Company does not endorse, control, or assume responsibility for any third-party content, products, or services. Your interactions with third parties found through the Service are solely between you and the third party, and the Company bears no liability for any loss or damage incurred as a result of such interactions.</p>

                <p><span className="d-block fw-bold">Social Networking Services </span>
                    You may access the Service through third-party social networking platforms, such as Instagram or LinkedIn. By integrating these services, you allow us to access certain information from your social networking profile as permitted under our Privacy Policy. We are not responsible for the privacy practices of third-party platforms, nor do we guarantee the accuracy or security of their content. You acknowledge that your use of such services is at your own risk.</p>

                <p><span className="d-block fw-bold">Mobile Services </span>
                    The Service includes mobile-compatible features, such as browsing, content uploads, and notifications. Standard carrier charges may apply, and compatibility varies by provider. By using mobile services, you consent to receiving communications via push notifications, SMS, or other electronic means. If you change your mobile number, you must update your account to avoid unintended message delivery.</p>

                <p><span className="d-block fw-bold">Notifications, Location Services, and Communications </span>
                    By using the Service, you agree to receive communications from us, including but not limited to emails, text messages, and push notifications. You may adjust your notification preferences in your account settings. Certain features of the Service rely on location data; enabling these features grants us permission to collect and use your location information as described in our Privacy Policy.</p>

                <p><span className="d-block fw-bold">Apple-Enabled Software Applications</span>
                    If you access the Service via an Apple-branded product, additional terms apply:</p>

                <ul>
                    <li>The agreement is between you and the Company, not Apple.</li>
                    <li>Apple is not responsible for maintenance, support, or warranty obligations regarding the Service.</li>
                    <li>Any claims related to the Service must be directed to the Company.</li>
                    <li>Apple and its affiliates are third-party beneficiaries of this agreement and may enforce its terms.</li>
                </ul>

                <p><span className="d-block fw-bold">Disclaimers</span>
                    The Service is provided "as is" and "as available," without warranties of any kind. The Company disclaims all liability for any loss or damage, including personal injury or data corruption, arising from:</p>

                <ul>
                    <li>Interactions between users, online or offline.</li>
                    <li>Attendance at events or venues discovered through the Service.</li>
                    <li>Technical failures, including network errors or device malfunctions.</li>
                    <li>Disruptions, terminations, or changes to the Service.</li>
                </ul>

                <p>By using the Service, you acknowledge that the Company is not responsible for verifying the identity, safety, or reliability of users and events accessed through the platform.</p>

                <p><span className="d-block fw-bold">Limitation of Liability </span>
                    To the maximum extent permitted by law, the Company and its affiliates shall not be liable for indirect, incidental, consequential, or punitive damages arising from your use of the Service. The Company's total liability, if any, shall be limited to the amount paid by you to access the Service, if applicable. Certain jurisdictions do not allow limitations on liability, so some provisions in this section may not apply to you.</p>

                <p><span className="d-block fw-bold">Arbitration and Governing Law</span>
                    Except for users residing within the European Union, Norway, and elsewhere where prohibited by applicable law, the exclusive means of resolving any dispute or claim arising out of or relating to these Terms of Use (including any alleged breach thereof) or the Service shall be BINDING ARBITRATION administered by the American Arbitration Association. The one exception to the exclusivity of arbitration is that you have the right to bring an individual claim against the Company in a small-claims court of competent jurisdiction. However, whether you choose arbitration or small-claims court, you may not under any circumstances commence or maintain against the Company any class action, class arbitration, or other representative action or proceeding.</p>

                <p>By using the Service in any manner, you agree to the above arbitration agreement. In doing so, YOU WAIVE YOUR RIGHT TO GO TO COURT to assert or defend any claims between you and the Company (except for matters that may be taken to small-claims court). YOU ALSO WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING. Your rights will be determined by a neutral arbitrator, not a judge or jury. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant any relief that a court can, but arbitration proceedings are typically more streamlined than trials. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons.</p>

                <p>Any proceeding to enforce this arbitration agreement, including any proceeding to confirm, modify, or vacate an arbitration award, may be commenced in any court of competent jurisdiction. In the event that this arbitration agreement is for any reason held to be unenforceable, any litigation against the Company may be commenced only in the federal or state courts located in the State of Delaware. You hereby irrevocably consent to the jurisdiction of those courts for such purposes and WAIVE YOUR RIGHT, IF ANY, TO A JURY TRIAL in such actions.</p>

                <p>These Terms of Use, and any dispute between you and the Company, shall be governed by the laws of the State of Delaware without regard to principles of conflicts of law, provided that this arbitration agreement shall be governed by the Federal Arbitration Act. For users residing in any jurisdiction where this arbitration agreement is prohibited by law, the laws of the State of Delaware, excluding Delaware’s conflict of laws rules, will apply to any disputes arising out of or relating to these Terms of Use. All claims arising out of or relating to these Terms of Use or the Services but not eligible for arbitration will be litigated exclusively in the federal or state courts located in the State of Delaware, and you consent to personal jurisdiction in those courts.</p>

                <p><span className="d-block fw-bold">Indemnification</span>
                    You agree to indemnify and hold the Company, its officers, directors, employees, agents, subsidiaries, and affiliates, harmless from any loss, liability, claim, or demand, including reasonable attorney’s fees, made by any third party due to or arising out of your breach of or failure to comply with these Terms of Use (including any breach of your representations and warranties contained herein), any event that you promote, market, create, host, or invite others to using the Service, any content you post in the Service, and the violation of any law or regulation by you. The Company reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with the Company in connection therewith.</p>

                <p><span className="d-block fw-bold">Event Creators and Hosts </span>
                    By utilizing the Service to create, host, promote, or invite others to a private or public event, you acknowledge and agree that:</p>

                <p>The Company is not responsible for the event or any aspect thereof, including, but not limited to, the behavior or safety of any attendee.</p>

                <p>You will not promote, market, or refer to the event as an official event of the Company or otherwise indicate that the Company has any involvement with the event other than as a networking platform.</p>

                <p>You agree to indemnify and hold the Company, its officers, directors, employees, agents, subsidiaries, and affiliates, harmless from any loss, liability, claim, or demand, including reasonable attorney’s fees, made by any third party relating to the event.</p>

                <p><span className="d-block fw-bold">Notice</span>
                    The Company may provide you with notices, including those regarding these Terms of Use, using any reasonable means, which may include email, SMS, MMS, text message, or postings in the Service. Such notices may not be received if you violate these Terms of Use by accessing the Service in an unauthorized manner. You agree that you are deemed to have received any and all notices that would have been delivered had you accessed the Service in an authorized manner.</p>

                <p><span className="d-block fw-bold">Reviews</span>
                    For the avoidance of doubt, nothing in these Terms of Use should be construed to prohibit you from offering customer reviews of the Company or its Service or engaging in any activities protected by consumer protection laws, such as the Consumer Review Fairness Act or any similar statute or rule.</p>

                <p><span className="d-block fw-bold">Entire Agreement; Miscellaneous</span>
                    These Terms of Use, together with the Privacy Policy, comprise the entire agreement between you and the Company regarding the use of the Service. If any provision of these Terms of Use is held invalid, the remainder hereof shall continue in full force and effect. The failure of the Company to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision. You agree that your account is non-transferable and all of your rights to your profile or contents within your account terminate upon your death or termination from the Service. No agency, partnership, joint venture, or employment is created as a result of these Terms of Use, and you may not make any representations or bind the Company in any manner.</p>

                <p><span className="d-block fw-bold">State-Specific Terms </span>
                    The following provisions are added to these Terms of Use for users residing in Arizona, California, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, and Wisconsin:</p>

                <p>You, the buyer, may cancel your membership, without penalty or obligation, at any time prior to midnight of the third business day following the original date of this contract, excluding Sundays and holidays.</p>

                <p>In the event that you die before the end of your subscription period, your estate shall be entitled to a refund of that portion of any payment you had made for your subscription which is allocable to the period after your death.</p>

                <p>In the event that you become disabled (such that you are unable to use the Service) before the end of your subscription period, you shall be entitled to a refund of that portion of any payment you had made for your subscription which is allocable to the period after your disability by providing the Company notice.</p>

                <p><span className="d-block fw-bold">Account Termination and Suspension </span>
                    We reserve the right to suspend or terminate your account at our discretion, with or without cause, if we determine that your actions violate these Terms, our community guidelines, or any applicable laws. You may also terminate your account at any time by following the designated process in your account settings. Termination of your account does not relieve you of any obligations incurred before such termination, including outstanding fees, legal responsibilities, or any continuing rights granted to the Company under these Terms.</p>

                <p><span className="d-block fw-bold">Changes to These Terms </span>
                    We reserve the right to update or modify these Terms at any time. Changes will be effective upon posting to the Service, with the "Last Updated" date revised accordingly. Your continued use of the Service after any such modifications constitute your acceptance of the updated Terms. If you do not agree to the modifications, you must discontinue use of the Service immediately.</p>

                <p><span className="d-block fw-bold">Governing Law and Dispute Resolution </span>
                    These Terms are governed by and construed in accordance with the laws of the USA and India, without regard to its conflict of laws principles. Both parties waive their right to a trial by jury and agree to resolve disputes on an individual basis, not as part of a class action or representative proceeding.</p>
            </>
        )
    },
    {
        id: "CONTACT",
        title: "CONTACT INFORMATION",
        content: (
            <>
                <p>For any questions regarding these Terms, please contact us at <Link to="javascript:void(0)" className="">support@luxurylife.ai</Link>.</p>
            </>
        )
    }
];

const TermsAndConditions = () => {
    const [activeSection, setActiveSection] = useState("");

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.pageYOffset;
            sections.forEach(({ id }) => {
                const section = document.getElementById(id);
                if (section) {
                    const vwOffset = (5 / 100) * window.innerWidth; // 5vw ko px me convert kiya
                    const sectionTop = section.offsetTop - vwOffset;
                    const sectionHeight = section.offsetHeight;
                    if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                        setActiveSection(id);
                    }
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [sections]);

    // ✅ `handleNavClick` ko useEffect ke bahar define karein
    const handleNavClick = (event, id) => {
        event.preventDefault();
        const section = document.getElementById(id);
        if (section) {
            const vwOffset = (4 / 100) * window.innerWidth; // 4vw ko px me convert kiya
            const sectionTop = section.offsetTop - vwOffset;

            window.scrollTo({
                top: sectionTop,
                behavior: "smooth",
            });

            setActiveSection(id); // Active section update karein
        }
    };

    return (
        <>
            <div className='aboutusnEwv1' style={{ padding: '5vw 20.5vw' }}>
                <Header />
                <div className="Terms-privCont d-flex">
                    <div className="privCont001" style={{ minWidth: '23vw' }}>
                        <div className="hdtoptermpriv">
                        <h2 className="font48vw text-black fw-bold" style={{ padding: '2vw 0 0vw 0' }}>Luxury Life - Terms and Conditions</h2>  
                            <p className="font08vw color7b">Updated and Effective 31-01-2025</p>
                        </div>
                        <nav className="sidebar-TermsPrivacy position-sticky">
                            <ul className="navigation">
                                {sections.map(({ id, title }) => (
                                    <li key={id}>
                                        <a
                                            href={`#${id}`}
                                            className={activeSection === id ? "active" : ""}
                                            onClick={(e) => handleNavClick(e, id)}
                                        >
                                            {title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="TermsPrivacy-content" style={{ minWidth: "32.5vw" }}>
                        {sections.map(({ id, title, content }) => (
                            <section id={id} key={id}>
                                <h2>{title}</h2>
                                {content}
                            </section>
                        ))}
                    </div>
                </div>
            </div>
            <LuxuryFooter />
        </>
    );
}

export default TermsAndConditions