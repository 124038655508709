import config from 'src/config';
import { deleteRequest, getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const memberSearchAPI = function (data) {
    const url = `${config.baseUrl}home/search`;
    return postRequest({
        url,
        data,
    });
};

export const memberFilterCreateAPI = function (data) {
    const url = `${config.baseUrl}home/save-user-filter`;
    return postRequest({
        url,
        data,
    });
};

export const memberFilterUpdateAPI = function ({
    _id,
    data,
}) {
    const url = `${config.baseUrl}home/update-user-filter/${_id}`;
    return putRequest({
        url,
        data,
    });
};
export const memberActionUpdateAPI = function ({
    memberId,
    data,
}) {
    const url = `${config.baseUrl}home/update-activity-log/${memberId}`;
    return putRequest({
        url,
        data,
    });
};

export const getFiltersAPI= function () {
    const url = `${config.baseUrl}home/get-user-filter`;
    return getRequest({
        url
    });
};
export const deleteFiltersAPI= function ({id}) {
    const url = `${config.baseUrl}home/delete-user-filter/${id}`;
    return deleteRequest({
        url
    });
};
export const getMemberDetailsAPI= function (id) {
    const url = `${config.baseUrl}home/get-user-profile/${id}`;
    return getRequest({
        url
    });
};
export const sendPrivatePicRequestAPI= function (data) {
    const url = `${config.baseUrl}home/request-private-pic-access`;
    return postRequest({
        url,
        data
    });
};