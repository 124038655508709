import React from 'react'

const OurVision = () => {
  return (
    <div>
       <div style={{ padding: '0vw 0vw 5vw 05.5vw' }}>
       <h2 className='fw-normal font-m font48vw mb-0' style={{padding: '0 0 2vw 0'}}>Our Vision</h2>
       <div className='ourvisiontextNew d-flex justify-content-between align-items-center'>
        <div className='text'>
        <p className='mb-0 font-2vw text-white'>We believe in fostering an ecosystem where the elite can collaborate, innovate, and build lasting relationships. Luxury Life is committed to maintaining a high standard of excellence, ensuring that every member benefits from a premium, well-curated experience.</p>
        </div>
        <span className='img'><img src='/assets/images/first-home/about-us/our-vision.svg' className='img-fluid' alt="" /></span>
       </div>
    </div>
    </div>
  )
}

export default OurVision
