import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPayload } from "src/containers/signup-container/actions/SignupActions";

const YourHobbies = () => {
  const dispatch = useDispatch();
  const {
    hobbies,
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [selectedHobbies, setSelectedHobbies] = useState(hobbies);

  // Define hobbies in an array
  const hobbiesList = [
    "Reading",
    "Traveling",
    "Cooking/Baking",
    "Hiking/Outdoor Adventures",
    "Photography",
    "Painting/Drawing",
    "Playing Sports",
    "Writing",
    "Yoga/Meditation",
    "Playing Musical Instruments",
    "Gardening",
    "Watching Movies/TV Shows",
    "Dancing",
    "Volunteering/Community Service"
  ];

  // Handle hobby selection
  const handleHobbiesSelection = (value) => {
    let updatedHobbies;
    if (selectedHobbies.includes(value)) {
      updatedHobbies = selectedHobbies.filter((hobby) => hobby !== value);
    } else if (selectedHobbies.length < 7) { // Limit to 7 selectedHobbies
      updatedHobbies = [...selectedHobbies, value];
    } else {
      updatedHobbies = selectedHobbies;
    }
    setSelectedHobbies(updatedHobbies);
    dispatch(setPayload({ data: { hobbies: updatedHobbies } }));
  };

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">What Are Your Hobbies? </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Select at least 3 hobbies that reflect your passions and lifestyle. (Maximum 7 hobbies only)
        </div>
      </div>
      <div className="">
        <div className="interest-selection text-center  mt-32 mb-16">
          {/* <h3 className="font-18 font-m fw-medium mt-24 mb-0">Choose Your Hobbies</h3> */}
          <div className="btnsetAll btnsetAll2 mt-6">
            {/* Dynamically render buttons */}
            {hobbiesList.map((hobby, index) => (
              <button
                key={index}
                className={`font-m border font-16 fw-normal ${hobbies.includes(hobby) ? "selected" : ""}`}
                onClick={() => handleHobbiesSelection(hobby)}
              >
                {hobby}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourHobbies