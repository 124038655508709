import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { shallowEqual, useSelector } from 'react-redux';
const CompleteVerification = () => {
  const {user} = useSelector((state) => state.auth, shallowEqual);
  return ( 
      <div className='FrontBackPicMn' style={{ textAlign: "center", margin: "20px" }}>
      <h3 className="text-center font-32">Verify Your Identity
      </h3>
      <p className="text-center font-18" style={{ color: 'rgba(0,0,0,0.7)' }}>
      We need to confirm it’s really you! Follow these quick steps to complete the identity verification.
      </p> 

      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        
        {/* Back Image Upload */}
        <div className="w-100"> 
         <div className='d-flex flex-column gap-2 justify-content-between align-items-center'>
         <CheckCircleIcon sx={{width: '51px', height: '51px', color: '#02BC7D',}} /> 
         <p className='font-14 colorc3 fw-bold'>Your document identity has been submitted </p>
         <p className='font-14 colorc3 fw-bold'>Status
         <span className='font-14 colorc3 d-block fw-normal fst-italic'>your document verification under process</span> </p>
         </div>
        <div className='d-flex gap-3 VerifyIdanty'>
            <div className='w-100 imgfirst'><img src={user?.documents?.front} className='img-fluid' /></div>
            <div className='w-100 imgsecond'><img src={user?.documents?.back} className='img-fluid' /></div>
        </div>
          
        </div>
      </div>
    </div> 
  )
}

export default CompleteVerification
