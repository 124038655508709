import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { Link } from "react-router-dom";
const UserProfileDetails = () => {
    const {selectedUser } = useSelector((state) => state.ChatReducer, shallowEqual);
    // Dynamic data with image URLs
    const profileData = [  
        { icon: "/assets/images/user/user-profile/ethnicity.svg", label: "Ethnicity", value: selectedUser.ethnicity || "Prefer Not To Say" },
        { icon: "/assets/images/user/user-profile/children.svg", label: "Children", value:  selectedUser.children || "Prefer Not To Say" },
        { icon: "/assets/images/user/user-profile/do-you-smoke.svg", label: "Smokes", value: selectedUser.smoke || "Prefer Not To Say" },
        { icon: "/assets/images/user/user-profile/do-you-drink.svg", label: "Drinks", value: selectedUser.drink || "Prefer Not To Say" },
        { icon: "/assets/images/user/user-profile/education.svg", label: "Education", value: selectedUser.education || "Prefer Not To Say" },
        { icon: "/assets/images/user/user-profile/occupation.svg", label: "Occupation", value: selectedUser.occupation || "Prefer Not To Say" },
        // { icon: "/assets/images/user/user-profile/annual-income.svg", label: "Annual income", value: "$150,000" },
        { icon: "/assets/images/user/user-profile/net-worth.svg", label: "Net worth", value: selectedUser.netWorthRange || "Prefer Not To Say" },
        // { icon: "/assets/images/user/user-profile/verification.svg", label: "Verification", value: "Not Verified" },
    ];

    const socialverifications = [
        { name: "Instagram Verification", icon: "/assets/images/icons/instagram-icon.svg", status: "verified" },
        { name: "LinkedIn Verification", icon: "/assets/images/icons/linkedin-icon.svg", status: "verified" },
        { name: "Facebook Verification", icon: "/assets/images/icons/facebook-icon.svg", status: "not_verified" },
        { name: "Selfie Verification", icon: "/assets/images/icons/selfie-icon.svg", status: "not_verified" },
        { name: "Identity", icon: "/assets/images/icons/identity-icon.svg", status: "not_verified" },
      ];

    return (
        <div className="w-100 d-flex flex-column align-content-center gap-12"> 
            {/* Profile Details */}
            {profileData.map((item, index) => (
                <div className="profile-DataList"
                    key={index} 
                >
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={item.icon}
                            alt={item.label}
                            style={{ width: "24px", height: "24px", marginRight: "10px" }}
                        />
                        <span className="font-14 fw-normal font-m" style={{ color: '#081726' }}>
                            {item.label}
                        </span>
                    </span>
                    <span className="text-end" style={{ color: '#081726', fontWeight: 'normal' }}>
                        {item.value}
                    </span>
                </div>
            ))}

             <div className="d-flex align-items-center text-black font-16 font-m fw-medium gap-4 pt-10 mb-10 Verificationsicb">
                      
                          <VerifiedOutlinedIcon style={{ color: '#BF8500' }} /> Verifications
                        </div> 
                        <div className="verification-socialList mt-0">
                    {socialverifications.map((item, index) => (
                      <div className="verification-item d-flex align-items-center justify-content-between" key={index}>
                      <div className='socialImgName'><img src={item.icon} alt={item.name} className="icon" />
                      <span className="name">{item.name}</span></div> 

                        {item.status === "verified" && (
                            <span className="verified fst-italic d-flex align-items-center gap-1">
                                <img src="/assets/images/icons/verified.svg" className="img-fluid" alt="" /> Verified
                            </span>
                            )}
                      </div>
                    ))}
                  </div>

            {/* Verifications Section */}
            {/* <h3 className="pt-24 d-flex justify-content-between align-items-center" style={{ fontSize: '16px', fontWeight: "bold", color: '#081726' }}>
                Verifications <VerifiedOutlinedIcon style={{ color: '#BF8500' }} />
            </h3>
            <div style={{ marginBottom: "10px" }}>
                {verifications.map((text, index) => (
                    <p key={index} style={{ marginBottom: "5px", fontSize: '14px', color: '#081726' }}>
                        {text}
                    </p>
                ))}
            </div> */}

            {/* Tags Section */}
            {/* <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {tags.map((tag, index) => (
                    <span
                        key={index}
                        style={{
                            border: "1px solid #ddd",
                            borderRadius: "20px",
                            padding: "5px 10px",
                            backgroundColor: "#f9f9f9",
                            fontSize: "0.9rem",
                        }}
                    >
                        {tag}
                    </span>
                ))}
            </div> */}
        </div>
    );
};

export default UserProfileDetails