import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ReplayIcon from '@mui/icons-material/Replay';
import { toast } from 'react-toastify';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fileDeleteAPI, fileDeleteByUrlAPI, uploadPrivateFileAPI, uploadPublicFileAPI } from 'src/api/FileApi';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';
import { cleanUrl } from 'src/utils/CommonUtils';
import CustomLoader from 'src/Component/loader/CustomLoader';
const PhotoUpload = () => {
    const dispatch = useDispatch();
    const {
        publicPhotos,
        privatePhotos,
        profilePicture
    } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
    const {
        user,
    } = useSelector((state) => state.auth, shallowEqual);
    const [isLoading, setIsLoading] = useState(null);
    const [photos, setPhotos] = useState({
        profilePicture,
        publicPhotos: Array(4).fill(null),
        privatePhotos: Array(4).fill(null),
    })
    useEffect(() => {
        setPhotos({
            profilePicture,
            publicPhotos: [...publicPhotos, ...Array(4 - publicPhotos.length).fill(null)],
            privatePhotos: [...privatePhotos, ...Array(4 - privatePhotos.length).fill(null)],
        })
    }, [profilePicture, publicPhotos, privatePhotos])

    const handleFileChange = async (event, photoType, index = null) => {
        const file = event.target.files[0];
        setIsLoading({type:photoType,index});
        if (file) {
            if (!file.type.startsWith("image/")) {
                toast.error("Only image files are allowed!");
                event.target.value = ""; // Reset file input
                return;
            }
            const payload = { file, by: user._id };
            let fileResponse;

            if (photoType === 'profilePicture') {
                fileResponse = await uploadPublicFileAPI(payload);
            } else if (photoType === 'publicPhotos') {
                fileResponse = await uploadPublicFileAPI(payload);
            } else if (photoType === 'privatePhotos') {
                fileResponse = await uploadPrivateFileAPI(payload);
            }

            if (fileResponse?.data?.data.url) {
                const fileUrl = fileResponse.data.data.url;
                const fileSignedUrl = fileResponse.data.data?.signedUrl

                setPhotos((prevState) => {
                    const updatedState = { ...prevState };

                    if (photoType === 'profilePicture') {
                        updatedState.profilePicture = fileUrl;
                    } else if (photoType === 'publicPhotos') {
                        const updatedPublicPhotos = [...updatedState.publicPhotos];
                        updatedPublicPhotos[index] = fileUrl;
                        updatedState.publicPhotos = updatedPublicPhotos;
                    } else if (photoType === 'privatePhotos') {
                        const updatedPrivatePhotos = [...updatedState.privatePhotos];
                        updatedPrivatePhotos[index] = fileSignedUrl;
                        updatedState.privatePhotos = updatedPrivatePhotos;
                    }
                    //Update privatePicture state with fileSignedUrl value for that index as well
                    // privatePhotosArray[index] = fileSignedUrl

                    // Dispatch the updated state immediately after updating local state
                    dispatch(
                        setPayload({
                            data: {
                                profilePicture: updatedState.profilePicture,
                                publicPhotos: updatedState.publicPhotos,
                                privatePhotos: updatedState.privatePhotos,
                            },
                        })
                    );
                    setIsLoading(null);
                    return updatedState; // Return the updated state for local update
                });
            } else {
                console.error('File upload failed:', fileResponse?.data?.errors || 'Unknown error');
            }
        }
    };
    const handleDelete = async (photoType, index) => {
        let photoToDelete;
        setIsLoading({type:photoType,index});
        if (photoType === 'profilePicture') {
            photoToDelete = photos.profilePicture;
        } else if (photoType === 'publicPhotos') {
            photoToDelete = photos.publicPhotos[index];
        } else if (photoType === 'privatePhotos') {
            photoToDelete = photos.privatePhotos[index];
        }
        if (photoToDelete) {
            try {
                await fileDeleteByUrlAPI({ url: photoToDelete });

                setPhotos(prevState => {
                    const updatedState = { ...prevState };
                    if (photoType === 'profilePicture') {
                        updatedState.profilePicture = null;
                    } else if (photoType === 'publicPhotos') {
                        updatedState.publicPhotos[index] = null;
                    } else if (photoType === 'privatePhotos') {
                        updatedState.privatePhotos[index] = null;
                    }
                    return updatedState;
                });

                dispatch(setPayload({
                    data: {
                        profilePicture: photoType === 'profilePicture' ? null : photos.profilePicture,
                        publicPhotos: photoType === 'publicPhotos' ? [...photos.publicPhotos.slice(0, index), null, ...photos.publicPhotos.slice(index + 1)] : photos.publicPhotos,
                        privatePhotos: photoType === 'privatePhotos' ? [...photos.privatePhotos.slice(0, index), null, ...photos.privatePhotos.slice(index + 1)] : photos.privatePhotos,
                    },
                }));
                setIsLoading(null);;
            } catch (error) {
                console.error('Error deleting photo:', error);
            }
        }
    };
    const handleLockPhoto = async (index) => {
        const photoUrlToLock = photos.publicPhotos[index];
        setIsLoading({type:'publicPhotos',index});
        if (photoUrlToLock) {
            try {
                // Fetch the image file from the URL
                const response = await fetch(photoUrlToLock);
                const blob = await response.blob();
                const file = new File([blob], "image.jpg", { type: blob.type });

                const payload = { file, by: user._id };
                const uploadResponse = await uploadPrivateFileAPI(payload);

                if (uploadResponse.data.data.url) {
                    const updatedPublicPhotos = [...photos.publicPhotos];
                    updatedPublicPhotos[index] = null;

                    const updatedPrivatePhotos = [...photos.privatePhotos];
                    const emptyIndex = updatedPrivatePhotos.indexOf(null);
                    if (emptyIndex !== -1) {
                        updatedPrivatePhotos[emptyIndex] = uploadResponse.data.data.url;
                    }

                    setPhotos(prevState => ({
                        ...prevState,
                        publicPhotos: updatedPublicPhotos,
                        privatePhotos: updatedPrivatePhotos,
                    }));

                    dispatch(setPayload({
                        data: {
                            publicPhotos: updatedPublicPhotos,
                            privatePhotos: updatedPrivatePhotos,
                        },
                    }));
                    setIsLoading(null);;
                }
            } catch (error) {
                console.error('Error locking photo:', error);
            }
        }
    };

    const handleUnlockPhoto = async (index) => {
        const photoUrlToUnlock = photos.privatePhotos[index];
        setIsLoading({type:'privatePhotos',index});
        if (photoUrlToUnlock) {
            try {
                // Fetch the image file from the URL
                const response = await fetch(photoUrlToUnlock);
                const blob = await response.blob();
                const file = new File([blob], "image.jpg", { type: blob.type });

                const payload = { file, by: user._id };
                const uploadResponse = await uploadPublicFileAPI(payload);

                if (uploadResponse.data.data.url) {
                    const updatedPrivatePhotos = [...photos.privatePhotos];
                    updatedPrivatePhotos[index] = null;

                    const updatedPublicPhotos = [...photos.publicPhotos];
                    const emptyIndex = updatedPublicPhotos.indexOf(null);
                    if (emptyIndex !== -1) {
                        updatedPublicPhotos[emptyIndex] = uploadResponse.data.data.url;
                    }

                    setPhotos(prevState => ({
                        ...prevState,
                        publicPhotos: updatedPublicPhotos,
                        privatePhotos: updatedPrivatePhotos,
                    }));

                    dispatch(setPayload({
                        data: {
                            publicPhotos: updatedPublicPhotos,
                            privatePhotos: updatedPrivatePhotos,
                        },
                    }));
                    setIsLoading(null);;
                }
            } catch (error) {
                console.error('Error unlocking photo:', error);
            }
        }
    };
    const handleMakeProfile = (photo) => {
        setPhotos(prevState => ({ ...prevState, profilePicture: photo }));
    };

    const handleReupload = (photoType, index) => {
        document.getElementById(`${photoType}-${index}`).click();
    };

    useEffect(() => {
        setPhotos((prevState) => {
            if (
                prevState.profilePicture !== profilePicture ||
                prevState.publicPhotos !== publicPhotos ||
                prevState.privatePhotos !== privatePhotos
            ) {
                return {
                    profilePicture,
                    publicPhotos: [...publicPhotos, ...Array(4 - publicPhotos.length).fill(null)],
                    privatePhotos: [...privatePhotos, ...Array(4 - privatePhotos.length).fill(null)],
                };
            }
            return prevState; // Prevents unnecessary updates
        });
    }, [profilePicture, publicPhotos, privatePhotos]);

    const renderPhotoBox = (photo, onClick, type, index, size) => {
        return (
            <div
                className="photo-box"
                onClick={() => document.getElementById(`${type}-${index}`).click()}
                style={{
                    position: 'relative',
                    padding: '0px',
                    borderRadius: '9px',
                    margin: '0px',
                    display: 'inline-block',
                    width: size.width,
                    height: size.height,
                    cursor: 'pointer',
                    overflow: 'hidden',
                    background: '#EBEBEB',
                }}
            >
                {/* {photo ? (
                    <img
                        src={photo instanceof File ? URL.createObjectURL(photo) : photo} // Check if `photo` is a File
                        alt="Uploaded" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                    <div style={{ textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span className="d-flex flex-column" style={{ color: 'rgba(196, 196, 196, 1)' }}><AddCircleOutlineIcon /> <CameraAltOutlinedIcon /></span>
                    </div>
                )} */}
                {isLoading && isLoading.type === type && isLoading.index === index ? (
                    <div style={{ textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span className="loader"></span> {/* Add a CSS loader */}
                    </div>
                ) : photo ? (
                    <img
                        src={photo instanceof File ? URL.createObjectURL(photo) : photo}
                        alt="Uploaded"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                ) : (
                    <div style={{ textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span className="d-flex flex-column" style={{ color: 'rgba(196, 196, 196, 1)' }}>
                            <AddCircleOutlineIcon /> <CameraAltOutlinedIcon />
                        </span>
                    </div>
                )}

                {photo && (
                    <div className='photoHoverEffect'>
                        <div
                            className="delete-icon"
                            onClick={(e) => { e.stopPropagation(); handleDelete(type, index); }}
                        >
                            <span style={{ fontSize: '11px' }}>X</span>
                        </div>

                        {type === 'privatePhotos' && (
                            <div
                                className="unlock-icon"
                                onClick={(e) => { e.stopPropagation(); handleUnlockPhoto(index); }}
                            >
                                <LockOpenIcon />
                            </div>
                        )}

                        {type === 'publicPhotos' && (
                            <div
                                className="lock-icon"
                                onClick={(e) => { e.stopPropagation(); handleLockPhoto(index); }}
                            >
                                <LockIcon />
                            </div>
                        )}

                        <div
                            className="reupload-icon"
                            onClick={(e) => { e.stopPropagation(); handleReupload(type, index); }}
                        >
                            <ReplayIcon />
                        </div>

                        {/* New Make Profile Button */}
                        <div
                            className="make-profile-icon"
                            onClick={(e) => { e.stopPropagation(); handleMakeProfile(photo); }}
                        >
                            Make Profile
                        </div>
                    </div>
                )}


                <input
                    id={`${type}-${index}`}
                    type="file"
                    accept="image/*"
                    onChange={event => handleFileChange(event, type, index)}
                    style={{ display: 'none' }}
                />
            </div>
        );
    };

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
            <div>
                <div className="text-center font-32 font-m fw-medium">Show Your Best Side!</div>
                <div className="colorf5 text-center font-18 font-m pt-6">Upload 4 photos, 1 primary and 3 public. Let matches see the real you!</div>
            </div>

            {/* <h3 className="font-18 font-m fw-medium mb-0 mt-24">Minimum Selection 3 Photos</h3> */}
            {/* <p></p> */}
            <div className="d-flex gap-3 mb-16 mobilesetupswidth mt-32">
                <div className="PrimaryPhoto">
                    <label style={{ color: '#081726', fontSize: '14px', fontWeight: 500 }}>Primary Photo</label>
                    <div style={{ display: 'flex', justifyContent: 'start', maxWidth: '300px', width: '100%' }}>
                        {renderPhotoBox(photos.profilePicture, handleFileChange, 'profilePicture', 0, { width: '200px', height: '266px' })}
                    </div>
                </div>

                <div className="text-start w-100">
                    <div className="publicPhotos">
                        <label style={{ color: '#081726', fontSize: '14px', fontWeight: 500 }}>Public Photos</label>
                        <div style={{ display: 'flex', justifyContent: 'start', gap: '10px' }}>
                            {photos.publicPhotos.map((photo, index) => renderPhotoBox(photo, handleFileChange, 'publicPhotos', index, { width: '89px', height: '121px' }))}
                        </div>
                    </div>
                    <div className="privatePhotos">
                        <label style={{ color: '#081726', fontSize: '14px', fontWeight: 500 }}>Upload Private Photos</label>
                        <div style={{ display: 'flex', justifyContent: 'start', gap: '10px' }}>
                            {photos.privatePhotos.map((photo, index) => renderPhotoBox(photo, handleFileChange, 'privatePhotos', index, { width: '89px', height: '121px' }))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhotoUpload;
