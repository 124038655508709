import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { MEMBER_ACTIONS } from '../actions/MemberActions';
import { MemberDefaultFilterProps, MemberDefaultProps } from '../MemberPropTypes';

const initialState = () => ({
    currentRecord: { ...MemberDefaultProps },
    currentFilter: { ...MemberDefaultFilterProps },
    filters: [],
    search: {
        data: [],
        currentPage: 0,
        totalCount: 0,
    },
    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        case MEMBER_ACTIONS.MEMBER_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        // case MEMBER_ACTIONS.MEMBER_SEARCH_SUCCESS: {
        //     return {
        //         ...state,
        //         search: {
        //             ...state.search,
        //             ...action.data
        //         },
        //         searchReqStatus: REQUEST_STATUS.SUCCESS,
        //     };
        // }
        case MEMBER_ACTIONS.MEMBER_SEARCH_SUCCESS: {
            return {
                ...state,
                search: {
                    ...state.search,
                    data: [...state.search.data, ...action.data.data], // Merge existing and new data
                    totalCount: action.data.totalCount,
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case MEMBER_ACTIONS.MEMBER_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case MEMBER_ACTIONS.MEMBER_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case MEMBER_ACTIONS.MEMBER_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case MEMBER_ACTIONS.MEMBER_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case MEMBER_ACTIONS.MEMBER_SEARCH_RESET: {
            return {
                ...state,
                search: {
                    data: [],
                    currentPage: 0,
                    totalCount: 0,
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case MEMBER_ACTIONS.MEMBER_RESET: {
            return {
                ...state,
                currentRecord: { ...MemberDefaultProps },
                currentFilter: { ...MemberDefaultFilterProps },
            };
        }
        case MEMBER_ACTIONS.SET_FILTER: {
            return {
                ...state,
                search: {
                    data: [],
                    currentPage: 0,
                    totalCount: 0,
                },
                currentFilter: {
                    ...state.currentFilter,
                    ...action.data,
                },
            };
        }
        case MEMBER_ACTIONS.GET_FILTER_SUCCESS: {
            return {
                ...state,
                filters: action.data,
            };
        }
        case MEMBER_ACTIONS.EDIT_FILTER: {
            return {
                ...state,
                currentFilter: {
                    ...action.data,
                },
            };
        }
        default:
            return state;
    }
};
