export const SIGNUP_ACTIONS = {
    SIGNUP_SEARCH: 'SIGNUP_SEARCH',
    SIGNUP_SEARCH_SUCCESS: 'SIGNUP_SEARCH_SUCCESS',
    SIGNUP_SEARCH_ERROR: 'SIGNUP_SEARCH_ERROR',

    SIGNUP_UPSERT: 'SIGNUP_UPSERT',
    SIGNUP_UPSERT_SUCCESS: 'SIGNUP_UPSERT_SUCCESS',
    SIGNUP_UPSERT_ERROR: 'SIGNUP_UPSERT_ERROR',

    SIGNUP_EDIT: 'SIGNUP_EDIT',

    SIGNUP_RESET: 'SIGNUP_RESET',
    SET_PAYLOAD: 'SET_PAYLOAD',

    SIGNUP_SEND_OTP: 'SIGNUP_SEND_OTP',
    SIGNUP_VERIFY_OTP: 'SIGNUP_VERIFY_OTP',
    SIGNUP_CREATE_PASSWORD: 'SIGNUP_CREATE_PASSWORD',
    SIGNUP_UPDATE_SECURITY_QUESTION: 'SIGNUP_UPDATE_SECURITY_QUESTION',
};

export const signupResetAction = () => ({
    type: SIGNUP_ACTIONS.SIGNUP_RESET,
});

export const signupSearchAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_SEARCH,
    ...payload,
});

export const signupEditAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_EDIT,
    ...payload,
});

export const signupUpsertAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_UPSERT,
    ...payload,
});
export const setPayload = (payload) => ({ type: SIGNUP_ACTIONS.SET_PAYLOAD, ...payload });
export const signupSendOtpAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_SEND_OTP,
    ...payload
})
export const verifyOtpAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_VERIFY_OTP,
    ...payload
})
export const createPasswordAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_CREATE_PASSWORD,
    ...payload
})
export const updateSecurityQuestionAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_UPDATE_SECURITY_QUESTION,
    ...payload
})

export default {
    SIGNUP_ACTIONS,
    signupSearchAction,
    signupUpsertAction,
    signupEditAction,
    setPayload,
    signupSendOtpAction,
    verifyOtpAction,
    createPasswordAction,
    updateSecurityQuestionAction
};
