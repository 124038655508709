import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Grid, Box, Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ProfileCompletion from '../../../../containers/profile-container/ProfileCompletion';

const CompleteYourProfile = () => {
     const [progress, setProgress] = useState(50); // Default progress value
    const steps = [
        {
          number: 1,
          title: "Get matched with people who align with your preferences.", 
        },
        {
          number: 2,
          title: "Boost your profile visibility and credibility.", 
        },
        {
          number: 3,
          title: "Show potential matches who you truly are.", 
        },
      ];
  return (
    <div
      style={{
        maxWidth: '805px',
        width: '100%',
        
      }}
    > 
     <h3 className="text-center font-32">Complete Your Profile for the Best Matches!  </h3>
      <p className="text-center font-20 fw-medium my-24" style={{ color: 'rgba(0,0,0,1)' }}>
      You’re almost there! A fully completed profile increases your chances of finding the perfect match and makes you stand out from other members.
      </p>

      <Grid container spacing={2}>
        {/* List Section */}
        <Grid item xs={12} sm={7} lg={7}>
       
            <Box>
            <Typography sx={{display: 'flex', alignItems: 'center', fontSize: '16px', color: '#C93',fontStyle: 'italic', fontWeight: '500', gap: '10px'}}>Why Complete Your Profile?</Typography>
            </Box>
          <Box>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {steps.map((step) => (
                <ListItem key={step.number} sx={{paddingLeft: '0'}}>
                  <ListItemAvatar  sx={{ minWidth: '40px' }}>
                    <Avatar sx={{ background: '#fff', color: '#916008', fontWeight: '600',fontSize: '14px', width: '30px', height: '30px' }}> 
                    <CheckIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={step.title} 
                    sx={{
                      '& .MuiListItemText-primary': {
                        color: '#081726',
                        fontSize: '14px',
                        fontWeight: '400',
                        fontFamily: 'indivisible',
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>

        {/* Avatar Section */}
        <Grid item xs={12} sm={5} lg={5}>
          <Box className="text-center h-100 d-flex align-items-center justify-content-center flex-column">
          <div className="Proscss001" style={{ padding: "20px" }}>
                        <ProfileCompletion progress={progress} />
                    </div>
          </Box>
        </Grid>
      </Grid>
 
      
    </div>
  )
}

export default CompleteYourProfile
