import React, { useEffect, useState } from 'react';
import AGESlider from '../../Component/Profile/AGESlider';
import LocationFilter from '../../Component/Profile/LocationFilter';
import ShowMembersSeeking from '../../Component/Profile/ShowMembersSeeking';
import FilterCheckboxList from '../../Component/Profile/FilterCheckboxList';
import StartIcon from '@mui/icons-material/Start';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import SavedFilters from '../../Component/Profile/user-profile/SavedFilters';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { getFilterAction, memberResetAction, memberSearchAction, setFilterAction, upsertFilterAction } from './actions/MemberActions';
import { homeResetAction } from '../home-container/actions/HomeActions';
import FilterOptionList from 'src/Component/Profile/FilterOptionList';

const Filters = ({ toggleFiltersVisibility, filtersVisible }) => {
  const dispatch = useDispatch();
  const { currentFilter } = useSelector((state) => state.MemberReducer, shallowEqual);
  const { filters } = useSelector((state) => state.MemberReducer);
  const user = useSelector((state) => state.auth.user, shallowEqual);
  // const [isFiltersVisible, setFiltersVisible] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filterName, setFilterName] = useState(currentFilter.filterName || '');

  const filterOptions = [
    "ID Verified",
    "Photos",
    "Premium",
    "Online Now",
    "Unviewed",
    "Viewed",
    "Viewed Me",
    "Favorited",
    "Favorited Me",
  ];
  const [options, setOptions] = useState(currentFilter.options);
  // const [isFiltersVisible, setFiltersVisible] = useState(window.innerWidth > 991);

  // useEffect(() => {
  //   const handleResize = () => {
  //     const shouldShow = window.innerWidth > 991;
  //     setFiltersVisible(shouldShow);

  //     if (!shouldShow) {
  //       document.body.classList.add('overflow-clip'); // Add class if hidden
  //     } else {
  //       document.body.classList.remove('overflow-clip'); // Remove class if shown
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize(); // Call it initially to set the state correctly
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);
  // const toggleFiltersVisibility = () => {
  //   setFiltersVisible(prevState => !prevState);
  //   document.body.classList.toggle('overflow-clip');
  // };

  // const handleCheckboxChange = (event) => {
  //   const { name, value } = event.target;
  //   dispatch(setFilterAction({ data: { [name]: [...currentFilter[name], value] } }));
  // };
  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target; // Get the `checked` state of the checkbox
    const updatedValues = checked
      ? [...currentFilter[name], value] // Add value if checked
      : currentFilter[name].filter((item) => item !== value); // Remove value if unchecked

    // Dispatch the updated values
    dispatch(setFilterAction({ data: { [name]: updatedValues } }));
  };

  const handleAgeChange = (ageRange) => {
    dispatch(setFilterAction({ data: { ageRange: { min: parseInt(ageRange[0]), max: parseInt(ageRange[1]) } } }));
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch(memberSearchAction({ where: currentFilter,preferredMeasurement:user.preferredMeasurement }));
    }, 500);
    return () => clearTimeout(debounceTimeout);
  }, [currentFilter, dispatch]);

  useEffect(() => {
    dispatch(getFilterAction());
  }, [dispatch]);
  useEffect(() => {
    setOptions(currentFilter.options);
    setFilterName(currentFilter.filterName);
  }, [currentFilter.options, currentFilter.filterName])


  const openDialog = () => setDialogOpen(true);

  const closeDialog = () => {
    setDialogOpen(false);
    setFilterName('');
  };

  const saveFilter = () => {
    if (filterName.trim()) {
      dispatch(upsertFilterAction({ filterName: filterName.trim(), ...currentFilter }));
      closeDialog();
    }
  };
  const handleReset = () => {
    dispatch(homeResetAction());
    dispatch(memberResetAction());
  }
  const handleOptionCheckboxChange = (name, checked) => {
    setOptions(prevOptions => ({
      ...prevOptions,
      [name]: checked
    }));
    dispatch(setFilterAction({ data: { options: { ...currentFilter.options, [name]: checked } } }));
  };
  return (
    <>      
      <div className={`filters-container overflowauto ${filtersVisible ? "show" : "hide"}`}> 
        <div className="font-32 fw-medium color1a mb-28 mt-20 font-hding">Select Your Preference</div>
        {filters.length > 0 &&
          <div className="filter-section">
            <SavedFilters filters={filters} />
          </div>
        }
        <LocationFilter />
        <div className="filter-section my-32">
          <div className="font-16 color1a fw-medium font-m mb-16 samefont">Options</div>         
          <FilterOptionList
            options={options}
            onChange={handleOptionCheckboxChange}
            gridClassName="grid2set001"
          />
        </div>

        <div className="filter-section my-18">
          <div className="font-16 color1a fw-medium font-m mb-16 samefont">Age</div>
          <AGESlider ageRange={currentFilter.ageRange} handleAgeChange={handleAgeChange} />
        </div>

        <div className="filter-section">
          <ShowMembersSeeking handleCheckboxChange={handleCheckboxChange} />
        </div>

        <div className="filter-buttons">
          <button className="Reset font-16 fw-normal font-m text-black" onClick={() => handleReset()} >Reset</button>
          <button className="SavethisSearch font-16 font-m fw-normal text-black" onClick={openDialog}>{currentFilter._id ? 'Update' : 'Save'} this Search</button>
        </div>
      </div>
      <div className='StartIconDv'><button className="StartIcon" onClick={toggleFiltersVisibility}>
        <StartIcon />
      </button></div>

      <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title" maxWidth="sm"
        fullWidth>
        <DialogTitle id="form-dialog-title" sx={{ fontSize: '24px !Important', fontWeight: 'bold' }}>{currentFilter._id ? 'Update' : 'Save'} Filter</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="filterName"
            placeholder="Filter Name"
            type="text"
            fullWidth
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            onBlur={(e) => dispatch(setFilterAction({ data: { filterName: e.target.value } }))}
            sx={{
              marginTop: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
              },
              height: '44px',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                borderRadius: '15px',
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#916008',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#916008',
                  borderWidth: '1px',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>


          <Button onClick={closeDialog} color="primary" variant="outlined"
            sx={{ border: '1px solid rgb(145, 96, 8)', color: 'rgb(145, 96, 8)' }}>
            Cancel
          </Button>
          <Button onClick={saveFilter} color="primary" sx={{
            border: '1px solid rgb(145, 96, 8)',
            color: 'rgb(252, 252, 252) !important',
            backgroundColor: 'rgb(145, 96, 8)',
            '&:hover': {
              borderColor: 'var(--warning-900)',
              backgroundColor: 'var(--warning-900)',
              color: '#fff'
            },
            '&:focus': {
              borderColor: 'var(--warning-900)',
              boxShadow: '0 0 0 4px rgba(0, 0, 0, 0.2)',
            },
            '&:active': {
              borderColor: 'var(--warning-900)',
              backgroundColor: 'var(--warning-900)',
              color: 'white',
            },
          }}
            disabled={!filterName.trim()}>
            {currentFilter._id ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Filters;
