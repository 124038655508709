import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    border: "0.1vw solid #E4E4E4",
    transition: "border 0.3s ease-in-out",
    margin: "1vw 0",
    boxShadow: "none",
    borderRadius: "0.3vw",
    "&.Mui-expanded": {
        border: "0.1vw solid #861D1D",
        margin: "1vw 0",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        transition: "transform 0.3s ease-in-out",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(45deg)",
        color: "#861D1D",
    },
    "& .MuiAccordionSummary-root": {
        padding: "1vw",
    },
    "& .MuiAccordionSummary-content": {
        color: "#1E1E1E",
        margin: "0",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
        color: "#861D1D",
        margin: "0vw 0",
    },
    "& .MuiAccordionDetails-root": {
        padding: "0vw 1vw 1vw 4vw",
    },
}));

const faqs = [
    {
        id: "panel1",
        question: "What is Luxury Life and who can join?",
        answer: [
            "Luxury Liife is an exclusive networking platform designed for high-profile individuals, entrepreneurs, and industry leaders who value meaningful connections and curated experiences. Membership is by invitation or application only, and applicants must meet specific eligibility criteria, including professional achievements, financial standing, or industry influence.",
            "We carefully vet each applicant to maintain the integrity and exclusivity of our community, ensuring that members benefit from a secure and high-caliber networking environment. If you believe you meet the criteria, we encourage you to apply for membership and join our elite network."
        ]
    },
    {
        id: "panel2",
        question: "How does the membership application process work?",
        answer: [
            "Applying for membership at Luxury Life involves a multi-step process to ensure exclusivity and alignment with our high standards. Applicants must submit an online application detailing their professional background, achievements, and industry status. Additionally, a referral from an existing member can enhance your chances of acceptance.",
            "Once your application is received, our review team conducts a thorough assessment, which may include verification of credentials and an interview. If approved, you will receive an invitation to select your preferred membership tier and gain access to our prestigious community and networking events."
        ]
    },
    {
        id: "panel3",
        question: "What are the different membership tiers and their benefits?",
        answer: [
            "Luxury Life offers a tiered membership model tailored to different levels of exclusivity and benefits. The Standard Elite Membership provides access to networking features, curated forums, and select events. The Executive Membership grants invitations to high-profile gatherings, concierge services, and business opportunities.",
            "The Ultra-Exclusive Tier offers unparalleled privileges, including private investment opportunities, access to elite retreats, and a direct line to influential industry leaders. Each tier is designed to provide increasing levels of access, ensuring that members receive the most value based on their status and networking needs."
        ]
    },
    {
        id: "panel4",
        question: "How do I upgrade my membership?",
        answer: [
            "Members can upgrade their membership at any time to access enhanced benefits. Simply log into your account, navigate to the Membership section, and choose your preferred tier. Our team will review your request, and upon approval, your membership level will be upgraded.",
            "Higher-tier memberships unlock premium networking opportunities, exclusive events, and personalized services. Upgrading your membership is an excellent way to further immerse yourself in the elite circles of influence and maximize the value of your experience within Luxury Life."
        ]
    },
    {
        id: "panel5",
        question: "Is my personal information secure on Luxury Life?",
        answer: [
            "We take data privacy and security very seriously. Luxury Life employs advanced encryption technologies and strict confidentiality measures to protect your personal information and communications.",
            "Our platform is designed with high-level security protocols, ensuring that member data remains private and inaccessible to unauthorized parties. We do not share, sell, or distribute member information, maintaining the integrity and discretion that our elite community expects. Our members can network with confidence, knowing that their privacy is our top priority."
        ]
    },
    {
        id: "panel6",
        question: "How can I connect with other members?",
        answer: [
            "Luxury Life provides multiple ways for members to interact and establish meaningful connections. You can join industry-specific discussion forums, participate in invite-only networking events, or engage through direct messaging with verified members.",
            "Our sophisticated matching algorithm also recommends potential connections based on shared interests, professional backgrounds, and mutual benefits. Higher-tier members enjoy even more exclusive networking opportunities, including access to VIP events and one-on-one introductions facilitated by our concierge team."
        ]
    },
    {
        id: "panel7",
        question: "What types of events does Luxury Life host?",
        answer: [
            "Our platform hosts a wide range of exclusive events, including intimate networking dinners, industry summits, luxury retreats, and high-profile galas. These events are meticulously curated to bring together the most influential individuals from various fields, allowing for meaningful conversations and strategic collaborations.",
            "Members receive invitations based on their membership tier and professional background, ensuring that each gathering is relevant and valuable. Attendance at these events presents unparalleled opportunities to expand your network and engage with global leaders."
        ]
    },
    {
        id: "panel8",
        question: "Can I bring a guest to exclusive events?",
        answer: [
            "Guests are welcome at select events, depending on your membership tier and the nature of the gathering. Some high-profile events are strictly members-only to maintain exclusivity, while others allow members to invite a guest who meets the required standards.",
            "If you wish to bring a guest, please check the event guidelines or contact our support team for approval. Ensuring a sophisticated and well-matched attendee list is part of our commitment to maintaining the quality of networking opportunities within our community."
        ]
    },
    {
        id: "panel9",
        question: "How do I access the luxury concierge services?",
        answer: [
            "Our luxury concierge services are available to Executive and Ultra-Exclusive Tier members. You can request bespoke travel arrangements, priority reservations at elite establishments, or personalized investment opportunities through your membership dashboard. ",
            "Our dedicated concierge team works with top-tier providers to ensure seamless and discreet service, catering to the sophisticated needs of our elite members. Whether you require private aviation, luxury accommodations, or VIP event access, our team is here to assist with every detail."
        ]
    },
    {
        id: "panel10",
        question: "What happens if my application is declined?",
        answer: [
            "If your membership application is not approved, you are welcome to reapply after six months, provided that your professional status or credentials have changed significantly. Due to the exclusivity of our platform, only a limited number of applicants are accepted at any given time.",
            "If you were referred by a member, they may provide additional endorsements to strengthen your reapplication. Our team is happy to provide feedback on how you can enhance your chances of acceptance in the future."
        ]
    },
    {
        id: "panel11",
        question: "How can I cancel my membership?",
        answer: [
            "Members who wish to cancel their membership can do so by submitting a cancellation request through their account settings. While we strive to provide unmatched value to our members, we understand that personal and professional circumstances change.",
            "If you choose to cancel, your access will remain active until the end of your current billing cycle. We encourage members to explore tier downgrades before opting for full cancellation to continue enjoying selective benefits."
        ]
    },
    {
        id: "panel12",
        question: "Are there refunds for canceled memberships?",
        answer: [
            "Due to the nature of our exclusive services, membership fees are generally non-refundable. However, exceptions may be made under special circumstances. If you believe you qualify for a refund, please contact our billing support team for further review.",
            "We take every request seriously and aim to ensure fairness while maintaining the integrity of our membership structure."
        ]
    },
    {
        id: "panel13",
        question: "Can I refer someone for membership?",
        answer: [
            "Yes, existing members can refer individuals who meet our membership criteria. A strong referral from an active member increases the likelihood of acceptance, as we value recommendations from trusted members of our community.",
            "Referred individuals must still undergo the application and vetting process to ensure alignment with our elite standards. To refer a candidate, please use the referral section in your account dashboard."
        ]
    },
    {
        id: "panel14",
        question: "How do I report inappropriate behavior?",
        answer: [
            "Maintaining a respectful and professional community is our top priority. If you encounter inappropriate behavior, fraudulent activity, or policy violations, please report it immediately to our security team. ",
            "We investigate all reports thoroughly and take appropriate action to protect the integrity of our platform. Our zero-tolerance policy ensures that all members adhere to the highest standards of conduct."
        ]
    },
    {
        id: "panel15",
        question: "How do I contact support?",
        answer: [
            "For any inquiries or assistance, our support team is available via email, phone, and live chat. You can also visit our Support page for detailed contact options and to submit a support request.",
            "We are committed to ensuring a seamless experience for our members and strive to provide timely and personalized assistance for all concerns."
        ]
    },
];

const FaqAccordion = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            {faqs.map((faq, index) => (
                <CustomAccordion
                    key={faq.id}
                    expanded={expanded === faq.id}
                    onChange={handleChange(faq.id)}
                >
                    <AccordionSummary expandIcon={<AddIcon className="iconStmnl" sx={{ fontSize: "2vw" }} />}>
                        <Typography
                            component="span" className="paraHeadAccoding"
                            sx={{ display: "flex", gap: "1vw", fontSize: "1.25vw", alignItems: "center" }}
                        >
                            <Typography
                                component="span"
                                className="count"
                                sx={{
                                    width: "1.83vw",
                                    height: "1.83vw",
                                    fontSize: "1vw",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "20vw",
                                    color: "#fff",
                                    background: "#1E1E1E",
                                }}
                            >
                                {index + 1}
                            </Typography>
                            {faq.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        {Array.isArray(faq.answer)
                            ? faq.answer.map((para, idx) => (
                                <p key={idx} className="paraTextAccoding">{para}</p>
                            ))
                            : faq.answer}
                    </AccordionDetails>
                </CustomAccordion>
            ))}
        </div>
    );
};

export default FaqAccordion;
