import React from 'react'
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { getAuthInfoAction } from 'src/containers/auth-container/actions/AuthActions';
import { useDispatch } from 'react-redux';

const ThankYouForSelfie = ({ source }) => {
const navigate = useNavigate();
const dispatch = useDispatch();
  const handleContinue = () => {
    dispatch(getAuthInfoAction());
    navigate('/members');
  }
  return (
    <div
      style={{
        maxWidth: '805px',
        width: '100%',
      }}
    >
      <div className="text-center mb-10"><img src="/assets/images/user/user-profile/verifying.svg" className="img-fluid" alt="" /></div>
      <h3 className="text-center font-32">Thank you for selfie verifying!</h3>
      <p className="text-center font-20 fw-bold my-24" style={{ color: 'rgba(0,0,0,1)' }}>
        Your commitment to authenticity helps us maintain a safe and exclusive community, ensuring every connection is genuine and meaningful.
      </p>
      {
        source =="signup" && (
          <Button className="mb-20"
            variant="contained"
            sx={{
              width: '100%',
              backgroundColor: '#916008',
              padding: '10px',
              color: '#fff',
              '&:hover': { backgroundColor: '#9d6f3b' },
            }}
            onClick={handleContinue}
          >
            Finish
          </Button>
        )
      }
    </div>
  )
}

export default ThankYouForSelfie