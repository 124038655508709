import React from 'react';

const FilterCheckboxList = ({ options, gridClassName, selectedValues = [], handleCheckboxChange, name }) => {
  return (
    <div className={gridClassName}>
      {options.map((option) => (
        <label className="checkinput" key={option}>
          {option}
          <input type="checkbox" name={name} value={option} onChange={handleCheckboxChange} checked={selectedValues?.includes(option)} />
          <span className="checkmark" />
        </label>
      ))}
    </div>
  );
};

export default FilterCheckboxList