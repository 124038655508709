import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";

const ProfileCardSkeleton = () => {
  return (
    <Grid container spacing={2}>
      {[...Array(8)].map((_, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>
          <Box sx={{ p: 2, bgcolor: "black", borderRadius: 2 }}>
            {/* Profile Image Skeleton */}
            <Skeleton variant="rectangular" width="100%" height={180} sx={{ borderRadius: 2, bgcolor: "#222" }} />

            {/* Name & Age */}
            <Skeleton variant="text" width="60%" sx={{ bgcolor: "#333", mt: 1 }} />
            <Skeleton variant="text" width="40%" sx={{ bgcolor: "#333" }} />

            {/* Location */}
            <Skeleton variant="text" width="50%" sx={{ bgcolor: "#444", mt: 1 }} />

            {/* Icons */}
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Skeleton variant="circular" width={30} height={30} sx={{ bgcolor: "#555" }} />
              <Skeleton variant="circular" width={30} height={30} sx={{ bgcolor: "#555" }} />
              <Skeleton variant="circular" width={30} height={30} sx={{ bgcolor: "#555" }} />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProfileCardSkeleton;
