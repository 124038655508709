import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const DoYouSmoke = () => {
  const dispatch = useDispatch();
  const {
    smoke,
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [youSmoke, setYouSmoke] = useState(smoke || "");

  // Define the options dynamically
  const options = ["Yes", "No", "Occasionally"];

  // const handleYouSmoke = (value) => {
  //   setYouSmoke(value);
  //   dispatch(setPayload({ data: { smoke: value } }));
  // };

  
 const handleYouSmoke = (value) => {
  // If the same option is clicked again, unselect it
  const newValue = youSmoke === value ? "" : value;
  setYouSmoke(newValue);
  dispatch(setPayload({ data: { smoke: newValue } }));
};

  return (
    <div>
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Do You Smoke? </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
        Let us know if you smoke so we can better match you with individuals who have similar preferences.
        </div>
      </div>
      <div className="YouSmoke-selection text-center  mt-32 mb-16">
        {/* <h3 className="font-18 font-m fw-medium">Select Your Choice</h3> */}
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render buttons */}
          {options.map((option, index) => (
            <button
              key={index}
              className={`font-m border font-16 fw-normal ${youSmoke === option ? "selected" : ""}`}
              onClick={() => handleYouSmoke(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoYouSmoke;
