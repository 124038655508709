import React, { useState } from 'react';

const FilterSingalSelect = ({ options, gridClassName }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelection = (value) => {
    const newValue = selectedOption === value ? "" : value;
    setSelectedOption(newValue);
  };

  return (
    <div className={gridClassName}>
      {options.map((option, index) => (
        <button
          key={index}
          className={`font-m border font-16 fw-normal ${selectedOption === option ? "selected" : ""}`}
          onClick={() => handleSelection(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default FilterSingalSelect;

