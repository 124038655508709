import { Box, Button, Paper, Typography } from '@mui/material';
import React from 'react';
import SwitchComp from '../../Component/PermissionsSettings/SwitchComp';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { shallowEqual, useSelector } from 'react-redux';
import SecurityAccodion from './SecurityAccodion';

const ManageYourAccount = ({ currentRecord, handleUpdate }) => {
    const { user } = useSelector((state) => state.auth, shallowEqual);

    // Configuration sections
    const config = [
        {
            title: 'Your Registered Email Address',
            content: user?.email,
            actions: [],//For Edit icon //<CreateOutlinedIcon />
        },
        {
            title: 'Appear in Search Results',
            content: <SwitchComp
                status={currentRecord.dashboardSearch}
                onChange={(event) => {
                    event.stopPropagation();
                    handleUpdate('dashboardSearch', null, event.target.checked);
                }}
            />,
        },
    ];

    const config2 = [
        {
            title: 'Preferred Language',
            content: (
                <select className="form-select">
                    <option>English</option>
                </select>
            ),
        },

        {
            title: 'Your Activity',
            section: 'activityAlert',
            settings: [
                { label: 'Online Status / Last Active Date', key: 'lastActive' },
                { label: 'When You View Someone', key: 'viewSomeone' },
                { label: 'When You Favorite Someone', key: 'favouriteSomeone' },
            ],
            actions: ['Visible', 'Hide'],
        },
        {
            title: 'Other Profile Information',
            section: 'otherProfileInfo',
            settings: [
                { label: 'Join Date', key: 'joinDate' },
                { label: 'Recent Login Location', key: 'recentLoginLocation' },
            ],
            actions: ['Visible', 'Hide'],
        },
        // {
        //     title: 'Search and Dashboard',
        //     section: 'dashboardSearch',
        //     actions: ['Visible', 'Hide'],
        // },
        {
            title: 'Preferred Measurement System',
            section: 'preferredMeasurement',
            actions: ['Metric System','Imperial System' ],
        },
        {
            title: 'Connected Accounts',
            section: 'connectAccount',
            settings: [
                { label: 'Google Account', key: 'google' },
                // { label: 'Apple Account', key: 'appleId' },
            ],
            actions: ['Visible', 'Hide'],
        },

    ];



    const isSectionVisible = (section) => {
        if (typeof currentRecord[section] === 'object') {
            return Object.values(currentRecord[section]).every(Boolean);
        }
        return !!currentRecord[section];
    };

    return (
        <div>
            <Typography className="ac-heading"
                variant="h6"
                gutterBottom
                sx={{
                    fontWeight: '500',
                    color: '#000',
                    fontFamily: 'ivypresto-headline',
                     
                }}
            >
                Manage Your Account
            </Typography>

            {config.map((section, index) => (
                <Box className="font1vw border001vw mtopBottom05 gap05vw p1vw"
                    key={index}
                    component={Paper}
                    sx={{
                        boxShadow: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box className="gap05vw"
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            fontFamily: 'indivisible',
                        }}
                    >
                        <Typography className="font875vw"
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontWeight: '500',
                                color: '#081726',
                                fontFamily: 'indivisible',
                                marginBottom: '0',
                            }}
                        >
                            {section.title}
                        </Typography>
                        <Box className="gap05vw vemailSet" sx={{ display: 'flex', alignItems: 'center', }}>
                            {section.content || null}
                            {section.actions?.map((action, actionIndex) => (
                                <Button
                                    key={actionIndex}
                                    variant={(action === 'Visible') === isSectionVisible(section.section) ? 'contained' : 'outlined'}
                                    onClick={() => handleUpdate(section.section, null, action === 'Visible')}
                                    // className={(action === 'Visible') === isSectionVisible(section.section) ? 'fillBtn' : 'outLineBtn'} 
                                    className={`${(action === 'Visible') === isSectionVisible(section.section) ? 'fillBtn' : 'outLineBtn'} customEmailBtn01`}
                                >
                                    {action}
                                </Button>
                            ))}
                        </Box>
                    </Box>

                    {section.settings &&
                        section.settings.map((setting, settingIndex) => (
                            <Box className="mtopBottom05"
                                key={settingIndex}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography className="font08vw"
                                    sx={{
                                        fontWeight: '400',
                                        color: '#081726',
                                        fontFamily: 'indivisible',
                                    }}
                                >
                                    {setting.label}
                                </Typography>
                                <SwitchComp
                                    status={currentRecord[section.section]?.[setting.key] ?? false}
                                    onChange={(event) => {
                                        event.stopPropagation();
                                        handleUpdate(section.section, setting.key, event.target.checked);
                                    }}
                                />
                            </Box>
                        ))}
                </Box>
            ))}

            {/* Render SecurityAccordion here without title */}
            <SecurityAccodion />

            {config2.map((section, index) => (
                <Box
                    key={index}
                    component={Paper}
                    className="boxfontsetvw p1vw border001vw mtopBottom05 gap05vw"
                    sx={{
                        boxShadow: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box className="gap05vw"
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            fontFamily: 'indivisible',
                        }}
                    >
                        <Typography className="font875vw"
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontWeight: '500',
                                color: '#081726',
                                fontFamily: 'indivisible',
                                marginBottom: '0',
                            }}
                        >
                            {section.title}
                        </Typography>
                        <Box className="gap05vw" sx={{ display: 'flex', alignItems: 'center', }}>
                            {section.content || null}
                            {section.actions?.map((action, actionIndex) => (
                                <Button
                                    key={actionIndex}
                                    variant={(action === 'Visible'||action === 'Imperial System') === isSectionVisible(section.section) ? 'contained' : 'outlined'}
                                    onClick={() => handleUpdate(section.section, null, action === 'Visible'||action === 'Imperial System')}
                                    // className={(action === 'Visible'||action === 'Imperial System') === isSectionVisible(section.section) ? 'fillBtn' : 'outLineBtn'}
                                    className={`${(action === 'Visible'||action === 'Imperial System') === isSectionVisible(section.section) ? 'fillBtn' : 'outLineBtn'} customClassBtn01`}
                                >
                                    {action}
                                </Button>
                            ))}
                        </Box>
                    </Box>

                    {section.settings &&
                        section.settings.map((setting, settingIndex) => (
                            <Box className="divflxset"
                                key={settingIndex}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography className="font08vw"
                                    sx={{
                                        fontWeight: '400',
                                        color: '#081726',
                                        fontFamily: 'indivisible',
                                    }}
                                >
                                    {setting.label}
                                </Typography>
                                <SwitchComp
                                    status={currentRecord[section.section]?.[setting.key] ?? false}
                                    onChange={(event) => {
                                        event.stopPropagation();
                                        handleUpdate(section.section, setting.key, event.target.checked);
                                    }}
                                />
                            </Box>
                        ))}
                </Box>
            ))}
        </div>
    );

};

export default ManageYourAccount