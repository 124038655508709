import React from 'react';

const StatusComponent = ({ status }) => {
  return (
    <span
      className={status === 'Pending' ? 'pending' : status === 'Rejected'|| status === 'Unverified' ? 'rejected' : 'approved'}
    >
      {status}
    </span>
  );
};

export default StatusComponent;
