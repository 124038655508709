import React from 'react';

const features = [
  {
    id: 1,
    imgSrc: '/assets/images/first-home/exclusive-networking-icon.svg',
    title: 'Exclusive Networking',
    description:
      'Connect with professionals, entrepreneurs, and influencers from diverse industries. Engage in meaningful conversations, collaborate on innovative ideas, and build relationships that support both personal and professional growth.',
  },
  {
    id: 2,
    imgSrc: '/assets/images/first-home/meaningful-connections-icon.svg',
    title: 'Meaningful Connections',
    description:
      'Join a community where you can engage with like-minded professionals and individuals who share your interests. Build relationships and expand your network in a collaborative, supportive environment.',
  },
  {
    id: 3,
    imgSrc: '/assets/images/first-home/private-conversations-icon.svg',
    title: 'Private Conversations',
    description:
      'Communicate securely within the platform with encrypted messaging, ensuring your discussions remain personal and confidential. Share ideas and insights without concerns about privacy.',
  },
  {
    id: 4,
    imgSrc: '/assets/images/first-home/personalized-insights-icon.svg',
    title: 'Personalized Insights',
    description:
      'Gain access to curated content and discussions that are relevant to your professional interests. Stay informed and discover opportunities that align with your goals and aspirations.',
  },
];

const EliteFeatures = () => {
  return (
    <div style={{ padding: '5vw 5.5vw' }}>
      <h2 className='text-start font48vw text-center' style={{ marginBottom: '4vw' }}>
        Elite Features
      </h2>
      <div className='EliteFeaturesbg d-flex justify-content-between'>
        {features.map((feature) => (
          <div key={feature.id} className='EliteFeatures text-center'>
            <span className='img'>
              <img src={feature.imgSrc} className='img-fluid' alt={feature.title} />
            </span>
            <h3 className='mb-0 font24vw'>{feature.title}</h3>
            <p className='mb-0 font08vw'>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EliteFeatures;