import React from 'react'

const HowItWorks = () => {
  return (
    <div style={{ padding: '5vw 0vw' }}>
       <h2 className='fw-normal font-m font48vw mb-0' style={{padding: '0 0 4vw 0'}}>How It Works</h2>

<div className='HowItWorksbgNew d-grid justify-content-between align-items-start'>
<div className='HowItWorkstextNew w-100'>
    <h3 className='font24vw d-flex align-items-center mb-0'><span className='count'>01</span> Exclusive Membership</h3>
    <p className='font04vw color7b mb-0'>Membership is granted through a strict vetting process, requiring either a referral from an existing member or an application showcasing professional achievements, financial standing, or cultural influence.</p>
</div>
<div className='HowItWorkstextNew w-100'>
    <h3 className='font24vw d-flex align-items-center mb-0'><span className='count'>02</span> Curated Connections</h3>
    <p className='font04vw color7b mb-0'>Our algorithm matches members based on shared interests, industries, and potential synergies, ensuring that every connection is valuable and authentic.</p>
</div>
<div className='HowItWorkstextNew w-100'>
    <h3 className='font24vw d-flex align-items-center mb-0'><span className='count'>03</span> Premium Events & Experiences</h3>
    <p className='font04vw color7b mb-0'>Gain access to world-class networking events, elite social gatherings, and curated experiences designed for high-caliber professionals.</p>
</div>
<div className='HowItWorkstextNew w-100'>
    <h3 className='font24vw d-flex align-items-center mb-0'><span className='count'>04</span> Private Communication </h3>
    <p className='font04vw color7b mb-0'>Enjoy secure, encrypted conversations that maintain the confidentiality and exclusivity that our members expect.</p>
</div>
<div className='HowItWorkstextNew w-100'>
    <h3 className='font24vw d-flex align-items-center mb-0'><span className='count'>05</span> Luxury Concierge Services </h3>
    <p className='font04vw color7b mb-0'>From exclusive travel arrangements to investment opportunities, our bespoke concierge services cater to every aspect of the elite lifestyle.</p>
</div>
<div className='HowItWorkstextNew w-100'>
    <h3 className='font24vw d-flex align-items-center mb-0'><span className='count'>06</span> Community Engagement </h3>
    <p className='font04vw color7b mb-0'>Participate in discussion forums, industry-specific groups, and thought-leadership initiatives with global influencers.</p>
</div>
</div>
    </div>
  )
}

export default HowItWorks
