import { put, call,select } from 'redux-saga/effects';
import { memberFilterCreateAPI,memberSearchAPI, memberActionUpdateAPI, getFiltersAPI, memberFilterUpdateAPI, deleteFiltersAPI } from 'src/api/MembersApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { MEMBER_ACTIONS } from '../actions/MemberActions';
import { toast } from 'react-toastify';

function* search({
    where, currentPage = 0, autopopulate = true,sortBy="distance",preferredMeasurement=true
} = {}) {
    try {
        const {
            data
        } = yield call(memberSearchAPI, {
            where,
            // requestType: 'mobile',
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
            sortBy,
            preferredMeasurement
        });

        yield put({
            type: MEMBER_ACTIONS.MEMBER_SEARCH_SUCCESS,
            data,
        });
        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: MEMBER_ACTIONS.MEMBER_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(memberSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {

            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }


        yield put({
            type: MEMBER_ACTIONS.MEMBER_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(memberFilterCreateAPI, payload.data); 
        // yield put({
        //     type: MEMBER_ACTIONS.MEMBER_SEARCH
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: MEMBER_ACTIONS.MEMBER_UPSERT_ERROR,
        });
    }
}

function* update(memberId, payload) {
    try {
        yield call(memberActionUpdateAPI, {
            memberId,
            data: payload.data,
        });
        // yield put(headerShowAction({
        //     data: 'Updated',
        // }));
        // yield put({
        //     type: MEMBER_ACTIONS.MEMBER_SEARCH
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: MEMBER_ACTIONS.MEMBER_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}
function* getFilters() {
    try {
        const {
            data: {
                data,
            },
        } = yield call(getFiltersAPI, {});

        yield put({
            type: MEMBER_ACTIONS.GET_FILTER_SUCCESS,
            data: data,
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put({
            type: MEMBER_ACTIONS.MEMBER_UPSERT_ERROR,
        });
    }
}
function* updateFilter(_id, payload) {
    try {
         yield call(memberFilterUpdateAPI, {
            _id,
            data: payload,
        });
        yield put({
            type: MEMBER_ACTIONS.GET_FILTER,
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put({
            type: MEMBER_ACTIONS.MEMBER_UPSERT_ERROR,
        });
        toast.error(error.response.data.message);
    }
}
function* insertFilter(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(memberFilterCreateAPI, payload);
        yield put({
            type: MEMBER_ACTIONS.GET_FILTER,
        });
        //set id to edit
        yield put({
            type: MEMBER_ACTIONS.SET_FILTER,
            data:{_id: data._id},
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put({
            type: MEMBER_ACTIONS.MEMBER_UPSERT_ERROR,
        });
        toast.error(error.response.data.message);
    }
}
function* upsertFilter({
    _id,
    ...payload
}) {
    try {
        if (_id) {
            yield updateFilter(_id, payload);
        }
        else {
            yield insertFilter(payload);
        }
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put({
            type: MEMBER_ACTIONS.MEMBER_UPSERT_ERROR,
        });
    }
}
function* deleteFilter({id}) {
    try {
        yield call(deleteFiltersAPI, {
            id,
        });
        yield put({
            type: MEMBER_ACTIONS.GET_FILTER,
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put({
            type: MEMBER_ACTIONS.MEMBER_UPSERT_ERROR,
        });
    }
}
export default [
    takeFirstSagaUtil(MEMBER_ACTIONS.MEMBER_SEARCH, search),
    takeFirstSagaUtil(MEMBER_ACTIONS.MEMBER_UPSERT, upsert),
    takeFirstSagaUtil(MEMBER_ACTIONS.MEMBER_EDIT, edit),
    takeFirstSagaUtil(MEMBER_ACTIONS.GET_FILTER, getFilters),
    takeFirstSagaUtil(MEMBER_ACTIONS.UPSERT_FILTER, upsertFilter),
    takeFirstSagaUtil(MEMBER_ACTIONS.DELETE_FILTER, deleteFilter),

];
