import React, { useState, useEffect } from "react";
import { Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const GoTop = ({ containerRef }) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (!containerRef?.current) return;

    const handleScroll = () => {
      setShowButton(containerRef.current.scrollTop > 500);
    };

    containerRef.current.addEventListener("scroll", handleScroll);
    return () => containerRef.current.removeEventListener("scroll", handleScroll);
  }, [containerRef]);

  const scrollToTop = () => {
    if (containerRef?.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Fab
      color="primary"
      aria-label="scroll back to top"
      onClick={scrollToTop}
      sx={{
        position: "absolute",
        bottom: 16,
        right: 16,
        opacity: showButton ? 1 : 0,
        transform: showButton ? "scale(1)" : "scale(0.5)",
        transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
      }}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  );
};

export default GoTop;
