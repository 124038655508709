import { useState, useMemo } from "react";
import { debounce } from "lodash";
import { checkUsernameAvailableAPI } from "src/api/SignupApi";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPayload } from "src/containers/signup-container/actions/SignupActions";

const UserNameComponent = () => {
    const dispatch = useDispatch();
    const {
        userName,
    } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
    const [inputUserName, setUsername] = useState(userName); // State to track input value
    const [isUsernameTaken, setIsUsernameTaken] = useState(false);
    // Debounced function to check username availability
    const debouncedCheckUsername = useMemo(
        () =>
            debounce(async (userName) => {
                const {data} = await checkUsernameAvailableAPI({ userName });
                if(data.status) dispatch(setPayload({data:{userName }}));
                else dispatch(setPayload({data:{userName:"" }}));
                setIsUsernameTaken(!data.status);
            }, 500), // 500ms debounce delay
        []
    );
    const handleUsernameChange = (e) => {
        const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        setUsername(value);
        debouncedCheckUsername(value);
    };
    return (
        <div className="btnsWithConnects mw-500-px mx-auto">

            <div className="">
                <div className="text-center font-32 font-m fw-medium">Create a Username?</div>
                <div className="colorf5 text-center font-14 font-m pt-6">
                Please note that the username you enter here cannot be changed later.
                </div>
            </div>
            <div className="mw-425-px mx-auto">
                <div className="position-relative w-100 icon-field mt-32 mx-auto psistetVefid">
                    <span className="font-16 icon" style={{ top: '8px' }}>
                        &#64;
                    </span>
                    <input
                        className="form-control mt-36"
                        type="text"
                        placeholder="Enter username"
                        autoComplete="off"
                        required
                        value={inputUserName}
                        onChange={handleUsernameChange}
                    />
                    {!isUsernameTaken && inputUserName && (
                        <span className="correct f14-mvw">
                            <img
                                src="/assets/images/signup/otp-verified.svg"
                                className="img-fluid"
                                alt="Verified"
                            />
                        </span>
                    )}
                </div>
                {/* Example text visible only when the input is empty */}
                {!inputUserName && (
                    <div className="text-start exmple f12-nvw mt-10">eg. johndoe25</div>
                )}
                {isUsernameTaken && (
                    <>
                        <p
                            className="text-red text-start f14-mvw incorrect mb-0 mt-10"
                            style={{ color: "red" }}
                        >
                            This username has already been taken.
                        </p>
                        <p className="text-start f14-mvw">Please choose another one.</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserNameComponent;
