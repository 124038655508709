import React, { useState } from "react";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { submitSupportTicket } from "src/api/UserApi";
import { useNotification } from "src/components/Notification/ToastNotification";
import { useEffect } from "react";
import { Button } from "@mui/material";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    concern: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const [isError, setIsError] = useState(false);

  // Validation function
  const validateForm = () => {
    let tempErrors = {};

    // Full Name validation
    if (!formData.fullName.trim()) {
      tempErrors.fullName = "Full name is required";
    } else if (formData.fullName.length < 2) {
      tempErrors.fullName = "Full name must be at least 2 characters";
    }

    // Email validation
    if (!formData.email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Please enter a valid email address";
    }

    // Concern validation
    if (!formData.concern) {
      tempErrors.concern = "Please select a concern";
    }

    // Description validation
    if (!formData.description.trim()) {
      tempErrors.description = "Description is required";
    } else if (formData.description.length < 10) {
      tempErrors.description = "Description must be at least 10 characters";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.name === "fullName") {
      value = value.replace(/[^a-zA-Z\s]/g, '');
    }
    if (e.target.name === "email") {
      value = value.replace(/[^a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, '');
    }
    setFormData({ ...formData, [name]: value });

    // Clear error when user starts typing
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleSupportSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await submitSupportTicket(formData);
      if (response.status === 200) {
        openSuccessNotification(response.data.message);
        // Reset form after successful submission
        setFormData({
          fullName: "",
          email: "",
          concern: "",
          description: "",
        });
        setErrors({});
      }
    } catch (ex) {
      console.log(ex);
      if (ex.response?.data?.errors) {
        setErrors(ex.response.data.errors);
      }
      openErrorNotification(ex.response?.data?.message || "An error occurred");
    }
  };
  useEffect(() => {
    if (formData.fullName && formData.email && formData.concern && formData.description && formData.description.length > 50) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [formData]);

  return (
    <form onSubmit={handleSupportSubmit}>
      <fieldset className="wizard-fieldset StillNeedbg">
        <h3 className='fw-medium font-m font-2vw mb-0' style={{ padding: '0.5vw 0', color: '#861D1D' }}>
          Still Need Help?
        </h3>
        <p className="font-1vw mb-0">
          If you haven’t found the support you need, submit a detailed support request, and our team will respond promptly.
        </p>
        <div className="row gapsetform">
          <div className="col-sm-6">
            <label className="form-label">Full Name*</label>
            <div className="position-relative">
              <input
                type="text"
                className={`form-control wizard-required ${errors.fullName ? 'is-invalid' : ''}`}
                placeholder="Enter Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
              {errors.fullName && (
                <div className="invalid-feedback">{errors.fullName}</div>
              )}
            </div>
          </div>

          <div className="col-sm-6">
            <label className="form-label">Email*</label>
            <div className="position-relative">
              <input
                type="email"
                className={`form-control wizard-required ${errors.email ? 'is-invalid' : ''}`}
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
          </div>

          <div className="col-sm-12">
            <label className="form-label">Concern*</label>
            <div className="position-relative">
              <select
                className={`form-control form-select ${errors.concern ? 'is-invalid' : ''}`}
                name="concern"
                value={formData.concern}
                onChange={handleChange}
                required
              >
                <option value="">Select Concern</option>
                <option value="Privacy">Privacy & Security Issues</option>
                <option value="Authenticity">Member Authenticity & Behavior</option>
                <option value="Unwanted Interactions">Unwanted Interactions & Safety Concerns</option>
                <option value="Access Issues">Membership & Access Issues</option>
                <option value="User Experience">Platform Functionality & User Experience</option>
                <option value="Other">Other</option>
              </select>
              {errors.concern && (
                <div className="invalid-feedback">{errors.concern}</div>
              )}
            </div>
          </div>

          <div className="col-sm-12">
            <label className="form-label">Description*</label>
            <div className="position-relative">
              <textarea
                className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Description"
              />
              {errors.description && (
                <div className="invalid-feedback">{errors.description}</div>
              )}
            </div>
          </div>

          <div className="form-group text-end">
            <Button
              type="submit"
              disabled={!isError}
              className="form-wizard-next-btn btn btn-primary-600 px-32 btnSubmitConts"
            >
              Submit
            </Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default ContactForm;