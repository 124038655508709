import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const LuxeInterests = () => {
  const dispatch = useDispatch();
  const {
    luxuryInterests,
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [interests, setInterests] = useState(luxuryInterests);
  const [warning, setWarning] = useState('');

  const interestList = [
    'Fine Dining',
    'Luxury Travel',
    'Yachting',
    'Private Jets',
    'Collecting',
    'Wine Tasting',
    'Fashion & Design',
    'Exclusive Events',
    'Golf',
    'High-End Cars',
    'Wellness & Fitness',
    'Spa Retreats',
    'Gourmet Cooking',
    'Philanthropy',
    'Skiing/Snowboarding',
    // "Collecting (e.g., stamps, coins)"
  ];

  const handleInterestSelection = (value) => {
    let updatedInterests;
    if (interests.includes(value)) {
      // Remove the interest if already selected
      updatedInterests = interests.filter((interest) => interest !== value);
      setWarning(''); // Clear the warning message
    } else {
      if (interests.length >= 7) {
        // Show warning if maximum limit is reached
        setWarning('You can select up to 7 interests only.');
        updatedInterests = interests;
      } else {
        // Add the interest
        updatedInterests = [...interests, value];
        setWarning(''); // Clear the warning message
      }
    }
    setInterests(updatedInterests);
    dispatch(setPayload({ data: { luxuryInterests: updatedInterests } }));
  };

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">
          Luxury Life, Luxe Interests!
        </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Select at least 3 tags that best describe your passions and interests. (Maximum 7 tags only)
        </div>
      </div>
      <div className="interest-selection text-center mt-32 mb-16">
        {/* <h3 className="font-18 font-m fw-medium mt-24 mb-0">Choose Your Interests</h3> */}
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render buttons from the interestList array */}
          {interestList.map((interest) => (
            <button
              key={interest}
              className={`font-m border font-16 fw-normal ${interests.includes(interest) ? 'selected' : ''
                }`}
              onClick={() => handleInterestSelection(interest)}
            >
              {interest}
            </button>
          ))}
        </div>
        {/* Warning Message */}
        {warning && (
          <div
            className="font-m font-14 color-error mt-2"
            style={{ color: 'red' }}
          >
            {warning}
          </div>
        )}
      </div>
    </div>
  );
};

export default LuxeInterests