import { REQUEST_STATUS } from "src/constants/CommonConstants"
import { CHAT_ACTIONS } from "../actions/ChatActions"
import { ChatDefaultProps } from "../ChatPropTypes"

const initialState = () => ({
    selectedUser: { ...ChatDefaultProps },
    pendingChatUser: null,
    pendingChatText:null,
    recentUserList: [],
        
    roomId: null,
    socket: null,
    isConnected: false,
    upsertReqStatus: null,
    searchReqStatus: null,
})

export default (state = initialState(), action) => {
    switch (action.type) {
        case CHAT_ACTIONS.CHAT_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            }
        }
        case CHAT_ACTIONS.CHAT_SEARCH_SUCCESS: {
            return {
                ...state,
                search: {
                    ...state.search,
                    ...action.data,
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            }
        }
        case CHAT_ACTIONS.CHAT_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            }
        }
        case CHAT_ACTIONS.CHAT_INIT_SOCKET: {
            return {
                ...state,
                socket: action.socket,
            }
        }
        case CHAT_ACTIONS.UPDATED_USER_LIST: {
            return {
                ...state,
                recentUserList: action.data,
            }
        }
        case CHAT_ACTIONS.INIT_CONVERSATIONS_LIST:
            return {
                ...state,
                conversations: action.data,
                roomId: action.roomId,
                selectedUser:{
                    ...state.selectedUser,
                    ...action.selectedUser
                }
                // selectedUser: {action.selectedUser},
            }
        case CHAT_ACTIONS.UPDATE_CONVERSATIONS_LIST: {
            return {
                ...state,
                conversations: [...state.conversations,action.data]
            }
        }
        // update all conversations value isRead=true
        case CHAT_ACTIONS.READ_MESSAGE:{
            return {
                ...state,
                conversations: state.conversations.map((message) => ({
                    ...message,
                    isDelivered: true,
                    isRead: true
                }))
            }
        }
        case CHAT_ACTIONS.DELIVERED_MESSAGE: {
            return {
                ...state,
                conversations: state.conversations.map((message) => ({
                    ...message,
                    isDelivered: true
                }))
            }
        }
        case CHAT_ACTIONS.PENDING_SET: {
            return {
                ...state,
                pendingChatUser: action.data.user,
                pendingChatText: action.data.text
            }
        }
        case CHAT_ACTIONS.PENDING_RESET: {
            return {
                ...state,
                pendingChatUser: null,
                pendingChatText: null
            }
        }
        case CHAT_ACTIONS.SET_SELECTED_USER_DETAILS:{
            return {
                ...state,
                selectedUser:{
                    ...state.selectedUser,
                    ...action.data
                }
            }
        }
        default:
            return state
    }
}

