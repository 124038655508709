import React from 'react'

const YourNetwork = () => {
  return (
    <div className='yournetworkbg'>
      <h2 className='font64vw text-white font-e'>Your network is your net worth. <span className='d-block font-e'>Elevate yours.</span></h2>
    </div>
  )
}

export default YourNetwork
