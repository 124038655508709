import React, { useEffect, useState } from "react";
import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Breadcrumbs,
    Typography,
    Button,
} from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { Link } from "react-router-dom";
// Import individual tab components

import UserHeader from "src/components/layout/UserHeader";
import ManageYourAccount from "src/components/account-settings/ManageYourAccount";
import MembershipAndBilling from "src/components/account-settings/MembershipAndBilling";
import NotificationsAndActions from "src/components/account-settings/NotificationsAndActions";
import BlockedMembers from "src/components/account-settings/BlockedMembers";
import HiddenMembers from "src/components/account-settings/HiddenMembers";
import MultimediaAndVoicePermissions from "src/components/account-settings/MultimediaAndVoicePermissions";
import SecurityInformation from "src/components/account-settings/SecurityInformation";
import YourVerifications from "src/components/account-settings/YourVerifications";
import HelpCenter from "src/components/account-settings/HelpCenter"; 
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AccountSettingSearchAction, AccountSettingUpsertAction, setCurrentActiveTabAction } from "./actions/AccountSettingActions";
import AboutUs from "src/components/account-settings/AboutUs";
import MembershipAndBillingStripe from "src/components/account-settings/MembershipAndBillingStripe";
import MembershipAndBillingPayPal from "src/components/account-settings/MembershipAndBillingPayPal";
import MembershipPlans from "src/components/account-settings/MembershipPlans";
import SubscriptionPage from "src/components/account-settings/SubscriptionPage";
import MembershipPlansList from "src/components/account-settings/MembershipPlansList";

const AccountSettingContainer = () => {
    const dispatch = useDispatch();
    const { currentRecord, currentActiveTab } = useSelector((state) => state.AccountSettingReducer, shallowEqual);
    const { user } = useSelector((state) => state.auth, shallowEqual);
    // const [activeTab, setActiveTab] = useState(currentActiveTab);
    const [activeTab, setActiveTab] = useState(currentActiveTab || "Manage Your Account");

    const [isDrawerHidden, setIsDrawerHidden] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 767) {
                setIsDrawerHidden(false); // Sidebar  
            }
            setIsMobileView(window.innerWidth <= 767);
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    useEffect(() => {
        currentActiveTab && setActiveTab(currentActiveTab);
    },[currentActiveTab])
    
    const handleTabClick = (label) => {
        setActiveTab(label);
        dispatch(setCurrentActiveTabAction({ data: { currentActiveTab: label } }));
        if (window.innerWidth <= 767) {
            setIsDrawerHidden(true); // Hide sidebar on tab click
        }
    };

    const handleGoBack = () => {
        if (window.innerWidth <= 767) { // Apply only for mobile view
            setIsDrawerHidden(false); // Show sidebar when Go Back is clicked
        }
    };



    useEffect(() => {
        dispatch(AccountSettingSearchAction({ userId: user?._id }));
    }, [user?._id, dispatch]);

    const handleUpdateSetting = (updatedRecord) => {
        dispatch(AccountSettingUpsertAction({ accountId: user?._id, data: updatedRecord }));
    }; 
    const handleUpdate = (section, key, value, subsection = null) => {
        if (subsection) {
          // Subsection-level update
          handleUpdateSetting({
            ...currentRecord,
            [section]: {
              ...currentRecord[section],
              [subsection]: {
                ...currentRecord[section][subsection],
                [key]: value,
              },
            },
          });
        } else if (key) {
          // Key-level update within a section
          handleUpdateSetting({
            ...currentRecord,
            [section]: {
              ...currentRecord[section],
              [key]: value,
            },
          });
        } else {
          // Section-level update
          if (typeof currentRecord[section] === 'object') {
            // Update all keys in the section
            const updatedSection = Object.fromEntries(
              Object.keys(currentRecord[section]).map((key) => [key, value])
            );
            handleUpdateSetting({
              ...currentRecord,
              [section]: updatedSection,
            });
          } else {
            // Direct section-level update
            handleUpdateSetting({
              ...currentRecord,
              [section]: value,
            });
          }
        }
      };
    // Tabs configuration

    useEffect(() => {
        if (currentActiveTab) {
            setActiveTab(currentActiveTab);
        }
    }, [currentActiveTab]);
    
    const tabs = [
        { label: "Manage Your Account", icon: "/assets/images/account-settings/manage-your-account.svg" },
        { label: "Membership and Billing", icon: "/assets/images/account-settings/membership-and-billing.svg" },
        { label: "Notifications & Actions", icon: "/assets/images/account-settings/notifications-and-actions.svg" },
        { label: "Block Member Settings", icon: "/assets/images/account-settings/block-member-setting.svg" },
        { label: "Hide Member", icon: "/assets/images/account-settings/member-settings.svg" },
        { label: "Multimedia and Voice Permissions", icon: "/assets/images/account-settings/multimedia-and-voice-permissions.svg" },
        { label: "Privacy & Security Information", icon: "/assets/images/account-settings/security-information.svg" },
        { label: "Verifications", icon: "/assets/images/account-settings/verifications.svg" },
        { label: "Help Center", icon: "/assets/images/account-settings/help-center.svg" },
        { label: "About", icon: "/assets/images/account-settings/about.svg" },
    ];

    // Function to get active/inactive icon path
    const getIconPath = (icon, isActive) => {
        if (isActive) {
            return icon.replace(".svg", "-active.svg"); // Append `-active` to the file name
        }
        return icon;
    };

    

    // Render the content of the active tab
    const renderContent = () => {
        switch (activeTab) {
            case "Manage Your Account":
                return <ManageYourAccount
                    currentRecord={currentRecord}
                    handleUpdate={handleUpdate}
                />;
            case "Membership and Billing":
                return <>
                {/* <MembershipAndBillingStripe /> */}
                
                <SubscriptionPage /> 
                </>;
            case "Notifications & Actions":
                return <NotificationsAndActions
                    currentRecord={currentRecord}
                    handleUpdate={handleUpdate}
                />;
            case "Block Member Settings":
                return <BlockedMembers />;
            case "Hide Member":
                return <HiddenMembers />;
            case "Multimedia and Voice Permissions":
                return <MultimediaAndVoicePermissions 
                currentRecord={currentRecord} 
                handleUpdate={handleUpdate}/>;
            case "Privacy & Security Information":
                return <SecurityInformation 
                currentRecord={currentRecord}
                handleUpdate={handleUpdate}
                />;
            case "Verifications":
                return <YourVerifications />;
            case "Help Center":
                return <HelpCenter />;
            case "About":
                return <AboutUs />;
            default:
                return  <ManageYourAccount currentRecord={currentRecord} handleUpdate={handleUpdate} />;
        }
    };

    return (
        <>
            {/* <div className="headNavbgscollnone">
                <UserHeader sx={{ background: "#fff" }} />
            </div> */}
            <Breadcrumbs className="a-s-nav"
                aria-label="breadcrumb"
                separator=""
                sx={{
                  
                }}
            >
                <Link className={`${isDrawerHidden ? 'DrawerListhide' : ''}`}
                    underline="hover"
                    color="inherit"
                    to="/members"
                    style={{ padding: "10px", color: "#081726" }}
                >
                    <ArrowBackIosNewOutlinedIcon />
                </Link>
                {isMobileView && (
            <button
                className={`dnonMobBtn border-0 bg-none ${isDrawerHidden ? 'content-expanded' : ''}`}
                onClick={handleGoBack}
                style={{ padding: "2vw", color: "#081726" }}
            >
                <ArrowBackIosNewOutlinedIcon />
            </button>
        )}
                <Typography sx={{
                    color: "#081726", 
                    fontFamily: 'indivisible',
                }}>
                    Account Settings
                </Typography>
            </Breadcrumbs>
            <Box className="accountdshBd"
                
            >
                {/* Left Sidebar */}
                <Drawer className={`LeftSidebarList01 ${isDrawerHidden ? 'DrawerListhide' : ''}`} variant="permanent" sx={{ 
                        [`& .MuiDrawer-paper`]: { 
                            fontFamily: 'indivisible',
                        },
                    }}
                    PaperProps={{ className: "overflowauto" }}>
                    
                    <List>
                        {tabs.map((tab) => (
                            <ListItem key={tab.label} disablePadding>
                                <ListItemButton className="listitmBtn"
                                    sx={{ 
                                        "&:hover": {
                                            color: "#916008", // Change this to your desired hover color
                                        },
                                        "&.Mui-selected": {
                                            color: "#916008", // Active tab text color
                                            "&:hover": {
                                                color: "#916008", // Active tab hover color
                                            },
                                        },
                                    }}
                                    // selected={activeTab === tab.label}
                                    // onClick={() => setActiveTab(tab.label)}
                                    selected={activeTab === tab.label} onClick={() => handleTabClick(tab.label)}
                                    
                                >
                                    <ListItemIcon className="ac-ListItem" sx={{ 
                                        fontFamily: 'indivisible',
                                    }}>
                                        <img
                                            src={getIconPath(tab.icon, activeTab === tab.label)}
                                            alt={tab.label}
                                            className="imgw24"
                                        />
                                    </ListItemIcon>
                                    <ListItemText className="fontsetvw" primary={tab.label} sx={{
    fontFamily: "indivisible",
    fontSize: "16px",
    fontWeight: "500",
    "& .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "500",
    },
  }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>

                {/* Main Content */}
                <Box className={`dMobileset ${isDrawerHidden ? 'content-expanded' : ''}`} sx={{ flex: 1, padding: "1vw", background: "#fff" }}> 
                    {renderContent()}
                </Box>
            </Box>
        </>
    )
}
export default AccountSettingContainer