import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';
import { isValidateDescInput } from 'src/utils/CommonUtils';

const YouLookingForPartner = () => {
  const dispatch = useDispatch();
  const {
    preferences
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [text, setText] = useState(preferences.aboutPartnerDescription);
  const [charCount, setCharCount] = useState(preferences.aboutPartnerDescription?.length);
  const [errorMessage, setErrorMessage] = useState('');

  const handleTextChange = (event) => {
    const value = event.target.value;
    setText(value);
    setCharCount(value.length); // Count characters
    if (value.length > 0) {
      setErrorMessage(''); // Clear the error message if text is typed
    }
  };

  const handleBlur = () => {
    if (text?.trim() !== '' && text?.split(' ').length < 8) {
      setErrorMessage('This is too short. It should have 8 words or more.');
    }
    else {
      dispatch(setPayload({ data: { preferences: { ...preferences, aboutPartnerDescription: text } } }));
    }
  };
  useEffect(() => { 
    const isValid = isValidateDescInput(text);
    if (isValid) {
      dispatch(setPayload({ data: { preferences: { ...preferences, aboutPartnerDescription: text } } }));
    }
    else {
      dispatch(setPayload({ data: { preferences: { ...preferences, aboutPartnerDescription: '' } } }));
    }
  }, [text])
  return (
    <div>
      <div className="font-m font-32 fw-medium text-center">What Are You Looking for in a Partner?  </div>
      <div className="font-m font-18 colorf5 pt-6 text-center">
        Tell us more about the qualities and traits you desire in a partner.
      </div>

     <div className='text-center mt-32 mb-16'>
     <TextField
        placeholder="Describe Your Ideal Partner"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        value={text}
        onChange={handleTextChange}
        onBlur={handleBlur} // Trigger when the field loses focus
        inputProps={{ maxLength: 4000 }}  // Limit to 4000 characters

        sx={{
          marginTop: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: '15px',
          },
          '& .MuiOutlinedInput-root': {

            borderRadius: '15px',
            '& fieldset': {
              borderColor: '#ccc',
            },
            '&:hover fieldset': {
              borderColor: '#916008',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#916008',
              borderWidth: '1px',
            },
          },
        }}
      />

      <div className='d-flex justify-content-between align-items-start'>
        {/* Show error message if the field is empty when it loses focus */}
        <div className=""> {errorMessage && (
          <Typography variant="body2" color="error" align="center" sx={{ marginTop: 1 }}>
            {errorMessage}
          </Typography>
        )}</div>
        <Typography variant="body2" color="textSecondary" align="right" sx={{ marginTop: 1 }}>
          {charCount} / 4000 characters
        </Typography>
      </div>
     </div>
    </div>
  );
};

export default YouLookingForPartner