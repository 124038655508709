import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import Favorites from './FavoriteProfile';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { favoriteSearchAction } from './actions/FavoriteActions';
import ViewedMeSkeleton from './ViewedMeSkeleton';

const FavoritesContainer = () => {
    const { search: { data } } = useSelector(state => state.FavoriteReducer, shallowEqual);
    const { currentLocation } = useSelector(state => state.HomeReducer, shallowEqual);
    const dispatch = useDispatch();

    // Manage selected tab state
    const [activeTab, setActiveTab] = useState('viewed'); // Default tab is 'viewed'
    const [currentPage, setCurrentPage] = useState(0);
    const [sortBy, setSort] = useState('lastActive');
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchProfiles = async () => {
            if (currentLocation.lat && currentLocation.lng) {
                setLoading(true);
                const where = { longitude: currentLocation.lng, latitude: currentLocation.lat };

                try {
                    await dispatch(favoriteSearchAction({ requestType: activeTab, where, sortBy, currentPage }));
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchProfiles();
    }, [dispatch, activeTab, currentPage, currentLocation, sortBy]);

    useEffect(() => {
        setProfiles(data);
    }, [data]);

    return (
        <div>
            <section className='border-bottom settomobilefx'>
                <Container maxWidth="100%" className="px-5vw">
                    {/* Tab Navigation */}
                    <div className="tabs tabsBtnWvDWf">
                        <Link to="/members" className='avw'>
                            <ArrowBackIosNewOutlinedIcon />
                        </Link>
                        <button
                            onClick={() => setActiveTab('viewed')}
                            className={activeTab === 'viewed' ? 'active' : ''}
                        >
                            Viewed Me
                        </button>
                        <button
                            onClick={() => setActiveTab('favorites')}
                            className={activeTab === 'favorites' ? 'active' : ''}
                        >
                            My Favorites
                        </button>
                        <button
                            onClick={() => setActiveTab('favorited')}
                            className={activeTab === 'favorited' ? 'active' : ''}
                        >
                            Favorited Me
                        </button>
                    </div>
                </Container>
            </section>

            <Container maxWidth="100%" className="px-5vw">
                <div className="selected-tabs">
                    {loading ? (
                        <ViewedMeSkeleton />
                    ) : (
                        <>
                            {activeTab === 'viewed' && (
                                <Favorites title="Who Viewed Me" sortingValue="Viewed Me" profiles={profiles} showFirstHeartIcon={false} setSort={setSort} />
                            )}
                            {activeTab === 'favorites' && (
                                <Favorites title="My Favorites" sortingValue="Favorited" profiles={profiles} showFirstHeartIcon={false} setSort={setSort} />
                            )}
                            {activeTab === 'favorited' && (
                                <Favorites title="Who Favorited Me" sortingValue="Favorited Me" profiles={profiles} showFirstHeartIcon={true} setSort={setSort} />
                            )}
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default FavoritesContainer;
