import React from 'react'
import { Link } from 'react-router-dom'

const WhoWeServe = () => {
  return (
    <div style={{ padding: '0vw 5.5vw 5vw 5.5vw' }}>
      <h2 className='fw-normal font-m font48vw mb-0' style={{padding: '0 0 2vw 0'}}>Who We Serve</h2>


<div className='OurmembersbgNew1'>
    <h3 className='color7b font-1-25vw' style={{marginBottom: '2vw'}}>Our members include</h3>
    
    <div className='Ourmemberstext d-grid justify-content-between align-items-start'>
        <div className='d-flex justify-content-between align-items-start flex-column OurtextN1'>
            <span className='img'><img src="/assets/images/first-home/about-us/ceos-enteprencurs.png" className='img-fluid' alt="" /></span>
            <p className='mb-0 font-1vw'>CEOs, entrepreneurs, and business moguls</p>
        </div>
        <div className='d-flex justify-content-between align-items-start flex-column OurtextN1'>
            <span className='img'><img src="/assets/images/first-home/about-us/high-profile.png" className='img-fluid' alt="" /></span>
            <p className='mb-0 font-1vw'>High-profile professionals in entertainment, finance, and technology</p>
        </div>
        <div className='d-flex justify-content-between align-items-start flex-column OurtextN1'>
            <span className='img'><img src="/assets/images/first-home/about-us/investors.png" className='img-fluid' alt="" /></span>
            <p className='mb-0 font-1vw'>Investors, philanthropists, and cultural leaders </p>
        </div>
        <div className='d-flex justify-content-between align-items-start flex-column OurtextN1'>
            <span className='img'><img src="/assets/images/first-home/about-us/tastemakers.png" className='img-fluid' alt="" /></span>
            <p className='mb-0 font-1vw'> Tastemakers in luxury, fashion, and art</p>
        </div>
    </div>
   <div className='text-center' style={{marginTop: '3vw'}}>  <Link to="/signup" className="text-white font-semibold text-decoration-none d-inline-block btnSetWth" >
           Join Now
           </Link></div>
    </div>

    </div>
  )
}

export default WhoWeServe
