import React from 'react'
import { Link } from 'react-router-dom'

const WhatYouGet = () => {
  return (
    <div className='WhatYouGet' style={{padding:'5vw 5.5vw',}}>
        <h2 className='text-center font48vw'>What You Get</h2>
        <div className='textAccessimgbg d-flex justify-content-between align-items-center'>
            <div className='textAccessimg w-100'>
                <div className='img'><img src="/assets/images/first-home/exclusive-access-icon.svg" className='img-fluid' /></div>
                 <h4 className='mb-0 font24vw'>Exclusive Access</h4>
                <p className='mb-0 font04vw'>Connect with like-minded individuals at the pinnacle of their fields.</p>
            </div>
            <div className='textAccessimg w-100'>
                <div className='img'><img src="/assets/images/first-home/premium-events.svg" className='img-fluid' /></div>
                 <h4 className='mb-0 font24vw'>Premium Events </h4>
                <p className='mb-0 font04vw'>Invitations to elite gatherings, networking opportunities, and private experiences.</p>
            </div>
            <div className='textAccessimg w-100'>
                <div className='img'><img src="/assets/images/first-home/curated-community-icon.svg" className='img-fluid' /></div>
                <h4 className='mb-0 font24vw'>Curated Community </h4>
                <p className='mb-0 font04vw'>A vetted membership ensures authenticity, trust, and discretion.</p>
            </div>
        </div>
       <Link to="/signup" className="text-white font-semibold text-decoration-none d-inline-block btnSetWth" >
               Join Now
               </Link>
    </div>
  )
}

export default WhatYouGet
