import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { initGoogleMaps } from 'src/utils/GoogleMapsLoader';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: '22px',
};

const YourLocation = () => {
  const dispatch = useDispatch();
  const {
    location, country, state, city
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [currentLocation, setLocation] = useState(city ? `${city}, ${state}, ${country}` : '');
  const [errorMessage, setErrorMessage] = useState('');
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(city ? `${city}, ${state}, ${country}` : '');


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleUseCurrentLocation = () => {
    setLoading(true);
    setErrorMessage('');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Fetch location details using Google Maps Geocoding API
            const response = await fetch(//AIzaSyD4uVvlqwit6JTSQ1jHyf9PMWH4vwbu11Y
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            );
            const data = await response.json();

            if (data.status === 'OK') {
              const addressComponents = data.results[0].address_components;

              // Extract country, state, and city from address components
              const country = addressComponents.find((component) =>
                component.types.includes('country')
              )?.long_name;
              const state = addressComponents.find((component) =>
                component.types.includes('administrative_area_level_1')
              )?.long_name;
              const city = addressComponents.find((component) =>
                component.types.includes('locality')
              )?.long_name;
              const zipCode = addressComponents.find((component) =>
                component.types.includes('postal_code')
              )?.long_name;
              setLocation({ 
                country,
                state,
                city
              }); // Update input value
              setInputValue(`${city}, ${state}, ${country}`);
              dispatch(setPayload({
                data: {
                  location: {
                    type: "Point",
                    coordinates: [
                      longitude, //longitude
                      latitude //latitude
                    ]
                  }
                  , country, state, city,zipCode
                }
              }));
            } else {
              setErrorMessage(
                <>
                  <div>Unable to fetch geolocation</div>
                  <div>Please try again or enter the details.</div>
                </>
              );
            }
          } catch (error) {
            console.error('Error fetching location:', error);
            setErrorMessage('An error occurred while fetching location.');
          }
          setLoading(false);
        },
        (error) => {
          console.error('Error fetching location:', error);
          setErrorMessage('Unable to access your location.');
          setLoading(false);
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported by your browser.');
      setLoading(false);
    }
  };
  const handleOtherLocationChange = (event, newValue) => {
    if (newValue && isGoogleLoaded && window.google) {
      const placeId = newValue.place_id;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: placeId }, (results, status) => {
        if (status === 'OK') {
          const place = results[0];
          const addressComponents = place.address_components;
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();

          const country = addressComponents.find((component) =>
            component.types.includes('country')
          )?.long_name;
          const state = addressComponents.find((component) =>
            component.types.includes('administrative_area_level_1')
          )?.long_name;
          const city = addressComponents.find((component) =>
            component.types.includes('locality')
          )?.long_name;
          const zipCode = addressComponents.find((component) =>
            component.types.includes('postal_code')
          )?.long_name;
          if(city === undefined || state === undefined || country === undefined) {
            setErrorMessage('Please enter a valid location with city, state and country.');
            return;
          }

          setInputValue(`${city}, ${state}, ${country}`);
          setLocation({ 
            country,
            state,
            city
          });
          dispatch(setPayload({
            data: {
              location: {
                type: "Point",
                coordinates: [
                  longitude, //longitude
                  latitude //latitude
                ]
              }
              , country, state, city,zipCode
            }
          }));
        }
      });
    }
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 2 && isGoogleLoaded && window.google) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        { input: newInputValue },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };
  useEffect(() => {
    initGoogleMaps().then(() => {
      setIsGoogleLoaded(true);
    });
  }, []);

  return (
    <div className="mw-425-px btnsWithConnects mx-auto">
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Where Are You Located?</div>
        <div className="font-m font-14 colorf5 pt-6 text-center">
          Your location helps us find the best matches near you.
        </div>
      </div>

      <form className="navbar-search mt-32">
        {/* <TextField
          type="text"
          name="search"
          placeholder="Search"
          variant="outlined"
          // disabled={true}
          fullWidth
          value={currentLocation}
          onChange={(e) => setLocation(e.target.value)}
          sx={{
            height: '44px',
            '& .MuiOutlinedInput-root': {
              height: '44px',
              borderRadius: '15px',
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#916008',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#916008',
                borderWidth: '1px',
              },
            },
          }}
        /> */}
        <Autocomplete
          freeSolo
          options={suggestions}
          getOptionLabel={(option) => option.description || ''}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleOtherLocationChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search for a location..."
              variant="outlined"
              fullWidth
              className="filter-input"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                borderRadius: '8px',
                height: "44px",
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#916008',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#916008',
                  },
                  '& .MuiOutlinedInput-input': {
                    height: '11px',
                  },
                },
              }}
            />
          )}
        />
      </form>

      <button
        className="CurrentLocation bg-white border-0 font-18 font-m fw-medium my-28"
        onClick={handleOpen}
      >
        <MyLocationIcon /> Use My Current Location
      </button>

      {loading && (
        <div className="loading-state flex-column gap-2">
          <div className="loading22"></div>
          <span className="d-block mt-6">Searching...</span>
        </div>
      )}

      {errorMessage && <div className="error-message text-center font-14 fw-normal font-m" style={{ color: '#DD4B39' }}>{errorMessage}</div>}


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mb: 4, fontSize: '24px', color: '#000', textAlign: 'center', fontWeight: '500', fontFamily: 'indivisible' }}>
            Allow browser to access your location
          </Typography>
          <Box className="d-flex justify-content-between w-100 gap-3">
            <Button className="w-100 outLineBtn rounded-1 text-black" onClick={handleClose}>Block</Button>
            <Button
              className="w-100 fillBtn rounded-1 text-white"
              onClick={() => {
                handleClose(); // Close the modal
                handleUseCurrentLocation(); // Execute the use location logic
              }}
              disabled={loading}
              style={{
                cursor: loading ? 'not-allowed' : 'pointer',
                background: 'rgba(0, 0, 0, 0.70)',
              }}
            >
              Allow
            </Button>

          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default YourLocation