import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Grid, Box, Button, Typography } from '@mui/material';

const HowItWorks = () => {
  // Array to store the list items
  const steps = [
    {
      number: 1,
      title: "Ensure Proper Lighting",
      description: "Use natural light for a clear photo.",
    },
    {
      number: 2,
      title: "Face Camera Directly",
      description: "Look straight and keep your face centered.",
    },
    {
      number: 3,
      title: "Avoid Blurry Images",
      description: "Hold steady and ensure the image is sharp.",
    },
  ];

  return (
    <div
      style={{
        maxWidth: '656px',
        width: '100%', 
      }}
    >
      <h3 className="text-center font-32">How It Works</h3>
      <p className="text-center font-18" style={{ color: 'rgba(0,0,0,0.7)' }}>
        To maintain a safe and genuine community, we require a quick selfie verification. This ensures that all profiles on our platform are real and trustworthy.
      </p>

      <Grid container spacing={2}>
        {/* List Section */}
        <Grid item xs={12} sm={7} lg={7} className="ListVYp">
            <Box>
            <Typography sx={{display: 'flex', alignItems: 'center', fontSize: '16px', color: '#916008', fontWeight: '600', gap: '10px'}}><span><img src="/assets/images/signup/instructions.svg" alt="" /></span> Instructions</Typography>
            </Box>
          <Box>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {steps.map((step) => (
                <ListItem key={step.number} sx={{paddingLeft: '0'}}>
                  <ListItemAvatar>
                    <Avatar sx={{ background: '#faf5eb', color: '#916008', fontWeight: '600',fontSize: '14px' }}>
                      {step.number}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={step.title}
                    secondary={step.description}
                    sx={{
                      '& .MuiListItemText-primary': {
                        color: '#081726',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: 'indivisible',
                      },
                      '& .MuiListItemText-secondary': {
                        color: '#081726',
                        fontSize: '12px',
                        fontWeight: '500',
                        fontFamily: 'indivisible',
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>

        {/* Avatar Section */}
        <Grid item xs={12} sm={5} lg={5} className="dnsetMbile">
          <Box className="text-center h-100 d-flex align-items-center justify-content-center flex-column">
            <img alt="User Name" src="/assets/images/signup/how-it-works.png" />
          </Box>
        </Grid>
      </Grid>
 
    </div>
  );
};

export default HowItWorks