import React, { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPayload } from "src/containers/signup-container/actions/SignupActions";

const LinkViaEmail = () => {
  const dispatch = useDispatch();
  const {
    email,
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [inputEmail, setInputEmail] = useState(email);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  //   const handleSubmit = () => {
  //     if (!captchaValue) {
  //       alert("Please complete the CAPTCHA to proceed.");
  //       return;
  //     }
  //     alert("Form submitted successfully!");
  //     // Proceed with form submission logic
  //   };
  const handleEmailInput = (e) => {
    const email = e.target.value;
    dispatch(setPayload({ data: { email } }));
  }
  const handlePaste = (e) => {
    setTimeout(() => {
      handleEmailInput(e); // Call after paste is processed
    }, 0);
  };
  useEffect(()=>{
    if (inputEmail && isValidEmail(inputEmail)) {
      dispatch(setPayload({ data: { email: inputEmail } }));
    }
  },[inputEmail])
  return (
    <div className="mw-400-px btnsWithConnects mx-auto">
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Link Via Email</div>
        <div className="font-m font-14 colorf5 pt-6 text-center">
          Enter your email address below to proceed to the next step.
        </div>
      </div>


      <div className="icon-field my-32 w-100">
        <span className="icon">
          <MailOutlineIcon />
        </span>
        <input
          type="email"
          name="email"
          className="form-control input-h-44"
          placeholder="Enter Email"
          value={inputEmail}
          onChange={(e) => setInputEmail(e.target.value)}
          onBlur={handleEmailInput}
          onPaste={handlePaste}
        />
      </div>

      {/* Add reCAPTCHA */}
      {/* <div className="bylinkTp font-m text-start">
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="exampleCheck1" style={{ width: '22px', height: '22px' }} />
          <label className="form-check-label ps-10" htmlFor="exampleCheck1">By linking your account, you agree to our <span className="d-block"><Link to="/">Terms</Link> and <Link to="/">Privacy Policy</Link>.</span></label>
        </div>
      </div> */}
    </div>
  );
};

export default LinkViaEmail;
