import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { io } from "socket.io-client"
import { updateCurrentLocationAction, getCurrentLocationAction } from '../home-container/actions/HomeActions';
import MemberContainer from '../member-container/Index';
import UserProfileContainer from '../profile-container/Index';
import UserHeader from 'src/components/layout/UserHeader';
import AccountSettingContainer from '../account-setting/Index';
import MemberDetailsContainer from '../member-container/MemberDetailsContainer';
import FavoritesContainer from '../favorites-container/Index';
import config from 'src/config/index';
import { chatInitAction } from '../chat-container/actions/ChatActions';
import ChatContainer from '../chat-container/Index';
import LabelBottomNavigation from 'src/components/layout/LabelBottomNavigation';
import SubscribedRoute from 'src/hoc/SubscribedRouteHOC';
const API = config.socketUrl;

function MainContainer() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.user?.jwtToken);
  const { currentLocation } = useSelector((state) => state.HomeReducer, shallowEqual);

  useEffect(() => {
    const fetchAndUpdateLocation = async () => {
      // Fetch current location from Redux store
      dispatch(getCurrentLocationAction());
      navigator.geolocation.getCurrentPosition(async (position) => {
        try {
          const { latitude, longitude } = position.coords;

          // Compare with the currentLocation from Redux
          if (
            currentLocation &&
            (currentLocation.lng !== 0 || currentLocation.lat !== 0) &&
            (currentLocation.lng !== longitude || currentLocation.lat !== latitude)
          ) {
            // Fetch from Google API if locations don't match
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
            );
            const data = await response.json();

            if (data.status === 'OK') {
              const addressComponents = data.results[0].address_components;

              // Extract country, state, and city from address components
              const country = addressComponents.find((component) =>
                component.types.includes('country')
              )?.long_name;
              const state = addressComponents.find((component) =>
                component.types.includes('administrative_area_level_1')
              )?.long_name;
              const city = addressComponents.find((component) =>
                component.types.includes('locality')
              )?.long_name;
              const zipCode = addressComponents.find((component) =>
                component.types.includes('postal_code')
              )?.long_name;

              // Build the payload
              const payload = {
                currentLocation: {
                  type: 'Point',
                  coordinates: [longitude, latitude],
                },
                country,
                state,
                city,
                zipCode,
              };
              // Dispatch the action to update the current location
              dispatch(updateCurrentLocationAction({ data: payload }));
            } else {
              console.error('Geocoding failed:', data.status);
            }
          }
        } catch (error) {
          console.error('Error fetching location:', error);
        }
      });
    };

    fetchAndUpdateLocation();
  }, [dispatch, currentLocation.lat]);

  useEffect(() => {
    const newSocketInstance = io(API.replace("ws://", "wss://"), {
      auth: { token },
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
    })

    newSocketInstance.on("connect", () => {
      dispatch(chatInitAction({ socket: newSocketInstance }))
    })

    newSocketInstance.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        newSocketInstance.connect()
      }
    })

    newSocketInstance.on("connect_error", (error) => {
      console.error("Connection error:", error)
    })

    return () => {
      newSocketInstance.off("connect")
      newSocketInstance.off("disconnect")
      newSocketInstance.off("connect_error")
      newSocketInstance.close()
    }
  }, [token, dispatch])

  return (
    <>
      <UserHeader />
      <Routes>
        <Route path="/members" element={<MemberContainer />} />
        <Route path="/member-details/:id" element={<MemberDetailsContainer />} />
        <Route path="/profile-edit" element={<UserProfileContainer />} />
        <Route path="/account-settings" element={<AccountSettingContainer />} />
        <Route path="/favorites" element={<FavoritesContainer />} />
        <Route path="/chat" element={<ChatContainer />} />

      </Routes>
      <LabelBottomNavigation />
    </>
  );
}

export default MainContainer;
