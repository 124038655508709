import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const YourEthnicity = () => {
  const dispatch = useDispatch();
  const {
    ethnicity,
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [selectedEthnicity, setSelectedEthnicity] = useState(ethnicity||'');

  const ethnicityOptions = [
    'Asian',
    'Black/African descent',
    'Hispanic/Latino',
    'Middle Eastern',
    'Native American/Indigenous',
    'Pacific Islander',
    'White/Caucasian',
    'Mixed/Multiracial',
    'Other',
    'Prefer not to say',
  ];

  // const handleEthnicity = (value) => {
  //   setSelectedEthnicity(value);
  //   dispatch(setPayload({ data: { ethnicity: value } }));
  // };

  const handleEthnicity = (value) => {
    // Toggle selection: If the same option is clicked again, unselect it
    const newValue = selectedEthnicity === value ? "" : value;
    setSelectedEthnicity(newValue);
    dispatch(setPayload({ data: { ethnicity: newValue } }));
  };

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">What’s Your Ethnicity?</div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Let your matches get to know you better by sharing your ethnicity.
        </div>
      </div>
      <div className="ethnicity-selection text-center mt-32 mb-16">
        {/* <h3 className="font-18 font-m fw-medium ">Choose Your Ethnicity</h3> */}
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render ethnicity options */}
          {ethnicityOptions.map((option) => (
            <button
              key={option}
              className={`font-m border font-16 fw-normal ${selectedEthnicity === option ? 'selected' : ''}`}
              onClick={() => handleEthnicity(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourEthnicity