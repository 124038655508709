import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Grid, Box, Button } from '@mui/material';

const ProfilePhotoUploadSteps = () => {
  return (
    <div className='verifytex001' style={{maxWidth: '656px', width: '100%',}}>
      <h3 className="text-center font-32 h3vtxt">Verify Your Profile with a Selfie</h3>
      <p className="text-center font-18 para18vtxt" style={{color: 'rgba(0,0,0,0.7)'}}>To maintain a safe and genuine community, we require a quick selfie verification. This ensures that all profiles on our platform are real and trustworthy.</p>

      <Grid container spacing={2}>
        {/* Avatar Section */}
        <Grid item xs={12} sm={5} lg={5} className="dnsetMbile">
          <Box>
            <img alt="User Name" src="/assets/images/signup/verify-your-profile-with-a-selfie.png" />
          </Box>
        </Grid>

        {/* List Section */}
        <Grid item xs={12} sm={7} lg={7} className="ListVYp">
          <Box>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ background: '#fff' }}>
                    <img alt="User Name" src="/assets/images/signup/private.svg" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  primary="100% Private" 
                  secondary="Your selfie is strictly for verification—it will never be visible to others." 
                  sx={{ 
                    '& .MuiListItemText-primary': {
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: '500',
                      fontFamily: 'indivisible',
                    },
                    '& .MuiListItemText-secondary': {
                      color: '#081726',
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'indivisible',
                    }
                  }} 
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ background: '#fff' }}>
                    <img alt="User Name" src="/assets/images/signup/show-authenticity.svg" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  primary="Show Authenticity" 
                  secondary="Demonstrate you're the real person behind your profile pictures." 
                  sx={{ 
                    '& .MuiListItemText-primary': {
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: '500',
                      fontFamily: 'indivisible',
                    },
                    '& .MuiListItemText-secondary': {
                      color: '#081726',
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'indivisible',
                    }
                  }} 
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ background: '#fff' }}>
                    <img alt="User Name" src="/assets/images/signup/elevate-safety.svg" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  primary="Elevate Safety" 
                  secondary="Contribute to a trusted environment for genuine and secure connections." 
                  sx={{ 
                    '& .MuiListItemText-primary': {
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: '500',
                      fontFamily: 'indivisible',
                    },
                    '& .MuiListItemText-secondary': {
                      color: '#081726',
                      fontSize: '12px',
                      fontWeight: '500',
                      fontFamily: 'indivisible',
                    }
                  }}  
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>

      
    </div>
  );
}

export default ProfilePhotoUploadSteps