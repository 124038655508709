import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPayload } from "src/containers/signup-container/actions/SignupActions";

const GenderInterestSelection = ({ isInternal = false }) => {
  const dispatch = useDispatch();
  const {
    currentRecord,
    currentRecord: { preferences }
  } = useSelector((state) => state.SignupReducer, shallowEqual);
  const [gender, setGender] = useState(currentRecord.gender || "");
  const [interests, setInterests] = useState(currentRecord.preferences.gender || []);

  const handleGenderSelection = (value) => {
    // If the same option is clicked again, unselect it
    const newValue = gender === value ? "" : value;
    setGender(newValue);
    dispatch(setPayload({ data: { gender: newValue } }));
  };
  const handleInterestSelection = (value) => {
    setInterests((prevInterest) => {
      // If the same option is clicked again, unselect it
      const newValue = prevInterest === value ? "" : value;
      dispatch(setPayload({ data: { preferences: { ...preferences, gender: newValue } } }));
      return newValue;
    });
  };

  return (
    <>
      {
        !isInternal && <div className="header text-center">
          <div className="font-m font-36 color08 fst-italic fw-medium">
            Welcome to <span className="text-gold-light font-p">Luxury Life</span>  <span className="font-16 fw-medium font-m text-black d-block fst-normal">
              Where Ambition Meets Affluence
            </span>
          </div>
          <div className="font-14 fw-normal font-m colorf5 mt-6 mb-36">
            Let’s get to know you better to create your personalized luxury dating experience.
          </div>
        </div>
      }
      <div className="GISec">
        {
          // self gender selection should disable on internal
          !isInternal &&
          <div className="gender-selection onwts text-center">
            <h3 className="font-24 font-m fw-medium mb-0">What is your gender?</h3>
            <div className="btnsetAll mt-6">
              <button
                className={`font-m border font-16 fw-normal ${gender === "Male" ? "selected" : ""}`}
                onClick={() => handleGenderSelection("Male")}
              >
                Male
              </button>
              <button
                className={`font-m border font-16 fw-normal ${gender === "Female" ? "selected" : ""}`}
                onClick={() => handleGenderSelection("Female")}
              >
                Female
              </button>
              <button
                className={`font-m border font-16 fw-normal ${gender === "Other" ? "selected" : ""}`}
                onClick={() => handleGenderSelection("Other")}
              >
                Non-binary/Other
              </button>
            </div>
          </div>
        }

        {/* Interest Selection */}
        <div className="interest-selection text-center onwts mt-16">
          <h3 className="font-24 font-m fw-medium mb-0">Who are you interested in?</h3>
          <div className="btnsetAll mt-6">
            <button
              className={`font-m border font-16 fw-normal ${interests.includes("Male") ? "selected" : ""}`}
              onClick={() => handleInterestSelection("Male")}
            >
              Male
            </button>
            <button
              className={`font-m border font-16 fw-normal ${interests.includes("Female") ? "selected" : ""}`}
              onClick={() => handleInterestSelection("Female")}
            >
              Female
            </button>
            <button
              className={`font-m border font-16 fw-normal ${interests.includes("Both") ? "selected" : ""}`}
              onClick={() => handleInterestSelection("Both")}
            >
              Both
            </button>
            <button
              className={`font-m border font-16 fw-normal ${interests.includes("Non-binary/Other") ? "selected" : ""}`}
              onClick={() => handleInterestSelection("Non-binary/Other")}
            >
              Non-binary/Other
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenderInterestSelection;
