import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CreateCatchyHeading from '../../components/signup/CreateCatchyHeading';
import YouLookingForPartner from '../../components/signup/YouLookingForPartner';
import YourLocation from '../../components/signup/YourLocation';
import FewWords from '../../components/signup/FewWords';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setCurrentActiveTabAction } from '../account-setting/actions/AccountSettingActions';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { validatePayload } from '../signup-container/SignupPropTypes';
// Modal Styles
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '700px',
  width: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
  maxHeight: '80vh',
  overflow: 'auto',
};

const BasicInformation = ({ updateUserInfo }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const {
    currentRecord,    
  } = useSelector((state) => state.SignupReducer, shallowEqual); 
  const navigation = useNavigate();
  const dispatch = useDispatch();
  // const [userData, setUserData] = useState({
  //     name: user.userName,
  //     age: user.age,
  //     location: `${user.country}, ${user.city}`,//'New Delhi, India • 600 miles',
  //     profileImage: user.profilePicture,
  //     verifications: ['ID Verification', 'Selfie Verification'],
  //     heading: user.myHeading,
  //     about:`${user?.aboutUsDescription || "Not available"}`,
  //     partnerLookingFor: `${user?.preferences?.aboutPartnerDescription || "Not available"}`,
  //     primaryLocation: `${user.city}, ${user.state}, ${user.country},`,//'Shalimar Baugh, Delhi, India',
  //     secondaryLocations: ['Secondary Location 1'],
  // });
  const [open, setOpen] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [localStep, setLocalStep] = useState(1);
  const [isValidated,setIsValidated] = useState(false);
  // const secondaryLocations = ['Secondary Location 1']



  const handleOpen = (info) => {
    setSelectedInfo(info);
    setLocalStep(info.step);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedInfo(null);
  };

  const handleSave = () => {
    updateUserInfo(selectedInfo.step);
    handleClose();
  };
  const handleNavigationVerification = () => {
    dispatch(setCurrentActiveTabAction({ data: { currentActiveTab: 'Verifications' } }));
    navigation('/account-settings');
  }
  const renderModalContent = () => {
    if (!selectedInfo) return null;

    switch (selectedInfo.key) {
      case 'heading':
        return <CreateCatchyHeading data={selectedInfo.value} />;
      case 'about':
        return <FewWords data={selectedInfo.value} />;
      case 'partnerLookingFor':
        return <YouLookingForPartner data={selectedInfo.value} />;
      case 'primaryLocation':
        return <YourLocation data={selectedInfo.value} />;
      default:
        return null;
    }
  };
  useEffect(() => {
    let isValid = false;
    try {
      validatePayload(localStep, 
        localStep <= 7 
          ? { data: currentRecord } 
          : { accountUpdatePayload: currentRecord }
      );
      isValid = true;
    } catch (error) {
      isValid = false;
    }  
    setIsValidated(isValid);
  }, [currentRecord, localStep]);
  if (!user) return <div>Loading...</div>;
  const socialverifications = [
    { name: "Instagram Verification", icon: "/assets/images/icons/instagram-icon.svg", status: user?.isInstagramVerified },
    { name: "LinkedIn Verification", icon: "/assets/images/icons/linkedin-icon.svg", status: user?.isLinkedinVerified },
    { name: "Facebook Verification", icon: "/assets/images/icons/facebook-icon.svg", status: user?.isFacebookVerified },
    { name: "Selfie Verification", icon: "/assets/images/icons/selfie-icon.svg", status: user?.isProfileVerified },
    { name: "Identity", icon: "/assets/images/icons/identity-icon.svg", status: user?.isIdVerified },
  ];
  return (
    <div>
      <div className="text-black font-24 fontm fw-medium mb-20">Basic Information</div>
      <div>
        {/* Verifications Section */}
        <div> 
            <div className="d-flex align-items-center text-black font-16 font-m fw-medium gap-4 mb-10 Verificationsicb">
          
              <VerifiedOutlinedIcon style={{ color: '#BF8500' }} /> Verifications
            </div>
            <p className="color7b font-16 font-m">
              Verify your profile to enhance trust and ensure a secure dating experience.
            </p>
            <div className="verification-socialList">
        {socialverifications.map((item, index) => (
          <div className="verification-item d-flex align-items-center justify-content-between" key={index}>
          <div className='socialImgName'><img src={item.icon} alt={item.name} className="icon" />
          <span className="name">{item.name}</span></div>
            {item?.status ? (
              <span className="verified fst-italic d-flex align-items-center gap-1">
                <img src="/assets/images/icons/verified.svg" className='img-fluid' alt="" /> Verified
                </span>
            ) : (
              <Button  className="verify-now fst-italic" onClick={handleNavigationVerification}>Verify Now</Button>
            )}
          </div>
        ))}
      </div>
          {/* {verifications?.map((verification, index) => (
            <Button
              key={index}
              onClick={handleNavigationVerification}
              className="d-block text-decoration-underline font-16 font-m fw-medium my-10 py-6"
              style={{ color: '#916008' }}
            >
              {verification}
            </Button>
          ))} */}
        </div>

        {/* Heading Section */}
        <div className="border-top py-20">
          <div className="d-flex align-items-center justify-content-between color08 font-16 fw-medium">
            Heading{' '}
            <div>
              {user?.myHeading && (
                <button className="ModeEditbtn" style={{ color: 'rgb(231, 214, 187)' }}>
                  <CheckCircleOutlineIcon />
                </button>
              )}
              <button
                className="ModeEditbtn"
                onClick={() => handleOpen({ key: 'heading', step: 25, value: user?.myHeading })}
              >
                {user?.myHeading ? <ModeEditOutlineOutlinedIcon /> : <Tooltip title="Complete your profile for better matches!" arrow placement="top"> <AddCircleOutlineIcon sx={{ color: '#916008' }} /></Tooltip>}
              </button>
            </div>
          </div>
          <p className="color7b font-16 font-m">{user?.myHeading || "Not available"}</p>
        </div>

        {/* About Me Section */}
        <div className="border-top py-20">
          <div className="d-flex align-items-center justify-content-between color08 font-16 fw-medium">
            About Me{' '}
            <div>
              {user?.aboutUsDescription && (
                <button className="ModeEditbtn" style={{ color: 'rgb(231, 214, 187)' }}>
                  <CheckCircleOutlineIcon />
                </button>
              )}
              <button
                className="ModeEditbtn"
                onClick={() => handleOpen({ key: 'about', step: 26, value: user?.aboutUsDescription })}
              >
                {user?.aboutUsDescription ? <ModeEditOutlineOutlinedIcon /> : <Tooltip title="Complete your profile for better matches!" arrow placement="top"> <AddCircleOutlineIcon sx={{ color: '#916008' }} /></Tooltip>}
              </button>
            </div>
          </div>
          <p className="color7b font-16 font-m">{user?.aboutUsDescription || "Skipped"}</p>
        </div>

        {/* What Are You Looking for in a Partner? Section */}
        <div className="border-top py-20">
          <div className="d-flex align-items-center justify-content-between color08 font-16 fw-medium">
            What Are You Looking for in a Partner?{' '}
            <div>
              {user?.preferences?.aboutPartnerDescription && (
                <button className="ModeEditbtn" style={{ color: 'rgb(231, 214, 187)' }}>
                  <CheckCircleOutlineIcon />
                </button>
              )}
              <button
                className="ModeEditbtn"
                onClick={() =>
                  handleOpen({
                    key: 'partnerLookingFor',
                    step: 23,
                    value: user?.preferences?.aboutPartnerDescription,
                  })
                }
              >
                {user?.preferences?.aboutPartnerDescription ? <ModeEditOutlineOutlinedIcon /> : <Tooltip title="Complete your profile for better matches!" arrow placement="top"> <AddCircleOutlineIcon sx={{ color: '#916008' }} /></Tooltip>}
              </button>
            </div>
          </div>
          <p className="color7b font-16 font-m">
            {user?.preferences?.aboutPartnerDescription || "Skipped"}
          </p>
        </div>

        {/* Primary Location Section */}
        <div className="border-top py-20">
          <div className="d-flex align-items-center justify-content-between color08 font-16 fw-medium">
            <span className="d-flex align-items-center gap-1 PrimaryLocation">
              <LocationOnOutlinedIcon /> Primary Location
            </span>
            <div>
              {(user?.city || user?.state || user?.country) && (
                <button className="ModeEditbtn" style={{ color: 'rgb(231, 214, 187)' }}>
                  <CheckCircleOutlineIcon />
                </button>
              )}
              <button
                className="ModeEditbtn"
                onClick={() =>
                  handleOpen({
                    key: 'primaryLocation',
                    step: 9,
                    value: `${user?.city}, ${user?.state}, ${user?.country}`,
                  })
                }
              >
                {user?.city || user?.state || user?.country ? <ModeEditOutlineOutlinedIcon /> : <Tooltip title="Complete your profile for better matches!" arrow placement="top"> <AddCircleOutlineIcon sx={{ color: '#916008' }} /></Tooltip>}
              </button>
            </div>
          </div>
          <p className="color7b font-16 font-m">
            {user?.city || user?.state || user?.country
              ? `${user?.city}, ${user?.state}, ${user?.country}`
              : "Not available"}
          </p>
        </div>
      </div>

      <Modal className="AddiMdalSet" open={open} onClose={handleClose}>
        <Box className="toolmdalSet" sx={style}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: '9' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {selectedInfo?.label}
          </Typography>
          {renderModalContent()}
          <Box display="flex" justifyContent="flex-end" gap={2} sx={{ marginTop: '16px', }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ border: '1px solid rgb(145, 96, 8)', color: 'rgb(145, 96, 8)' }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => { handleSave() }}
              variant="contained"
              disabled={!isValidated}
              color="primary"
              sx={{
                background: 'linear-gradient(90deg, #916008, rgba(204, 153, 51, 1))',
                color: '#fff',
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicInformation;
