import React from 'react';
import { Skeleton, Box, Stack } from '@mui/material';

const ChatSkeleton = () => {
    return (
        <Stack direction="row" spacing={2} sx={{ width: '100%', height: '100vh', padding: 2 }}>
            {/* Sidebar Skeleton */}
            <Box className="SidebarSkton" sx={{ width: '17vw', minWidth: "17vw",maxWidth: '17vw', }}> 
                <Skeleton variant="rectangular" width={'100%'} height={50} />
                <Stack spacing={2} mt={2}>
                    {[...Array(5)].map((_, index) => (
                        <Stack direction="row" spacing={2} key={index}>
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="text" width={'60%'} height={30} />
                        </Stack>
                    ))}
                </Stack>
            </Box>
            
            {/* Chat Section Skeleton */}
            <Box className="MobDnSkton" sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',padding: '0 5vw', }}>
                {/* Chat Header */}
                <div className="chat-header-skeleton d-flex justify-content-between  align-items-center w-100" style={{padding: '0 0 2vw 0'}}>
                <div className='d-flex align-items-center w-100'>
                <Skeleton variant="circular" width={40} height={40} /> {/* Left Side Profile Image */}
                <div className="chat-header-text">
                    <Skeleton variant="text" width={120} height={20} /> {/* Name */}
                    <Skeleton variant="text" width={80} height={15} />  {/* Status */}
                </div>
                </div>
                <div className="chat-header-icons d-flex align-items-center">
                    <Skeleton variant="circular" width={30} height={30} />
                    <Skeleton variant="circular" width={30} height={30} />
                    <Skeleton variant="circular" width={30} height={30} />
                    <Skeleton variant="circular" width={30} height={30} />
                </div>
            </div>
                
                {/* Messages */}
                <Stack spacing={2} mt={2} sx={{ width: '100%' }}>
                    {[...Array(5)].map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width={'60%'} height={40} sx={{ alignSelf: index % 2 === 0 ? 'flex-start' : 'flex-end' }} />
                    ))}
                </Stack>
                
                {/* Chat Input Box */}
                <Skeleton variant="rectangular" width={'80%'} height={50} sx={{ mt: 'auto', mb: 2 }} />
            </Box>
        </Stack>
    );
};

export default ChatSkeleton