import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const signupSearchAPI = function (data) {
    const url = `${config.baseUrl}signup/search`;
    return postRequest({
        url,
        data,
    });
};
export const signupCreateAPI = function (data) {
    const url = `${config.baseUrl}auth/sign-up`;
    return postRequest({
        url,
        data,
    });
};

export const signupUpdateAPI = function ({
    signupId,
    data,
}) {
    const url = `${config.baseUrl}auth/update-account/${signupId}`;
    return putRequest({
        url,
        data,
    });
};
export const sendOTPAPI = function (data) {
    const url = `${config.baseUrl}auth/send-otp`;
    return postRequest({
        url,
        data,
    });
};
export const verifyOTPAPI = function (data) {
    const url = `${config.baseUrl}auth/verify-otp`;
    return putRequest({
        url,
        data,
    });
};
export const createPasswordAPI = function (data) {
    const url = `${config.baseUrl}auth/create-password`;
    return postRequest({
        url,
        data,
    });
};
export const updateSecurityQuestionAPI = function ({
    signupId,
    data,
}) {
    const url = `${config.baseUrl}auth/update-security-questions/${signupId}`;
    return putRequest({
        url,
        data,
    });
};
export const checkUsernameAvailableAPI = function (data) {
    const url = `${config.baseUrl}auth/check-username-available`;
    return postRequest({
        url,
        data,
    });
};
export const googleSignupAPI = function (data) {
    const url = `${config.baseUrl}auth/sign-in-google`;
    return postRequest({
        url,
        data,
    });
};
export const getQuestionAPI = function (email) {
    const url = `${config.baseUrl}auth/get-security-questions/${email}`;
    return getRequest({
        url
    });
};
export const checkSecurityQuestionAPI = function (data) {
    const url = `${config.baseUrl}auth/check-security-question`;
    return putRequest({
        url,
        data
    });
};