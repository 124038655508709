import { put, call } from 'redux-saga/effects';
import { getAccountSettingAPI, accountUpdateAPI, updatePrivatePicAccessAPI, getPrivatePicAccessAPI, removeAllPrivatePicAccessAPI, getHideMemberAPI, updateBlockedMemberAPI, unblockUnhideMemberAPI, getPlanListAPI } from 'src/api/AccountSettingApi'; 
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { ACCOUNT_SETTING_ACTIONS } from '../actions/AccountSettingActions';

function* search({ userId } = {}) {
    try {
        const { data: { data } } = yield call(getAccountSettingAPI, { userId });
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_SUCCESS,
            data,
        });
        return data;
    } catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_ERROR,
        });
        return null;
    }
}
function* update({ accountId, data }) {
    try {
        yield call(accountUpdateAPI, { accountId, data });
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_UPSERT_SUCCESS,
            data,
        });
        // Reload the data after update
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH,
            userId: accountId,
        });
    } catch (error) {
        console.error('LOG: update error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_UPSERT_ERROR,
        });
    }
}
function* getPrivatePicAccessList({ accountId }) {
    try {
        const { data: { data } } = yield call(getPrivatePicAccessAPI, { accountId });
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SET_PRIVATE_PIC_ACCESS,
            data,
        });
    } catch (error) {
        console.error('LOG: function*getPrivatePicAccessList -> error', error);
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_ERROR,
        });
    }
}
function* updatePrivatePicAccess({userId, accessId, data }) {
    try {
        yield call(updatePrivatePicAccessAPI, { accessId, data });
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_GET_PRIVATE_PIC_ACCESS,
            accountId: userId,
        });
    } catch (error) {
        console.error('LOG: function*updatePrivatePicAccess -> error', error);
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_ERROR,
        });
    }
}
function* removeAllPrivatePicAccess({ userId }) {
    try {
        yield call(removeAllPrivatePicAccessAPI, { userId });
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_GET_PRIVATE_PIC_ACCESS,
            accountId: userId,
        });
    } catch (error) {
        console.error('LOG: function*removeAllPrivatePicAccess -> error', error);
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_ERROR,
        });
    }
}
function* getRestrictedUsersProfiles({ userId,userType }){
    try {
        const getRestrictedUsersProfilesAPI = userType === 'blocked' ? updateBlockedMemberAPI : getHideMemberAPI;
        const { data: { data } } = yield call(getRestrictedUsersProfilesAPI, { userId });
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.SET_RESTRICTED_USERS,
            blockedMembers: userType === 'blocked' ? data : [],
            hideMembers: userType === 'hide' ? data : [],
        });
    } catch (error) {
        console.error('LOG: function*GetRestrictedUsersProfiles -> error', error);
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_ERROR,
        });
    }
}
function* removeRestriction({ activityId,userId, activityType }) {
    try {
        yield call(unblockUnhideMemberAPI, { activityId });
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.GET_RESTRICTED_USERS,
            userId: userId,
            userType: activityType,
        });
    } catch (error) {
        console.error('LOG: function*updateBlockedMember -> error', error);
        yield put({
            type: ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH_ERROR,
            data: error.message,
        });
    }
}
function* getPlanList(){
 try{
      const {data} = yield call(getPlanListAPI,{});
      yield put({
        type: ACCOUNT_SETTING_ACTIONS.SET_PLAN_LIST,
        data: data
    });
 }
 catch(error){

 }
}

export default [
    takeFirstSagaUtil(ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_SEARCH, search),
    takeFirstSagaUtil(ACCOUNT_SETTING_ACTIONS.ACCOUNT_SETTING_UPSERT, update),
    takeFirstSagaUtil(ACCOUNT_SETTING_ACTIONS.ACCOUNT_GET_PRIVATE_PIC_ACCESS, getPrivatePicAccessList),
    takeFirstSagaUtil(ACCOUNT_SETTING_ACTIONS.ACCOUNT_UPDATE_PRIVATE_PIC_ACCESS, updatePrivatePicAccess),
    takeFirstSagaUtil(ACCOUNT_SETTING_ACTIONS.REMOVE_ALL_PRIVATE_PIC_ACCESS, removeAllPrivatePicAccess),
    takeFirstSagaUtil(ACCOUNT_SETTING_ACTIONS.GET_RESTRICTED_USERS, getRestrictedUsersProfiles),
    takeFirstSagaUtil(ACCOUNT_SETTING_ACTIONS.SET_UNRESTRICTED_USERS, removeRestriction),
    takeFirstSagaUtil(ACCOUNT_SETTING_ACTIONS.GET_PLAN_LIST, getPlanList),
];
