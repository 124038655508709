import React, { useState, useEffect } from "react";
import RangeSlider from "./RangeSlider";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setFilterAction } from "src/containers/member-container/actions/MemberActions";
import { initGoogleMaps } from "src/utils/GoogleMapsLoader";

const LocationFilter = () => {
  const dispatch = useDispatch();
  const { currentFilter, currentFilter: { isOtherLocation }} = useSelector((state) => state.MemberReducer, shallowEqual);
  const { currentLocation } = useSelector((state) => state.HomeReducer, shallowEqual);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(currentFilter.location.country?`${currentFilter.location.city}, ${currentFilter.location.state}, ${currentFilter.location.country}`:'');
  const [otherLocationCoordinates, setOtherLocationCoordinates] = useState({ longitude: 0, latitude: 0, country: '', state: '', city: '' });
  const [locationPayload, setLocationPayload] = useState(currentFilter.location);
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const [isOtherLocationSelected, setIsOtherLocationSelected] = useState(false);

  useEffect(() => {
    initGoogleMaps().then(() => {
      setIsGoogleLoaded(true);
    });
  }, []);

  const handleLocationChange = (event) => {
    const { value } = event.target;
    const isOtherLocation = value === "other";
    dispatch(setFilterAction({ data: { isOtherLocation } }));
    if(!isOtherLocation) {
      setLocationPayload({
        longitude:currentLocation.currentLocation.coordinates[0],
        latitude:currentLocation.currentLocation.coordinates[1],
        country:currentLocation.country,
        state:currentLocation.state,
        city:currentLocation.city
      });
    }
    else {
      setLocationPayload({
        longitude:otherLocationCoordinates.longitude,
        latitude:otherLocationCoordinates.latitude,
        country:otherLocationCoordinates.country,
        state:otherLocationCoordinates.state,
        city:otherLocationCoordinates.city
      });
    }
  };

  const handleOtherLocationChange = (event, newValue) => {
    if (newValue && isGoogleLoaded && window.google) {
      const placeId = newValue.place_id;
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: placeId }, (results, status) => {
        if (status === 'OK') {
          const place = results[0];
          const addressComponents = place.address_components;
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();

          const country = addressComponents.find((component) =>
            component.types.includes('country')
          )?.long_name;
          const state = addressComponents.find((component) =>
            component.types.includes('administrative_area_level_1')
          )?.long_name;
          const city = addressComponents.find((component) =>
            component.types.includes('locality')
          )?.long_name;

          setInputValue(`${city}, ${state}, ${country}`);
          setOtherLocationCoordinates({ longitude, latitude, country, state, city });
          setLocationPayload({
            longitude,
            latitude,
            country,
            state,
            city
          });
        }
      });
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 2 && isGoogleLoaded && window.google) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        { input: newInputValue },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleDistanceChange = (newValue) => {
    dispatch(setFilterAction({ data: { maxDistance: parseInt(newValue) } }));
  };
  useEffect(() => {
    dispatch(setFilterAction({ data: { location: locationPayload } }));
  }, [locationPayload]);
  useEffect(() => {
    setIsOtherLocationSelected(isOtherLocation);
    setInputValue(currentFilter.location.country?`${currentFilter.location.city}, ${currentFilter.location.state}, ${currentFilter.location.country}`:'');
  }, [isOtherLocation]);

  return (
    <div className="filter-section d-flex flex-column align-items-start justify-content-between gap-15">
      <div className="font-16 color1a fw-medium font-m samefont">Location</div>
      <div className="d-flex flex-column align-items-start justify-content-between gap-15">
        <label className="radioinput">{currentLocation.city}
          <input type="radio" checked={!isOtherLocationSelected} value={currentLocation.city} name="location" onClick={(e)=>{handleLocationChange(e)}} />
          <span className="checkmark" />
        </label>
        <label className="radioinput">Other Locations
          <input type="radio" value="other" checked={isOtherLocationSelected} name="location" onClick={(e)=>{handleLocationChange(e)}} />
          <span className="checkmark" />
        </label>
      </div>
      {isOtherLocationSelected && (
        <div className="searchCity my-16 w-100">
          <Autocomplete
            freeSolo
            options={suggestions}
            getOptionLabel={(option) => option.description || ''}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onChange={handleOtherLocationChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search for a location..."
                variant="outlined"
                fullWidth
                className="filter-input"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: '8px',
                  height: "44px",
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#916008',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#916008',
                    },
                    '& .MuiOutlinedInput-input': {
                      height: '11px',
                    },
                  },
                }}
              />
            )}
          />
          {isOtherLocation && inputValue.length<3 && <div className="text-danger">location is required</div>}
        </div>
      )}
      <RangeSlider handleDistanceChange={handleDistanceChange} maxDistance={currentFilter.maxDistance} />
    </div>
  );
};
export default LocationFilter;