import { Box, Button, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import SharingPrivatePhotos from '../../Component/PermissionsSettings/SharingPrivatePhotos';
import SwitchComp from '../../Component/PermissionsSettings/SwitchComp';
import { AccountGetPrivatePicAccessAction, RemoveAllPrivatePicAccessAction } from 'src/containers/account-setting/actions/AccountSettingActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const MultimediaAndVoicePermissions = ({ currentRecord, handleUpdate }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth, shallowEqual);
    const { privatePicAccess } = useSelector((state) => state.AccountSettingReducer, shallowEqual);
    const { multimediaAndVoicePermissions: { voiceSettings, photoSettings, videoSettings, otherMultimediaSettings } } = currentRecord

    const handelRemoveAccessAll = () => {
        dispatch(RemoveAllPrivatePicAccessAction({ userId: user._id }));
    }
    useEffect(() => {
        dispatch(AccountGetPrivatePicAccessAction({ accountId: user?._id }))
    }, [dispatch])
    const profile = {
        image: '/assets/images/user/img30.png',
        name: 'John Doe',
        location: 'New York, USA',
        description: 'Loves photography and traveling.',
    };

    return (
        <div>
            <Typography variant="h6" className="ac-heading" gutterBottom sx={{ fontWeight: '500', color: '#081726', fontFamily: 'ivypresto-headline', }}>
                Multimedia and Voice Permissions
            </Typography>

            <Box className="border001vw mtopBottom05 p1vw" component={Paper} sx={{ boxShadow: 'none' }}>
                <Typography variant="h6" className="font875vw" gutterBottom sx={{ fontSize: '20px', fontWeight: '500', color: '#081726', fontFamily: 'indivisible' }}>
                    Voice Settings
                </Typography>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Receive voice messages </Typography>
                    <SwitchComp status={voiceSettings.receiveVoiceMessages}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'receiveVoiceMessages', event.target.checked, 'voiceSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontSize: '14px', fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Voice note playback</Typography>
                    <SwitchComp status={voiceSettings.voiceNotePlayback}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'voiceNotePlayback', event.target.checked, 'voiceSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontSize: '14px', fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Voice chat requests </Typography>
                    <SwitchComp status={voiceSettings.voiceChatRequests}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'voiceChatRequests', event.target.checked, 'voiceSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontSize: '14px', fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}> Set incoming ringtone</Typography>
                    <SwitchComp status={voiceSettings.setIncomingRingtone}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'setIncomingRingtone', event.target.checked, 'voiceSettings')
                            }
                        }
                    />
                </Box>

            </Box>

            <Box className="border001vw mtopBottom05 p1vw" component={Paper} sx={{ padding: "20px", border: '1px solid #ccc', margin: '10px 0', boxShadow: 'none' }}>
                <Typography className="font875vw" variant="h6" gutterBottom sx={{ fontWeight: '500', color: '#081726', fontFamily: 'indivisible' }}>
                    Photo Settings
                </Typography>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Photo sharing</Typography>
                    <SwitchComp status={photoSettings.photoSharing}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'photoSharing', event.target.checked, 'photoSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontSize: '14px', fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Profile photo visibility</Typography>
                    <SwitchComp status={photoSettings.profilePhotoVisibility}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'profilePhotoVisibility', event.target.checked, 'photoSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontSize: '14px', fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Photo auto-download</Typography>
                    <SwitchComp status={photoSettings.photoAutoDownload}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'photoAutoDownload', event.target.checked, 'photoSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontSize: '14px', fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Private photo access </Typography>
                    <SwitchComp status={photoSettings.privatePhotoAccess}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'privatePhotoAccess', event.target.checked, 'photoSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05  divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontSize: '14px', fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Private photo sharing with </Typography>
                    <SwitchComp status={photoSettings.privatePhotoSharingWith}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'privatePhotoSharingWith', event.target.checked, 'photoSettings')
                            }
                        }
                    />
                </Box>

            </Box>

            <Box component={Paper} className="border001vw mtopBottom05 p1vw" sx={{ boxShadow: 'none' }}>
                <Typography variant="h6" className="font875vw" gutterBottom sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>
                    Video Settings
                </Typography>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                    }}
                >
                    <Typography className="font08vw" sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Video sharing</Typography>
                    <SwitchComp status={videoSettings.videoSharing}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'videoSharing', event.target.checked, 'videoSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                    }}
                >
                    <Typography className="font08vw" sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Video auto-playback </Typography>
                    <SwitchComp status={videoSettings.videoAutoPlayback}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'videoAutoPlayback', event.target.checked, 'videoSettings')
                            }
                        }
                    />
                </Box>
                <Box className="mtopBottom05 divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                    }}
                >
                    <Typography className="font08vw" sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Video call permissions</Typography>
                    <SwitchComp status={videoSettings.videoCallPermissions}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'videoCallPermissions', event.target.checked, 'videoSettings')
                            }
                        }
                    />
                </Box>

            </Box>


            <Box component={Paper} className="border001vw mtopBottom05 p1vw" sx={{ boxShadow: 'none' }}>
                <Typography variant="h6" className="font875vw" gutterBottom sx={{ fontSize: '16px', fontWeight: '500', color: '#081726', fontFamily: 'indivisible' }}>Other Multimedia Settings</Typography>
                <Box className="divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>File sharing</Typography>
                    <SwitchComp status={otherMultimediaSettings.fileSharing}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'fileSharing', event.target.checked, 'otherMultimediaSettings')
                            }
                        }
                    />
                </Box>
                <Box className="divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Multimedia preview</Typography>
                    <SwitchComp status={otherMultimediaSettings.multimediaPreview}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'multimediaPreview', event.target.checked, 'otherMultimediaSettings')
                            }
                        }
                    />
                </Box>
                <Box className="divflxset"
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography className="font08vw" sx={{ fontWeight: '400', color: '#081726', fontFamily: 'indivisible' }}>Storage permissions</Typography>
                    <SwitchComp status={otherMultimediaSettings.storagePermissions}
                        onChange={
                            (event) => {
                                event.stopPropagation();
                                handleUpdate('multimediaAndVoicePermissions', 'storagePermissions', event.target.checked, 'otherMultimediaSettings')
                            }
                        }
                    />
                </Box>
            </Box>
            {privatePicAccess?.length > 0 ? (
                <Box className="mtopBottom05">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: '25px 0 10px 0'
                        }}
                    >
                        <Typography className="font875vw"
                            variant="h6"
                            gutterBottom
                            sx={{ fontSize: '24px', fontWeight: '400', color: '#000', fontFamily: 'indivisible' }}
                        >
                            Sharing Private Photos with
                        </Typography>
                        <Button
                            variant="outlined"
                            className="d-flex align-items-center gap05vw font08vw"
                            onClick={handelRemoveAccessAll}
                            sx={{
                                border: '0.01vw solid #916008',

                                color: '#916008',
                                borderRadius: '50vw',
                                padding: '0.5vw 1vw',
                                textTransform: 'capitalize',
                                fontFamily: 'indivisible'
                            }}
                        >
                            <img src="/assets/images/hideb.svg" className="img-fluid" alt="" /> Hide All Photos
                        </Button>
                    </Box>
                    {privatePicAccess.map((item, index) => (
                        <SharingPrivatePhotos
                            access={item}
                            profile={item.targetUserId}
                            key={index}
                            user={user}
                        />
                    ))}
                </Box>
            ) : (
                <div className="border001vw rounded-4 p1vw text-center">
                    <h4 className='ac-heading' style={{fontFamily: 'ivypresto-headline',}}>You haven't shared any private photos. </h4>
                    <p className='font08vw'>
                        Members that you have shared private photos with will display here.
                    </p>
                </div>
            )}

        </div>
    )
}

export default MultimediaAndVoicePermissions
