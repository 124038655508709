import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { memberUpsertAction } from "src/containers/member-container/actions/MemberActions";
import { setPendingAction } from "src/containers/chat-container/actions/ChatActions";
import { Box, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { signupUpsertAction } from "src/containers/signup-container/actions/SignupActions";
import { getAuthInfoAction } from "src/containers/auth-container/actions/AuthActions";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Tooltip } from "react-tooltip";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 4,
};

const ProfileCard = ({ profile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { socket, selectedUser, roomId } = useSelector((state) => state.ChatReducer, shallowEqual);
  const isLiked = profile.activity_logs.find((log) => log.action === 'LIKE');
  const [isFavorite, setIsFavorite] = useState(isLiked || false); // State to toggle heart icon
  const [isVisible, setIsVisible] = useState(true); // State to manage visibility
  const [isHiding, setIsHiding] = useState(false); // State to trigger animation
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    dispatch(getAuthInfoAction());
  }, [dispatch]);
  const handleAction = (action) => {
    if (action === 'hide') {
      dispatch(memberUpsertAction({ action: 'hide', _id: user._id, data: { targetUserId: profile.userId, action: 'HIDE' } }));
      setIsHiding(true); // Trigger the zoom-out animation
      setTimeout(() => setIsVisible(false), 300); // Remove the component after animation
    }
    if (action === 'favorite') {
      const actionType = isFavorite ? 'UNLIKE' : 'LIKE';
      dispatch(memberUpsertAction({ action: actionType, _id: user._id, data: { targetUserId: profile.userId, action: actionType } }));
      setIsFavorite(!isFavorite);
    }
  }
  if (!isVisible) return null; // Do not render if not visible
  const handleInitChat = () => {
    const modifiedProfile = { ...profile, _id: profile.userId };//Modify the profile object coz into this _id eating id locationId and further i need userId into _id
    dispatch(setPendingAction({ data: { user: modifiedProfile, text: null } }))
    localStorage.removeItem('chatRoomId');
    navigate('/chat');
  }
  const handleHideConfirm = () => {
    const payload = { _id: user._id, data: { step: 17, isInternal: true, accountUpdatePayload: { isDisableConfirmation: true } } }
    dispatch(signupUpsertAction(payload));
    handleAction('hide');
    handleClose();
  }
  const truncateText = (text, charLimit = 12) => {
    if (!text) return "";
    return text.length > charLimit ? text.substring(0, charLimit) + "..." : text;
  };

  return (
    <div className={`profile-card ${isHiding ? "hide" : ""}`}>
      <div className="userProfileimg">
        <Link to={`/member-details/${profile.userId}`} className="profiuserimg">
          <img src={profile.profilePicture} alt={profile.userName} className="profile-image" /> <div className="iconsUser">
            <div className="d-flex align-items-start flex-column profile-details ps-0">
              <span className="newHereUser">New Here</span>
              <div>
                <div className="d-flex align-items-center"> {/*username */}
                  <div className="text-white font-16 font-m fw-medium d-flex align-items-center gap-1">
                    <span className="nameUser username">{truncateText(profile.userName)}</span>,
                    <span className="ageUser">{profile.age}</span>
                    <span className="status">
                      {profile.isOnline && (
                        <span className="d-flex align-items-center font-10 font-m gap-1">
                          <CircleIcon sx={{ color: "#34A853", height: "10px", width: "10px" }} />
                        </span>
                      )}
                    </span></div><span className="veryfiedIcnc"><img src="/assets/images/icons/check-blue.svg" className="img-fluid" alt="" /></span></div>
              </div>
              <div>

                <div className="text-white font-m fw-normal statecity d-flex align-items-center gap-1">
                  <span className="state">{profile.state},</span><span className="city">{profile.city}</span>,
                  <span className="distance">{profile.distance === 0 ? 1 : profile.distance} {user?.preferredMeasurement ? "km" : "mile"}</span>
                </div>

              </div>
              <div className="">
                <ul className="d-flex align-items-center gap-1 list-unstyled mb-0">
                  <li>
                    <img
                      src="/assets/images/icons/insta-icon.svg"
                      className="img-fluid"
                      alt="Instagram"
                      data-tooltip-id="tooltip-insta"
                    />
                    {/* <Tooltip id="tooltip-insta" place="top" content="Instagram" /> */}
                  </li>

                  <li>
                    <img
                      src="/assets/images/icons/fb-icon.svg"
                      className="img-fluid"
                      alt="Facebook"
                      data-tooltip-id="tooltip-fb"
                    />
                    {/* <Tooltip id="tooltip-fb" place="top" content="Facebook" /> */}
                  </li>

                  <li>
                    <img
                      src="/assets/images/icons/linkedin.svg"
                      className="img-fluid"
                      alt="LinkedIn"
                      data-tooltip-id="tooltip-linkedin"
                    />
                    {/* <Tooltip id="tooltip-linkedin" place="top" content="LinkedIn" /> */}
                  </li>

                  <li>
                    <img
                      src="/assets/images/icons/selfie.svg"
                      className="img-fluid"
                      alt="Selfie"
                      data-tooltip-id="tooltip-selfie"
                    />
                    {/* <Tooltip id="tooltip-selfie" place="top" content="Selfie Verification" /> */}
                  </li>
                </ul>
              </div>
            </div>
            {/* {
            profile?.publicPhotos?.length > 0 && (
              <span className="picCouner">
                <img
                  src="/assets/images/user/profileimg.svg"
                  className="img-fluid"
                  alt="profile icon"
                />{" "}
                {profile.publicPhotos.length}
              </span>
            )
          } */}
          </div>
        </Link>
        <div className="d-flex leftbuttons flex-column DynamicHeart">
          {/* Dynamic Heart Icon with Active Class */}
          <button
            className={`border-0 FavoriteIcon ${isFavorite ? "active" : ""}`}
            onClick={() => handleAction('favorite')}
          >
            <img
              src={
                isFavorite
                  ? "/assets/images/user/heart-iconr.svg" // Path when favorited
                  : "/assets/images/user/heart-iconb.svg" // Default path
              }
              className="img-fluid"
              alt="heart icon"
            />
          </button>
          <button className="border-0 ClearIcon" onClick={user.isDisableConfirmation ? () => handleAction('hide') : handleOpen}> <img
            src="/assets/images/user/cross-icon.svg"
            className="img-fluid"
            alt="clear icon"
          />
          </button>
          {/* <button className="border-0 ClearIcon" >
             
            </button> */}
          <button className="bg-none border-0 InsertCommentOutlinedIcon" onClick={handleInitChat}>
            <img
              src="/assets/images/user/chat-icon.svg"
              className="img-fluid"
              alt="comment icon"
            />
          </button>
        </div>

        {/* Diamond and Gold Buttons */}
        <div className="DimondGold">
          {profile.isDiamond && (
            <Button className="Dimond">
              <img src="/assets/images/user/user-profile/dimond.svg" alt="diamond icon" /> Dimond
            </Button>
          )}
          {profile.isGold && (
            <Button className="Gold">Gold</Button>
          )}
          {
            profile?.publicPhotos?.length > 0 && (
              <span className="picCouner text-white">
                <CameraAltIcon />{" "}
                {profile.publicPhotos.length}
              </span>
            )
          }
        </div>
        <div>
          {/* <div
            className="text-black font-12 font-m fw-medium miles"
          >
            {profile.distance===0?1:profile.distance} {user?.preferredMeasurement? "km" : "mile"}
            <div className="text-black font-10 font-m fw-normal statecity">
              {profile.state},{profile.city}
            </div>
          </div> */}
        </div>
      </div>




      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="respWSet"
      >
        <Box className="modalsetM0"
          sx={{
            ...style,
            maxHeight: '80vh', // Adjust as needed
            overflowY: 'auto', // Enables vertical scroll
          }}
        >
          <Box className="d-flex modalsetM1 align-items-start justify-content-center">
            <div
              className="modal-title modalsetM1_1 text-black text-center font-32 fw-medium"
              id="exampleModalLabel"
            >
              Are you sure you want to hide this member?
            </div>
          </Box>
          <p className="modal-title text-center text-color3c font-14 fw-normal mb-16 mt-10">
            This member will no longer appear in your main view.
          </p>
          <p className="modal-title text-center text-color3c font-14 fw-normal">
            You can manage hidden members in your
            <span className="color3c d-block fw-bold">Account Settings &gt; Hidden Members section.</span>
          </p>
          <div className="d-flex flex-column justify-content-between text-center gap-2 mt-32 popup-actions">
            <Button className="modalsetM4_1" onClick={() => handleHideConfirm()}
              variant="contained"
              sx={{
                width: '100%', position: 'sticky', bottom: '0',
                backgroundColor: '#916008',
                padding: '10px',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': { backgroundColor: '#9d6f3b' },
              }}
            >
              Hide Member
            </Button>
            <Button className="modalsetM4_1" onClick={handleClose}
              variant="contained"
              sx={{
                maxWidth: '120px', width: '100%', margin: '0 auto', position: 'sticky', bottom: '0',
                backgroundColor: '#fff',
                border: '1px solid #081726',
                padding: '10px', boxShadow: 'none',
                color: '#081726',
                textTransform: 'capitalize',
                '&:hover': { backgroundColor: '#9d6f3b', color: '#fff', border: '1px solid #9d6f3b', },
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ProfileCard;
