import { takeLatest, put, call, take, fork, } from 'redux-saga/effects';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from './authActionTypes';
import { setUserDetails } from '../actions/authAction';
import { AUTH_ACTIONS } from 'src/containers/auth-container/actions/AuthActions';
import { fetchUserDetailsAPI, fetchUserScopesAPI, userSearchAPI } from 'src/api/UserApi';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';

function* loginSuccessSaga(action) {
    yield put(setUserDetails(action.payload.user));
}

function* logoutSaga() {
    yield put(setUserDetails(null));
}

function* authSaga() {
    yield takeLatest(LOGIN_SUCCESS, loginSuccessSaga);
    yield takeLatest(LOGOUT, logoutSaga);
}


export function* fetchUserScopes({
    userId,
    roleId
}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(fetchUserScopesAPI, {
            userId,
            roleId
        });

        yield put({
            type: AUTH_ACTIONS.FETCH_PERMISSION_SCOPES_SUCCESS,
            payload: {
                userScopes: data,
            },
        });
    }
    catch (error) {
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
    }
}

export function* fetchUserDetails({
    userId,
}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(fetchUserDetailsAPI, {
            userId,
        });
        yield put({
            type: AUTH_ACTIONS.FETCH_USER_DETAILS_SUCCESS,
            payload: {
                userDetails: data,
            },
        });


    }
    catch (error) {
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        console.error('LoginSaga', error);

        yield put({
            type: AUTH_ACTIONS.LOGIN_ERROR,
        });
    }
}
function* getAuthInfo() {
    try {
        const response = yield call(userSearchAPI);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        yield put({
            type: AUTH_ACTIONS.SET_AUTH_INFO,
            data: response.data.data,
        });
    }
    catch (error) {
        console.log('error', error); 
        yield put(headerShowAction({
            data: error.data,
        }));

        // yield put({
        //     type: AUTH_ACTIONS.LOGIN_ERROR,
        // });
    }
}

export default [
    takeFirstSagaUtil(AUTH_ACTIONS.FETCH_USER_DETAILS, fetchUserDetails),
    takeFirstSagaUtil(AUTH_ACTIONS.FETCH_PERMISSION_SCOPES, fetchUserScopes),
    authSaga,
    takeFirstSagaUtil(AUTH_ACTIONS.GET_AUTH_INFO, getAuthInfo),
];

