import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const HaveChildren = () => {
  const dispatch = useDispatch();
  const {
    children,
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [haveChildren, setHaveChildren] = useState(children || "");

  // Define the options dynamically
  const options = [
    "Yes",
    "No",
    "Prefer not to say"
  ];

  // const handleHaveChildren = (value) => {
  //   setHaveChildren(value);
  //   dispatch(setPayload({ data: { children: value } }));
  // };

  
 const handleHaveChildren = (value) => {
  // If the same option is clicked again, unselect it
  const newValue = haveChildren === value ? "" : value;
  setHaveChildren(newValue);
  dispatch(setPayload({ data: { children: newValue } }));
};


  return (
    <div>
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Do You Have Children? </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Let us know if you have children to help us find matches with similar family values and preferences.
        </div>
      </div>
      <div className="HaveChildren-selection text-center mt-32 mb-16">
        {/* <h3 className="font-18 font-m fw-medium mt-24 mb-16">Select Your Option</h3> */}
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render buttons */}
          {options.map((option, index) => (
            <button
              key={index}
              className={`font-m border font-16 fw-normal ${haveChildren === option ? "selected" : ""}`}
              onClick={() => handleHaveChildren(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HaveChildren