import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const EmptyChatStatus = () => {
  return (
    <div className="chatbox EmptyChatStatus">
    <div className="modal-dialog-scrollable">
        <div className="modal-content">  
        <div className="modal-body bg-EB">
        <div className="select-message-box" style={{display: 'flex',justifyContent: 'center', flexDirection: 'column',alignItems: 'center', height: '100%',}}> 
          <Link to="/members" style={{background: '#916008',textDecoration: 'none', color: '#fff', fontWeight: '600', fontSize: '0.875vw', fontFamily: 'indivisible', textTransform: 'capitalize', padding: '0.6vw 4vw'}} data-bs-toggle="modal" data-bs-target="#new-chat">
           Find Members
          </Link>
        </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default EmptyChatStatus;
