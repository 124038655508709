import React, { useState,useEffect } from "react";
import { Box, Slider, Typography } from '@mui/material';
import { shallowEqual, useSelector } from "react-redux";
const RangeSlider = ({handleDistanceChange,maxDistance}) => {
  const [rangeValue, setRangeValue] = useState(maxDistance); // Initial range value
  const user = useSelector((state) => state.auth.user,shallowEqual);

  const handleRangeChange = (e) => {
    setRangeValue(e.target.value);
    handleDistanceChange(e.target.value);
  };
  useEffect(()=>{
    setRangeValue(maxDistance);
  },[maxDistance])

  return (
    <div className="range-slider w-100">
      <label className="range-label d-flex justify-content-between align-items-center fw-medium">
      Maximum Distance <span className="fw-bold">{rangeValue} {user?.preferredMeasurement? "km" : "mile"}</span>
      </label>
      {/* <input
        type="range"
        min="0"
        max="150"
        value={rangeValue}
        onChange={handleRangeChange}
         
        style={{
          background: `linear-gradient(to right, #916008 ${rangeValue / 1.5}%, #e4e4e4 ${rangeValue / 1.5}%)`,
          width: "100%",
          appearance: "none",
          height: "6px",
          borderRadius: "3px",
          outline: "none",
        }}
      /> */}


  <Slider  type="range"
          className="sldrsetup"
          value={rangeValue}
        onChange={handleRangeChange}
          valueLabelDisplay="auto"
          min={0}
          max={1500} 
          sx={{

            
            width: "100%",
            appearance: "none",
            height: "6px",
            borderRadius: "3px",
            outline: "none",
            // Main color of the slider
            color: '#916008',

            // Styles for the track (filled area of the slider)
            '& .MuiSlider-track': {
              backgroundColor: '#916008', // Track color
              height: '7px',
            },

            // Styles for the thumb (the draggable circle)
            '& .MuiSlider-thumb': {
              backgroundColor: '#BF8500', // Thumb color 
              '&:hover': {
                backgroundColor: '#734f05', // Thumb color on hover
              },
              '&.Mui-active': {
                backgroundColor: '#a07511', // Thumb color when active (dragging)
              },
            },

            // Styles for the rail (unfilled area of the slider)
            '& .MuiSlider-rail': {
              backgroundColor: '#d9d9d9', // Rail color
              height: '7px',
            },

            // Styles for the value label (popup displaying current value)
            '& .MuiSlider-valueLabel': {
              backgroundColor: '#916008', // Value label background color
              color: '#fff',              // Value label text color
            },
          }}
        />

    </div>
  );
};

export default RangeSlider