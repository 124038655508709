import * as React from 'react';
import { styled } from '@mui/material/styles'; 
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import EastIcon from '@mui/icons-material/East';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary {...props} />
  ))(({ theme, active }) => ({
    backgroundColor: active 
      ? '#fff' // Change to active color when expanded
      : theme.palette.mode === 'dark' ? '#fff' : '#fff', // Default background
    color: active ? '#861D1D' : '#000', // Change text color when active
    flexDirection: 'row-reverse', 
    [`& .MuiAccordionSummary-content`]: {
      marginLeft: theme.spacing(1),
      fontSize: '1.25vw', // Set the font size of the accordion heading
      transition: 'font-size 0.3s ease', // Smooth transition for font size
    },
    [`& .css-rizt0-MuiTypography-root`]: {
        fontSize: '2vw',
        textTransform: 'uppercase',
        fontWeight: '900',
        fontFamily: 'indivisible',
    },
    '&:hover .MuiAccordionSummary-content': {
      fontSize: '1.25vw', // Increase font size on hover for the heading
      fontWeight: 'bold', // Optional: make the text bold on hover
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: 'none',
    [`& .MuiTypography-root`]: {
      fontSize: '1.25vw', // Set the font size of the accordion content
    },
  }));

const accordionData = [
  {
    panel: 'panel1',
    title: 'Privacy & Trust',
    content: `While traditional platforms with fake profiles and privacy issues,
              Luxury Life ensures trust, while keeping your data confidential and authenticity through verified profiles, creating a safe space for genuine connections.`,
    image: '/assets/images/home/provacy-and-trust.png',
  },
  {
    panel: 'panel2',
    title: 'Meaningful Likes',
    content: `Endless swiping on traditional apps wastes time, but Luxury Life uses AI-driven matching to deliver curated connections based on shared preferences, saving you time and delivering real compatibility.`,
    image: '/assets/images/home/meaninfgull-links.png',
  },
  {
    panel: 'panel3',
    title: 'Finding Like-Minded Partners',
    content: `While traditional platforms struggle with fake profiles and privacy issues,
              Luxury Life ensures trust, while keeping your data confidential and authenticity through verified profiles, creating a safe space for genuine connections.`,
    image: '/assets/images/home/finding-like.png',
  }
];

const FindAccordion = () => {
  const [expanded, setExpanded] = React.useState('panel1'); // Default the expanded state to 'panel1'
  const [activeImage, setActiveImage] = React.useState(1); // Track which image to show

  const handleChange = (panel, imageIndex) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setActiveImage(newExpanded ? imageIndex : 1); // Update active image index when accordion is expanded
  };

  return (
    <div className="d-flex justify-content-between AccordionNewHomeFindbg">
      <div className="AccordionNewImgFind w-100 h-100">
        {/* Show images conditionally based on activeImage state */}
        {accordionData.map((accordion, index) => (
          activeImage === index + 1 && (
            <span key={accordion.panel} className="active">
              <img src={accordion.image} className="img-fluid" alt={accordion.title} />
            </span>
          )
        ))}
      </div>

      <div className="AccordionNewHomeFind w-100 d-flex justify-content-between flex-column align-items-start">
        <div className="AccordionNewHomeFind">
          {accordionData.map((accordion, index) => (
            <Accordion className="findAccTitlebg"
              key={accordion.panel} 
              expanded={expanded === accordion.panel} 
              onChange={handleChange(accordion.panel, index + 1)}
            >
              <AccordionSummary  className="findAccTitle"
                aria-controls={`${accordion.panel}-content`} 
                id={`${accordion.panel}-header`} 
                active={expanded === accordion.panel} // Pass active prop
              >
                <Typography component="span">{accordion.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {accordion.content}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <div className='h-100 d-flex justify-content-end align-items-end'>
        <button className="btn-output fw-bold rounded-pill d-flex justify-content-start gap-3 text-nowrap mt-5vh mb-0 btnJoinus position-sticky bottom-0 z-3">
          Join the Exclusive Community <EastIcon />
        </button>
        </div>
      </div>
    </div>
  );
};

export default FindAccordion;
