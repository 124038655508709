import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import SelfieVerificationComponent from '../selfie-verification/index';
import { getAuthInfoAction } from 'src/containers/auth-container/actions/AuthActions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '700px',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '90%',
    overflow: "auto",
    p: '30px 30px 0 30px',
    borderRadius: '20px',
};
const SelfieVerification = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleOpen = (type) => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };
    const handleVerificationButtonClick = () => {
        // Increment the step for photo verification
        setCurrentStep(currentStep + 1);
        // if (currentStep === 1) {
        //     setCurrentStep(4);
        // } else {
        //     setCurrentStep(currentStep + 1);
        // }
    };
    const handleContinue = () => {
        dispatch(getAuthInfoAction());
        navigate('/members');
    }
    return (
        <>
            <Grid item xs={6} md={6}>
                <Card className="h-100 d-flex flex-column justify-content-between align-items-center p-20 border" sx={{ boxShadow: 'none' }}>
                    <div>
                        <CardMedia
                            component="img"
                            height="140"
                            image="/assets/images/account-settings/photo-verifications.png"
                            alt="Photo Verification"
                            sx={{ objectFit: 'contain' }}
                        />
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography
                                gutterBottom
                                component="div"
                                sx={{ fontSize: '32px', fontWeight: '500', color: '#081726', fontFamily: 'indivisible' }}
                            >
                                Selfie Verification
                            </Typography>
                            <Box>
                                <Typography
                                    variant="body2"
                                    sx={{ fontSize: '14px', fontWeight: '500', color: '#081726', fontFamily: 'indivisible' }}
                                >
                                    For users who prefer a quicker, no-cost verification, we also offer selfie verification. This process requires you to upload a selfie that matches your profile picture. We use advanced technology to confirm the authenticity of your image and ensure that your profile is genuine. This option is easy to complete and enhances the overall safety of your interactions.
                                </Typography>
                                <Typography
                                    sx={{ fontSize: '14px', fontStyle: 'italic', marginTop: '24px', fontWeight: '500', color: '#7B7B7B', fontFamily: 'indivisible' }}
                                >
                                    (Note: Quick, easy, and free to verify profile authenticity instantly.)
                                </Typography>
                            </Box>

                            <div className='font-14 fw-normal color7b font-m text-center py-24'>
                                <div className='d-flex justify-content-center flex-column align-items-center gap-1 font-16 fw-bold colorc3'><span className='d-block'><img src="/assets/images/verified-icon.svg" className='img-fluid' /></span> Verified
                                    <p style={{ fontSize: '14px', fontWeight: '500', color: '#081726', fontFamily: 'indivisible' }}>Your profile is trusted and ready to connect with others.</p>
                                </div>
                            </div>
                        </CardContent>
                    </div>
                    <CardActions>
                        <Button
                            size="small"
                            onClick={() => handleOpen('photo')}
                            className="fillBtn2 px-20 py-10 mx-auto"
                            sx={{ fontSize: '12px', fontWeight: '400', color: '#7B7B7B', fontFamily: 'indivisible' }}
                        >
                            Start Verification
                        </Button>
                    </CardActions>
                    <CardActions>
                        <button onClick={handleContinue}
                            className='w-100 border-0 text-decoration-underline bg-white font-16 font-m fw-medium skipbtn'
                        >
                        Do it Later
                        </button>
                    </CardActions>
                </Card>
            </Grid>

            <Modal className="SelfieVerificationModal" open={modalOpen} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="motoolModl" sx={style}>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton onClick={handleModalClose} sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: '9' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {/* Conditional rendering based on the selected verification type */}
                    {/* <SelfieVerification currentStep={currentStep} setCurrentStep={setCurrentStep} />  */}
                    <SelfieVerificationComponent currentStep={currentStep} setCurrentStep={setCurrentStep} source="signup" />
                    {/* Button Section */}
                    {
                        currentStep < 3 && (
                            <Box textAlign="center" marginTop="20px" className="position-sticky bottom-0 py-20 bg-white z-3">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleVerificationButtonClick}
                                    size="large"
                                    sx={{
                                        width: '100%',
                                        background: '#916008',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {currentStep === 0
                                        ? 'Upload Your Profile Photo'
                                        : currentStep === 1
                                            ? 'How It Works'
                                            : currentStep === 2
                                                ? 'Allow Camera Access'
                                                : ''}
                                </Button>
                                {
                                    currentStep === 2 && (
                                        <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="10px">
                                            <button
                                                className='w-100 border-0 text-decoration-underline bg-white font-16 font-m fw-medium skipbtn'
                                                onClick={handleContinue}
                                            >
                                                Do it Later
                                            </button>
                                        </Box>
                                    )
                                }
                            </Box>
                        )
                    }
                </Box>
            </Modal>
        </>
    )
}
export default SelfieVerification