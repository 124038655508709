import { put, call,select } from 'redux-saga/effects';
import { signupCreateAPI, sendOTPAPI, signupSearchAPI, signupUpdateAPI, verifyOTPAPI, createPasswordAPI, updateSecurityQuestionAPI } from 'src/api/SignupApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { SIGNUP_ACTIONS } from '../actions/SignupActions';
import { toast } from 'react-toastify';
import { loginSuccess } from 'src/containers/home-container/actions/authAction';
import { setDefaultHeaders } from 'src/utils/RequestUtil';
import { getAuthInfoAction } from 'src/containers/auth-container/actions/AuthActions';
// Selector function to access state
const selectCurrentRecord = (state) => state.SignupReducer.currentRecord;

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(signupSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(signupSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {

            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }


        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(signupCreateAPI, payload.data); 
        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_SEND_OTP,
            data: {
                email: payload.data.email,
                // _id: data.user._id,
            },
        });
        const {localStep,maxStep} = yield select(selectCurrentRecord);
        const updatedMaxStep = (localStep + 1) > maxStep ? (localStep + 1) : maxStep;
        yield put({
            type: SIGNUP_ACTIONS.SET_PAYLOAD,
            data: {step:1,localStep:localStep+1,maxStep:updatedMaxStep},
        });
        // yield put({
        //     type: SIGNUP_ACTIONS.SIGNUP_SEARCH
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_ERROR,
        });
    }
}

function* update(signupId, payload) {
    try {
        yield call(signupUpdateAPI, {
            signupId,
            data: payload.data,
        });
        const {localStep,maxStep} = yield select(selectCurrentRecord);
        const updatedMaxStep = (localStep + 1) > maxStep ? (localStep + 1) : maxStep;
        yield put({
            type: SIGNUP_ACTIONS.SET_PAYLOAD,
            data: {localStep:localStep+1,maxStep:updatedMaxStep},
        });
        if(payload.data?.isInternal){
            yield put(getAuthInfoAction());
        }
        // yield put(headerShowAction({
        //     data: 'Updated',
        // }));
        // yield put({
        //     type: SIGNUP_ACTIONS.SIGNUP_SEARCH
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}
function* sendOTP({data}) {
    try {
        yield call(sendOTPAPI, {
            email: data.email,
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        toast.error(error.response.data.data);
        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_ERROR,
        });
    }
}
function* verifyOTP({ data }) {
    try {
        const payload = {
            email: data.email,
            otp: data.otp,
        };
        // API Call
        const response = yield call(verifyOTPAPI, payload);
        const { user, token } = response.data.data; // Extract `user` and `token` from API response
        
        // Store user data and token in localStorage
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token); // Token is usually stored as a plain string
        
        // Dispatch login success action
        yield put(
            loginSuccess({
                ...user,
                jwtToken: token,
            })
        );
        setDefaultHeaders({
            Authorization: `${token}`, //Bearer 
        });
        
        if (user?.profileCompleted) {
            window.location.href = "/members";
        }
        
        // Fetch current localStep and maxStep
        const { localStep, maxStep } = yield select(selectCurrentRecord);
        
        // Update localStep and maxStep based on user.step
        let updatedLocalStep = localStep + 1; // Default increment of localStep
        let updatedMaxStep = maxStep; // Default maxStep
        
        if (user.step > localStep && user.step > maxStep) {
            updatedLocalStep = user.step+4;
            updatedMaxStep = user.step+4;
        } else {
            updatedMaxStep = updatedLocalStep > maxStep ? updatedLocalStep : maxStep;
        }
        
        // Dispatch updated payload
        yield put({
            type: SIGNUP_ACTIONS.SET_PAYLOAD,
            data: { ...user, localStep: updatedLocalStep, maxStep: updatedMaxStep },
        });

    } catch (error) {
        // Handle error
        console.error("LOG: Verify OTP error", error);

        // Display error toast (check if the error response exists)
        const errorMessage = error.response?.data?.data || "Failed to verify OTP";
        toast.error(errorMessage);

        // Dispatch error action
        yield put(headerShowAction({
            data: error.response.data.message,
        }));
    }
}
function* createPassword({data}) {
    try {
        yield call(createPasswordAPI, {
            userId: data.userId,
            password: data.password,
            confirmPassword: data.confirmPassword
        });
        const {localStep,maxStep} = yield select(selectCurrentRecord);
        const updatedMaxStep = (localStep + 1) > maxStep ? (localStep + 1) : maxStep; 
        yield put({
            type: SIGNUP_ACTIONS.SET_PAYLOAD,
            data: {localStep:localStep+1,maxStep:updatedMaxStep},
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        toast.error(error.response.data.data);
        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_ERROR,
        });
    }
}
function* updateSecurityQuestion({data}) {
    try {
        yield call(updateSecurityQuestionAPI, {
            signupId: data.userId,
            data: {
              userId: data.userId,
              questions: data.questions
            },
          });
        const {localStep,maxStep} = yield select(selectCurrentRecord);
        const updatedMaxStep = (localStep + 1) > maxStep ? (localStep + 1) : maxStep;
        yield put({
            type: SIGNUP_ACTIONS.SET_PAYLOAD,
            data: {localStep:localStep+1,maxStep:updatedMaxStep},
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        toast.error(error.response.data.data);
        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_ERROR,
        });
    }
}

export default [
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_SEARCH, search),
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_UPSERT, upsert),
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_EDIT, edit),
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_SEND_OTP, sendOTP),
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_VERIFY_OTP, verifyOTP),
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_CREATE_PASSWORD, createPassword),
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_UPDATE_SECURITY_QUESTION, updateSecurityQuestion),
];
