import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { time_ago } from 'src/utils/CommonUtils';
// Import any necessary actions
// import { acceptPicRequest, declinePicRequest } from '../actions/chatActions';

const NotificationList = ({ msg, userId }) => {
  const dispatch = useDispatch();
  const isReceiver = msg.senderId !== userId;
  
  // Format timestamp
  const formattedTime = msg.timestamp ? time_ago(msg.timestamp) : '';
  
  // Handle accepting pic request
  const handleAccept = () => {
    // dispatch(acceptPicRequest(msg.id));
    console.log('Accepted pic request', msg.id);
  };
  
  // Handle declining pic request
  const handleDecline = () => {
    // dispatch(declinePicRequest(msg.id));
    console.log('Declined pic request', msg.id);
  };
  
  return (
    <li className={`notification-message ${isReceiver ? 'receiver' : 'sender'}`}>
      <div className="notification-content">
        <div className="notification-header">
          <span className="notification-title">Photo Request</span>
          <span className="notification-time">{formattedTime}</span>
        </div>
        
        <div className="notification-body">
          <p>{isReceiver 
            ? `${msg.senderName || 'Someone'} has requested to see your photos`
            : `You requested to see ${msg.receiverName || 'their'} photos`
          }</p>
          
          {/* Only show accept/decline buttons for the receiver */}
          {isReceiver && msg.status === 'pending' && (
            <div className="notification-actions">
              <Button 
                variant="contained" 
                color="primary" 
                size="small"
                onClick={handleAccept}
                className="accept-btn"
              >
                Accept
              </Button>
              <Button 
                variant="outlined" 
                color="secondary" 
                size="small"
                onClick={handleDecline}
                className="decline-btn"
              >
                Decline
              </Button>
            </div>
          )}
          
          {/* Show status if it's resolved */}
          {msg.status === 'accepted' && (
            <div className="notification-status accepted">
              Request Accepted
            </div>
          )}
          
          {msg.status === 'declined' && (
            <div className="notification-status declined">
              Request Declined
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default NotificationList;
