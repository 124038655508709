import React, { useState } from "react";
import {
  Container, Typography, Box, Button, ToggleButton, IconButton, ToggleButtonGroup, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material";

import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import MembershipPlansList from "./MembershipPlansList";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '1080px',
  width: "90%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '30px 60px',
  borderRadius: '20px'
}; 

const MembershipPlans = ({ open, handleClose }) => {
 

  const [openMainModal, setOpenMainModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);

  const handleOpenMain = () => setOpenMainModal(true);
  const handleCloseMain = () => setOpenMainModal(false); 

  const handleCloseSecond = () => setOpenSecondModal(false);

  return (
    <div> 
      <Modal className="AddiMdalSet"
        open={open} onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="toolmdalSet" sx={{ ...style, maxHeight: '90vh', overflow: 'auto' }}>
          {/* Close Button */}
          <Box display="flex" justifyContent="flex-end" sx={{position: 'sticky', top: '0', right: '0', zIndex: '1'}}>
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', top: '-20px', right: '-50px', zIndex: '9' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
             <Typography variant="h4" fontWeight="bold" sx={{ color: '#0C0D0E', fontFamily: 'indivisible', fontSize: "36px", textAlign: 'center', }}>Luxurylife Membership Plans</Typography>
                      <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2, color: '#667085', fontSize: '16px', fontFamily: 'indivisible', textAlign: 'center', maxWidth: '700px', margin: '10px auto 0px auto', }}>
                        Unlock elite dating with our Gold and Luxury plans—premium perks, private browsing, and exclusive connections for affluent individuals.
                      </Typography>
          </Box>
       <MembershipPlansList />
        </Box>
      </Modal>

      {/* Second Modal */}
      <Modal open={openSecondModal} onClose={() => setOpenSecondModal(false)}>
        <Box sx={{ ...style, maxWidth: '500px' }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setOpenSecondModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="h5">Confirm Membership</Typography>
          <Typography variant="body1">Are you sure you want to proceed?</Typography>
          <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={handleCloseSecond}>
            Confirm & Close
          </Button>
        </Box>
      </Modal>
    </div>
  )
}

export default MembershipPlans
