import { put, call } from 'redux-saga/effects';
import { chatCreateAPI, chatSearchAPI, chatUpdateAPI } from 'src/api/ChatApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { CHAT_ACTIONS } from '../actions/ChatActions';
import { getMemberDetailsAPI } from 'src/api/MembersApi';

function* search({
    where, currentPage = 0, autopopulate = true
} = {}) {
    try {
        const {
            data
        } = yield call(chatSearchAPI, {
            where,
            requestType: 'mobile',
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: CHAT_ACTIONS.CHAT_SEARCH_SUCCESS,
            data,
        });
        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: CHAT_ACTIONS.CHAT_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(chatSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {

            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }


        yield put({
            type: CHAT_ACTIONS.CHAT_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(chatCreateAPI, payload.data);
        // yield put({
        //     type: CHAT_ACTIONS.CHAT_SEARCH
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: CHAT_ACTIONS.CHAT_UPSERT_ERROR,
        });
    }
}

function* update(chatId, payload) {
    try {
        yield call(chatUpdateAPI, {
            chatId,
            data: payload.data,
        });
        // yield put(headerShowAction({
        //     data: 'Updated',
        // }));
        // yield put({
        //     type: CHAT_ACTIONS.CHAT_SEARCH
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: CHAT_ACTIONS.CHAT_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}
function* getSelectedUserDetails({
    participantId
} = {}) {
    try {
        const { data: { data }} = yield call(getMemberDetailsAPI, participantId);

        yield put({
            type: CHAT_ACTIONS.SET_SELECTED_USER_DETAILS,
            data,
        });
        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: CHAT_ACTIONS.CHAT_SEARCH_ERROR,
        });
        return null;
    }
}


export default [
    takeFirstSagaUtil(CHAT_ACTIONS.CHAT_SEARCH, search),
    takeFirstSagaUtil(CHAT_ACTIONS.CHAT_UPSERT, upsert),
    takeFirstSagaUtil(CHAT_ACTIONS.CHAT_EDIT, edit),
    takeFirstSagaUtil(CHAT_ACTIONS.GET_SELECTED_USER_DETAILS, getSelectedUserDetails)

];
