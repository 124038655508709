import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./css/luxury-style.css"
import { Link } from "react-router-dom";

const images = [
  "/assets/images/first-home/banner-img1.png",
  "/assets/images/first-home/banner-img2.png",
  "/assets/images/first-home/banner-img3.png",
  "/assets/images/first-home/banner-img4.png",
];


const LuxuryBanner = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 10000);
      return () => clearInterval(interval);
    }, []);
  
    const handleDotClick = (i) => {
      setIndex(i);
    }; 
  return (
    <>
      <div className="position-relative flex items-center justify-center overflow-hidden bannerImgNw001" style={{background: '#f2f2f2', width: '100%', height: '100vh',}}>
      {/* <AnimatePresence>
        <motion.div
          key={index}
          className="postion-absolute top-0 start-0 end-0 bottom-0 inset-0 bg-cover bg-center h-100 w-100"
          style={{ backgroundImage: `url(${images[index]})`,  backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        />
      </AnimatePresence> */}

{/* Background Images Layered for Smooth Transition */}
{images.map((img, i) => (
        <motion.div
          key={i}
          className="position-absolute top-0 start-0 end-0 bottom-0 inset-0 bg-cover bg-center h-100 opastyset"
          style={{
            backgroundImage: `url(${img})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            opacity: i === index ? 1 : 0,
          }}
          animate={{ opacity: i === index ? 1 : 0 }}
          transition={{ duration: 0.05 }} // Smooth transition
        />
      ))}

      <div className="position-absolute text-white text-start bnrfontsizeSet" style={{}}>
        <h1 className="font-m">Where The Elite <span className="d-block">Connect, Collaborate,</span> and Conquer</h1>
        <p>Join the most exclusive social networking list designed for the world’s most exclusive influential minds. Your next opportunity is just a conversation away.</p>
        <Link to="/signup" className="text-white font-semibold text-decoration-none d-inline-block btnSetWth">
        Join Now
        </Link>
      </div>
       {/* Dots Indicator */}
       <div className="position-absolute dotsBannerSlider d-flex align-items-center" style={{left: '50%', transform: 'translateX(-50%)'}}>
        {images.map((_, i) => (
          <button
            key={i}
            onClick={() => handleDotClick(i)}
            className={`rounded-pill border-0 ${i === index ? "bg-white" : "bg-white-50"}`}
          />
        ))}
      </div>
    </div>
    </>
  )
}

export default LuxuryBanner
