import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import LoginForm from './LoginForm';
import Footer from '../../containers/home-container/layout/Footer';
import Modal from '@mui/material/Modal';
import LinksYourAccount from 'src/components/signup/LinksYourAccount';
import { handleLogin } from 'src/api/AuthAPI';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loginSuccess, logout } from '../home-container/actions/authAction';
import { checkSecurityQuestionAPI, createPasswordAPI, getQuestionAPI, sendOTPAPI, verifyOTPAPI } from 'src/api/SignupApi';
import EmailVerification from 'src/components/signin/EmailVerification';
import CreatePassword from 'src/components/signin/CreatePassword';
import { headerShowAction } from '../header-container/actions/HeaderActions';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '20px',
    p: 4,
};


const LogIn = () => {
    const dispatch = useDispatch();
    const {
        user
    } = useSelector((state) => state.auth, shallowEqual);
    const {headerMessages} = useSelector((state) => state.HeaderReducer, shallowEqual);
    const [currentStep, setCurrentStep] = useState(1);
    const [captchaValue, setCaptchaValue] = useState(null);// null  'testing'
    const [credential, setCredential] = useState({ userName: '', password: '' });
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState(null);
    const [resetEmail, setResetEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [resetPassword, setResetPassword] = useState('');
    const [securityQuestionEmail, setSecurityQuestionEmail] = useState('');
    const [questions, setQuestions] = useState([]);
    const [answer, setAnswer] = useState({ questionId: '', question: '', answerHash: '' });
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let navigate = useNavigate();
    const [forgetPassId,setForgetPassId] = useState();
    const stepTexts = {
        1: "",
        2: "Can’t Access Your Email?",
        3: "Can’t Access Your Email?",
    };

    const headerContent = {
        title: "Reset Your Password", 
      };
      

    const handleContinue = async () => {
        try {
            setError(null);
            dispatch(headerShowAction({ data: '', }));
            if (currentStep === 1) {
                setCurrentStep((prevStep) => prevStep + 1);
            }
            else if (currentStep === 2) {
                const resetResponse = await sendOTPAPI({ email: resetEmail });
                if (!resetResponse.data.error) {
                    toast.success(resetResponse.data.message);
                    setCurrentStep(3);
                }
            }
            else if (currentStep === 3) {
                const OtpVerificationResponse = await verifyOTPAPI({ email: resetEmail, otp: otp });
                const { user, token } = OtpVerificationResponse.data.data; // Extract `user` and `token` from API response
                // Store user data and token in localStorage
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("token", token); // Token is usually stored as a plain string
                // Dispatch login success action
                dispatch(
                    loginSuccess({
                        ...user,
                        jwtToken: token,
                    })
                );
                setForgetPassId(user._id);
                setCurrentStep(4);
            }
            else if (currentStep === 4) {
                const payload = { userId: forgetPassId, password: resetPassword, confirmPassword: resetPassword };
                const createPassResponse = await createPasswordAPI(payload);
                dispatch(logout())
                if (!createPassResponse.data.error) setCurrentStep(5);
            }
            else if (currentStep === 5) {
                setCurrentStep(1);
            }
            else if (currentStep === 6) {
                const getQuestion = await getQuestionAPI(securityQuestionEmail);
                setQuestions(getQuestion.data.data);
                setCurrentStep(7);
            }
            else if (currentStep === 7) {
                const payload = { email: securityQuestionEmail, answers: [{ ...answer }] };
                const verifyQuestion = await checkSecurityQuestionAPI(payload)
                const { user, token } = verifyQuestion.data.response; // Extract `user` and `token` from API response
                // Store user data and token in localStorage
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("token", token); // Token is usually stored as a plain string
                // Dispatch login success action
                dispatch(
                    loginSuccess({
                        ...user,
                        jwtToken: token,
                    })
                );
                setCurrentStep(8);
            }
            else if (currentStep === 8) {
                const payload = { userId: user?._id, password: resetPassword, confirmPassword: resetPassword };
                const createPassResponse = await createPasswordAPI(payload);
                if (!createPassResponse.data.error) setCurrentStep(5);
                setCurrentStep(9);
            }
            else if (currentStep === 9) {
                dispatch(logout())
                setCurrentStep(1);
            }
        }
        catch (error) {
            // toast.error(error?.response?.data?.message);
            setError(error?.response?.data?.message);
        }
        // if (currentStep < 9) setCurrentStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        if (currentStep > 1)
            {
                
                if(currentStep===6){
                    setCurrentStep(1);
                }
                else
                setCurrentStep((prevStep) => prevStep - 1);
            } 
            dispatch(headerShowAction({ data: '', }));
            setError(null);
            
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };


    // se

    // const [questions] = useState([
    //     "What is your favorite color?",
    //     "What is your mother's maiden name?",
    //     "What was your first pet's name?",
    //     "What is your hometown?",
    //     "What was your first car?"
    // ]);

    // const [answers, setAnswers] = useState({
    //     question1: "",
    //     answer1: "",

    // });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'questionId') {
            const selectedQuestion = questions.find((question) => question.questionId == value);
            setAnswer((prevAnswer) => ({
                ...prevAnswer,
                questionId: value,
                question: selectedQuestion ? selectedQuestion.question : '', // Set question text if found
            }));
        } else {
            setAnswer((prevAnswer) => ({
                ...prevAnswer,
                [name]: value, // Update other fields like answerHash
            }));
        }
    };


    const buttonTexts = {
        1: "Continue",
        2: "Send Request",
        3: "Continue",
        4: "Confirm",
        5: "Back to Log In",
        6: "Continue",
        7: "Continue",
        8: "Confirm",
        9: "Back to Log In",
    };
    const handleLoginSubmit = async () => {
        try {
            const loginResponse = await handleLogin(credential)
            const { user, token } = loginResponse.data.data; // Extract `user` and `token` from API response
            // Store user data and token in localStorage
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", token); // Token is usually stored as a plain string
            // Dispatch login success action
            dispatch(
                loginSuccess({
                    ...user,
                    jwtToken: token,
                })
            );
            navigate('/members')
        }
        catch (error) {
            // toast.error(error?.response?.data?.message);
            setError(error?.response?.data?.message);
        }
        // Add login logic here
        // If login is successful, redirect to the user's profile
        // navigate('/profile-edit');
    };
    //redirect to members if already logged in
    // useEffect(() => {
    //     if (user) {
    //         navigate('/members')
    //     }
    // }, [user])

    useEffect(() => {
        if (headerMessages?.length > 0) {
            setError(headerMessages);
        } else {
            setError(null);
        }
    }, [headerMessages]);
    useEffect(() => {
        if(user?.jwtToken && user?.profileCompleted && currentStep===1) 
            navigate('/members')
    },[user?.jwtToken,user?.profileCompleted,currentStep]);
    useEffect(() => {
     dispatch(headerShowAction({ data: '', }));   
    },[])

    const renderStepContent = (step) => {
        switch (step) {
            case 1://Login Component
                return (
                    <div className="step step1">
                        <div className="">
                            <div className="text-center font-36 font-p fst-italic fw-medium">Welcome to <span className='text-gold-light font-p'>Luxury Life!</span> </div>
                            <div className="colorf5 text-black text-center font-16 font-m fw-medium pt-0">
                                Where Ambition Meets Affluence 
                            </div>
                        </div>
                        <div className="mw-400-px mx-auto">
                            <p className="text-black text-center font-14 font-m pt-16">
                                Discover exclusivity and meaningful connections.
                            </p>
                            <LoginForm setCredential={setCredential} captchaValue={captchaValue} credential={credential} />
                            <div className="text-end w-100">
                                <Link
                                    className="colorc3 font-14 font-m fw-medium"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleContinue();   // Move to the next step
                                    }}
                                >
                                    Forgot Password? 
                                </Link>
                            </div>
                        </div>
                        <div className="LoginAccountLink"> 
                             <p className="colorf5 text-center font-12 font-m mb-0 pt-6">or continue with </p>
                            <LinksYourAccount type="login" captchaValue={captchaValue}/>
                           
                            {/* Add reCAPTCHA */}
                            <ReCAPTCHA className="d-flex justify-content-center text-center mb-0 mx-auto"
                                sitekey="6LfjQ6YqAAAAAGZq85s28C8n5Y_FaMSzdzBsWB9K" // Replace with your reCAPTCHA site key
                                onChange={handleCaptchaChange}
                            />
                        </div>
                    </div>
                );
            case 2://Reset Password
                return (
                    <div className="step step2">
                        <div className="">
                            <div className="text-center font-32 font-m fw-medium">Reset Your Password</div>
                            <div className="colorf5 text-center font-14 font-m fw-medium pt-6">
                                Forgot your password? Don’t worry – it happens!
                            </div>
                            <p className="text-black text-center font-14 font-m fw-normal pt-16">Enter your registered email ID below, and we’ll send you a secure link to reset your password.</p>
                            <div className="w-100 icon-field mw-400-px mx-auto my-32">
                                <span className="icon">
                                    <MailOutlineIcon />
                                </span>
                                <input
                                    type="email"
                                    name="email"
                                    autoComplete="off"
                                    value={resetEmail}
                                    onChange={(e) => setResetEmail(e.target.value)} // Use e.target.value
                                    className="form-control input-h-44"
                                    placeholder="Enter Email"
                                />
                            </div>
                            <p className="text-black text-center font-16 font-m fw-medium pt-16">Need help? Contact our <Link className="text-black">support team</Link> for assistance. </p>
                        </div>
                    </div>
                );
            case 3://OTP Verification
                return (
                    <div className="text-center step step3">
                        <EmailVerification
                            title="Verify Your Email"
                            subtitle="Enter the verification code sent to your email to proceed."
                            handleBack={handleBack}
                            setOtp={setOtp}
                            email={resetEmail} />
                        <div className="text-center"><button className="color7b border-0 text-decoration-underline font-12 font-m fw-normal my-16" style={{ background: 'none' }} onClick={handleOpen}>Troubleshoot</button></div>
                    </div>
                );
            case 4://Create Password
                return (
                    <div className="text-center step step3">
                        <CreatePassword setResetPassword={setResetPassword} headerContent={headerContent} />
                    </div>
                );
            case 5://Password Changed
                return (
                    <div className="text-center my-32 step step3">
                        <div className="mb-24"><img src="/assets/images/signup/otp-verified.svg" className="img-fluid" alt="" /></div>
                        <div className="font-32 font-m fw-medium mw-300-px mx-auto">Your Password has been changed</div>
                    </div>
                );
            case 6://Email for Security Questions
                return (
                    <div className="text-center my-32 step step3">
                        <div className="">
                            <div className="text-center font-32 font-m fw-medium">Verify Your Identity with Security Questions </div>
                            <div className="colorf5 text-center font-18 font-m fw-normal pt-6">
                                Answer the security questions below as an alternate verification method if you can’t access your email.
                            </div>
                            <div className="w-100 icon-field mw-400-px mx-auto my-32">
                                <span className="icon">
                                    <MailOutlineIcon />
                                </span>
                                <input
                                    type="email"
                                    name="email"
                                    autoComplete="off"
                                    onChange={(e) => setSecurityQuestionEmail(e.target.value)}
                                    className="form-control input-h-44"
                                    placeholder="Enter Email"
                                />
                            </div>
                        </div>
                    </div>
                );
            case 7://Security Questions
                return (
                    <div className="text-center my-32 step step3">
                        <div className="btnsWithConnects mw-570-px mx-auto security-setup-container">
                            <div className="">
                                <div className="text-center font-32 font-m fw-medium">Security Questions </div>
                                <div className="colorf5 text-center font-14 font-m pt-6">
                                    Please answer the security question you selected during account setup to verify your identity.
                                </div>
                            </div>
                            <form className="pt-24 security-form">
                                <div className="text-center font-20 font-m fw-medium mb-16">Choose One of The Following</div>
                                <div className="d-block gap-3 security-row">
                                    <select className="form-select w-100 mb-10"
                                        name={`questionId`}
                                        onChange={handleChange}
                                        required
                                        style={{ borderRadius: '15px' }}
                                    >
                                        <option value="">Select Any Question</option>
                                        {questions.map((question, index) => (
                                            <option key={index} selected={question.questionId === answer.questionId} value={question.questionId}>
                                                {question.question}
                                            </option>
                                        ))}
                                    </select>
                                    <input className="form-control mb-10"
                                        type="text"
                                        name={`answerHash`}
                                        autoComplete="off"
                                        placeholder="Type your answer here"
                                        value={answer.answerHash}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                );
            case 8://Create Password
                return (
                    <div className="text-center step step3">
                        <CreatePassword setResetPassword={setResetPassword} headerContent={headerContent} />
                    </div>
                );
            case 9://Password Changed
                return (
                    <div className="text-center my-32 step step9">
                        <div className="mb-24"><img src="/assets/images/signup/otp-verified.svg" className="img-fluid" alt="" /></div>
                        <div className="font-32 font-m fw-medium mw-300-px mx-auto">Your Password has been changed</div>
                    </div>
                );
            default:
                return null;
        }
    };


    const steps = [
        {
            number: 1,
            title: "Check Your Spam/Junk Folder",
            description: "Sometimes, our emails can end up in your spam folder. Make sure to check there!",
        },
        {
            number: 2,
            title: "Resend the Reset Link",
            description: "If you still haven’t received the email, click below to resend the password reset link to your inbox.",
            linkText: "Resend Link",
            link: "Resend Link",
        },
        {
            number: 3,
            title: "Contact Support",
            description: "If you're still experiencing issues, feel free to contact our support team for further assistance.",
            linkText: "support@luxurylife.ai",
            link: "support@luxurylife.ai2",
        },
    ];

    return (
        <>
        <div className="container-fluid" >
            <div className="row">
                <div className="col-md-12 p-16 loginScreen signUpmain">
                    <Box className="d-flex flex-column align-items-stretch justify-content-between signUpTextdv">
                        <div className="">
                            <div className="d-flex align-items-center justify-content-between logoWithTop py-0">
                                <div className="Logo">
                                    <Link to="/">
                                        <img
                                            src="/assets/images/signup/logo-signup.svg"
                                            className="img-fluid"
                                            alt="logo"
                                        />
                                    </Link>
                                </div>
                                <div className="font-16 fw-medium pb-24">
                                    Don't have an account?   <Link className="text-gold-light" to="/signup">Join today.</Link>
                                </div>
                            </div>
                            {
                                currentStep > 1 &&
                                <div className="maxw602">
                                <div className="d-flex align-items-center justify-content-between">
                                    <button
                                        className="bg-transparent border-0 text-dark font-16 font-m"
                                        onClick={handleBack}
                                    >
                                        Back
                                    </button>

                                </div>
                            </div>
                            }
                           
                        </div>
                        {error && <p className="align-self-center text-danger">{error}</p>}
                        <Box className="h-100 maxw602">{renderStepContent(currentStep)}</Box>

                        <div className="bg-white text-center maxw602 mt-32 stykbtnsebtn" style={{ position: 'sticky', bottom: '0' }}>
                            <div className="text-center mb-0 mt-16">
                                {([].includes(currentStep)) && (
                                    <button
                                        className="bg-white border-0 text-decoration-underline font-16 font-m fw-medium skipbtn"
                                        onClick={handleContinue} // Skip action: move to the next step
                                    >
                                        Skip
                                    </button>
                                )}
                                {([2, 3].includes(currentStep)) && (
                                    <div
                                        className="bg-white border-0 font-16 font-m fw-normal login"
                                    >
                                        Back to{" "}
                                        <Link
                                            style={{ color: '#916008', fontWeight: 500 }}
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent the default link behavior
                                                setCurrentStep(1);  // Set the currentStep to 1 (first step)
                                            }}
                                        >
                                            Log in
                                        </Link>
                                    </div>
                                )}


                            </div>
                            <div className="pb-16 pdBopsetmobile">
                                <small className="color5f font-m fst-italic fw-medium">
                                    {stepTexts[currentStep]}{" "}
                                    {[2, 3].includes(currentStep) && (
                                        <Link
                                            // to="/some-path" // Replace with the path you want to navigate to
                                            style={{ color: '#000', fontWeight: 500 }}
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent the default link behavior
                                                setCurrentStep(6);  // Set the currentStep to 1 (first step)
                                            }}
                                        >
                                            Try Another Way
                                        </Link>
                                    )}

                                </small>
                            </div>
                            <Box className="ctfnsbtn">
                                {currentStep === 1 ? (
                                    <Button
                                        onClick={handleLoginSubmit}
                                        variant="contained"
                                        disabled={!credential.userName || !credential.password || !captchaValue}
                                        sx={{
                                            width: '100%', textTransform: 'capitalize',
                                            backgroundColor: '#916008',
                                            padding: '10px',
                                            color: '#fff',
                                            '&:hover': { backgroundColor: '#9d6f3b' },
                                        }}
                                    >
                                        Continue
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width: '100%',
                                            backgroundColor: '#916008',
                                            padding: '10px',
                                            color: '#fff', textTransform: 'capitalize',
                                            '&:hover': { backgroundColor: '#9d6f3b' },
                                        }}
                                        onClick={handleContinue}
                                    >
                                        {buttonTexts[currentStep] || 'Continue'}
                                    </Button>
                                )}
                            </Box>
                        </div>
                    </Box>
                    <div className="rightImgSignup">
                        <img
                            src="/assets/images/how-luxury-life-works-bg.png"
                            className="img-fluid"
                            alt="background"
                        />
                    </div>
                </div>
            </div>
           
        </div>
        {/* <Footer /> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="respWSet"
            >
                <Box className="modalsetM0"
                    sx={{
                        ...style,
                        maxHeight: '80vh', // Adjust as needed
                        overflowY: 'auto', // Enables vertical scroll
                    }}
                >
                    <Box className="d-flex modalsetM1 align-items-start justify-content-between">
                        <div
                            className="modal-title modalsetM1_1 text-black font-32 fw-medium"
                            id="exampleModalLabel"
                        >
                            Trouble Resetting Your Password?
                        </div>
                        <img
                            src="/assets/images/login/trouble.svg"
                            className="img-fluid"
                            alt=""
                        />
                    </Box>
                    <p className="modal-title text-black font-18 fw-normal">
                        If you didn’t receive the password reset link or are having trouble, try
                        one of the following options:
                    </p>
                    <List className="modalsetM2">
                        {steps.map((step) => (
                            <ListItem className="modalsetM2_1" key={step.number} sx={{ paddingLeft: '0' }}>
                                <ListItemAvatar className="modalsetM2_2">
                                    <Avatar className="modalsetM2_3"
                                        sx={{
                                            background: '#faf5eb',
                                            color: '#916008',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {step.number}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText className="modalsetM3"
                                    primary={step.title}
                                    secondary={
                                        <>
                                            <span>{step.description}</span>
                                            {step.link && (
                                                <a
                                                    href={step.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        color: '#916008',
                                                        fontSize: '12px',
                                                        textDecoration: 'underline',
                                                        display: 'block',
                                                        marginTop: '4px',
                                                    }}
                                                >
                                                    {step.linkText}
                                                </a>
                                            )}
                                        </>
                                    }
                                    sx={{
                                        '& .MuiListItemText-primary': {
                                            color: '#081726',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            fontFamily: 'indivisible',
                                        },
                                        '& .MuiListItemText-secondary': {
                                            color: '#081726',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            fontFamily: 'indivisible',
                                        },
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <div className="text-center popup-actions">
                        <Link className="d-inline-block modalsetM4 bg-none border-0 p-10 text-black font-16 font-m fw-medium mb-16 text"
                            href=""
                            onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                setCurrentStep(6);
                            }}
                        >
                            Try Another Way
                        </Link>
                        <Button className="modalsetM4_1"
                            variant="contained"
                            sx={{
                                width: '100%', position: 'sticky', bottom: '0',
                                backgroundColor: '#916008',
                                padding: '10px',
                                color: '#fff',
                                textTransform: 'capitalize',
                                '&:hover': { backgroundColor: '#9d6f3b' },
                            }}
                            onClick={handleContinue}
                        >
                            Complete Verification
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default LogIn;