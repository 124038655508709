import { useEffect, useState } from "react";
import Filters from "./Filters"
import ProfileGrid from "./ProfileGrid"

const MemberContainer = () => {
    const [filtersVisible, setFiltersVisible] = useState(window.innerWidth > 991);

    useEffect(() => {
        const handleResize = () => {
            const shouldShow = window.innerWidth > 991;
            setFiltersVisible(shouldShow);
        };

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleFiltersVisibility = () => {
        setFiltersVisible((prevState) => !prevState);
    };
    return (
        <>
           <div className={`profile-container ${!filtersVisible ? "overflow-hidden" : ""}`}>
           <Filters toggleFiltersVisibility={toggleFiltersVisibility} filtersVisible={filtersVisible} />
                <ProfileGrid />
            </div>
        </>
    )
}

export default MemberContainer