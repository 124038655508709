import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const BodyType = () => {
    const dispatch = useDispatch();
        const {
          bodyType,
        } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [selectedBodyType, setSelectedBodyType] = useState(bodyType||"");

  const bodyTypesList = [
    "Slim",
    "Athletic",
    "Average",
    "Curvy",
    "Plus-size",
    "Petite",
    "Muscular",
    "Broad",
    "Lean",
    "Prefer not to say",
  ];

  const handleBodyType = (value) => {
    setSelectedBodyType(value);
    dispatch(setPayload({ data: { bodyType: value } }));     
  };

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">
          What Best Describes Your Body Type?
        </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Help us understand more about you by selecting the option that best matches your body type.
        </div>
      </div>
      <div className="bodytype-selection text-center mt-32 mb-16">
        {/* <h3 className="font-18 font-m fw-medium mt-24 mb-16">Choose Your Body Type</h3> */}
        <div className="btnsetAll btnsetAll2 mt-6">
          {bodyTypesList.map((type) => (
            <button
              key={type}
              className={`font-m border font-16 fw-normal ${
                selectedBodyType === type ? "selected" : ""
              }`}
              onClick={() => handleBodyType(type)}
            >
              {type}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BodyType