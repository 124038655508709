import React from 'react'
import Header from 'src/containers/home-container/layout/Header'
import LuxuryFooter from '../../luxury-home/LuxuryFooter'
import FaqAccordion from './FaqAccordion'

const FrequentlyAskedQuestions = () => {
  return (
    <>
      <div className='aboutusnEwv1' style={{ padding: '5vw 5.5vw' }}>
        <Header />
        <div className='aboutusTextNewbg' style={{ marginBottom: '4vw' }}>
          <div className='aboutusTextNew text-center mx-auto'>
            <h2 className='fw-semibold font-m font48vw mb-0' style={{ padding: '2vw 0' }}>FAQs  </h2>
            <p className='fw-normal font-m font20vw mb-0'>Here are quick answers to the most commonly asked questions </p>
          </div>
        </div>
        <FaqAccordion />
      </div>
      <LuxuryFooter />
    </>
  )
}

export default FrequentlyAskedQuestions
