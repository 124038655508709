import React from 'react'
import { Link } from 'react-router-dom'

const LuxuryFooter = () => {
  return (
    <div className='d-flex justify-content-between footerBgNew1' style={{ padding: '5vw 5.5vw' }}>
      <div className='d-flex align-items-center FooterNewlogobg'><div className='FooterNewlogo'><Link className="" to="/"><img src='/assets/images/first-home/logo-footer.svg' className='img-fluid' alt="logo luxury" /></Link></div>
        <div className='footerTextNew font24vw fw-bold'>Greatness thrives in the company of greatness. Are you ready to belong?</div></div>
      <div className='linksFooterNew'>
        <ul className='d-flex'>
          <li><a href='/about-us'>About Us</a></li>
          <li><a href='/privacy-policy'>Privacy Policy</a></li>
          <li><a href='/faqs'>FAQs</a></li>
          <li><a href='/contact-us'>Contact Us</a></li>
          <li><a href='/terms-and-conditions'>Terms & Conditions</a></li>
        </ul>
        <p className='mb-0 copyrightNewone1 font08vw'>Copyright © 2025 Luxury Life All rights reserved.</p>
      </div>

    </div>
  )
}

export default LuxuryFooter
