import React from 'react';

const FilterOptionList = ({ options, gridClassName, onChange }) => {
  return (
    <div className={gridClassName}>
      {Object.entries(options).map(([key, value]) => (
        <label className="checkinput" key={key}>
          {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          <input
            type="checkbox"
            name={key}
            checked={value}
            onChange={(e) => onChange(key, e.target.checked)}
          />
          <span className="checkmark" />
        </label>
      ))}
    </div>
  );
};

export default FilterOptionList;

