import React from 'react'

const AboutUs = () => {
  return (
    <div style={{padding:'5vw 5.5vw', background: '#081726',}}>
      <div className='d-flex justify-content-between align-items-center AboutUsNewLuxury font-m'><div className='text-white fw-bold textAboutus font48vw text-nowrap'>About Us</div>
      <div className='text-white text font24vw'>A private network designed for high achievers, influential leaders, and tastemakers. We curate a community of individuals who value exclusivity, sophistication, and meaningful connections.</div></div>
    </div>
  )
}

export default AboutUs
