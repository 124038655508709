import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import SwitchComp from '../../Component/PermissionsSettings/SwitchComp';

const NotificationsAndActions = ({ currentRecord, handleUpdate }) => {
  const { notificationSetting = {} } = currentRecord;

  const config = [
    {
      title: 'Display in-app alerts for',
      subsection: 'appAlertMessage',
      settings: [
        { label: 'Message Alerts', key: 'onceSendMeMessage' },
        { label: 'Profile Views', key: 'onceProfileViews' }, 
        { label: 'Promotional Emails', key: 'oncePromotionalEmails' }, 
        { label: 'Frequency Settings', key: 'onceFrequencySettings' }, 
      ],
    },
    {
      title: 'Send email alerts for',
      subsection: 'emailAlert',
      settings: [
        { label: 'Sends me a message', key: 'onceSendMeMessage' },
        { label: 'Adds me as a favorite', key: 'onceAddMeFavorite' },
        { label: 'Views my profile', key: 'onceViewMyProfile' },
      ],
    },
    {
      title: 'Also send me emails about',
      subsection: 'emailAlert',
      settings: [ 
        { label: 'New Members and Matches', key: 'onNewMembers' },
        { label: 'When My Profile Information Is Approved', key: 'onceProfileApprove' },
        { label: 'Verification and Profile Requests', key: 'onVerificationAndInformation' },
        { label: 'News and Updates', key: 'onNewsEvents' },
        { label: 'Promotions and Other Offers', key: 'onPromotionOtherOffer' },
      ],
    },
    // {
    //   // title: 'Unsubscribe All',
    //   className: 'unsubscribe-section',
    //   settings: [{ label: 'Unsubscribe All', component: <SwitchComp /> }],
    // },
  ];

  const handleSwitchChange = (key, value, subSection) => {
    handleUpdate("notificationSetting", key, value, subSection);
    // const updatedNotificationSetting = {
    //   ...notificationSetting,
    //   [subSection]: {
    //     ...notificationSetting[subSection],
    //     [key]: value,
    //   },
    // };

    // handleUpdate({ ...currentRecord, notificationSetting: updatedNotificationSetting });
  };

  return (
    <div>
      <Typography className="ac-heading"
        variant="h6"
        gutterBottom
        sx={{
          fontSize: '24px',
          fontWeight: '500',
          color: '#000',
          fontFamily: 'ivypresto-headline',
        }}
      >
        Notification Settings
      </Typography>
      {config.map((sectionConfig, index) => (
        <Box className="font1vw border001vw mtopBottom05 gap05vw p1vw"
          key={index}
          component={Paper}
          sx={{ 
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column', 
          }}
        >
          <Typography  className="font875vw" variant="h6" sx={{fontWeight: '500', color: '#081726',  fontFamily:'indivisible',}}>{sectionConfig.title}</Typography>
          <Box>
            {sectionConfig.settings.map((setting, settingIndex) => (
              <Box  className="mtopBottom05"
                key={settingIndex}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center', 
                  fontFamily: 'indivisible'
                }}
              >
                <Typography  className="font08vw" sx={{fontFamily: 'indivisible',fontWeight: '400', color: '#081726',}}>{setting.label}</Typography>
                <SwitchComp
                  status={
                    notificationSetting?.[sectionConfig.subsection]?.[setting.key] ?? false
                  }
                  onChange={(event) => {
                    event.stopPropagation();
                    handleSwitchChange(setting.key, event.target.checked, sectionConfig.subsection);
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </div>
  );
};

export default NotificationsAndActions;
