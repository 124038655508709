import React, { useRef } from 'react';
import Slider from 'react-slick';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

const testimonials = [
  {
    id: 1,
    imgSrc: '/assets/images/first-home/alecandra-ceo.png',
    name: 'Alexandra M.',
    designation: 'CEO',
    review: 'This platform has opened doors I didn’t even know existed. Worth every penny.”',
  },
  {
    id: 2,
    imgSrc: '/assets/images/first-home/james-t-venture-capitalist.png',
    name: 'James T.',
    designation: 'Venture Capitalist',
    review: 'The connections I’ve made here have transformed my business and my life.”',
  },
  {
    id: 3,
    imgSrc: '/assets/images/first-home/sophia-fashion-mogu.png',
    name: 'Sophia L.',
    designation: 'Fashion Mogul',
    review: 'Finally, a social network that matches my ambition and lifestyle.”',
  },
];

const WhatOurMembersAreSaying = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
  };

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between testimonialBgNew' style={{ padding: '5vw 5.5vw' }}>
        <div className='text-start whatOurMembers'>
          <h2 className='text-start font48vw' style={{ marginBottom: '1vw' }}>What Our Members Are Saying</h2>
          <p className='font20vw'>Here’s what other subscribers had to say about platform</p>
          <div className='d-flex align-items-center btnDsliderbg'>
            <button className='btnDslider' onClick={() => sliderRef.current.slickPrev()}><WestIcon /></button>
            <button className='btnDslider' onClick={() => sliderRef.current.slickNext()}><EastIcon /></button>
          </div>
        </div>
        <div className='text-start clintesliderbg'>
          <Slider ref={sliderRef} {...settings}>
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className='clinteslider'>
                <span><img src='/assets/images/first-home/dual-arrow-icon.svg' className='img-fluid' alt='' /></span>
                <div className='clientReviewtext font-e fst-italic'>{testimonial.review}</div>
                <div className='userDtails d-flex align-items-center'>
                  <span className='clientimg'><img src={testimonial.imgSrc} className='img-fluid' alt='' /></span>
                  <div className='Username'>{testimonial.name}, <span className='designation'>{testimonial.designation}</span></div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default WhatOurMembersAreSaying;
