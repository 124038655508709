import React from 'react'

const ApplyForMembership = () => {
  const handleApplyNowClick = () => {
    window.location.href = '/exclusive-membership-plans';
  };
  return (
    <div className='d-flex justify-content-between align-items-center ApplyforMembership' style={{background: "#861D1D"}}>
      <div className='img'><img src='/assets/images/first-home/apply-for-membership.png' className='img-fluid' alt="" /></div>
      <div className='text' style={{ padding: '5vw 5.5vw' }}>
        <h2 className='text-white font48vw mb-0'>Apply for Membership</h2>
        <p className='text-white font-1-25vw mb-0' style={{padding: '1vw 0'}}>Your journey into the world’s most exclusive social network begins here. Submit your application and join a circle of excellence.</p>
        <button onClick={handleApplyNowClick} className="font-bold ApplyNowBntSt">
        Apply Now
        </button> 
      </div>
    </div>
  )
}

export default ApplyForMembership
