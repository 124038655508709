import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const profileSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/profile/search`;
    return postRequest({
        url,
        data,
    });
};
export const getDesignationAPI = function (data) {
    const url = `${config.baseUrl}user/getDesignation`;
    return postRequest({
        url,
        data,
    });
};

export const profileCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/profile/save`;
    return postRequest({
        url,
        data,
    });
};

export const profileUpdateAPI = function ({
    profileId,
    data,
}) {
    const url = `${config.baseUrl}admin/profile/update/${profileId}`;
    return putRequest({
        url,
        data,
    });
};
