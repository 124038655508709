import { put, call } from 'redux-saga/effects';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { FAVORITE_ACTIONS } from '../actions/FavoriteActions';
import { favoritedSearchAPI, favoriteSearchAPI, viewedSearchAPI } from 'src/api/FavoriteApi';

function* search({
    requestType,where,sortBy, currentPage = 0
} = {}) {
    try {
        const apiMap = {
            viewed: viewedSearchAPI,
            favorites: favoriteSearchAPI,            
            default: favoritedSearchAPI
        };
        const searchAPI = apiMap[requestType] || apiMap.default;
        const { data } = yield call(searchAPI, {
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            where,
            sortBy,
            currentPage
        });
        yield put({
            type: FAVORITE_ACTIONS.FAVORITE_SEARCH_SUCCESS,
            data,
        });
        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: FAVORITE_ACTIONS.FAVORITE_SEARCH_ERROR,
        });
        return null;
    }
}
 
export default [
    takeFirstSagaUtil(FAVORITE_ACTIONS.FAVORITE_SEARCH, search),
];
