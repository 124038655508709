// get public IP address using javascript
// const API = `http://localhost:8001`
const API = `https://www.${process.env.REACT_APP_PUBLIC_IP}`
// //${process.env.REACT_APP_PUBLIC_IP}
export default {
  socketUrl: API,
  baseUrl: `${API}/api/v1/`,
  imageUrl: "https://stagging.luxurylife.ai/uploads/",
};


// const API = "https://server.luxurylife.ai/"
// export default {
//   baseUrl: `${API}/api/v1/`,
//   imageUrl: "https://stagging.luxurylife.ai/uploads/",
// };
 
 