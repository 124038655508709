import HowItWorks from "src/Component/Profile/user-profile/profilephotouploadsteps/HowItWorks"
import ProfilePhotoUploadSteps from "src/Component/Profile/user-profile/profilephotouploadsteps/ProfilePhotoUploadSteps"
import AllowCameraAccess from "../account-settings/VerifyYourProfileQRCode"
import CompleteYourSelfie from "src/Component/Profile/user-profile/profilephotouploadsteps/CompleteYourSelfie"
import CompleteYourProfile from "src/Component/Profile/user-profile/profilephotouploadsteps/CompleteYourProfile"
import TakePhoto from "src/Component/Profile/user-profile/profilephotouploadsteps/TakePhoto"
import ThankYouForSelfie from "src/Component/PermissionsSettings/ThankYouForSelfie"
import VerifyYourProfileQRCode from "../account-settings/VerifyYourProfileQRCode"

const SelfieVerificationComponent = ({ currentStep, setCurrentStep,source }) => {
    return (
        <>
            {currentStep === 0 && <ProfilePhotoUploadSteps />}
            {currentStep === 1 && <HowItWorks />}
            {currentStep === 2 && <CompleteYourProfile />}
            {currentStep === 3 && <TakePhoto setCurrentStep={setCurrentStep} source={source}/>}
            {currentStep === 4 && <ThankYouForSelfie source={source}/>}

            {/* {currentStep === 0 && <CompleteYourSelfie />}  */}
            {/* {currentStep === 0 && <AllowCameraAccess setCurrentStep={setCurrentStep} />}  */}
            {/* {currentStep === 0 && <VerifyYourProfileQRCode setCurrentStep={setCurrentStep} />} */}
        </>
    )
}

export default SelfieVerificationComponent