import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { uploadPublicFileAPI } from "src/api/FileApi";
import { signupUpdateAPI } from "src/api/SignupApi";
import { toast } from 'react-toastify';
import { getAuthInfoAction } from "src/containers/auth-container/actions/AuthActions";

const IdentityUpload = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [documentType,setDocumentType] = useState('passport')
  const [documentNumber, setDocumentNumber] = useState('')

  const handleImageUpload = async (e, setImage, type) => {
    const file = e.target.files[0];
    if(!documentType||!documentNumber){
      toast.error('Please enter document type and number')
      return
    }
    if (file) {
      const payload = { file, by: user._id };
      const uploadResponse = await uploadPublicFileAPI(payload);
      if (uploadResponse.data.data.url) {
        setImage(URL.createObjectURL(file)); // Preview ke liye URL generate kare
        const updatePayload = {
          signupId: user._id,
          data: { step: 23, accountUpdatePayload: { documents: { [type]: uploadResponse.data.data.url, type:documentType, number:documentNumber,status:"Pending" } } }
        };
        const updateResponse = await signupUpdateAPI(updatePayload);
        dispatch(getAuthInfoAction());

        if (!updateResponse.data.errors) {
          // setCurrentStep(6);
        } else {
          toast.error(updateResponse.data.message);
          // handleRetake();
        }
      } else {
        toast.error('Error during file upload: ' + uploadResponse.data.message);
        // handleRetake();
      }
    }
  };

  return (
    <div className="FrontBackPicMn" style={{ textAlign: "center", margin: "20px" }}>
      <h3 className="text-center font-32">Upload Your Identity</h3>
      <p className="text-center font-18" style={{ color: 'rgba(0,0,0,0.7)' }}>
        We need to confirm it’s really you! Follow these quick steps to
        complete the selfie verification.
      </p>
      <div className="d-flex justify-content-between gap-3 mb-24 flex-column">
        <select className="form-select" value={documentType} onChange={(e) => setDocumentType(e.target.value)}>
          <option value={"passport"}>Passport</option>
          <option value={"dl"}>Driving License </option>
          <option value={"gov_id"}>Other Government Issued ID </option>
        </select>
        <input type="text" placeholder="Enter Document Number" value={documentNumber} onChange={(e) => setDocumentNumber(e.target.value)} className="form-control rounded-2" />
      </div>

      <div className="FrontBackPic" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        {/* Front Image Upload */}
        <div className="w-100">
          <div
            style={{
              maxWidth: "265px",
              width: "100%",
              height: "158px",
              border: "2px dashed #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px", borderRadius: '15px', overflow: 'hidden'
            }}
          >
            {frontImage ? (
              <img
                src={frontImage}
                alt="Front Preview"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <span>+</span>
            )}
          </div>
          <div className="text-center font-14 color7b fw-medium mb-16">Front</div>
          <div className="handleImageUpload"> <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, setFrontImage, "front")}
          /> Upload</div>
        </div>

        {/* Back Image Upload */}
        <div className="w-100">
          <div
            style={{
              maxWidth: "265px",
              width: "100%",
              height: "158px",
              border: "2px dashed #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px", borderRadius: '15px', overflow: 'hidden'
            }}
          >
            {backImage ? (
              <img
                src={backImage}
                alt="Back Preview"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <span>+</span>
            )}
          </div>
          <div className="text-center font-14 color7b fw-medium mb-16">Back</div>
          <div className="handleImageUpload"> 
            <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e, setBackImage, "back")}
          /> Upload
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentityUpload