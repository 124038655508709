import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LuxuryFooter from 'src/components/luxury-home/LuxuryFooter'
import Header from 'src/containers/home-container/layout/Header'

const sections = [
    {
        id: "Rigorous",
        title: "Rigorous Member Verification",
        content: (
            <>
                <p>To uphold the integrity of our exclusive community, every member undergoes a meticulous verification process. This includes identity confirmation, professional background checks, and personal referrals where applicable. This ensures that all members meet the highest standards and fosters a trustworthy environment.</p>
            </>
        )
    },
    {
        id: "End-to-End",
        title: "End-to-End Data Security",
        content: (
            <>
                <p>Our platform employs state-of-the-art encryption and cybersecurity protocols to safeguard personal and financial data. We use advanced authentication systems to prevent unauthorized access and continuously monitor our security infrastructure to protect against potential threats.</p>
            </>
        )
    },
    {
        id: "Confidentiality & Privacy",
        title: "Confidentiality & Privacy Controls",
        content: (
            <>
                <p>Members have full control over their privacy settings, allowing them to customize visibility and communication preferences. Our strict confidentiality policy ensures that personal details, conversations, and shared information remain protected and accessible only to authorized individuals.</p>
            </>
        )
    },
    {
        id: "Reporting & Moderation",
        title: "Reporting & Moderation System",
        content: (
            <>
                <p>We encourage members to report any suspicious activity, inappropriate behavior, or breaches of our community guidelines. Our dedicated moderation team promptly investigates all reports and takes appropriate action, which may include account suspension or permanent removal to maintain a respectful and secure networking space.</p>
            </>
        )
    },
    {
        id: "Secure Communication",
        title: "Secure Communication Channels",
        content: (
            <>
                <p>All interactions on Luxury Life occur through highly secure messaging and video conferencing systems. We employ AI-driven security features to detect and prevent fraud, phishing attempts, or any form of malicious behavior.</p>
            </>
        )
    },
    {
        id: "Zero-Tolerance",
        title: "Zero-Tolerance Policy for Harassment & Fraud",
        content: (
            <>
                <p>We enforce a zero-tolerance policy for harassment, discrimination, fraudulent activities, and any behavior that compromises the integrity of our elite network. Any violation results in swift disciplinary action, ensuring a safe and professional community for all members.</p>
            </>
        )
    },
    {
        id: "Safety Tips",
        title: "Safety Tips for Members",
        content: (
            <>
                <ul>
                    <li>Always verify the authenticity of connections before sharing personal or financial information.</li>
                    <li>Avoid clicking on suspicious links or sharing sensitive details through unsecured channels.</li>
                    <li>Report any suspicious behavior to our security team immediately.</li>
                    <li>Use the platform’s built-in communication features for all interactions rather than third-party apps.</li>
                </ul>
            </>
        )
    },
    {
        id: "Our Commitment",
        title: "Our Commitment to Your Safety",
        content: (
            <>
                <p>At Luxury Life, we are committed to providing a premium and secure networking experience. Our dedicated team works tirelessly to maintain the highest safety standards, ensuring that you can connect with confidence in an environment that prioritizes discretion, professionalism, and security.</p>

                <p>For further inquiries or to report a safety concern, please contact our security team at <Link to="mailto:support@luxurylife.ai" style={{}}>support@luxurylife.ai</Link>.</p>
            </>
        )
    },
];
const SafetyAndSecurity = () => {
    const [activeSection, setActiveSection] = useState("");

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.pageYOffset;
            sections.forEach(({ id }) => {
                const section = document.getElementById(id);
                if (section) {
                    const vwOffset = (5 / 100) * window.innerWidth; // 5vw ko px me convert kiya
                    const sectionTop = section.offsetTop - vwOffset;
                    const sectionHeight = section.offsetHeight;
                    if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                        setActiveSection(id);
                    }
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [sections]);

    // ✅ `handleNavClick` ko useEffect ke bahar define karein
    const handleNavClick = (event, id) => {
        event.preventDefault();
        const section = document.getElementById(id);
        if (section) {
            const vwOffset = (4 / 100) * window.innerWidth; // 4vw ko px me convert kiya
            const sectionTop = section.offsetTop - vwOffset;

            window.scrollTo({
                top: sectionTop,
                behavior: "smooth",
            });

            setActiveSection(id); // Active section update karein
        }
    };
    return (
        <>
            <div className='aboutusnEwv1' style={{ padding: '5vw 20.5vw' }}>
                <Header />
                <div className="d-flex Terms-privCont">
                    <div className="privCont001" style={{ minWidth: '23vw', paddingTop: '2vw', }}>
                        <div className="position-sticky hdtoptermpriv" style={{ top: '4vw' }}>
                            <h2 className="text-black font48vw fw-bold">Safety and Security  </h2>
                            <p className="color7b font08vw"  style={{ marginBottom: '1vw' }}>At Luxury Life, the privacy and security of our elite members are of the utmost importance. We implement stringent measures to ensure a safe, respectful, and secure networking environment. Below are the key steps we take to maintain the highest standards of safety within our platform.</p>
                            <span className="d-flex"><img src="/assets/images/first-home/safety-and-security/safety-and-security.svg" className="img-fluid" alt="" style={{ width: '7vw', }} /></span>
                        </div>
                        {/* <nav className="position-sticky sidebar-TermsPrivacy">
            <ul className="navigation">
              {sections.map(({ id, title }) => (
                <li key={id}>
                  <a
                    href={`#${id}`}
                    className={activeSection === id ? "active" : ""}
                    onClick={(e) => handleNavClick(e, id)}
                  >
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </nav> */}
                    </div>
                    <div className="TermsPrivacy-content" style={{ minWidth: "32.5vw" }}>
                        {sections.map(({ id, title, content }) => (
                            <section id={id} key={id}>
                                <h2>{title}</h2>
                                {content}
                            </section>
                        ))}
                    </div>
                </div>
            </div>
            <LuxuryFooter />
        </>
    )
}

export default SafetyAndSecurity
