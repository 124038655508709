import React from "react";
import { Button, Menu, MenuItem, Fade, Box, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
  maxHeight: '80vh',
  overflow: 'auto',
};

const UserActionIcons = ({ icons,handleUserAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleSave = () => {
    console.log('Saved information:',);
    handleCloseModal();
};
  return (
    <div className="user-action-icons">
      {icons.map((iconData, index) => (
        <button
          key={index}
          onClick={()=>{handleUserAction(iconData.alt)}}
          className={`user-icon-button ${iconData.className}`}
        >
          <img
            src={iconData.icon}
            className="img-fluid"
            alt={iconData.alt}
          />
        </button>
      ))}

      <Button
        id="fade-button"
        className="more"
        aria-controls={menuOpen ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
      >
        <img src="/assets/images/user/user-profile/dotted1.svg" className="img-fluid" alt="" />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
      >
        <MenuItem
          className="d-flex gap-2 align-items-center"
          onClick={()=>{handleUserAction("Hide")}}
        >
          <span style={{ minWidth: '25px', textAlign: 'center' }}>
            <img src="/assets/images/hide.svg" className="img-fluid" alt="" />
          </span>
          Hide
        </MenuItem>
        <MenuItem
          className="d-flex gap-2 align-items-center"
          onClick={() => {
            handleUserAction("Block")
            handleCloseMenu();
          }}
        >
          <span style={{ minWidth: '25px', textAlign: 'center' }}>
            <img src="/assets/images/block.svg" className="img-fluid" alt="" />
          </span>
          Block
        </MenuItem>
        <MenuItem
          className="d-flex gap-2 align-items-center"
          onClick={handleCloseMenu}
        >
          <span style={{ minWidth: '25px', textAlign: 'center' }}>
            <img src="/assets/images/report.svg" className="img-fluid" alt="" />
          </span>
          Report
        </MenuItem>
      </Menu>

       
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box display="flex" justifyContent="flex-end">
                        <IconButton
                            onClick={handleCloseModal}
                            sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: '9' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
          <Typography id="modal-modal-title" sx={{fontSize: '32px', fontWeight: '500', color: '#000', textAlign: 'center', fontFamily: 'indivisible'}}>
          Are You Sure You Want to Block This Member? 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2,fontSize: '16px', fontWeight: '500', color: '#081726', textAlign: 'center', fontFamily: 'indivisible' }}>
          Blocking someone means they will no longer be able to contact you, view your profile, or interact with you in any way.  
          </Typography> 
          <Typography id="modal-modal-description" sx={{ mt: 2,fontSize: '16px', fontWeight: '500', color: '#081726', fontFamily: 'indivisible' }}>
            <span style={{fontSize: '16px', fontWeight: 'bold', color: '#081726',display: 'block',}}>Take a Moment to Decide</span>
          If you’re experiencing inappropriate behavior, consider reporting it instead. 
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2,fontSize: '16px', fontWeight: 'bold', color: '#EA4335', fontStyle: 'italic', fontFamily: 'indivisible' }}>
          Blocking is permanent, and it can’t be undone.
          </Typography>
             <Box display="flex" justifyContent="flex-end" gap={2} sx={{ margin: '32px 0', }}>
                                  <Button
                                      onClick={handleCloseModal}
                                      variant="outlined"
                                      sx={{ width:'100%', border: '1px solid rgb(155, 155, 155)', color: 'rgb(0, 0, 0)',textTransform: 'capitalize', fontSize: '15px',fontWeight: '600', }}
                                  >
                                      Report
                                  </Button>
                                  <Button 
                                      variant="outlined" 
                                      onClick={handleCloseModal}
                                      sx={{width:'100%',
                                        border: '1px solid rgb(155, 155, 155)', color: 'rgb(0, 0, 0)',textTransform: 'capitalize', fontSize: '15px',fontWeight: '600', 
                                      }}
                                  >
                                      Cancel
                                  </Button>
                              </Box>
           <Box gap={2} sx={{ marginTop: '16px', }}> 
                                  <Button
                                      onClick={handleSave}
                                      variant="contained"
                                      color="primary"
                                      sx={{ fontFamily: 'indivisible', fontSize: '15px', fontWeight: '600',
                                          background: '#916008',
                                          color: '#fff', width: '100%', textTransform: 'capitalize',
                                      }}
                                  >
                                      Proceed to Block
                                  </Button>
                              </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default UserActionIcons;
