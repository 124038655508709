export const HOME_ACTIONS = {
    HOME_SEARCH: 'HOME_SEARCH',
    HOME_SEARCH_SUCCESS: 'HOME_SEARCH_SUCCESS',
    HOME_SEARCH_ERROR: 'HOME_SEARCH_ERROR',

    HOME_UPSERT: 'HOME_UPSERT',
    HOME_UPSERT_SUCCESS: 'HOME_UPSERT_SUCCESS',
    HOME_UPSERT_ERROR: 'HOME_UPSERT_ERROR',

    HOME_EDIT: 'HOME_EDIT',

    HOME_RESET: 'HOME_RESET',

    GET_CURRENT_LOCATION: 'GET_CURRENT_LOCATION',
    SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',
    UPDATE_CURRENT_LOCATION: 'UPDATE_CURRENT_LOCATION',
};

export const homeResetAction = () => ({
    type: HOME_ACTIONS.HOME_RESET,
});

export const homeSearchAction = (payload) => ({
    type: HOME_ACTIONS.HOME_SEARCH,
    ...payload,
});

export const homeEditAction = (payload) => ({
    type: HOME_ACTIONS.HOME_EDIT,
    ...payload,
});

export const homeUpsertAction = (payload) => ({
    type: HOME_ACTIONS.HOME_UPSERT,
    ...payload,
});
export const getCurrentLocationAction = (payload) => ({
    type: HOME_ACTIONS.GET_CURRENT_LOCATION,
    ...payload
})
export const setCurrentLocationAction = (payload) => ({
    type: HOME_ACTIONS.SET_CURRENT_LOCATION,
    ...payload
})
export const updateCurrentLocationAction = (payload) => ({
    type: HOME_ACTIONS.UPDATE_CURRENT_LOCATION,
    ...payload
})

export default {
    HOME_ACTIONS,
    homeSearchAction,
    homeUpsertAction,
    homeEditAction,
    getCurrentLocationAction
};
