import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMemberDetailsAPI, sendPrivatePicRequestAPI } from "src/api/MembersApi";
import UserAboutText from "src/Component/Profile/user-profile/UserAboutText";
import UserActionIcons from "src/Component/Profile/user-profile/userActionIcons";
import UserPhotos from "src/Component/Profile/user-profile/UserPhotos";
import UserProfileDetails from "src/Component/Profile/user-profile/UserProfileDetails";
import UserProfileHeader from "src/Component/Profile/user-profile/UserProfileHeader";
import { time_ago } from "src/utils/CommonUtils";
import { memberUpsertAction } from "./actions/MemberActions";
import { setPendingAction } from "../chat-container/actions/ChatActions";
import ProfileDetailSkeleton from "./ProfileDetailSkeleton";


const MemberDetailsContainer = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth, shallowEqual);
    const { id } = useParams(); // Get the `id` parameter from the route
    const [memberData, setMemberData] = useState(null); // State to store member details
    const [loading, setLoading] = useState(true); // State to handle loading state
    const [error, setError] = useState(null);
    const [privatePickRequestStatus, setPrivatePickRequestStatus] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchMemberDetails = async () => {
            try {
                setLoading(true); // Set loading to true before the API call
                const response = await getMemberDetailsAPI(id); // Fetch data from the API
                if (response.data.data) {
                    setMemberData(response.data.data); // Update the member data state
                    setPrivatePickRequestStatus(response.data.data?.privatePicAccess?.status || null);
                }
            } catch (err) {
                console.error("Error fetching member details:", err);
                setError("Failed to load member details.");
            } finally {
                setLoading(false); // Set loading to false after the API call
            }
        };

        fetchMemberDetails(); // Trigger the async function
    }, [id]); // Dependency array ensures the effect runs when `id` changes

    if (loading) return <div className="container-fluid px-5vw"><ProfileDetailSkeleton /></div>;


    const userProfileData = {
        _id: memberData?._id,
        userName: memberData?.userName,
        profilePicture: memberData?.profilePicture,
        age: memberData?.age,
        isOnLine: memberData?.isOnLine,
        location: `${memberData?.city},${memberData?.country} • ${memberData.lastLocation?.distance==0?1:memberData.lastLocation?.distance} ${user?.preferredMeasurement? "km" : "mile"}`,//"New Delhi, India600 miles",
        subheading: memberData?.myHeading || "Not available",
        description: memberData?.aboutUsDescription || "Not available",
        profileImage: memberData?.profilePicture,
        infoCards: [
            { icon: "/assets/images/user/user-profile/height-icon.svg", value: `${memberData?.tall?.cm.toFixed(0)} cm`, label: "Height" },
            { icon: "/assets/images/user/user-profile/member-since.svg", value: time_ago(memberData.createdAt), label: "Member Since" },
            { icon: "/assets/images/user/user-profile/curvy-icon.svg", value: memberData.bodyType || "NA", label: "Body" },
            { icon: "/assets/images/user/user-profile/single-icon.svg", value: memberData.currentRelationshipStatus || "NA", label: "Relationship status" },
            { icon: "/assets/images/user/user-profile/verification.svg", value: memberData.isProfileVerified ? "Verified" : "Not Verified", label: "Profile Verification" },
        ],
    };
    const firstGalleryImages = memberData.publicPhotos || [];
    // [
    //     { thumb: '/assets/images/user/img1.png', full: '/assets/images/user/img1.png' },
    //     { thumb: '/assets/images/user/img2.png', full: '/assets/images/user/img2.png' },
    //     { thumb: '/assets/images/user/img3.png', full: '/assets/images/user/img3.png' },
    //     { thumb: '/assets/images/user/img4.png', full: '/assets/images/user/img4.png' },
    //     { thumb: '/assets/images/user/img5.png', full: '/assets/images/user/img5.png' },
    //     { thumb: '/assets/images/user/img6.png', full: '/assets/images/user/img6.png' },
    //     { thumb: '/assets/images/user/img7.png', full: '/assets/images/user/img7.png' },
    // ];


    const aboutContent = [
        {
            title: "About Me",
            paragraphs: [
                `${memberData?.aboutUsDescription || "Not available"}`,
            ],
        },
        {
            title: "What I am Seeking",
            paragraphs: [
                `${memberData?.preferences?.aboutPartnerDescription || "Not available"}`,
            ],
        },
    ];


    const userActionIcons = [
        {
            className: `favorite ${memberData.isLiked ? 'active' : ''}`,
            icon: memberData.isLiked
                ? "/assets/images/user/heart-iconr.svg"
                : "/assets/images/user/heart-iconb.svg",
            alt: "Favorite",
        },
        {
            className: "close",
            icon: "/assets/images/user/user-profile/cross-icon.svg",
            alt: "Close",
        },
        {
            className: "chat",
            icon: "/assets/images/user/user-profile/messages.svg",
            alt: "Chat",
        },
    ];
    const handleUserAction = async (alt) => {
        if (alt === "Favorite") {
            const actionType = memberData.isLiked ? 'UNLIKE' : 'LIKE';
            dispatch(memberUpsertAction({ action: actionType, _id: user._id, data: { targetUserId: memberData._id, action: actionType } }));
            setMemberData(prevData => ({ ...prevData, isLiked: !prevData.isLiked }));
        }
        else if (alt === "Close") {
            dispatch(memberUpsertAction({ action: 'hide', _id: user._id, data: { targetUserId: memberData._id, action: 'DISLIKE' } }));
            navigate('/members');
        }
        else if (alt === "Hide") {
            dispatch(memberUpsertAction({ action: 'hide', _id: user._id, data: { targetUserId: memberData._id, action: 'HIDE' } }));
            navigate('/members');
        }
        else if (alt === "Block") {
            dispatch(memberUpsertAction({ action: 'hide', _id: user._id, data: { targetUserId: memberData._id, action: 'BLOCK' } }));
            navigate('/members');
        }
        else if (alt === "Chat") {
            const modifiedProfile = { ...memberData, _id: memberData?._id };
            console.log('modifiedProfile=>', modifiedProfile);
            dispatch(setPendingAction({ data: { user: modifiedProfile, text: null } }));
            localStorage.removeItem('chatRoomId');
            navigate('/chat');
        }
    };
    const handlePrivatePicRequest = () => {
        const payload = {
            targetUserId: user._id,
            receiverId: memberData._id
        }
        sendPrivatePicRequestAPI(payload)
        setPrivatePickRequestStatus("Pending");
    }
    return (
        <>
            <div className="position-relative z-2">
                <section className="border-top pt-2vw">
                    <div className="container-fluid  px-5vw">
                        <div className="user-profile-card">
                            <UserProfileHeader userData={userProfileData} />
                        </div>
                        <div className="d-flex align-items-start UserProfileDetailsDvXc">
                            <div className="UserProfileDetailsDv w-100">
                                <UserProfileDetails userData={userProfileData} />
                            </div>
                            <div className="w-100 border-start pxpt Profile-R-Details">
                                <div className="w-100 userPhoto01">
                                    <div className="font-24 font-m fw-medium text-black pb-16vw">Photo</div>
                                    <UserPhotos images={firstGalleryImages} defaultLimit={6} />
                                </div>
                                <div className="PrivatePhotos py-32vw">
                                    <div
                                        className="d-flex justify-content-between align-items-center font-24 font-m fw-medium text-black pb-16vw"
                                    >
                                        Private Photos
                                        <span>
                                            {
                                                !memberData?.privatePicAccess || memberData.privatePicAccess?.length === 0 ? (
                                                    // Case 1: If privatePicAccess is empty, show lock icon
                                                    <img src="/assets/images/user/user-profile/lock.svg" className="img-fluid" alt="Lock" onClick={handlePrivatePicRequest} />
                                                ) : memberData?.privatePicAccess?.status === "Pending" || privatePickRequestStatus === "Pending" ? (
                                                    // Case 2: If status is "Pending", show pending icon or status
                                                    <span className="text-warning">Pending</span>
                                                ) : memberData?.privatePicAccess?.status === "Rejected" ? (
                                                    // Case 3: If status is "Rejected", show rejected status
                                                    <span className="text-danger">Rejected</span>
                                                ) : memberData?.privatePicAccess?.status === "Accepted" ? (
                                                    // Case 4: If status is "Approve", show unlock icon
                                                    <img src="/assets/images/user/user-profile/unlock.svg" className="img-fluid" alt="Unlock" />
                                                ) : (
                                                    // Default: If none of the above cases match, show lock icon
                                                    <img src="/assets/images/user/user-profile/lock.svg" className="img-fluid" alt="Lock" onClick={handlePrivatePicRequest} />
                                                )
                                            }
                                        </span>
                                    </div>
                                    <UserPhotos 
                                            images={memberData?.privatePhotos || [""]}
                                            defaultLimit={1}
                                            onClick={handlePrivatePicRequest}  
                                            showRequestView={privatePickRequestStatus !== "Accepted"}
                                            privatePickRequestStatus={privatePickRequestStatus}  
                                    />
                                </div>
                                <UserAboutText content={aboutContent} />
                            </div>
                        </div>
                    </div>
                </section>

                <UserActionIcons icons={userActionIcons} handleUserAction={handleUserAction} />
                {/* <Footer /> */}
            </div>
        </>
    )
}
export default MemberDetailsContainer