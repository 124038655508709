import { put, call, select } from 'redux-saga/effects';
import { profileCreateAPI, profileSearchAPI, profileUpdateAPI } from 'src/api/ProfileApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { PROFILE_ACTIONS } from '../actions/ProfileActions';
import { getPrivatePicAccessRequestAPI, setPrivatePicRequestAccessStatusAPI } from 'src/api/AccountSettingApi';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(profileSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: PROFILE_ACTIONS.PROFILE_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: PROFILE_ACTIONS.PROFILE_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(profileSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {

            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }


        yield put({
            type: PROFILE_ACTIONS.PROFILE_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(profileCreateAPI, payload.data);
        yield put({
            type: PROFILE_ACTIONS.PROFILE_SEND_OTP,
            data: {
                email: payload.data.email,
                // _id: data.user._id,
            },
        });
        // yield put({
        //     type: PROFILE_ACTIONS.PROFILE_SEARCH
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: PROFILE_ACTIONS.PROFILE_UPSERT_ERROR,
        });
    }
}

function* update(profileId, payload) {
    try {
        yield call(profileUpdateAPI, {
            profileId,
            data: payload.data,
        });
        // yield put(headerShowAction({
        //     data: 'Updated',
        // }));
        // yield put({
        //     type: PROFILE_ACTIONS.PROFILE_SEARCH
        // });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: PROFILE_ACTIONS.PROFILE_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}
function* getPrivatePicRequest({currentPage = 0}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(getPrivatePicAccessRequestAPI,{currentPage});
        yield put({
            type: PROFILE_ACTIONS.SET_PRIVATE_PIC_REQUEST,
            data,
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}
function* updatePrivatePicRequest(payload) {
    try {
        yield call(setPrivatePicRequestAccessStatusAPI,{
            data: payload.data,
        });
        yield put({
            type: PROFILE_ACTIONS.GET_PRIVATE_PIC_REQUEST
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

export default [
    takeFirstSagaUtil(PROFILE_ACTIONS.PROFILE_SEARCH, search),
    takeFirstSagaUtil(PROFILE_ACTIONS.PROFILE_UPSERT, upsert),
    takeFirstSagaUtil(PROFILE_ACTIONS.PROFILE_EDIT, edit),
    takeFirstSagaUtil(PROFILE_ACTIONS.GET_PRIVATE_PIC_REQUEST, getPrivatePicRequest),
    takeFirstSagaUtil(PROFILE_ACTIONS.UPDATE_PRIVATE_PIC_REQUEST, updatePrivatePicRequest),
];
