import React from 'react';

const sections = [
  {
    title: 'Unparalleled Access to Influential Connections',
    description: 'Our exclusive network brings together the most accomplished and influential individuals from various industries. Whether you are a business mogul, a creative visionary, or a high-profile professional, our platform ensures that every interaction is purposeful. You will engage with like-minded individuals who share your ambitions, lifestyle, and vision.',
    image: '/assets/images/first-home/unparalleled-access-to-influential-connections.png',
    reverse: false,
  },
  {
    title: 'Exclusive Content and Insights',
    description: 'Access a wealth of premium, expertly curated content designed to keep you informed and ahead of industry trends. From in-depth articles to exclusive interviews and insightful webinars, our platform provides valuable knowledge tailored to the needs and interests of high-caliber professionals like yourself, ensuring continuous growth.',
    image: '/assets/images/first-home/exclusive-content-and-insights.png',
    reverse: true,
  },
  {
    title: 'Private Forum for Thoughtful Discussions',
    description: 'Engage in private, members-only discussions on topics that matter most to you. Our exclusive forum fosters meaningful exchanges where you can ask questions, share ideas, and receive perspectives from other accomplished professionals. This space encourages thoughtful dialogue, ensuring valuable connections and insights are always within reach.',
    image: '/assets/images/first-home/private-forum-for-thoughtful-discussions.png',
    reverse: false,
  },
  {
    title: 'Encrypted Messaging for Secure Communication',
    description: 'Communicate confidently with our end-to-end encrypted messaging system, ensuring that your conversations remain private and secure. Whether you are discussing sensitive business matters or personal topics, our platform prioritizes your privacy with state-of-the-art encryption, allowing you to connect with trusted professionals without concerns over data breaches or unauthorized access.',
    image: '/assets/images/first-home/encrypted-messaging-for-secure-communication.png',
    reverse: true,
  },
];

const WhyJoinOurEliteNetwork = () => {
  return (
    <div style={{ padding: '5vw 5.5vw' }}>
      <h2 className='text-center font48vw' style={{ marginBottom: '4vw' }}>
        Why Join Our Elite Network?
      </h2>
      <div className='d-flex flex-column WhyJoinbg001' style={{ gap: '3vw' }}>
        {sections.map((section, index) => (
          <div key={index} >
            {section.reverse ? (
              <div className='WhyJoinbg d-flex justify-content-between' style={{ gap: '3vw' }}>
                <div className='img'>
                  <img src={section.image} className='img-fluid' alt={section.title} />
                </div>
                <div className='text d-flex justify-content-center flex-column' style={{ padding: '1vw 4vw 1vw 2vw' }}>
                  <h3 className='font-e fst-italic'>
                    {section.title.split(' ').slice(0, -1).join(' ')}
                    <span className='d-block font-e fst-italic'> {section.title.split(' ').slice(-1)}</span>
                  </h3>
                  <p>{section.description}</p>
                </div>
              </div>
            ) : (
              <div className='WhyJoinbg d-flex justify-content-between reverseDv' style={{ gap: '3vw' }}>
                <div className='text d-flex justify-content-center flex-column' style={{ padding: '1vw 4vw 1vw 0' }}>
                  <h3 className='font-e fst-italic'>
                    {section.title.split(' ').slice(0, -1).join(' ')}
                    <span className='d-block font-e fst-italic'> {section.title.split(' ').slice(-1)}</span>
                  </h3>
                  <p>{section.description}</p>
                </div>
                <div className='img'>
                  <img src={section.image} className='img-fluid' alt={section.title} />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyJoinOurEliteNetwork;
