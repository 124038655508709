import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ProfilePropTypes = PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
});

export const ProfileDefaultProps = {
    userName: '',
    email: '',
    Step: 1,
    dateOfBirth: '',
    gender: '',
    tall: {
        unit: 'cm',
        value: 0
    },
    bodyType: '',
    ethnicity: '',
    hobbies: [],
    smoke: '',
    drink: '',
    luxuryInterests: [],
    aboutUsDescription: '',
    myHeading: '',
    highestEducation: '',
    workField: '',
    currentRelationshipStatus: '',
    children: '',
    incomeRange: '',
    netWorthRange: '',
    bio: '',
    location: {
        type: "Point",
        coordinates: [
            0, //longitude
            0  //latitude
        ]
    },

    preferences: {
        ageRange: { min: 18, max: 70 },
        gender: ['Female'],
        distance: 700,
        aboutPartnerDescription: ''
    },
    city: '',
    state: '',
    country: '',
    realTimePicture: '',
    profilePicture: null,
    publicPhotos: Array(4).fill(null),
    privatePhotos: Array(4).fill(null),
    securityQuestions: [],
    otp: '',
    password: '',
    confirmPassword: '',
    ReCAPTCHAToken: 'asdasdsdasdasdasd',
    isTermsAndConditionsAccepted: false,
    isOtpVerified: false,
    isProfileVerified: false,
    isIdVerified: false,
    securityQuestionsIds: [],
    localStep: 1,
    maxStep: 1,
    steps: Array(25).fill(false),
    _id: undefined
};

export const ProfileYupSchema = Yup.object().shape({
    // firstName: Yup.string().required('Required'),
    // phoneNumber: Yup.number().required("Required").positive().integer().min(9, 'Invalid phone number'),
    // email: Yup.string().required('Required').email('Invalid email format'),
    // panCardNo: Yup.string()
    // .required('Required')
    // .matches(
    //   /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //   'Invalid PAN card format. It should be 10 characters: first 5 letters, then 4 numbers, and last 1 letter.'
    // ),
    // accountNumber: Yup.number().required("Required").positive("Invalid account number").integer("Invalid account number"),
    // ifscCode: Yup.string().required('Required'),
    // bankName: Yup.string().required('Required'),
    // state: Yup.string().required('Required'),
    // city: Yup.string().required('Required'),
    // pincode: Yup.string().required('Required'),
    // services: Yup.string().required('Required'),
    // accountHolderName: Yup.string().required('Required'),
    // accountNumber: Yup.string().required('Required'),
    // documentType: Yup.string().required('Required'),
});
export const validatePayload = (step, payload) => {
    switch (step) {
        case 1: // Validation for step 1 (Gender Interest Selection)
            if (!payload.data.gender || !payload.data.preferences || payload.data.preferences.length === 0) {
                throw new Error("Gender and at least one interest are required for this step.");
            }
            break;
        case 2: // Validation for step 2 (Date of Birth Selection)
            if (!payload.data.dateOfBirth) {
                throw new Error("Date of Birth is required for this step.");
            }
            break;
        case 3: // Validation for step 3 (Tall Selection)
            if (!payload.data.isTermsAndConditionsAccepted) {
                throw new Error("Please accept the terms and conditions to go ahead.");
            }
            break;
        case 4: // Validation for step 4 (Hobbies Selection)
            if (!payload.data.email) {
                throw new Error("Email is required for this step.");
            }
            if (!payload.data.ReCAPTCHAToken) {
                throw new Error("ReCAPTCHA is required for this step.");
            }
            break;
        case 5: // Validation for step 5 (Verify OTP)
            if (!payload.data.email || !payload.data.otp) {
                throw new Error("Email and OTP are required for this step.");
            }
            break;
        case 6: // Validation for step 6 (Create Password)
            const { password, confirmPassword } = payload.data;
            if (!password || !confirmPassword) {
                throw new Error("Password and Confirm Password are required for this step.");
            }
            if (password !== confirmPassword) {
                throw new Error("Password does not match.");
            }
            break;
        case 7: // Validation for step 7 (Update Security Question)
            if (!payload.data.questions || payload.data.questions.length === 0) {
                throw new Error("At least one security question are required for this step.");
            }
            break;
        case 8: // Validation for step 8
            if (!payload.accountUpdatePayload.userName) {
                throw new Error("User Name is required for this step.");
            }
            break;
        case 9: // Validation for step 9
            const { location, city, state, country } = payload.accountUpdatePayload;
            if (!location || !city || !state || !country) {
                throw new Error("All location fields are required for this step.");
            }
            break;
        case 10: // Validation for step 10
            if (!payload.accountUpdatePayload.tall) {
                throw new Error("Tall is required for this step.");
            }
            break;
        case 11: // Validation for step 11
            if (!payload.accountUpdatePayload.bodyType) {
                throw new Error("Body Type is required for this step.");
            }
            break;
        case 12: // Validation for step 12
            if (!payload.accountUpdatePayload.ethnicity) {
                throw new Error("Ethnicity is required for this step.");
            }
            break;
        case 13: // Validation for step 13
            if (!payload.accountUpdatePayload.highestEducation) {
                throw new Error("Highest Education is required for this step.");
            }
            break;
        case 14: // Validation for step 14
            if (!payload.accountUpdatePayload.workField) {
                throw new Error("Work Field is required for this step.");
            }
            break;
        case 15: // Validation for step 15
            if (!payload.accountUpdatePayload.currentRelationshipStatus) {
                throw new Error("Current Relationship Status is required for this step.");
            }
            break;
        case 16: // Validation for step 16
            if (!payload.accountUpdatePayload.children) {
                throw new Error("Children is required for this step.");
            }
            break;
        case 17: // Validation for step 18
            if (!payload.accountUpdatePayload.netWorthRange) {
                throw new Error("Net Worth Range is required for this step.");
            }
            break;
        case 18: // Validation for step 19
            if (!payload.accountUpdatePayload.preferences) {
                throw new Error("Preferences is required for this step.");
            }
            break;
        case 19: // Validation for step 20
            if (!payload.accountUpdatePayload.hobbies) {
                throw new Error("Hobbies is required for this step.");
            }
            break;
        case 20: // Validation for step 21
            if (!payload.accountUpdatePayload.smoke) {
                throw new Error("Smoke is required for this step.");
            }
            break;
        case 21: // Validation for step 22
            if (!payload.accountUpdatePayload.drink) {
                throw new Error("Drink is required for this step.");
            }
            break;
        case 22: // Validation for step 23
            if (!payload.accountUpdatePayload.luxuryInterests) {
                throw new Error("Luxury Interests is required for this step.");
            }
            break;
        case 23: // Validation for step 24
            if (!payload.accountUpdatePayload.preferences.aboutPartnerDescription) {
                throw new Error("About Partner Description is required for this step.");
            }
            break;
        case 24: // Validation for step 25
            if (!payload.accountUpdatePayload.profilePicture) {
                throw new Error("Profile Picture is required for this step.");
            }
            break;
        case 25: // Validation for step 26
            if (!payload.accountUpdatePayload.myHeading) {
                throw new Error("My Heading is required for this step.");
            }
            break;
        case 26: // Validation for step 27
            if (!payload.accountUpdatePayload.aboutUsDescription) {
                throw new Error("Real Time Picture is required for this step.");
            }
            break;
        case 27: // Validation for step 28
            if (!payload.accountUpdatePayload.realTimePicture) {
                throw new Error("Real Time Picture is required for this step.");
            }
        // Add more validation cases for other steps
        default:
            break;
    }
};
export const ProfilePaymentYupSchema = Yup.object().shape({
    date: Yup.string().required('Required'),
    amount: Yup.number().required("Required").positive().integer().min(1, 'Invalid Amount'),
});

export default {
    ProfileDefaultProps,
    ProfilePropTypes,
    ProfileYupSchema,
};
