import React from 'react'
import { Link } from 'react-router-dom'
import LuxuryFooter from 'src/components/luxury-home/LuxuryFooter'
import Header from 'src/containers/home-container/layout/Header'
import ContactForm from './ContactForm'

const SupPort = () => {
  return ( 
    <>
      <div className='aboutusnEwv1' style={{ padding: '5vw 5.5vw' }}>
                <Header />
                <div className='aboutusTextNewbg'>
                    <div className='aboutusTextNew'>
                        <h2 className='fw-semibold font-m font48vw mb-0' style={{ padding: '2vw 0' }}>Support</h2>
                        <p className='fw-normal font-m font24vw mb-0'>We are committed to providing exceptional support to our exclusive members. Whether you need assistance with your account, have technical issues, or require membership guidance, our dedicated support team is here to help.</p>
                    </div>
                </div>   
                <div className='d-flex justify-content-between contactbgText'>
                    <div className='contactLeftText' style={{ background: '#F4F2EF', }}>
                        <div className='text singal'>
                            <h4 className='font04vw fw-bold mb-0'>Frequently Asked Questions (FAQs) </h4>
                            <p className='color7b font08vw mb-0'>Before reaching out, check our comprehensive FAQ section for quick answers to common inquiries.</p>
                            <Link to="/faqs" className='textRedLik font20vw'>Read</Link>  
                               </div>
                     <div className='d-flex justify-content-between twogridContactus'>
                        <div className='w-100 text'>
                            <h4 className='font04vw fw-bold mb-0'>Membership Assistance</h4>
                            <p className='color7b font08vw mb-0'>For queries related to membership applications, approvals, or upgrades, please contact our membership support team.</p>
                            <Link to="mailto:support@luxurylife.ai" className='textRedLik font08vw'>support@luxurylife.ai</Link>
                        </div>
                        <div className='w-100 text'>
                            <h4 className='font04vw fw-bold mb-0'>Technical Support</h4>
                            <p className='color7b font08vw mb-0'>Experiencing technical difficulties with your account or platform access? Our IT support team is available to resolve your issues promptly.</p>
                            <Link to="mailto:support@luxurylife.ai" className='textRedLik font08vw'>support@luxurylife.ai</Link>
                        </div> 
                        </div> 
                <div className='d-flex justify-content-between twogridContactus'>
                <div className='w-100 text'>
                    <h4 className='font04vw fw-bold mb-0'>Billing & Subscription Support</h4>
                       <p className='color7b font08vw mb-0'>For inquiries regarding billing, invoices, or subscription plans, please contact our finance support team</p>
                    <Link to="mailto:support@luxurylife.ai" className='textRedLik font08vw'>support@luxurylife.ai</Link>
                </div>
                <div className='w-100 text'>
                    <h4 className='font04vw fw-bold mb-0'>Report a Concern</h4>
                    <p className='color7b font08vw mb-0'>If you encounter suspicious activity or need to report a security issue, please reach out to our security team.</p>
                    <Link to="mailto:support@luxurylife.ai" className='textRedLik font08vw'>support@luxurylife.ai</Link>
                </div>
            </div>
            </div>
            <div className='contactusImg CFcontactusImg'>  
          <ContactForm />

            </div>
            </div> </div>
            <LuxuryFooter />

            </>
   
  )
}

export default SupPort
