import React, { useState } from 'react';
import { TextField, Box, InputAdornment, IconButton, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
const LoginForm = ({ setCredential,captchaValue, credential }) => {
  const handleEmailChange = (e) => setCredential({ ...credential, userName: e.target.value });
  const handlePasswordChange = (e) => setCredential({ ...credential, password: e.target.value });
   const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        margin: 'auto',
      }}
    >
      <TextField
        placeholder="Email"
        type="email"
        autoComplete="off"
        variant="outlined"
        fullWidth
        required
        // disabled={!captchaValue}
        value={credential.userName}
        onChange={handleEmailChange}
        sx={{
          height: '44px',
          overflow: 'hidden',
          borderRadius: '15px',
          '& .MuiOutlinedInput-root': {
            height: '44px',
            borderRadius: '15px',
            '& fieldset': {
              borderColor: '#ccc',
            },
            '&:hover fieldset': {
              borderColor: '#916008',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#916008',
              borderWidth: '1px',
            },
          },
        }}
      />
      <TextField
        placeholder="Password" 
        type={showPassword ? 'text' : 'password'}
          value={credential.password}
        autoComplete="off"
        variant="outlined"
        fullWidth
        required 
        // disabled={!captchaValue}
        onChange={handlePasswordChange}
        InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff /> }
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
        sx={{
          height: '44px',
          overflow: 'hidden',
          borderRadius: '15px',
          '& .MuiOutlinedInput-root': {
            height: '44px',
            borderRadius: '15px',
            '& fieldset': {
              borderColor: '#ccc',
            },
            '&:hover fieldset': {
              borderColor: '#916008',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#916008',
              borderWidth: '1px',
            },
          },
        }}
      />
    </Box>
  );
};

export default LoginForm;
