import * as React from "react";
import { Link, useLocation } from "react-router-dom"; 
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

const LabelBottomNavigation = () => {
  const location = useLocation();
  const [value, setValue] = React.useState("/members");

  React.useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  return (
    <BottomNavigation className="BottomNavigation"
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      sx={{
        display: { xs: "flex", sm: "none" },
        position: "fixed",  
        zIndex: 1000,
        borderRadius: '100px',
        backgroundColor: "#2c1300",
        boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
      }}
    >
      <BottomNavigationAction className="BottomNavigationAction"
        component={Link}
        to="/members" 
        value="/members"
        icon={
          <img
            src={value === "/members" ? "/assets/images/mobile-nav/home-active.svg" : "/assets/images/mobile-nav/home.svg"}
            alt="Home" 
          />
        }
        sx={{
          color: value === "/members" ? "#FFD700" : "#FFFFFF", // Active: Gold, Inactive: White
        }}
      />
      <BottomNavigationAction className="BottomNavigationAction"
        component={Link}
        to="/favorites" 
        value="/favorites"
        icon={
          <img
            src={value === "/favorites" ? "/assets/images/mobile-nav/favorites-active.svg" : "/assets/images/mobile-nav/favorites.svg"}
            alt="Favorites" 
          />
        }
        sx={{
          color: value === "/favorites" ? "#FFD700" : "#FFFFFF",
        }}
      />
      <BottomNavigationAction className="BottomNavigationAction"
        component={Link}
        to="/chat" 
        value="/chat"
        icon={
          <img
            src={value === "/chat" ? "/assets/images/mobile-nav/chat-active.svg" : "/assets/images/mobile-nav/chat.svg"}
            alt="Chat" 
          />
        }
        sx={{
          color: value === "/chat" ? "#FFD700" : "#FFFFFF",
        }}
      />
      <BottomNavigationAction className="BottomNavigationAction"
        component={Link}
        to="/profile-edit" 
        value="/profile-edit"
        icon={
          <img
            src={value === "/profile-edit" ? "/assets/images/mobile-nav/profile-active.svg" : "/assets/images/mobile-nav/profile.svg"}
            alt="Profile" 
          />
        }
        sx={{
          color: value === "/profile-edit" ? "#FFD700" : "#FFFFFF",
        }}
      />
    </BottomNavigation>
  );
};

export default LabelBottomNavigation;
