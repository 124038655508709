import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';

const NetWorth = () => {
    const dispatch = useDispatch();
    const {
      netWorthRange,
    } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
    const [netWorth, setNetWorth] = useState(netWorthRange||"");
    
    // const handleNetWorth = (value) => {
    //     setNetWorth(value);
    //     dispatch(setPayload({ data: { netWorthRange: value } }));
    //   };

      
 const handleNetWorth = (value) => {
  // If the same option is clicked again, unselect it
  const newValue = netWorth === value ? "" : value;
  setNetWorth(newValue);
  dispatch(setPayload({ data: { netWorthRange: newValue } }));
};
  return (
    <div>
    <div className="">
     <div className="font-m font-32 fw-medium text-center">What Is Your Net Worth? </div>
     <div className="font-m font-18 colorf5 pt-6 text-center">
     Enter the approximate amount that best reflects your current financial standing.
     </div>
   </div> 
   <div className="netWorth-selection text-center"> 
     <div className="btnsetAll btnsetAll2 mt-32 mb-16">  
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "Below $200,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("Below $200,000")}>Below $200,000</button> 
              <button
             className={`font-m border font-16 fw-normal ${netWorth === "$200,000 - $500,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$200,000 - $500,000")}>$200,000 - $500,000</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$500,000 - $1 Million" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$500,000 - $1 Million")}>$500,000 - $1 Million</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$1 Million - $5 Million" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$1 Million - $5 Million")}>$1 Million - $5 Million</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "More Than $5 Million" ? "selected" : ""}`}
             onClick={() => handleNetWorth("More Than $5 Million")}>More Than $5 Million</button>             
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "Prefer not to say" ? "selected" : ""}`}
             onClick={() => handleNetWorth("Prefer not to say")}>Prefer not to say</button> 
                  
     </div> 
   </div>
 </div>
  )
}

export default NetWorth
