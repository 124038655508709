import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { FAVORITE_ACTIONS } from '../actions/FavoriteActions';
import { FavoriteDefaultProps } from '../FavoritesPropTypes';

const initialState = () => ({
    currentRecord: { ...FavoriteDefaultProps },
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        totalCount: 0,
    },
    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        case FAVORITE_ACTIONS.FAVORITE_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case FAVORITE_ACTIONS.FAVORITE_SEARCH_SUCCESS: {
            return {
                ...state,
                search: {
                    ...state.search,
                    ...action.data
                },
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case FAVORITE_ACTIONS.FAVORITE_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case FAVORITE_ACTIONS.FAVORITE_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case FAVORITE_ACTIONS.FAVORITE_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case FAVORITE_ACTIONS.FAVORITE_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        default:
            return state;
    }
};
