import React from 'react'

const TwoTierMembershipStructure = () => {
  const handlePlanClick = () => {
    window.location.href = '/exclusive-membership-plans';
  };
  return (
    <div style={{padding:'5vw 5.5vw'}}>
      <div className='col-md-8 mx-auto text-center'  style={{marginBottom: '4vw',}}> 
      <h2 className='text-center font48vw' style={{marginBottom: '1vw'}}>Two-Tier Membership Structure  </h2>
      <p className='font20vw'>Our platform offers two exclusive membership tiers, each designed to provide you with a personalized experience that aligns with your networking and professional goals. Choose the tier that best fits your aspirations and access a range of tailored benefits.</p>
      </div>

     <div className='d-flex justify-content-center imgTextplanBg'>
        <div className='text-center imgTextbg imgTextGold d-flex justify-content-between align-items-center flex-column'>
        <div className='imgText d-flex justify-content-between align-items-center flex-column'>
            <div className='img'><img src="/assets/images/first-home/gold-access-icon.svg" className='img-fluid' alt="" /></div>
            <h4 className='mb-0'>Gold Access </h4>
            <p className='mb-0'>Unlock access to curated content, high-level connections, and private discussion forums. Perfect for professionals who are looking to expand their network and stay informed with exclusive insights.</p>
        </div>
        <button onClick={handlePlanClick}>Discover your membership plan</button>
        </div>
        <div className='text-center imgTextbg imgTextDiamond d-flex justify-content-between align-items-center flex-column'>
        <div className='imgText d-flex justify-content-between align-items-center flex-column'>
            <div className='img'><img src="/assets/images/first-home/diamond-access-icon.svg" className='img-fluid' alt="" /></div>
            <h4 className='mb-0'>Diamond Access  </h4>
            <p className='mb-0'>The ultimate membership for those seeking a full, tailored experience. Enjoy everything in Premium Access plus priority support, advanced connection tools, and opportunities to engage with top-tier professionals for collaboration and mentorship.</p>
        </div>
        <button onClick={handlePlanClick}>Discover your membership plan</button>
        </div>
     </div>

    </div>
  )
}

export default TwoTierMembershipStructure
