import React, { useEffect, useState } from 'react';
import { TextField, IconButton, InputAdornment, Box, Paper, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SwitchComp from '../../Component/PermissionsSettings/SwitchComp'; // Assuming SwitchComp is a custom component
import PrivacyOptionsAccordion from '../../Component/PermissionsSettings/PrivacyOptionsAccordion'; // Assuming you have this component
import { deleteAccountAPI, accountStatusAPI } from 'src/api/AccountSettingApi';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { handleLogout } from 'src/api/AuthAPI';
import { useNotification } from '../Notification/ToastNotification';
import { logout } from 'src/containers/home-container/actions/authAction';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';
// import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 4,
};

const SecurityInformation = ({ currentRecord, handleUpdate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { privacyAndSecuritySetting: { securitySetting, deviceManagement, profileVisibility, additionalPrivacySettings, onlineStatus, thirdPartyAccess } } = currentRecord;
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [userStatus, setUserStatus] = useState(user?.status || false);
  const { openSuccessNotification, openErrorNotification } = useNotification();
  // Updated `securityOptions` with a compatible structure
  const [open, setOpen] = useState(null);
  const handleOpen = (type) => setOpen(type);
  const handleClose = () => setOpen(null);
  const [isValid, setIsValid] = useState(false)
  const [deleteReason, setDeleteReason] = useState('');
  const [otherReason, setOtherReason] = useState("");
  const handleReasonChange = (event) => {
    setDeleteReason(event.target.value);
  };

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const passwordsMatch = password === confirmPassword || confirmPassword === '';


  useEffect(() => {
    if (password === confirmPassword) {
      dispatch(setPayload({ data: { password, confirmPassword } }));
      setErrorMessage('');
    }
    else {
      setErrorMessage('Password does not match');
    }

  }, [password, confirmPassword]);


  const handleAccountStatus = async () => {
    try {
      const response = await accountStatusAPI({ status: userStatus, password });
      handleClose();
      if (response.status === 200) {
        setUserStatus(!userStatus);
        await handleLogout(user?._id)
        dispatch(logout());
        navigate("/login");
        openSuccessNotification("you have been successfully deactivated.");
      }
    }
    catch (error) {
      openErrorNotification(error.response.data.message);
    }
  }
  const handleDeleteAccount = async () => {
    try {
      if (deleteReason === '') return openErrorNotification('Please enter the reason for deleting your account.');
      const response = await deleteAccountAPI({ password, reason: deleteReason, otherReason });
      handleClose();
      if (response.status === 200) {
        // await handleLogout(user?._id)
        dispatch(logout());
        navigate("/login");
        openSuccessNotification("you have been successfully deleted.");
      }
    }
    catch (error) {
      openErrorNotification(error.response.data.message);
    }
  }
  const securityOptions = [
    {
      title: 'Security Settings',
      subsection: 'securitySetting',
      settings: [
        { label: 'Two-Factor Authentication', key: 'toFactorAuth', value: securitySetting.toFactorAuth },
        { label: 'Login Alerts', key: 'loginAlert', value: securitySetting.loginAlert },
        { label: 'Profile Privacy Mode', key: 'profilePrivacyMode', value: securitySetting.profilePrivacyMode },
        { label: 'Password Reset Notifications', key: 'passwordResetNotifications', value: securitySetting.passwordResetNotifications },
      ],
    },
    {
      title: 'Device Management',
      subsection: 'deviceManagement',
      settings: [
        { label: 'Show all devices', key: 'showAllDevices', value: deviceManagement.showAllDevices },
        { label: 'Show only mobile devices', key: 'onlyMobileDevices', value: deviceManagement.onlyMobileDevices },
        { label: 'Show only desktops', key: 'onlyDesktops', value: deviceManagement.onlyDesktops },
        { label: 'Allow login from any device', key: 'allowLoginFromAnyDevice', value: deviceManagement.allowLoginFromAnyDevice },
      ],
    },
    {
      title: 'Profile Visibility',
      subsection: 'profileVisibility',
      settings: [
        { label: 'Anyone', key: 'anyone', value: profileVisibility.anyone },
        { label: 'Members Only', key: 'members', value: profileVisibility.members },
        { label: 'Matches Only', key: 'matches', value: profileVisibility.matches },
        { label: 'Non-Members Only', key: 'nonMembers', value: profileVisibility.nonMembers },
      ],
    },
    {
      title: 'Additional Privacy Settings',
      subsection: 'additionalPrivacySettings',
      settings: [
        { label: 'Anonymous Browsing Mode', key: 'anonymousBrowsing', value: additionalPrivacySettings.anonymousBrowsing },
        // { label: 'Activity History Tracking', key: 'activityHistory', value: additionalPrivacySettings.activityHistory },
      ],
    },
  ];

  const privacySettings = [
    {
      title: 'Online Status',
      subsection: 'onlineStatus',
      settings: [
        { label: 'Show Online Status', key: 'showOnlineStatus', value: onlineStatus.showOnlineStatus },
        { label: 'Show Last Active Time', key: 'showLastActive', value: onlineStatus.showLastActive },
      ],
    },
    // {
    //   title: 'Third-Party Access',
    //   subsection: 'thirdPartyAccess',
    //   settings: [
    //     { label: 'Third-Party Services to Access Your Profile', key: 'thirdPartyServices', value: thirdPartyAccess.thirdPartyServices },
    //   ],
    // },

  ];

  const handleSwitchChange = (key, value, subsection) => {
    handleUpdate("privacyAndSecuritySetting", key, value, subsection);
    // const updatedNotificationSetting = {
    //   ...notificationSetting,
    //   [subsection]: {
    //     ...notificationSetting[subsection],
    //     [key]: value,
    //   },
    // };

    // handleUpdate({ ...currentRecord, notificationSetting: updatedNotificationSetting });
  };

  const [openDeactivate, setOpenDeactivate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDeactivate = () => setOpenDeactivate(true);
  const handleCloseDeactivate = () => setOpenDeactivate(false);

  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);


  useEffect(() => {
    const isValidDelete =
      open === "delete" &&
      password &&
      deleteReason &&
      (deleteReason !== "other" || otherReason);
    const isValidStatus = open === "status" && password;
    setIsValid(isValidDelete || isValidStatus);
  }, [open, password, deleteReason, otherReason]);



  return (
    <div>
      <Typography className="ac-heading"
        variant="h6"
        gutterBottom
        sx={{
          fontWeight: '500',
          color: '#081726',
          fontFamily: 'ivypresto-headline',
        }}
      >
        Security Information
      </Typography>
      <Typography className="font875vw"
        sx={{
          fontWeight: '400', color: '#081726',
          color: '#081726',
          fontFamily: 'indivisible',
        }}
      >
        Here are our options to give your account some additional security:
      </Typography>

      {securityOptions.map((section, index) => (
        <Box className="border001vw font1vw gap05vw mtopBottom05 p1vw"
          key={index}
          component={Paper}
          sx={{
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'indivisible',
            fontWeight: '400', color: '#081726',
          }}
        >
          <Typography className="font875vw" variant="h6" sx={{ fontWeight: 'bold', color: '#081726', fontFamily: 'indivisible', fontWeight: '500', }}>{section.title}</Typography>
          <Box>
            {section.settings.map((setting, settingIndex) => (
              <Box className="mtopBottom05"
                key={settingIndex}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'indivisible',
                  fontWeight: '400', color: '#081726',
                }}
              >
                <Typography className="font08vw" sx={{ fontFamily: 'indivisible', fontWeight: '400', color: '#081726', }}>{setting.label}</Typography>
                <SwitchComp
                  status={setting.value}
                  onChange={(event) => {
                    event.stopPropagation();
                    handleSwitchChange(setting.key, event.target.checked, section.subsection);
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      {/* <PrivacyOptionsAccordion /> */}

      <Typography className="ac-heading mt24vw mtopBottom05"
        variant="h6"
        gutterBottom
        sx={{
          fontWeight: '500',
          color: '#000',
          fontFamily: 'indivisible',
        }}
      >
        Privacy Settings
      </Typography>
      <Typography className="font875vw"
        sx={{
          fontWeight: '400',
          color: '#081726',
          fontFamily: 'indivisible',
        }}
      >
        Here are our options to give your account some additional security:
      </Typography>
      {privacySettings.map((section, index) => (
        <Box className="border001vw gap05vw mtopBottom05 p1vw"
          key={index}
          component={Paper}
          sx={{
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'indivisible',
            fontWeight: '400', color: '#081726',
          }}
        >
          <Typography className="font875vw" variant="h6" sx={{ fontWeight: 'bold', color: '#081726', fontFamily: 'indivisible', fontWeight: '500', }}>{section.title}</Typography>
          <Box className="divflxset">
            {section.settings.map((setting, settingIndex) => (
              <Box className="mtopBottom05"
                key={settingIndex}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontFamily: 'indivisible',
                  fontWeight: '400', color: '#081726',
                }}
              >
                <Typography className="font08vw" sx={{ fontFamily: 'indivisible', color: '#081726' }}>{setting.label}</Typography>
                <SwitchComp
                  status={
                    setting.value
                  }
                  onChange={(event) => {
                    event.stopPropagation();
                    handleSwitchChange(setting.key, event.target.checked, section.subsection);
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
      <Box className="border001vw rounded-3 gap05vw mtopBottom05 p1vw" sx={{
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography className="font875vw" variant="h6" sx={{ fontWeight: 'bold', color: '#081726', fontFamily: 'indivisible', fontWeight: '500', }}>Account Deactivation/Deletion</Typography>
        <Box className="divflxset setflexsedv"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0px',
            fontFamily: 'indivisible',
            fontWeight: '400', color: '#081726',
          }}
        >
          <Typography className="font08vw" sx={{ fontFamily: 'indivisible', fontWeight: '400', color: '#081726', }}>Account {userStatus ? 'Active' : 'Inactive'}</Typography>
          <Button className="font08vw h40vw px24vw py03vw"
            onClick={() => handleOpen("status")}
            variant="contained"
            sx={{
              borderRadius: '50vw',  width: '234px',
              background: 'rgba(191, 133, 0, 1)',
              textTransform: 'capitalize',
              fontFamily: 'indivisible',
              fontWeight: '400', color: '#fff',
            }}
          >
            {userStatus ? 'Deactivate' : 'Deactivate'} Account
          </Button>
        </Box>
        <Box className="mtopBottom05 setflexsedv"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'indivisible',
            fontWeight: '400', color: '#081726',
          }}
        >
          <Typography className="font08vw" sx={{ fontFamily: 'indivisible', fontWeight: '400', color: '#081726', }}>Completely delete your account and all related data. </Typography>
          <Button className="font08vw h40vw px24vw py03vw"
            variant="contained"
            onClick={() => handleOpen("delete")}
            // onClick={handleDeleteAccount}
            sx={{
              borderRadius: '50vw', width: '234px',
              background: 'rgba(191, 133, 0, 1)',
              textTransform: 'capitalize',
              fontFamily: 'indivisible',
              fontWeight: '400', color: '#fff',
            }}
          >
            Delete Account
          </Button>
        </Box>
      </Box>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="respWSet"
      >
        <Box className="modalsetM0"
          sx={{
            ...style,
            maxHeight: '80vh', // Adjust as needed
            overflowY: 'auto', // Enables vertical scroll
          }}
        >
          {
            open === "status" ? (
              <>
                <Box className="d-flex modalsetM1 align-items-start justify-content-center">
                  <div
                    className="modal-title modalsetM1_1 text-black text-center font-32 fw-medium"
                    id="exampleModalLabel"
                  >
                    Do you really want to deactivate your account?
                  </div>
                </Box>
                <p className="modal-title text-center text-color3c font-14 fw-normal mb-16 mt-10">
                  Your account will be temporarily disabled. You can log back in anytime to reactivate.
                </p>
              </>) : (
              <>
                <Box className="d-flex modalsetM1 align-items-start justify-content-center">
                  <div
                    className="modal-title modalsetM1_1 text-black text-center font-32 fw-medium"
                    id="exampleModalLabel"
                  >
                    Are you sure you want to delete your account?
                  </div>
                </Box>
                <p className="modal-title text-center text-color3c font-14 fw-normal mb-16 mt-10">
                  This action is irreversible. All your data will be lost, and you'll need to create a new account to use our services again.
                </p>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{
                    height: "auto",
                    "& .MuiOutlinedInput-root": {
                      height: "44px",
                      borderRadius: "15px",
                      "& fieldset": { borderColor: "#ccc" },
                      "&:hover fieldset": { borderColor: "#916008" },
                      "&.Mui-focused fieldset": { borderColor: "#916008", borderWidth: "1px" },
                    },
                  }}
                >
                  <InputLabel
                    id="delete-reason-label"
                    autoComplete="off"
                    sx={{
                      lineHeight: "16px",
                      color: "#666",
                      "&.Mui-focused": { color: "#916008" },
                    }}
                  >
                    Reason for Deleting Account
                  </InputLabel>

                  <Select
                    labelId="delete-reason-label"
                    value={deleteReason}
                    onChange={handleReasonChange}
                    label="Reason for Deleting Account"
                    sx={{
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#916008" },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#916008" },
                    }}
                  >
                    <MenuItem value="found_match">Found a Match</MenuItem>
                    <MenuItem value="break">Taking a Break</MenuItem>
                    <MenuItem value="privacy">Privacy Concerns</MenuItem>
                    <MenuItem value="dissatisfaction">Dissatisfaction</MenuItem>
                    {/* <MenuItem value="life_changes">Life Changes</MenuItem> */}
                    <MenuItem value="financial">Financial Reasons</MenuItem>
                    <MenuItem value="preferences">Changing Preferences</MenuItem>
                    {/* <MenuItem value="no_say">Prefer not to say</MenuItem> */}
                    <MenuItem value="other">Other</MenuItem>
                  </Select>

                  {deleteReason === "other" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      margin="dense"
                      placeholder="Please specify..."
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                      sx={{
                        mt: 2,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": { borderColor: "#ccc" },
                          "&:hover fieldset": { borderColor: "#916008" },
                          "&.Mui-focused fieldset": { borderColor: "#916008" },
                        },
                      }}
                    />
                  )}
                </FormControl>
              </>)

          }

          <TextField
            placeholder="Enter your password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            autoComplete="off"
            onChange={handlePasswordChange}
            // onblur={handleNext}
            fullWidth
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              height: '44px',
              overflow: 'hidden',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                borderRadius: '15px',
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#916008',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#916008',
                  borderWidth: '1px',
                },
              },
            }}
          />


          <div className="d-flex flex-column justify-content-between text-center gap-2 mt-32 popup-actions">
            <Button className="modalsetM4_1" onClick={() => open === "status" ? handleAccountStatus() : handleDeleteAccount()}
              variant="contained"
              disabled={!isValid}
              sx={{
                width: '100%', position: 'sticky', bottom: '0',
                backgroundColor: '#916008',
                padding: '10px',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': { backgroundColor: '#9d6f3b' },
              }}
            >
              Confirm {open === "status" ? "Deactivation" : "Deletion"}
            </Button>
            <Button className="modalsetM4_1" onClick={handleClose}
              variant="contained"
              sx={{
                maxWidth: '120px', width: '100%', margin: '0 auto',
                backgroundColor: '#fff',
                border: '1px solid #081726',
                padding: '10px', boxShadow: 'none',
                color: '#081726',
                textTransform: 'capitalize',
                '&:hover': { backgroundColor: '#9d6f3b', color: '#fff', border: '1px solid #9d6f3b', },
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>




      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        className="respWSet"
      >
        <Box className="modalsetM0" sx={{
          ...style,
          maxHeight: '80vh', // Adjust as needed
          overflowY: 'auto', // Enables vertical scroll
        }}>
          <Box className="d-flex modalsetM1 align-items-start justify-content-center">
            <div className="modal-title modalsetM1_1 text-black text-center font-32 fw-medium">
              Are you sure you want to permanently delete your account?
            </div>
          </Box>
          <p className="modal-title text-center text-color3c font-14 fw-normal mb-16 mt-10">
            This action is irreversible. All your data, including messages and preferences, will be lost. You'll need to create a new account to use our services again.
          </p>
          {/* <TextField placeholder="Enter your email" type="text" fullWidth variant="outlined" margin="normal"
            sx={{
              height: '44px',
              overflow: 'hidden',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                borderRadius: '15px',
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#916008',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#916008',
                  borderWidth: '1px',
                },
              },
            }} /> */}
          <FormControl fullWidth variant="outlined" margin="normal"
            sx={{
              height: '44px',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                borderRadius: '15px',
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#916008',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#916008',
                  borderWidth: '1px',
                },
              },
            }}
          >
            <InputLabel id="delete-reason-label">Select Reason</InputLabel>
            <Select
              labelId="delete-reason-label"
              value={deleteReason}
              onChange={handleReasonChange}
              label="Select Reason"
            >
              <MenuItem value="privacy">Privacy concerns</MenuItem>
              <MenuItem value="not_useful">Not useful for me</MenuItem>
              <MenuItem value="too_expensive">Too expensive</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            placeholder="Enter your password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            // onblur={handleNext}
            fullWidth
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              height: '44px',
              overflow: 'hidden',
              '& .MuiOutlinedInput-root': {
                height: '44px',
                borderRadius: '15px',
                '& fieldset': {
                  borderColor: '#ccc',
                },
                '&:hover fieldset': {
                  borderColor: '#916008',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#916008',
                  borderWidth: '1px',
                },
              },
            }}
          />
          <div className="d-flex flex-column justify-content-between text-center gap-2 mt-32 popup-actions">
            <Button onClick={handleDeleteAccount} variant="contained" sx={{
              width: '100%', position: 'sticky', bottom: '0',
              backgroundColor: '#916008',
              padding: '10px',
              color: '#fff',
              textTransform: 'capitalize',
              '&:hover': { backgroundColor: '#9d6f3b' },
            }} >
              Confirm Deletion
            </Button>
            <Button onClick={handleCloseDelete} variant="contained" sx={{
              maxWidth: '120px', width: '100%', margin: '0 auto',
              backgroundColor: '#fff',
              border: '1px solid #081726',
              padding: '8px', boxShadow: 'none',
              color: '#081726',
              textTransform: 'capitalize',
              '&:hover': { backgroundColor: '#9d6f3b', color: '#fff', border: '1px solid #9d6f3b', },
            }} >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SecurityInformation;
