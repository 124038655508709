import React from 'react'
import LuxuryFooter from 'src/components/luxury-home/LuxuryFooter'
import Header from 'src/containers/home-container/layout/Header'
import HowItWorks from './HowItWorks'
import OurVision from './OurVision'
import WhoWeServe from './WhoWeServe'

const AboutUs = () => {
  return (
    <>
      <div className='aboutusnEwv1' style={{ padding: '5vw 5.5vw' }}>
        <Header />
        <div className='aboutusTextNewbg'>
          <div className='aboutusTextNew'>
          <h2 className="font48vw text-black fw-bold" style={{ padding: '2vw 0 1vw 0' }}>About Us</h2> 
            <h2 className='fw-light font-e fst-italic font48vw mb-0' style={{ padding: '0 0 2vw 0' }}>An Exclusive Digital Ecosystem for Elite Professionals & Industry Leaders</h2>
            <p className='fw-normal font-m font24vw mb-0'>Luxury Life is more than just a social network—it is an exclusive digital ecosystem designed for high achievers, industry leaders, and cultural influencers. Our platform is built on the principles of trust, discretion, and excellence, ensuring that every interaction you have is meaningful and valuable.</p>
          </div>

          <span className='img d-block'><img src='/assets/images/first-home/about-us/an-exclusive-digital.png' className='img-fluid' alt="" /></span>
        </div>
        <HowItWorks />

      </div>
      <OurVision />
      <WhoWeServe />
      <LuxuryFooter />
    </>
  )
}

export default AboutUs
