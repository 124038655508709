import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { setPayload } from "src/containers/signup-container/actions/SignupActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { convertHeight } from "src/utils/CommonUtils";

// const cmToFeet = (cm) => (cm / 30.48).toFixed(2); // Convert CM to Feet
// const cmToFeet = (cm) => {
//   const totalFeet = cm / 30.48; // Convert cm to total feet
//   const feet = Math.floor(totalFeet); // Extract whole feet
//   const inches = Math.round((totalFeet - feet) * 12); // Convert decimal part to inches
//   return `${feet}.${inches}`; // Return in "feet.inches" format
// };
const cmToFeet = (cm) => {
  const totalFeet = cm / 30.48; // Convert cm to total feet
  const feet = Math.floor(totalFeet); // Extract whole feet
  const inches = Math.round((totalFeet - feet) * 12); // Convert decimal part to inches
  return `${feet}.${inches.toString().padStart(inches>=9 ? 2 : 1, '0')}`; // Format inches correctly
};
const feetToCm = (feet) => {
  const wholeFeet = Math.floor(feet); // Extract whole feet
  const inches = feet.toString()?.split('.')[1] || 0;
  const totalFeet = wholeFeet + (inches / 12); // Convert inches to feet
  return Math.round(totalFeet * 30.48); // Convert to cm and round
};

const HeightSelector = () => {
  const dispatch = useDispatch();
      const {
          tall,
      } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  const [unit, setUnit] = useState(tall.unit || "feet");
  const [height, setHeight] = useState(tall.value || 4.0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const scaleRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  // Generate scale values for cm and ft danish code 
  // const scaleValues = unit === "cm"
  //   ? Array.from({ length: 80 }, (_, i) => 150 + i)
  //   : Array.from({ length: 15 }, (_, i) => (4.5 + i * 0.1).toFixed(1)).map(Number);

  // // Ensure visibleValues is always valid
  // const visibleValues = scaleValues.slice(currentIndex, Math.min(currentIndex + 500, scaleValues.length));

  const scaleValues = unit === "cm"
    ? Array.from({ length: 96 }, (_, i) => 122 + i) 
    : Array.from({ length: 45 }, (_, i) => {
        const feet = Math.floor(4 + (i / 12));
        const inches = i % 12;
        return `${feet}.${inches}`;
      });
  // Generate Scale Values with Restrictions
// const scaleValues = unit === "cm"
// ? Array.from({ length: 69 }, (_, i) => 150 + i) // 150cm to 218cm
// : Array.from({ length: 28 }, (_, i) => (4.5 + i * 0.1).toFixed(1)).map(Number); // 4.5ft to 7.2ft

const visibleValues = scaleValues.slice(currentIndex, Math.min(currentIndex + 500, scaleValues.length));

  // Center selected value
  useLayoutEffect(() => {
    if (scaleRef.current) {
      const selectedElement = scaleRef.current.querySelector(".active");
      if (selectedElement) {
        const scaleWidth = scaleRef.current.offsetWidth;
        const valueWidth = selectedElement.offsetWidth;
        const offset = (scaleWidth / 2) - (valueWidth / 2);
        scaleRef.current.scrollTo({
          left: selectedElement.offsetLeft - offset,
          behavior: "smooth",
        });
      }
    }
  }, [activeIndex]);

  // Handle Unit Toggle
  const handleUnitToggle = (selectedUnit) => {
    if (selectedUnit !== unit) {
      if (selectedUnit === "cm") {
        setHeight(feetToCm(height)); // Convert to CM
        setCurrentIndex(0);
      } else {
        setHeight(cmToFeet(height)); // Convert to Feet
        setCurrentIndex(0);
      }
      setUnit(selectedUnit);
      dispatch(setPayload({ data: { tall: { value: height, unit:selectedUnit } } }));
    }
  };


  const handleHeightChange = (value, index) => {
    if ((unit === "cm" && value > 218) || (unit === "feet" && value > 7.8)) {
      return; // Restrict max height
    }
    setHeight(value);
    setActiveIndex(index);
    dispatch(setPayload({ data: { tall: { value: value, unit } } }));
  };

  // Mouse Dragging Events
  const handleMouseDown = (e) => {
    isDragging.current = true;
    scaleRef.current.classList.add("dragging");
    startX.current = e.pageX - scaleRef.current.offsetLeft;
    scrollLeft.current = scaleRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - scaleRef.current.offsetLeft;
    const walk = (x - startX.current) * 1.5;
    scaleRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    scaleRef.current.classList.remove("dragging");
  };

  // Touch Dragging Events
  const handleTouchStart = (e) => {
    isDragging.current = true;
    startX.current = e.touches[0].pageX - scaleRef.current.offsetLeft;
    scrollLeft.current = scaleRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;
    const x = e.touches[0].pageX - scaleRef.current.offsetLeft;
    const walk = (x - startX.current) * 1.5;
    scaleRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
  };

  // Update Redux store
  const convertHeightInScale = (height) => {
    const feet = Math.floor(height); // Extract feet
    const decimalPart = String(height).split(".")[1]; // Extract decimal part as string
    const inches = decimalPart?.length === 1 ? parseInt(decimalPart) : parseInt(decimalPart); // Handle single/double digit
    return `${feet}' ${inches}"`;
  };

  return (
    <div className="height-selector">
      <div className="header">
        <div className="font-m font-32 fw-medium text-center">How Tall Are You?</div>
        <p>Let your potential matches know your height.</p>
      </div>

      <div className="unit-toggle">
        <button className={unit === "cm" ? "active" : ""} onClick={() => handleUnitToggle("cm")}>
          cm
        </button>
        <button className={unit === "feet" ? "active" : ""} onClick={() => handleUnitToggle("feet")}>
          ft/in
        </button>
      </div>

      <div className="height-display">
        {unit === "cm" ? `${height} cm` : convertHeight(height)}
      </div>

      <div className="slider-container">
        <button className="slider-btn" onClick={() => setCurrentIndex(Math.max(currentIndex - 1, 0))}>
          <KeyboardArrowLeftIcon />
        </button>

        <div
          className="slider-scale Scroll-none"
          ref={scaleRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="scale-values ">
            {visibleValues.map((value, index) => (
              <span
                key={value}
                className={`scale-value ${value == height ? "active" : ""}`}
                onClick={() => handleHeightChange(value, index + currentIndex)}
              >
                {(unit==="cm")?`${value} ${unit}`:convertHeightInScale(value)}
              </span>
            ))}
          </div>
        </div>

        <button className="slider-btn" onClick={() => setCurrentIndex(Math.min(currentIndex + 1, scaleValues.length - 5))}>
          <KeyboardArrowRightIcon />
        </button>
      </div>
    </div>
  );
};

export default HeightSelector;
