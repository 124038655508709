export const FAVORITE_ACTIONS = {
    FAVORITE_SEARCH: 'FAVORITE_SEARCH',
    FAVORITE_SEARCH_SUCCESS: 'FAVORITE_SEARCH_SUCCESS',
    FAVORITE_SEARCH_ERROR: 'FAVORITE_SEARCH_ERROR',

    FAVORITE_UPSERT: 'FAVORITE_UPSERT',
    FAVORITE_UPSERT_SUCCESS: 'FAVORITE_UPSERT_SUCCESS',
    FAVORITE_UPSERT_ERROR: 'FAVORITE_UPSERT_ERROR',

    FAVORITE_EDIT: 'FAVORITE_EDIT',

    FAVORITE_RESET: 'FAVORITE_RESET',
};

export const favoriteResetAction = () => ({
    type: FAVORITE_ACTIONS.FAVORITE_RESET,
});

export const favoriteSearchAction = (payload) => ({
    type: FAVORITE_ACTIONS.FAVORITE_SEARCH,
    ...payload,
});

export const favoriteEditAction = (payload) => ({
    type: FAVORITE_ACTIONS.FAVORITE_EDIT,
    ...payload,
});

export const favoriteUpsertAction = (payload) => ({
    type: FAVORITE_ACTIONS.FAVORITE_UPSERT,
    ...payload,
});


export default {
    FAVORITE_ACTIONS,
    favoriteSearchAction,
    favoriteUpsertAction,
    favoriteEditAction
};
