import React, { useState } from 'react';
import Fancybox from './Fancybox';

const UserPhotos = ({ images, defaultLimit, showRequestView, privatePickRequestStatus, onClick }) => {
  // Count of additional images
  const additionalImagesCount = images.length - defaultLimit;
  // Handle the "View All" logic (trigger Fancybox)
  const handleViewAllClick = () => {
    // Programmatically open Fancybox when the "More" overlay is clicked
    const firstImage = document.querySelector('[data-fancybox="gallery"]');
    if (firstImage) {
      firstImage.click(); // Trigger the Fancybox by clicking the first image
    }
  };

  return (
    <div>
      <Fancybox
        options={{
          Carousel: {
            infinite: false, // Set to `true` if you want infinite scrolling
          },
        }}
      >
        {/* Only show images up to the defaultLimit */}
        {images.slice(0, defaultLimit).map((image, index) => (
          <div key={index} className="photo-item">
            <a data-fancybox="gallery" href={image}>
              <img
                src={image}
                className={`img-fluid ${showRequestView ? 'blur-image' : ''}`}
                alt={`Image ${index + 1}`}
              />
            </a>
            {showRequestView && (
              <div className="requestView">
                <img
                  src="/assets/images/user/padlock.svg"
                  className="img-fluid Lockedpadloc"
                  alt="Locked"
                />
                <img
                  src="/assets/images/user/user-profile/padlock.svg"
                  className="img-fluid lockClr001"
                  alt="Locked"
                />
                <div className='text-white font-12 PhotosLock001'>3 Photos Locked</div>
                {
                  privatePickRequestStatus === "Pending" ? (
                    <div className='text-white font-12 PrivatePicRS'>Private Pic Request Sent</div>
                  ) : privatePickRequestStatus === "Rejected" ? (
                    <div className='text-danger font-12 PrivatePicRR'>
                      Request Rejected.
                      {/* <button className="request-button mt-2" onClick={onClick}>Try Again</button> */}
                    </div>
                  ) : (
                    <button className="request-button" onClick={onClick}>Request to Unlock</button>
                  )
                }

              </div>
            )}
            {/* Overlay for additional images count */}
            {index === defaultLimit - 1 && additionalImagesCount > 0 && (
              <div className="overlay flex-column" onClick={handleViewAllClick}>
                {`+${additionalImagesCount} `}
                <span className='d-block'>More</span>
              </div>
            )}
          </div>
        ))}

        {/* Hidden links for Fancybox to include extra images in modal */}
        {images.length > defaultLimit && (
          <div style={{ display: 'none' }}>
            {images.slice(defaultLimit).map((image, index) => (
              <a key={index} data-fancybox="gallery" href={image} />
            ))}
          </div>
        )}
      </Fancybox>
    </div>
  );
};

export default UserPhotos