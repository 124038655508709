import { all } from 'redux-saga/effects'; 
import HomeSaga from './containers/home-container/saga/HomeSaga';
import AuthSaga from './containers/home-container/saga/AuthSaga'; 
import SignupSaga from './containers/signup-container/saga/SignupSaga';
import AppSaga from './containers/app-container/saga/AppSaga';
import AccountSettingSaga from './containers/account-setting/saga/AccountSettingSaga';
import ProfileSaga from './containers/profile-container/saga/ProfileSaga';
import MemberSaga from './containers/member-container/saga/MemberSaga';
import FavoriteSaga from './containers/favorites-container/saga/FavoriteSaga';
import ChatSaga from './containers/chat-container/saga/ChatSaga';
 

export default function* sagas() {
    yield all([
        ...AppSaga,
        ...HomeSaga,        
        ...AuthSaga, 
        ...SignupSaga,
        ...AccountSettingSaga,
        ...ProfileSaga,
        ...MemberSaga,
        ...FavoriteSaga,
        ...ChatSaga
    ]);
}
