import React, { useEffect, useRef } from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';
import { GoogleLogin } from '@react-oauth/google';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { googleSignupAPI } from 'src/api/SignupApi';
import { loginSuccess } from 'src/containers/home-container/actions/authAction';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
const LinksYourAccount = ({ type, AccountTitle, AccountSubTitle, handleContinue, captchaValue }) => {
  const dispatch = useDispatch();
  const iframeRef = useRef(null);
  let navigate = useNavigate();
  const {
    isTermsAndConditionsAccepted,
    ReCAPTCHAToken,
    gender,
    preferences,
    dateOfBirth,
    maxStep,
    localStep
  } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
  // Generate initials from the first name and last name
  const [selectedTermsAndConditions, setSelectedTermsAndConditions] = React.useState(isTermsAndConditionsAccepted);


  const checkboxRef = useRef(null);

  const handleGoogleSignInClick = () => {
    if (checkboxRef.current) {
      checkboxRef.current.focus();
    }
  };

  useEffect(() => {
    dispatch(setPayload({ data: { isTermsAndConditionsAccepted: selectedTermsAndConditions } }));
  }, [selectedTermsAndConditions]);
  const responseMessage = async (response) => {
    const payload = {
      idToken: response.credential,
      gender,
      preferences,
      dateOfBirth,
    }
    try {
      const signupResponse = await googleSignupAPI(payload);
      if (!signupResponse.data.data.error) {
        const { user, token } = signupResponse.data.data; // Extract `user` and `token` from API response
        // Store user data and token in localStorage
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token); // Token is usually stored as a plain string
        // Dispatch login success action
        dispatch(
          loginSuccess({
            ...user,
            jwtToken: token,
          })
        );
        if (type === "signup") {
          dispatch(headerShowAction({ data: '', }));
          // Update localStep and maxStep based on user.step if user.step is less than 6 then assign 6 to localStep and maxStep else assign user.step to localStep and maxStep
          if(user.step===22&& user.profileCompleted){
            // navigate = '/members';
            navigate('/members');
          }
          else{
          const updatedLocalStep = user.step < 2 ? 6 : user.step + 4;
          const updatedMaxStep = user.step < 2 ? 6 : user.step + 4;
          dispatch(setPayload({ data: { ...user, localStep: updatedLocalStep, maxStep: updatedMaxStep } }));
          }
          // const updatedMaxStep = 6 > maxStep ? 6 : maxStep;
          // dispatch(setPayload({ data: { localStep: 6, maxStep: updatedMaxStep } }));
        }
        else {
          if(!user.profileCompleted){
            toast.error("Profile is not completed. Please complete the profile from signup")
            return
          }
          navigate('/members');
        }
      }
      else {
        dispatch(setPayload(headerShowAction({
          data: signupResponse.data.data.message,
        })));
      }
    } catch (error) {
      console.log(error);
      errorMessage(error.response.data.message);
    }

  };
  const errorMessage = (error) => {
    console.log(error);
    dispatch(setPayload(headerShowAction({
      data: error,
    })));
  };

  




  useEffect(() => {
    const applyStyles = () => {
      if (iframeRef.current) {
        const iframe = iframeRef.current;
        const iframeDoc = iframe.contentDocument;
  
        if (iframeDoc) {
          const style = iframeDoc.createElement("style");
          style.textContent = `
            #container {
              padding: 2px 0px !important;
              width: 100% !important;
            }
          `;
          iframeDoc.head.appendChild(style);
        }
      }
    };
  
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = applyStyles;
    }
  
    return () => {
      if (iframe) {
        iframe.onload = null;
      }
    };
  }, []);
  const handleCaptchaChange = (value) => {
    dispatch(setPayload({ data: { ReCAPTCHAToken: value } }));
  };

  return (
    <>
      {/* Great! Let’s Link Your Account */}
      {/* Connect your account to get started quickly and securely. */}
      <div className="">
        <div className="font-m font-32 fw-medium text-center">{AccountTitle}</div>
        <div className="font-m font-14 colorf5 pt-6 text-center">
          {AccountSubTitle}
        </div>
      </div>

      <div className="btnsWithConnects gapset py-16 w-400-px mx-auto">
        <div className="d-flex align-items-center flex-column gapset mt-16">
          {type === "signup" ? "" : ""}
          <div className="height40 fontSt w-100 setupEmailSetup">
            {/* <div className="d-flex align-items-center">
              <span className="d-flex align-items-center justify-content-center w-40-px h-40-px rounded-circle flex-shrink-0 overflow-hidden bg-secondary text-white fw-medium userimg">
                {avatar ? (
                  <img
                    src={avatar}
                    alt="User Avatar"
                    className="w-40-px h-40-px rounded-circle"
                  />
                ) : (
                  getInitials()
                )}
              </span>
              <div className="flex-grow-1 nameWemail text-start">
                <div className="font-12 mb-0 fw-medium text-start">{`Sign in as ${firstName}`}</div>
                <span className="font-12 text-secondary-light fw-medium text-start">{email}</span>
              </div>
            <span className="text-primary-light text-md fw-medium google-iconEmail">
              <img
                src="/assets/images/google-icon-logo.svg"
                alt="Google Logo"
                className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden"
              />
            </span>
            </div> */}
            {(type === "signup" && ReCAPTCHAToken) || (type === "login" && captchaValue) ? (
              <GoogleLogin className="w-100 rounded-pill p-0 mw-300-px"  width="400" ref={iframeRef}  onSuccess={responseMessage} onError={errorMessage} shape="pill" />
            ) : (
              <>
                <div className="d-flex align-items-center d-flex align-items-center contWEmail12 SigninTooltip font-12 fw-medium borderSt height40 fontSt cursor-pointer" onClick={handleGoogleSignInClick}>
                  <span className="text-primary-light text-md fw-medium google-iconEmail ps-10">
                    <img
                      src="/assets/images/google-icon-logo.svg"
                      alt="Google Logo"
                      className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden"
                    />
                  </span>
                  <div className="flex-grow-1 nameWemail text-center pe-16 w-100">
                    <div className="font-14 mb-0 fw-medium text-center ">{`Continue with Google`}</div>
                  </div>
                </div>
                <Tooltip anchorSelect=".SigninTooltip" place="top">
                  Click on the captcha to continue.
                </Tooltip>
              </>
            )}
          </div>
          {/* <button className="bg-black text-white contWapple font-12 fw-medium borderSt height40 fontSt d-flex align-items-center">
            <AppleIcon /> Continue with Apple Account
          </button> */}
          <button className="contWEmail2 font-12 fw-medium borderSt height40 fontSt" onClick={handleContinue}>Continue with Email</button>
        </div>
      </div>
      <div className="bylinkTp font-m text-start w-400-px mx-auto">
        <div className="mb-3 form-check">
          <ReCAPTCHA className="mx-auto text-center d-flex justify-content-center mb-32"
                  sitekey="6LfjQ6YqAAAAAGZq85s28C8n5Y_FaMSzdzBsWB9K" // Replace with your reCAPTCHA site key
                  onChange={handleCaptchaChange}
                />
          {/* <input type="checkbox"
            checked={selectedTermsAndConditions}
            onChange={(e) => setSelectedTermsAndConditions(e.target.checked)}
            className="form-check-input" id="exampleCheck1"
            style={{ width: '22px', height: '22px' }} 
          ref={checkboxRef} // Attach ref to checkbox 
            /> */}
          <label className="form-check-label ps-10" htmlFor="exampleCheck1">By continuing you agree to Luxury Life's <span className="d-block"><Link to="/terms-and-conditions" target='_blank'>Terms &amp; Conditions</Link> and <Link to="/privacy-policy" target='_blank'>Privacy Policy</Link>.</span></label>
        </div>
      </div>
    </>
  );
};

export default LinksYourAccount