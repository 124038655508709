import React from "react";
import { Box, Grid, Skeleton, Button } from "@mui/material";

const ProfileDetailSkeleton = () => {
  return (
    <Box sx={{ p: 3 }}>
      {/* Profile Header */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <Skeleton variant="circular" width={150} height={150} />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Skeleton variant="text" width="40%" height={40} />
          <Skeleton variant="text" width="30%" />
          <Skeleton variant="text" width="50%" />
        </Grid>
      </Grid>

      {/* Profile Details Boxes */}
      <Grid container spacing={2} sx={{ mt: 3 }}>
        {[...Array(5)].map((_, index) => (
          <Grid item xs={6} sm={2.4} key={index}>
            <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: 2 }} />
          </Grid>
        ))}
      </Grid>

      {/* Message Input & Button */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 3 }}>
        <Skeleton variant="rectangular" width="60%" height={40} sx={{ borderRadius: 20 }} />
        <Skeleton variant="rectangular" width={100} height={40} sx={{ borderRadius: 20 }} />
      </Box>

      {/* Additional Details */}
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12} sm={6}>
          {[...Array(4)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" width="100%" height={50} sx={{ borderRadius: 5, mt: 1 }} />
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text" width="30%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={150} sx={{ borderRadius: 5 }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileDetailSkeleton;
