export const PROFILE_ACTIONS = {
    PROFILE_SEARCH: 'PROFILE_SEARCH',
    PROFILE_SEARCH_SUCCESS: 'PROFILE_SEARCH_SUCCESS',
    PROFILE_SEARCH_ERROR: 'PROFILE_SEARCH_ERROR',

    PROFILE_UPSERT: 'PROFILE_UPSERT',
    PROFILE_UPSERT_SUCCESS: 'PROFILE_UPSERT_SUCCESS',
    PROFILE_UPSERT_ERROR: 'PROFILE_UPSERT_ERROR',

    PROFILE_EDIT: 'PROFILE_EDIT',

    PROFILE_RESET: 'PROFILE_RESET',
    SET_PAYLOAD: 'SET_PAYLOAD',

    PROFILE_SEND_OTP: 'PROFILE_SEND_OTP',
    PROFILE_VERIFY_OTP: 'PROFILE_VERIFY_OTP',
    PROFILE_CREATE_PASSWORD: 'PROFILE_CREATE_PASSWORD',
    PROFILE_UPDATE_SECURITY_QUESTION: 'PROFILE_UPDATE_SECURITY_QUESTION',

    GET_PRIVATE_PIC_REQUEST: 'GET_PRIVATE_PIC_REQUEST',
    SET_PRIVATE_PIC_REQUEST: 'SET_PRIVATE_PIC_REQUEST',
    UPDATE_PRIVATE_PIC_REQUEST: 'UPDATE_PRIVATE_PIC_REQUEST',
};

export const profileResetAction = () => ({
    type: PROFILE_ACTIONS.PROFILE_RESET,
});

export const profileSearchAction = (payload) => ({
    type: PROFILE_ACTIONS.PROFILE_SEARCH,
    ...payload,
});

export const profileEditAction = (payload) => ({
    type: PROFILE_ACTIONS.PROFILE_EDIT,
    ...payload,
});

export const profileUpsertAction = (payload) => ({
    type: PROFILE_ACTIONS.PROFILE_UPSERT,
    ...payload,
});
export const getPrivatePicRequestAction = (payload) => ({
    type: PROFILE_ACTIONS.GET_PRIVATE_PIC_REQUEST,
    ...payload,
});
export const updatePrivatePicRequestAction = (payload) => ({
    type: PROFILE_ACTIONS.UPDATE_PRIVATE_PIC_REQUEST,
    ...payload,
})


export default {
    PROFILE_ACTIONS,
    profileSearchAction,
    profileUpsertAction,
    profileEditAction,
    getPrivatePicRequestAction
};
