/* eslint-disable prefer-promise-reject-errors */
import config from 'src/config';
import { postRequest, deleteRequest, postAsyncRequest } from 'src/utils/RequestUtil';

export const fileSearchAPI = function (data) {
    const url = `${config.baseUrl}file/search`;
    return postRequest({
        url,
        data,
    });
};

export const fileCreateAPI = function (data) {
    const fd = new FormData();

    Object.keys(data).forEach((key) => {
        fd.append(key, data[key]);
    });

    const url = `${config.baseUrl}file`;

    return postRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const fileDeleteAPI = function ({
    fileId,
}) {
    const url = `${config.baseUrl}file/${fileId}`;
    return deleteRequest({
        url,
    });
};
export const uploadPublicFileAPI = function (data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString);
        } else if (data[key] instanceof FileList) {
            // If it's a FileList, iterate over it to append each file
            Array.from(data[key]).forEach((file, index) => {
                fd.append(`${key}[${index}]`, file); // appending each file
            });
        } else if (data[key] instanceof File) {
            fd.append(key, data[key]); // Directly append the file
        } else {
            fd.append(key, data[key]);
        }
    });

    const url = `${config.baseUrl}file/upload`;
    return postAsyncRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export const uploadPrivateFileAPI = function (data) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString);
        } else if (data[key] instanceof FileList) {
            // If it's a FileList, iterate over it to append each file
            Array.from(data[key]).forEach((file, index) => {
                fd.append(`${key}[${index}]`, file); // appending each file
            });
        } else if (data[key] instanceof File) {
            fd.append(key, data[key]); // Directly append the file
        } else {
            fd.append(key, data[key]);
        }
    });

    const url = `${config.baseUrl}file/upload-private`;
    return postAsyncRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
export const fileDeleteByUrlAPI = function (data) {
    const url = `${config.baseUrl}file/deleteByUrl`;
    return postRequest({
        url,
        data,
    });
};

export default {
    fileSearchAPI,
    fileCreateAPI,
    fileDeleteAPI,
    fileDeleteByUrlAPI,
};
