import React, { useState, useEffect, useRef, useCallback } from "react";
import ProfileCard from "../../Component/Profile/ProfileCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MEMBER_ACTIONS, memberSearchAction } from "./actions/MemberActions";
import { Button, Menu, MenuItem, Fade, } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { REQUEST_STATUS } from "src/constants/CommonConstants";
import ProfileCardSkeleton from "./ProfileCardSkeleton";
import GoTop from "src/components/gotop/GoTop";
const ProfileGrid = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const { currentFilter, searchReqStatus, search: { data: allProfiles, totalCount } } = useSelector((state) => state.MemberReducer, shallowEqual);
  const [profiles, setProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSort] = useState('distance');
  const containerRef = useRef(null); 

  const fetchProfiles = useCallback(() => {
    if (searchReqStatus === REQUEST_STATUS.SUCCESS && allProfiles.length < totalCount) {
      dispatch(memberSearchAction({ where: currentFilter, currentPage: currentPage + 1,sortBy,preferredMeasurement:user.preferredMeasurement }));
      setCurrentPage(prevPage => prevPage + 1);
    }
  }, [dispatch, currentFilter, currentPage, searchReqStatus, totalCount]);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 20 &&
        searchReqStatus === REQUEST_STATUS.SUCCESS
      ) {
        fetchProfiles();
      }
    }
  }, [fetchProfiles, searchReqStatus,sortBy,user.preferredMeasurement]);

  useEffect(() => {
    setProfiles(allProfiles);
  }, [allProfiles]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (allProfiles.length === 0) {
      fetchProfiles();
    }
  }, []);



  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSort = (value) => {
    setSort(value);
    setCurrentPage(0);
    dispatch({type: MEMBER_ACTIONS.MEMBER_SEARCH_RESET});
    dispatch(memberSearchAction({ where: currentFilter, currentPage: 0,sortBy: value,preferredMeasurement:user.preferredMeasurement }));
    handleCloseMenu();
  };

  return (
    <div
      className="profile-grid"
      ref={containerRef}
      style={{
        flex: 1,
        // padding: "40px",
        overflow: "auto",
        // top: "80px", 
      }}
    >
       <div className="font-16 fw-medium font-m mt-10 d-flex justify-content-between align-items-center">Your Preference Results   <div className="font-16 fw-medium"><Button
        id="fade-button"
        className="more"
        aria-controls={menuOpen ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
        sx={{ textTransform: 'capitalize', color: '#000' }}
      >
        Sort By: <KeyboardArrowDownIcon />
      </Button>
         <Menu className="end-0"
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          TransitionComponent={Fade}
        >
          <MenuItem
            className=""
            onClick={() => handleSort('distance')}
          >
          Nearest  
          </MenuItem>

          <MenuItem
            className=""
            onClick={() => handleSort('user.lastActive')}
          >
           Recently Active 
          </MenuItem>
          <MenuItem
            className=""
            onClick={() => handleSort('user.createdAt')}
          >
            Newest 
          </MenuItem>
        </Menu> </div></div>  
      <div className="grid-container">
        {profiles.map((profile) => (
          <ProfileCard key={profile._id} profile={profile} />
        ))}
      </div>
      {searchReqStatus === MEMBER_ACTIONS.PENDING && <div className="loader"><ProfileCardSkeleton /></div>}

      {/* Scroll to Top Button */}
      <GoTop containerRef={containerRef} />
    </div>
  );
};

export default ProfileGrid;