import axios from "axios";
import configureStore from "src/Store";
import { logout } from "src/containers/home-container/actions/authAction";
export const CancelToken = axios.CancelToken;
var source = CancelToken.source();

const responseInterceptors = new Set([]);
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      configureStore().dispatch(logout());
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    for (const callback of responseInterceptors) {
      callback(error);
    }
    return Promise.reject(error);
  }
);

export const addResponseInterceptor = ({ callback }) => {
  responseInterceptors.add(callback);
  return () => {
    responseInterceptors.delete(callback);
  };
};

export const setDefaultHeaders = (headers) => {
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    ...headers,
  };
};

export const getRequest = ({ url, params, headers }) =>
  axios({
    method: "get",
    url,
    params,
    cancelToken: source.token,
    headers,
  });

export const postRequest = ({
  url,
  params,
  headers,
  data,
  onUploadProgress = () => {},
}) =>
  axios({
    method: "post",
    url,
    params,
    headers,
    data,
    onUploadProgress,
  });

export const putRequest = ({ url, params, headers, data }) =>
  axios({
    method: "put",
    url,
    params,
    headers,
    data,
  });

export const patchRequest = ({ url, params, headers, data }) =>
  axios({
    method: "patch",
    url,
    params,
    headers,
    data,
  });

export const deleteRequest = ({ url, params, headers, data }) =>
  axios({
    method: "delete",
    url,
    params,
    headers,
    data,
  });

export const getAsyncRequest = async ({ url, params, headers }) =>
  await axios({
    method: "get",
    url,
    params,
    cancelToken: source.token,
    headers,
  });

export const postAsyncRequest = async ({ url, params, headers, data }) =>
  await axios({
    method: "post",
    url,
    params,
    headers,
    data,
  });

export const putAsyncRequest = async ({ url, params, headers, data }) =>
  await axios({
    method: "put",
    url,
    params,
    headers,
    data,
  });

export const patchAsyncRequest = async ({ url, params, headers, data }) =>
  await axios({
    method: "patch",
    url,
    params,
    headers,
    data,
  });

export const deleteAsyncRequest = async ({ url, params, headers, data }) =>
  await axios({
    method: "delete",
    url,
    params,
    headers,
    data,
  });

export default {
  setDefaultHeaders,
  addResponseInterceptor,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  patchRequest,
  getAsyncRequest,
  postAsyncRequest,
  putAsyncRequest,
  patchAsyncRequest,
  deleteAsyncRequest,
};
