import React, { useState, useRef, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import Webcam from 'react-webcam';
import { uploadPublicFileAPI } from 'src/api/FileApi';
import { shallowEqual, useSelector } from 'react-redux';
import { signupUpdateAPI } from 'src/api/SignupApi';
import { toast } from 'react-toastify';

const TakePhoto = ({ setCurrentStep }) => {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const [isPhotoTaken, setIsPhotoTaken] = useState(false);
  const [photo, setPhoto] = useState(null);
  const webcamRef = useRef(null);

  // Capture photo from webcam
  const capturePhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
    setIsPhotoTaken(true);
  }, [webcamRef]);

  // Handle retake
  const handleRetake = () => {
    setPhoto(null);
    setIsPhotoTaken(false);
  };

  // Handle upload
  const handleUpload = async () => {
    if (!photo) return;

    try {
      const base64Data = photo.split(',')[1];
      const binaryData = atob(base64Data);
      const arrayBuffer = new Uint8Array(binaryData.length);

      for (let i = 0; i < binaryData.length; i++) {
        arrayBuffer[i] = binaryData.charCodeAt(i);
      }

      const imageFile = new File([arrayBuffer], 'realTimePhoto.jpg', { type: 'image/jpeg' });
      const payload = { file: imageFile, by: user._id };
      const uploadResponse = await uploadPublicFileAPI(payload);

      if (uploadResponse.data.data.url) {
        const updatePayload = {
          signupId: user._id,
          data: { step: 23, accountUpdatePayload: { realTimePicture: uploadResponse.data.data.url}}
        };
        const updateResponse = await signupUpdateAPI(updatePayload);

        if (!updateResponse.data.errors) {
          setCurrentStep(4);
        } else {
          toast.error(updateResponse.data.message);
          handleRetake();
        }
      } else {
        toast.error('Error during file upload: ' + uploadResponse.data.message);
        handleRetake();
      }
    } catch (error) {
      toast.error('Failed to upload photo. Please try again.');
    }
  };


  const handleQrScanButtonClick = () => {
    setCurrentStep(5);
  };

  return (
    <div
      style={{
        maxWidth: '805px',
        width: '100%',
      }}
    >
      <h3 className="text-center font-32">Take Photo</h3>
      <p
        className="text-center font-20 fw-medium my-24"
        style={{ color: 'rgba(0,0,0,1)' }}
      >
        Opens your camera for real-time verification.
      </p>
      <div className="takePhotoImg mx-auto">
        {!isPhotoTaken ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            className="img-fluid"
          />
        ) : (
          <img
            src={photo}
            alt="Captured"
            className="img-fluid"
            style={{ borderRadius: '10px' }}
          />
        )}
      </div>
      <div className="text-center position-sticky bottom-0 z-3 bg-white py-20">
        {!isPhotoTaken ? (
          <>
          <Button
            onClick={capturePhoto}
            variant="contained"
            color="primary"
            size="large"
            sx={{
              width: '100%',
              background: '#916008',
              textTransform: 'capitalize',
            }}
          >
            Capture Photo
          </Button>
          {/* <div className='text-center'>
                            <Button
                                sx={{
                                    background: '#FFF', border: 'none',
                                    borderRadius: '6px',
                                    color: '#916008',
                                    fontWeight: '500',
                                    display: 'flex',
                                    gap: '10px',
                                    fontFamily: 'indivisible',
                                    margin: '0px auto'
                                }}
                                onClick={handleQrScanButtonClick}
                            >
                                 or scan a QR CODE to CONTINUE in a different device
                            </Button>
                        </div> */}
          </>
        ) : (
          <>
            <Button
              onClick={handleRetake}
              variant="contained" 
              className="outLineBtn"
              sx={{
                fontSize: '18px',
                color: '#000000',
                fontWeight: '400',
                fontFamily: 'indivisible',
                margin: '10px auto',
                display: 'inline-block',
                background: '#fff',
              }}
            >
              Re-take
            </Button>
            <Button
              onClick={handleUpload}
              variant="contained"
              color="primary"
              size="large"
              sx={{
                width: '100%',
                background: '#916008',
                textTransform: 'capitalize',
              }}
            >
              Upload Photo
            </Button> 
            {/* <div className='text-center'>
                            <Button
                                sx={{
                                    background: '#FFF', border: 'none',
                                    borderRadius: '6px',
                                    color: '#916008',
                                    fontWeight: '500',
                                    display: 'flex',
                                    gap: '10px',
                                    fontFamily: 'indivisible',
                                    margin: '0px auto'
                                }}
                                onClick={handleQrScanButtonClick}
                            >
                                 or scan a QR CODE to CONTINUE in a different device
                            </Button>
                        </div> */}
          </>
        )}
      </div>
      {/* <Box textAlign="center" marginTop="20px">
        <Button
          onClick={() => setCurrentStep(3)}
          style={{
            fontSize: '14px',
            color: '#916008',
            fontWeight: '500',
            fontFamily: 'indivisible',
            marginTop: '10px',
          }}
        >
          or scan a QR code to continue in a different device
        </Button>
      </Box> */}
    </div>
  );
};

export default TakePhoto;
