import React, { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Menu, MenuItem, Fade } from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { time_ago } from "src/utils/CommonUtils"
import { Link } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { memberUpsertAction } from "../member-container/actions/MemberActions"
import { useEffect } from "react"
import { setPendingAction } from "../chat-container/actions/ChatActions"

const FavoriteProfile = ({ profile, title, showFirstHeartIcon }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth, shallowEqual)
  const [isVisible, setIsVisible] = useState(true)
  const [isHiding, setIsHiding] = useState(false)
  const [showUndo, setShowUndo] = useState(false)
  const [isFavorite, setIsFavorite] = useState((profile.userLikeActionCount > 0 || title === "My Favorites") || false)
  const [isMutualLike, setIsMutualLike] = useState(profile.isMutualLike || false)
  const [isSecondFavorite, setIsSecondFavorite] = useState(false)
  const undoTimer = useRef(null)
  const [userProfile, setUserProfile] = useState(profile.user)

  useEffect(() => {
    setIsFavorite((profile.userLikeActionCount > 0 || title === "My Favorites") || false)
    setIsMutualLike(profile.isMutualLike || false)
  }, [profile, title])

  useEffect(() => {
    setUserProfile(title === "My Favorites" ? profile.targetUser : profile.user)
  }, [title, profile])

  const handleHideProfile = () => {
    setIsHiding(true)
    setTimeout(() => {
      setIsVisible(false)
      setIsHiding(false)
      setShowUndo(true)

      undoTimer.current = setTimeout(() => {
        handleAction("hide")
        setShowUndo(false)
      }, 5000)
    }, 400)
  }

  const handleUndo = () => {
    if (undoTimer.current) {
      clearTimeout(undoTimer.current)
    }
    setIsVisible(true)
    setShowUndo(false)
  }

  const handleAction = (action) => {
    const actionId = title === "My Favorites" ? profile.targetUser._id : profile.userId
    if (action === "hide") {
      dispatch(
        memberUpsertAction({
          action: "hide",
          _id: user._id,
          data: { targetUserId: actionId, action: "HIDE" },
        }),
      )
      setIsHiding(true)
      setTimeout(() => setIsVisible(false), 300)
    }
    if (action === "favorite") {
      const actionType = isFavorite ? "UNLIKE" : "LIKE"
      dispatch(
        memberUpsertAction({
          action: actionType,
          _id: user._id,
          data: { targetUserId: actionId, action: actionType },
        }),
      )
      setIsFavorite(!isFavorite)
      setIsSecondFavorite(!isSecondFavorite)
    }
  }

  const handleInitChat = () => {
    const modifiedProfile = { ...profile,...profile.user, _id: profile.userId };//Modify the profile object coz into this _id eating id locationId and further i need userId into _id
    dispatch(setPendingAction({ data: { user: modifiedProfile, text: null } }))
    localStorage.removeItem('chatRoomId');
    navigate("/chat")
  }

  return (
    <>
      {!userProfile ? (
        <div>Loading...</div>
      ) : (
        <div className={`favorite-profile ${isHiding ? "hiding" : ""}`}>
          <div className="d-flex align-items-center w-100">
            <img
              src={userProfile?.profilePicture || "/placeholder.svg"}
              alt={userProfile?.userName}
              className="profile-img"
            />
            <div className="profile-info">
              <div className="d-flex align-items-start justify-content-between gap-1 infousrfav mb-0">
                <div className="infousrfavVh">
                  <h3 className="mb-0">
                    <span className="d-flex align-items-center text-black font-20 font-m fw-medium">
                      {`${userProfile?.userName}, ${userProfile?.age}`}
                      {userProfile?.isSubscribed && (
                        <button className="border-0 rounded-3 text-white cursor-inherit font-12 fw-medium gradientbtn ms-10 px-16 py-6">
                          Premium
                        </button>
                      )}
                    </span>
                  </h3>
                  <p className="color5f font-12 font-m fw-normal mb-0 statecitycountry">
                    {profile.country}, {profile.state}, {profile.city}
                  </p>
                  <p className="color5f font-14 font-m fw-normal mb-0 mobile-none">{userProfile?.myHeading}</p>
                </div>
                <div className="myViewedDes">
                  <span className="mobile-none mt-0 viewed-time">Viewed {time_ago(profile.activityLog.timestamp)}</span>
                  <span className="viewed-time"><span className="txDistance">Distance</span> {`${profile.distance==0?1:profile.distance}`} {user?.preferredMeasurement? "km" : "mile"} <span className="mobile-none">away</span></span>
                </div>
              </div>

            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 profile-actions">
            <div className="d-flex gap-15 userProfileStx">
              <p className="font-14 fw-normal mb-0" style={{ color: "#7B7B7B" }}>
                <span style={{ color: "#081726", fontWeight: "500" }}>Ethnicity:</span> {userProfile?.ethnicity}
              </p>
              <p className="font-14 fw-normal mb-0" style={{ color: "#7B7B7B" }}>
                <span style={{ color: "#081726", fontWeight: "500" }}>Smokes:</span> {userProfile?.smoke}
              </p>
              <p className="font-14 fw-normal mb-0" style={{ color: "#7B7B7B" }}>
                <span style={{ color: "#081726", fontWeight: "500" }}>Relationship Status:</span>{" "}
                {userProfile?.currentRelationshipStatus}
              </p>
            </div>
            <div className="d-flex gap-15">
              <div className="d-flex gap-15">
                <Link to={`/member-details/${profile.userId}`} className="mb-0 view-btn">
                  View Profile
                </Link>
                <button className="hide-btn mb-0" onClick={handleHideProfile}>
                  Hide Profile
                </button>
              </div>
              <div className="d-flex action-icons gap-15">
                {showFirstHeartIcon && (
                  <span className="first heart-icon">
                    <img
                      src="/assets/images/user/user-profile/dark-heart.svg"
                      className="img-fluid"
                      alt="First Heart Icon"
                    />
                  </span>
                )}
                <span className="heart-icon second" onClick={() => handleAction("favorite")}>
                  <img
                    src={
                      isFavorite
                        ? "/assets/images/user/user-profile/dark-heart.svg"
                        : "/assets/images/user/user-profile/blank-heart.svg"
                    }
                    className="img-fluid"
                    alt="Second Heart Icon"
                  />
                </span>
                <span className="chat-icon1" onClick={handleInitChat}>
                  <img src="/assets/images/user/user-profile/message1.svg" className="img-fluid" alt="Chat Icon" />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUndo && (
        <div
          className="text-center undo-container"
          style={{
            background: "rgba(191, 131, 0, 0.2)",
            padding: "19px 20px",
            borderRadius: "15px",
            border: "1px solid #D9D9D9",
            margin: "10px 0",
          }}
        >
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#081726",
            }}
          >
            This profile has been hidden.
          </h3>
          <button className="bg-none border-0 font-16 fw-medium" style={{ color: "#916008" }} onClick={handleUndo}>
            <span className="d-block bg-none border-0">
              <img src="/assets/images/user/user-profile/undo.svg" className="img-fluid" alt="Undo Icon" />
            </span>
            Unhide Profile on fire tablet
          </button>
        </div>
      )}
    </>
  )
}

const Favorites = ({ title,sortingValue, profiles, showFirstHeartIcon, setSort }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const menuOpen = Boolean(anchorEl)
  const [sortText, setSortText] = useState("Last Active")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const handleSortSelection = (value, text) => {
    handleCloseMenu()
    setSort(value)
    setSortText(text)
  }

  return (
    <>
      <div className="favorites-page FavProF">
        <header className="favorites-header">
          <div className="font-24 fw-medium">{title} </div>
          <div className="font-16 fw-medium">
            Sort By:
            <Button
              id="fade-button"
              className="more"
              aria-controls={menuOpen ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleClick}
              sx={{ textTransform: "capitalize", color: "#000" }}
            >
              {sortText} <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleCloseMenu}
              TransitionComponent={Fade}
            >
              <MenuItem
                className=""
                onClick={() => {
                  handleSortSelection("lastActive", "Last Active")
                }}
              >
                Last Active
              </MenuItem>

              {/* <MenuItem
                className=""
                onClick={() => {
                  handleSortSelection("age", "Age")
                }}
              >
                Age
              </MenuItem> */}
              <MenuItem
                className=""
                onClick={() => {
                  handleSortSelection("distance", "Distance")
                }}
              >
                Distance
              </MenuItem>
              <MenuItem
                className=""
                onClick={() => {
                  handleSortSelection("whenFavorited", "When Favorited")
                }}
              >
                When {sortingValue}
              </MenuItem>
            </Menu>{" "}
          </div>
        </header>
        <div className="favorites-list">
          {profiles.length === 0 ? (
            <div className="border p-20 rounded-4 text-center blankList gdidsetMobile">
              <h4>Hurray...you don't have any {title} profiles.</h4>
              <p>Click on the heart icon on any profile to add it to your favorites.</p>
            </div>
          ) : (
            profiles.map((profile, index) => (
              <FavoriteProfile key={index} title={title} profile={profile} showFirstHeartIcon={showFirstHeartIcon} />
            ))
          )}
        </div>
      </div>
    </>
  )
}

export default Favorites