import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setPayload } from 'src/containers/signup-container/actions/SignupActions';
import { isValidateDescInput } from 'src/utils/CommonUtils';
import { useEffect } from 'react';

const CreateCatchyHeading = () => {
    const dispatch = useDispatch();
    const {
      myHeading,
    } = useSelector((state) => state.SignupReducer.currentRecord, shallowEqual);
    const [text, setText] = useState(myHeading);
    const [charCount, setCharCount] = useState(myHeading?.length);
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleTextChange = (event) => {
      const value = event.target.value;
      setText(value);
      setCharCount(value.length); // Count characters
      if (value.length > 0) {
        setErrorMessage(''); // Clear the error message if text is typed
      }
    };
  
    const handleBlur = () => {
      // Add validation logic here
      if (text?.trim() !== '' && text.length < 50) {
        setErrorMessage('This is too short. It should have 50 characters or more.');
      }
      else if (text?.trim() === '') {
        setErrorMessage('You are missing your heading.');
        dispatch(setPayload({ data: { myHeading: '' } })); 
      }
      else {
        dispatch(setPayload({ data: { myHeading: text } })); 
      }
    };
      useEffect(() => {
        const isValid = text?.trim() !== '' && text.length >= 50;
        if (isValid) {
          dispatch(setPayload({ data: { myHeading: text } })); 
        }
        else {
          dispatch(setPayload({ data: { myHeading: '' } })); 
        }
      }, [text])
  return (
    <div>
    <div className="font-m font-32 fw-medium text-center">Create a Catchy Heading </div>
    <div className="font-m font-18 colorf5 pt-6 text-center">
    First impressions matter! Make your headline count with a unique detail.
    </div> 

   <div className='mt-32 mb-16'>
   <TextField className=""
      placeholder="Example Ideas: Adventure seeker looking for my next journey."
      variant="outlined"  
      fullWidth
      value={text}
      onChange={handleTextChange}
      onBlur={handleBlur} // Trigger when the field loses focus
      inputProps={{ maxLength: 150 }}  // Limit to 50 characters
      
      sx={{
        marginTop: 2,
        '& .MuiOutlinedInput-root': {
          borderRadius: '15px',
        },
        height: '44px',
        '& .MuiOutlinedInput-root': {
          height: '44px',
          borderRadius: '15px',
          '& fieldset': {
            borderColor: '#ccc',
          },
          '&:hover fieldset': {
            borderColor: '#916008',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#916008',
            borderWidth: '1px',
          },
        },
      }}
    />
    
   <div className='d-flex justify-content-between align-items-start mb-16'>  
    {/* Show error message if the field is empty when it loses focus */}
   <div className=""> {errorMessage && (
      <Typography variant="body2" color="error" align="center" sx={{ marginTop: 1 }}>
        {errorMessage}
      </Typography>
    )}</div>
    <Typography variant="body2" color="textSecondary" align="right" sx={{ marginTop: 1 }}>
      {charCount} / 150 characters
    </Typography>
    </div></div> 
  </div>
  )
}

export default CreateCatchyHeading
