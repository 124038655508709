import React, { useState } from "react"; 
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton'; 
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Modal, Box, MenuItem, Button } from '@mui/material';
import MembershipPlans from "./MembershipPlans";
import MembershipPlansList from "./MembershipPlansList";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 4,
};

const SubscriptionPage = () => {
const [open, setOpen] = useState(null);
  const handleOpen = (type) => setOpen(type);
  const handleClose = () => setOpen(null);


  const [openMainModal, setOpenMainModal] = useState(false);

  const handleOpenMain = () => setOpenMainModal(true);
  const handleCloseMain = () => setOpenMainModal(false);

    const planData = {
        planName: "Gold Plan",
        price: "100 USD",
        nextPaymentDate: "October 10th, 2025",
        benefits: [
          "Unlock conversations",
          "Hide online status & last login details",
          "Premium member perks"
        ],
        upgradeLink: "#",
        cancelLink: "#"
      };
    
      const offerData = {
        discountTitle: "Get 20% Off",
        discountSubtitle: "on Annual Plans!",
        benefits: [
          "Private Browsing",
          "Featured on Attractive Members' Dashboard",
          "Priority Response from Elite Members",
          "Luxury Badge for Instant Recognition"
        ],
        buttonText: "Upgrade Now & Save 20%!"
      };


      

  return (
    <>
    <div className="container">
      <div className="">
      <div className="">
            <h2 className="title ac-heading font-hding">No Active Subscription </h2>
          <p className="subtitle">
          You currently don't have an active subscription. Upgrade to a Gold or Luxury plan to enjoy exclusive benefits
          </p> 
          <p className="fw-bold font-16 text-center unlockPAcc py-24">Unlock Premium Access!</p>
         </div>
         <MembershipPlansList />
      </div>


         <div className="d-flex justify-content-between align-items-start">
         <div className="">
            <h2 className="title font-hding">My subscription</h2>
          <p className="subtitle">
            Manage your plan, update payment details, or explore exclusive upgrades.
          </p> 
         </div>
         <div className="">
         <Dropdown>
      <MenuButton><MoreHorizIcon/></MenuButton>
      <Menu placement="bottom-end" >
        <MenuItem onClick={handleOpen}>Cancel subscription renewal </MenuItem>
        <MenuItem>Switch to a higher plan</MenuItem> 
      </Menu>
    </Dropdown>
         </div>
         </div>

      <div className="subscription-wrapper MySubcriptionPlan d-flex justify-content-between gap-3">
        {/* Subscription Plan Card */}
        <div className="subscription-card w-100 border rounded-4 p-4"> 
          <div className="plan-box">
            <div className="d-flex align-items-center justify-content-between font-14 mb-5 CurrentActive">Current Active Plan <CheckCircleIcon /></div>
            <h3 className="plan-title font-24 fw-semibold  mb-5">
              <span className="gold-icon"><img src="/assets/images/user/gold-plan-icon.svg" alt="Plan Icon" style={{width: '40px',height: '40px'}} /></span> {planData.planName}
              <span className="plan-price fw-normal"> {planData.price} </span><span className="font-14 fw-normal">/month</span>
            </h3>

            <p className="next-payment mb-5">
              <strong>Next Payment:</strong> <span>{planData.nextPaymentDate}</span>
            </p>

            <h4 className="benefits-title">Current Benefits</h4>
            <ul className="benefits-list mb-5">
              {planData.benefits.map((benefit, index) => (
                <li key={index}>✔ {benefit}</li>
              ))}
            </ul>

            <div className="links">
              <button className="blue-link d-block" onClick={handleOpenMain}>Switch to a higher plan</button>
              {/* <a href={planData.cancelLink} className="red-link d-block">Cancel subscription renewal</a> */}
            </div>
          </div>
        </div>

        {/* Offer Section */}
        <div className="offer-card  w-100  border rounded-4 p-4">
          <h3 className="offer-title  text-white">{offerData.discountTitle}</h3>
          <p className="offer-subtitle text-white">{offerData.discountSubtitle}</p>

          <h4 className="offer-benefits-title text-white mt-5 mb-0">Experience True Exclusivity</h4>
          <ul className="offer-benefits-list">
            {offerData.benefits.map((benefit, index) => (
              <li className="text-white" key={index}>✔ {benefit}</li>
            ))}
          </ul> 
          <button className="upgrade-button">{offerData.buttonText}</button>
        </div>
      </div>
    </div>
    <MembershipPlans open={openMainModal} handleClose={handleCloseMain} />

 <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="respWSet"
      >
        <Box className="modalsetM0"
          sx={{
            ...style,
            maxHeight: '80vh', // Adjust as needed
            overflowY: 'auto', // Enables vertical scroll
          }}
        >
 <Box className="d-flex modalsetM1 align-items-start justify-content-center">
                  <div
                    className="modal-title modalsetM1_1 text-black text-center font-32 fw-medium"
                    id="exampleModalLabel"
                  >
                   Cancel Subscription? 
                  </div>
                </Box>
                <p className="modal-title text-center text-color3c font-16 fw-normal mb-16 mt-10">
                Are you sure you want to cancel your subscription? By canceling, you will lose access to premium features, including
                </p>
                <p className="modal-title text-center text-color3c font-16 fw-bold mb-16 mt-10"><span className="d-block">Looking for a better plan?</span> 
                Upgrade to the Luxury Plan at a discounted rate!</p>
  <div className="d-flex flex-column justify-content-between text-center gap-2 mt-32 popup-actions">
            <Button className="modalsetM4_1" variant="contained" 
              sx={{
                width: '100%', position: 'sticky', bottom: '0',
                backgroundColor: '#916008',
                padding: '10px',
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': { backgroundColor: '#9d6f3b' },
              }}
            >
              Cancel Anyway 
            </Button>
            <Button className="modalsetM4_1 mt-3"  variant="contained" onClick={handleClose}
              sx={{
                maxWidth: '240px', width: '100%', margin: '0 auto',
                backgroundColor: '#fff',
                border: '1px solid #081726',
                padding: '10px', boxShadow: 'none',
                color: '#081726',
                textTransform: 'capitalize',
                '&:hover': { backgroundColor: '#9d6f3b', color: '#fff', border: '1px solid #9d6f3b', },
              }}
            >
             Keep My Subscription
            </Button>
          </div>
        </Box>
        </Modal>

    </>
  );
};

export default SubscriptionPage