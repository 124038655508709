import config from 'src/config';
import { postRequest } from 'src/utils/RequestUtil';

export const favoriteSearchAPI = function (data) {
    const url = `${config.baseUrl}home/get-my-favorite`;
    return postRequest({
        url,
        data,
    });
};
export const favoritedSearchAPI = function (data) {
    const url = `${config.baseUrl}home/get-favorite/favorited`;
    return postRequest({
        url,
        data,
    });
};
export const viewedSearchAPI = function (data) {
    const url = `${config.baseUrl}home/get-favorite/viewed`;
    return postRequest({
        url,
        data,
    });
};
 